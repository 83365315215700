import React, { useEffect, useState } from 'react'
import { Card, Container, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap'
import { bulkOrdersDelete, bulkOrdersList, fetchShippingServices } from '../../../redux/services/ServicesThunk';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Pagination, Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "../../../assets/styles/bulkOrders/bulkOrders.css"
import AddressCard from '../../../components/profileComponent/UserAddressCards';
import { resetErrors } from '../../../redux/landing/landingSlice';
import { deletecartdetail, fetchCountryDetails } from '../../../redux/landing/landingThunk';
import { getParcels, getcart } from '../../../redux/user_profile/ProfileThunk';
import { formatPrice } from '../../../utils/formatPrice';
import BulkOrderPreviewModal from './BulkOrderPreviewModal';
import SwalFireComponent from '../../../components/common/SwalFireComponent';
import BulkOrderBookServiceModal from './BulkOrderBookServiceModal';
import { fetchAddressList } from '../../../redux/address/adressThunk';
import BulkOrderPreviewSkeleton from './BulkOrderPreviewSkeleton';
import { Link, useNavigate } from 'react-router-dom';
import SelectDateTime from '../../../components/addressBook/SelectDateTime';
import AccordianCustomDetails from '../../../components/accordianAddressbook/AccordianCustomDetails';
import Swal from 'sweetalert2';

const BulkOrderPreview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uniquesessionid = localStorage.getItem("uniquesessionid");
    const userData = JSON.parse(localStorage.getItem("user"));
    const serviceData = JSON.parse(localStorage.getItem("serviceData"));

    const BulkOrderPreviewDataPending = useSelector(
        (state) => state?.servicedata?.bulkorderDataList?.BulkOrderData?.data?.pending
    );
    const BulkOrderPreviewDataCompleted = useSelector(
        (state) => state?.servicedata?.bulkorderDataList?.BulkOrderData?.data?.completed
    );
    const isbulkorderListLoading = useSelector(
        (state) => state?.servicedata?.isbulkorderListLoading
    );

    const parcel_details = useSelector(
        (state) => state?.profileData?.parcelData?.Parcel?.data
    );

    const isbulkorderDeleteLoading = useSelector(
        (state) => state?.servicedata?.isbulkorderDeleteLoading
    );

    const userAddressList = useSelector(
        (state) => state?.addressValidation?.addressList?.CustomerAddress?.data
    );

    const dropoffdata = useSelector(
        (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location
    );

    const pickupdatanew = useSelector(
        (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.pickup_date
    );

    const dropoffdatanew = useSelector(
        (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location?.date
    );

    const shipmentDataNew = useSelector(
        (state) => state?.servicedata.ShippingService?.data
    );

    const bookservicedata = useSelector(
        (state) => state?.servicedata.ShippingService?.data
    );

    const bookserviceloading = useSelector(
        (state) => state?.servicedata?.isServiceLoading
    );

    const bookserviceError = useSelector(
        (state) => state?.servicedata?.ShippingService?.error
    );

    const bookserviceErrors = useSelector(
        (state) => state?.servicedata?.ShippingService?.errors
    );
    const ispickupdropLoading = useSelector(
        (state) => state?.servicedata?.isPickupLoading
    );

    const cartData = useSelector(
        (state) => state?.profileData?.cartData?.cartData?.data
    );

    const country_Name = useSelector(
        (state) => state.landingReducer?.countryDeatils?.data
    );

    const iscartloading = useSelector(
        (state) => state?.profileData?.isCartDataLoading
    );

    const isDeleteCardLoading = useSelector(
        (state) => state?.landingReducer?.isDeleteCardLoading
    );

    const dropdowndata = [
        "Sale or Purchase",
        "Gift",
        "Sample",
        "Repair/Return",
        "Documents",
        "Intracompany Transfer",
        "Temporary Export",
        "Personal Effect ",
    ];

    const [collectionAddress, setCollectionAddress] = useState()
    const useriD = JSON.parse(localStorage.getItem("user"));
    const [selectData, setSelectData] = useState();
    // const [parcelData, setParcelData] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showAddressModal, setshowAddressModal] = useState(false);
    const [showModalService, setShowModalService] = useState(false);
    const [showpickupdrop, setshowpickupdrop] = useState(false);
    const [selectedBulkData, setSelectedBulkData] = useState(null);
    const [deleteId, setDeleteId] = useState()
    const [bookingId, setbookingId] = useState()
    const [cancelServiceid, setcancelServiceid] = useState()
    const [bookserviceid, setbookserviceid] = useState()
    const [allerrors, setAllerrors] = useState('')
    const [bookServiceLoad, setBookserviceloading] = useState(false)
    const [bookServiceLoadingPopup, setbookServiceLoadingPopup] = useState(false)
    const [collectionDates, setCollectionDates] = useState();
    const [collectionValues, setCollectionValues] = useState();
    const [dropupDates, setDropupDates] = useState();
    const [serviceDataNewFromApi, setserviceDataNewFromApi] = useState()
    const [selectedCollectionService, setSelectedCollectionService] = useState("Drop");
    const [pickupValidation, setPickupValidation] = useState(false);
    const [isinsuranceopen, setisinsuranceopen] = useState(false);
    const [key, setKey] = useState("pending");
    const [continueBulk, setContinueBulk] = useState(false)
    const [submitBulk, setSubmitBulk] = useState(false)
    const [vatdata, setVatdata] = useState({
        countryInput: "Canada",
        Reason: dropdowndata[0],
    });
    const [formData, setFormData] = useState({
        unit_value: '',
        parcel_weight: '',
        parcel_length: '',
        parcel_width: '',
        parcel_height: '',
        parcel_weight_in: 'LBS',
        parcel_size_in: 'IN'
    });
    const [selectedRadio, setSelectedRadio] = useState(serviceData?.cart_details?.is_enhanced_cover === "Yes" ? "Yes" : "No");
    const [formShipData, setFormShipData] = useState({
        insuranceRadio: serviceData?.cart_details?.is_enhanced_cover === "Yes" ? "Yes" : "No",
    });
    const hasDefaultAddress = userAddressList && userAddressList?.some(i => i?.is_default === "Yes");

    useEffect(() => {
        const cart_id = localStorage.getItem("cart_id");
        const cartdetails_id = localStorage.getItem("cartdetail_id");
        const newdata = {
            cartdetail_id: cartdetails_id,
            cart_id: cart_id,
            customer_id: useriD?.id,
            has_drop_off_service:
                selectedCollectionService === "Drop" ? "Yes" : "No",
            collection_date:
                selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew?.length && pickupdatanew[0]?.label : "",
            collection_value:
                selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew?.length && pickupdatanew[0]?.value : "",
            drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew && dropoffdatanew[0] : "",
            shipContent: [{
                id: serviceDataNewFromApi?.cart_details?.cart_package?.length && serviceDataNewFromApi?.cart_details?.cart_package[0]?.id,
                contents: selectedBulkData?.parcel_description || '',
                ship_value: selectedBulkData?.parcel_value,
            }],
            insurance: formShipData?.insuranceRadio,
            channelType: 'Bulk',
            channel_order_id: bookserviceid
        }
        dispatch(fetchShippingServices(newdata)).unwrap().then((response) => {
            if (response.status === true && response.statusCode === 200) {
                setserviceDataNewFromApi(response?.data)
                localStorage.setItem("serviceData", JSON.stringify(response?.data));
            }
        });
    }, [formShipData])

    useEffect(() => {
        if (userAddressList) {
            const data = userAddressList?.find((i) => {
                return i.is_default === "Yes"
            })
            setCollectionAddress(data)
        }
    }, [userAddressList])

    useEffect(() => {
        dispatch(bulkOrdersList())
            .unwrap().then((response) => {
                if (response?.BulkOrderData?.status === "success" && response?.BulkOrderData?.code === "200") {
                    let data = {
                        // cart_id: Number(cartId),
                        customer_id: userData?.id,
                    };
                    dispatch(getcart(data));
                }
            })
    }, [dispatch]);

    useEffect(() => {
        if (BulkOrderPreviewDataPending) {
            setFormData(BulkOrderPreviewDataPending);
            dispatch(
                fetchAddressList({
                    // address_id: formData?.address_id,
                    customer_id: useriD.id,
                    uniquesessionid: uniquesessionid,
                })
            );
        }
    }, [BulkOrderPreviewDataPending]);

    useEffect(() => {
        if (parcel_details && parcel_details.length > 0) {
            const select = {
                parcel_name: "Select",
            };
            const updatedParcelDetails = [select, ...parcel_details];
            setSelectData(updatedParcelDetails);
        }
    }, [parcel_details]);

    useEffect(() => {
        dispatch(resetErrors());
        localStorage.removeItem("searchData");
        localStorage.removeItem("cartdetail_id");
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        dispatch(fetchCountryDetails());
        dispatch(getParcels({ customer_id: useriD?.id }));
    }, [dispatch]);


    const handleContinueClick = () => {
        setContinueBulk(true)
        const cart_id = localStorage.getItem("cart_id");
        const cartdetails_id = localStorage.getItem("cartdetail_id");
        let data = {
            cartdetail_id: cartdetails_id,
            cart_id: cart_id,
            customer_id: useriD?.id,
            has_drop_off_service:
                selectedCollectionService === "Drop" ? "Yes" : "No",
            collection_date:
                selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew[0]?.label : "",
            collection_value:
                selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew[0]?.value : "",
            drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew[0] : "",
            insurance: formShipData?.insuranceRadio,
            channelType: 'Bulk',
            channel_order_id: bookserviceid,
            has_customdoc: bookservicedata?.cart_details
                ?.collection_country_id === bookservicedata?.cart_details
                    ?.delivery_country_id ? "No" : "Yes",
            // shipContent: serviceData?.cart_details?.edit && data1,
            // shipcontent: [{
            //     id: response?.data?.cart_details?.cart_package[0]?.id,
            //     contents: selectedBulkData?.parcel_description || '',
            //     ship_value: selectedBulkData?.parcel_value,
            // }]
        };
        dispatch(fetchShippingServices(data))
            .unwrap()
            .then((response) => {
                if (response.status === true && response.statusCode === 200) {
                    localStorage.setItem("DropOffData", JSON.stringify(data));
                    localStorage.setItem("serviceData", JSON.stringify(response?.data));
                    setserviceDataNewFromApi(response?.data)

                    const newdata = {
                        cartdetail_id: cartdetails_id,
                        cart_id: cart_id,
                        customer_id: useriD?.id,
                        has_drop_off_service:
                            selectedCollectionService === "Drop" ? "Yes" : "No",
                        collection_date:
                            selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew[0]?.label : "",
                        collection_value:
                            selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew[0]?.value : "",
                        drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew[0] : "",
                        shipContent: [{
                            id: response?.data?.cart_details?.cart_package[0]?.id,
                            contents: selectedBulkData?.parcel_description || '',
                            ship_value: selectedBulkData?.parcel_value,
                        }],
                        insurance: formShipData?.insuranceRadio,
                        channelType: 'Bulk',
                        channel_order_id: bookserviceid
                    }
                    dispatch(fetchShippingServices(newdata)).unwrap().then((response) => {
                        setContinueBulk(false)
                        if (response.status === true && response.statusCode === 200) {
                            setisinsuranceopen(true)
                            setserviceDataNewFromApi(response?.data)
                            localStorage.setItem("serviceData", JSON.stringify(response?.data));
                        }
                    });
                } else if (response.statusCode === 440 && response.status === false) {
                    // navigate("/service");
                    setContinueBulk(false)
                }
            });
    }

    const finalsubmit = () => {
        setSubmitBulk(true)
        const datad = country_Name?.find((i) => {
            return i?.name === vatdata?.countryInput;
        });

        const cart_id = localStorage.getItem("cart_id");
        const cartdetails_id = localStorage.getItem("cartdetail_id");
        const newdata = {
            cartdetail_id: cartdetails_id,
            cart_id: cart_id,
            customer_id: useriD?.id,
            has_drop_off_service:
                selectedCollectionService === "Drop" ? "Yes" : "No",
            collection_date:
                selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew[0]?.label : "",
            collection_value:
                selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew[0]?.value : "",
            drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew[0] : "",
            shipContent: [{
                id: serviceDataNewFromApi?.cart_details?.cart_package[0]?.id,
                contents: selectedBulkData?.parcel_description || '',
                ship_value: selectedBulkData?.parcel_value,
            }],
            insurance: formShipData?.insuranceRadio,
            is_completed: true,
            channelType: 'Bulk',
            channel_order_id: bookserviceid,
            has_customdoc: bookservicedata?.cart_details
                ?.collection_country_id === bookservicedata?.cart_details
                    ?.delivery_country_id ? "No" : "Yes",


        }
        if (bookservicedata?.cart_details
            ?.collection_country_id !== bookservicedata?.cart_details
                ?.delivery_country_id) {
            newdata.reason_for_export = vatdata?.Reason;
            newdata.country_of_manufacture = datad?.id;
        }
        dispatch(fetchShippingServices(newdata))
            .unwrap()
            .then((response) => {
                setSubmitBulk(false)
                if (response.status === true && response.statusCode === 200) {
                    setbookserviceid()
                    let data = {
                        // cart_id: Number(cartId),
                        customer_id: userData?.id,
                    };
                    dispatch(getcart(data));
                    dispatch(bulkOrdersList())
                    setserviceDataNewFromApi(response?.data)
                    localStorage.setItem("serviceData", JSON.stringify(response?.data));
                    setSelectedCollectionService('Drop')
                    setCollectionValues('')
                    setCollectionDates('')
                    setshowpickupdrop(false)
                    setShowModalService(false)
                }
            })
            .catch((error) => {
                setSubmitBulk(false)
            })
    }

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setSelectedRadio(value);

        // If 'Yes' is selected, update formShipData with relevant data
        if (value === "Yes") {
            setFormShipData({
                ...formShipData,
                insuranceRadio: "Yes",
            });

            // setShipContent({
            //   ...shipContent,
            //   insurance: "Yes",
            // })
        } else {
            setFormShipData({
                ...formShipData,
                insuranceRadio: "No",
            });
            // setShipContent({
            //   ...shipContent,
            //   insurance: "No",
            // });
            // setShipContent({
            //   ...shipContent,
            //   insurance: "Yes",
            // })
        }
    };

    const validationSchema = Yup.object({
        unit_value: Yup.number().required('required'),
        parcel_weight: Yup.number().required('required'),
        parcel_length: Yup.number().required('required'),
        parcel_width: Yup.number().required('required'),
        parcel_height: Yup.number().required('required'),
    });

    const formik = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
        },
    });

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [index]: { ...formData[index], [name]: value }
        }));
    };

    const handleBookClick = (bulkData, index) => {
        if (hasDefaultAddress) {
            setbookingId(bulkData?.id)
            setBookserviceloading(true)
            setSelectedBulkData(bulkData)
            setShowModalService(true);
            setbookserviceid(bulkData?.id)
            setSelectedCollectionService("Drop")
            setCollectionValues()
            setCollectionDates()
        } else {
            setbookserviceid(bulkData?.id)
            setbookingId(bulkData?.id)
            setAllerrors('Please add collection address')
        }

    }

    const handlecancelService = (bulkData) => {
        setcancelServiceid(bulkData?.id)
        let finalactivedata = {
            cart_id: bulkData?.cart_details?.cart_id,
            cartdetail_id: bulkData?.cart_details?.id,
            customer_id: useriD?.id,
        };
        dispatch(deletecartdetail(finalactivedata))
            .then((response) => {
                if (
                    response?.payload?.status === true &&
                    response?.payload?.statusCode === 200
                ) {
                    dispatch(bulkOrdersList())
                    let data = {
                        // cart_id: Number(cartId),
                        customer_id: userData?.id,
                    };
                    dispatch(getcart(data));
                    // SwalFireComponent({
                    //     icon: "success",
                    //     title: "Cart Remove Successfully",
                    // });
                }
                if (response?.payload?.status === false && response?.payload?.statusCode === 500) {
                    Swal.fire({
                        icon: "warning",
                        title: "Oops...",
                        text: "Something went wrong! please try some time later",
                        timer: 5000,
                    }).then((result) => {
                        if (
                            result.isConfirmed ||
                            result.dismiss === Swal.DismissReason.timer
                        ) {
                            // navigate("/");
                            window.location.reload()
                        }
                    });
                }
            })
            .catch((error) => { });
    }

    const handleEditClick = (bulkData) => {
        // handlecancelService(bulkData)
        setSelectedBulkData(bulkData)
        setShowModal(true);
        // dispatch(bulkOrdersList())
    }

    const handleDelete = (bulkData) => {
        setDeleteId(bulkData?.id)
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success me-3 px-4 ",
                cancelButton: "btn btn-danger me-2 px-4",
            },
            buttonsStyling: false,
            // confirmButtonColor: "#113468"
        });
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete this items ?",
                text: "This action is irreversible and will permanently delete the items.",
                icon: "warning",
                showCancelButton: true,
                // confirmButtonColor: "#113468",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire({
                        title: "Deleted",
                        text: "Item deleted successfully",
                        icon: "warning",
                    });
                    dispatch(bulkOrdersDelete({ cart_id: bulkData?.id })).unwrap().then((response) => {
                        if (response.BulkOrder.statusCode === 200 && response.BulkOrder.status === "success") {
                            SwalFireComponent({ icon: "success", title: "Cart item delete successfully" });
                            dispatch(bulkOrdersList())
                            let data = {
                                // cart_id: Number(cartId),
                                customer_id: userData?.id,
                            };
                            dispatch(getcart(data));
                        }
                    });
                }
            })
    }

    const handleDeleteAll = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success me-3 px-4",
                cancelButton: "btn btn-danger me-2 px-4",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete all items ?",
                text: "This action is irreversible and will permanently delete all the items.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire({
                        title: "Deleted",
                        text: "Item deleted successfully",
                        icon: "warning",
                    });
                    dispatch(bulkOrdersDelete({ cart_id: `delete-all-${key}` })).unwrap().then((response) => {
                        if (response.BulkOrder.statusCode === 200 && response.BulkOrder.status === "success") {
                            SwalFireComponent({ icon: "success", title: "Cart item delete successfully" });
                            dispatch(bulkOrdersList())
                            let data = {
                                // cart_id: Number(cartId),
                                customer_id: userData?.id,
                            };
                            dispatch(getcart(data));
                        }
                    });
                }
            })
    }

    const handleCloseModal = () => {
        setshowpickupdrop(false)
        setshowAddressModal(false);
        setShowModal(false);
        // setShowModalService(false)
        setisinsuranceopen(false)
        setSelectedCollectionService("Drop")
        setCollectionValues()
        setCollectionDates()
        setShowModalService(false)
        dispatch(resetErrors())
        // setBookserviceloading(false)
        // setbookingId()
        // setbookserviceid()


    };
    const setopenaddressmodel = () => {
        setshowAddressModal(true);
    };

    const cardData = [
        { text: 'Optimize your shipment in one click!' },
        { text: 'Update courier, dates, & parcels effortlessly' },
        { text: 'Perfect for same-country shipments' },
        { text: 'Convenience with bulk uploads' }
    ];
    const CourierPartners = ["Select", "FEDEX", "UPS", "USPS", "DHL"]
    const courierIdMap = {
        "FEDEX": 1,
        "UPS": 2,
        "DHL": 3,
        "USPS": 4,
    };

    const handleParcelSelect = (selectedParcelIndex) => {
        const selectedParcel = selectData[selectedParcelIndex];
        formik?.setValues({
            ...formik?.values,
            unit_value: selectedParcel.unit_value || "1",
            parcel_weight: selectedParcel?.parcel_weight,
            parcel_length: selectedParcel?.parcel_length,
            parcel_width: selectedParcel?.parcel_width,
            parcel_height: selectedParcel?.parcel_height,
            parcel_size_in: selectedParcel?.parcel_dimension_in,
            parcel_weight_in: selectedParcel?.parcel_weight_in
        });
    };

    const handleTabChange = (selectedKey) => {
        // setShowError({ auto: false, add: false, autoamount: false, dropdown: false });
        setKey(selectedKey);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPagecomplete, setCurrentPagecomplete] = useState(1);

    const filteredDataCount = BulkOrderPreviewDataPending?.length;
    const filteredDataCountcomplete = BulkOrderPreviewDataCompleted?.length;

    const itemsPerPage = 30;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handlePageChangecomplete = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return BulkOrderPreviewDataPending?.slice(startIndex, endIndex);
    };
    const getCurrentPageDatacomplete = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return BulkOrderPreviewDataCompleted?.slice(startIndex, endIndex);
    };


    const [isChecked, setIsChecked] = useState(false);
    const handleSelectChange = (event) => {
        const selectedCourierPartner = event.target.value;
        const selectedCourierId = courierIdMap[selectedCourierPartner] || "";

        setFormData(prevFormData => ({
            ...prevFormData,
            Courier_Partners: selectedCourierPartner,
            courier_id: selectedCourierId
        }));
    };
    const handleChangecheckbox = (e, index) => {
        setIsChecked(e.target.checked);
    };

    return (
        <>
            <div className="pickupdrop-outer">
                <Container className='p-1'>
                    {BulkOrderPreviewDataPending ? (
                        <>
                            <div className={`my-5 ${bookserviceloading && "py-5"}`}>
                                {/* {!bookServiceLoad ? ( */}
                                <>
                                    <div className=' d-flex flex-column flex-xl-row gap-4 px-4'>
                                        <div style={{ flex: "4" }} className=' d-flex flex-column gap-3 '>
                                            {/* {BulkOrderPreviewDataPending?.length > 1 && <> */}

                                            <div className="d-flex align-items-center ">
                                                <div className="me-3">
                                                    <p className="collectoinh5 blue-text">
                                                        <span>Master Details</span>
                                                    </p>
                                                </div>
                                                <div className="hrlinedivdelivery">
                                                    <hr className="hrlinedelivery" />
                                                </div>
                                            </div>

                                            <div className='d-flex gap-3 bulk_cards_outer '>
                                                {cardData.map((card, index) => (
                                                    <Card className='card-border bulk_info_card' style={{ flex: "1" }} key={index}>
                                                        <Card.Body className=' d-flex align-items-center p-3'>
                                                            <div className='d-flex gap-2'>
                                                                <div className='' style={{ flex: "1" }}>
                                                                    <div className=' bulk-round rounded-5 h-100' style={{ width: '60px', height: '60px' }}></div>
                                                                </div>
                                                                <div className='' style={{ flex: "2" }}>
                                                                    <div>
                                                                        <p className='paragraph2 bulk-card-text'>
                                                                            {card.text}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                ))}
                                            </div>

                                            <div>
                                                {/*   <Card className='card-border'>
                                                        <Card.Body className=''>
                                                            <form onSubmit={formik.handleSubmit}>
                                                                <div className='d-flex flex-column gap-2'>
                                                                    <div>
                                                                        <h6 className='m-0'>Step-01</h6>
                                                                    </div>

                                                                    <div className='d-flex gap-3'>
                                                                        <Form.Group className="w-100" style={{ flex: "3" }}  >
                                                                            <Form.Label className="paragraph2 p-multihead mt-4 mt-xl-0">
                                                                                Parcels
                                                                            </Form.Label>
                                                                            <Form.Select value={selectData?.parcel_name} className={`wallet-input is-valid-paper height-50 ${parcelData?.parcel_name ? "testclass" : "pickup-placeholder-select"}`}
                                                                                onChange={(e) => handleParcelSelect(e.target.value)}  >
                                                                                {selectData ? (
                                                                                    selectData.map((options, index) => (
                                                                                        <option key={index} value={index} disabled={index === 0} selected={index === 0} >
                                                                                            {options?.parcel_name}
                                                                                        </option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option disabled>
                                                                                        No Parcel Saved...
                                                                                    </option>
                                                                                )}
                                                                            </Form.Select>
                                                                        </Form.Group >

                                                                        <Form.Group className="w-100" style={{ flex: "1" }}>
                                                                            <Form.Label className="paragraph2 p-multihead mt-4 mt-xl-0">
                                                                                Quantity
                                                                            </Form.Label>
                                                                            <FormControl type="number" name="unit_value" placeholder="01" className={`form-control multiship-fields height-50 ${formik.touched.unit_value && formik.errors.unit_value ? 'is-invalid-paper ' : 'is-valid-paper'}`}
                                                                                value={formik.setFieldValue.unit_value || "1"}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            {formik.touched.unit_value && formik.errors.unit_value && (
                                                                                <div className="text-danger">{formik.errors.unit_value}</div>
                                                                            )}
                                                                        </Form.Group>
                                                                        <div className="d-flex gap-4  " style={{ flex: "1" }}>
                                                                            <Form.Group className="w-100">
                                                                                <p className="paragraph2 p-multihead">
                                                                                    Weight
                                                                                </p>
                                                                                <div className="d-flex flex-column position-relative">
                                                                                    <FormControl
                                                                                        type="number"
                                                                                        name="parcel_weight"
                                                                                        placeholder="240"
                                                                                        className={`w-100 multiship-fields ${formik.touched.parcel_weight && formik.errors.parcel_weight ? 'is-invalid-paper ' : 'is-valid-paper'}`}
                                                                                        value={formik.values.parcel_weight}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                    />
                                                                                    <Form.Select
                                                                                        className="bg-transparent form-control border-0 position-absolute w-auto ps-3 multiship-fields multiship-select-in-lbs"
                                                                                        size="sm"
                                                                                        name="parcel_weight_in"
                                                                                        value={formik.values.parcel_weight_in}
                                                                                        onChange={formik.handleChange}
                                                                                    >
                                                                                        <option value="LBS">LBS</option>
                                                                                        <option value="KG">KG</option>
                                                                                    </Form.Select>
                                                                                    {formik.touched.parcel_weight && formik.errors.parcel_weight && (
                                                                                        <div className="text-danger ">{formik.errors.parcel_weight}</div>
                                                                                    )}
                                                                                </div>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-row flex-xxl-row gap-3">



                                                                        <div className=" " style={{ flex: "3" }}>
                                                                            <p className="paragraph2 p-multihead">
                                                                                Size
                                                                            </p>
                                                                            <Form.Group className="size-formgroup-multiship">
                                                                                <div className="d-flex gap-3">
                                                                                    <div className="d-flex" style={{ flex: "4" }}>
                                                                                        <div className="d-flex" style={{ flex: "1" }}>
                                                                                            <div className="w-100">
                                                                                                <FormControl
                                                                                                    type="number"
                                                                                                    name="parcel_length"
                                                                                                    placeholder="length"
                                                                                                    className={`multiship-fields  ${formik.touched.parcel_length && formik.errors.parcel_length ? 'is-invalid-paper ' : 'is-valid-paper'}`}
                                                                                                    value={formik.values.parcel_length}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                />
                                                                                                {formik.touched.parcel_length && formik.errors.parcel_length && (
                                                                                                    <div className="text-danger">{formik.errors.parcel_length}</div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <CloseIcon style={{ color: "#bababa", paddingLeft: "0px !important", paddingRight: "0px !important", height: "20px !important", width: "20px", marginTop: "14px" }} />
                                                                                        </div>
                                                                                        <div className="d-flex" style={{ flex: "1" }}>
                                                                                            <div className="w-100">
                                                                                                <FormControl
                                                                                                    type="number"
                                                                                                    name="parcel_width"
                                                                                                    placeholder="width"
                                                                                                    className={`multiship-fields  ${formik.touched.parcel_width && formik.errors.parcel_width ? 'is-invalid-paper' : 'is-valid-paper'}`}
                                                                                                    value={formik.values.parcel_width}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                />
                                                                                                {formik.touched.parcel_width && formik.errors.parcel_width && (
                                                                                                    <div className="text-danger">{formik.errors.parcel_width}</div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <CloseIcon style={{ color: "#bababa", paddingLeft: "0px !important", paddingRight: "0px !important", height: "20px !important", width: "20px", marginTop: "14px" }} />
                                                                                        </div>
                                                                                        <div className="d-flex flex-column" style={{ flex: "1" }}>
                                                                                            <div className="w-100">
                                                                                                <FormControl
                                                                                                    type="number"
                                                                                                    name="parcel_height"
                                                                                                    placeholder="height"
                                                                                                    className={`multiship-fields  ${formik.touched.parcel_height && formik.errors.parcel_height ? 'is-invalid-paper ' : 'is-valid-paper'}`}
                                                                                                    value={formik.values.parcel_height}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                />
                                                                                                {formik.touched.parcel_height && formik.errors.parcel_height && (
                                                                                                    <div className="text-danger">{formik.errors.parcel_height}</div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='ms-3' style={{ flex: "1" }}>
                                                                                            <div className="h-100" style={{ flex: "1" }}>
                                                                                                <Form.Select
                                                                                                    className="multiship-fields multiship-select-in-lbh is-valid-paper"
                                                                                                    size="sm"
                                                                                                    name="parcel_size_in"
                                                                                                    value={formik.values.parcel_size_in}
                                                                                                    onChange={formik.handleChange}
                                                                                                >
                                                                                                    <option value="IN">IN</option>
                                                                                                    <option value="CM">CM</option>
                                                                                                </Form.Select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ flex: "1" }}>
                                                                                        <Button type="submit" className={`secondry btn text-nowrap height-50 w-100`} variant="outlined" onClick={handleDeleteAll} >
                                                                                            <>
                                                                                                <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/deletebulk.svg`} />
                                                                                                Apply to All
                                                                                            </>
                                                                                        </Button>
                                                                                    </div>
                                                                                    <div style={{ flex: "1" }}>
                                                                                        <Button className={`text-nowrap secondry btn height-50 w-100 `} variant="outlined" onClick={handleDeleteAll} >
                                                                                            <>
                                                                                                <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/deletebulk.svg`} />
                                                                                                Delete
                                                                                            </>
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </Card.Body>
                                                    </Card>*/}
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <Form.Group controlId="formGridfirstname">
                                                    <Form.Label className="formlabel">
                                                        <p className=" of-black-dark">Courier Partner</p>
                                                    </Form.Label>
                                                    <Form.Select
                                                        placeholder='Select'
                                                        className={`Pickup-select-input shadow-none`} onChange={handleSelectChange} value={formData.Courier_Partners}
                                                    >
                                                        {CourierPartners.map((option, index) => {
                                                            return (
                                                                <option key={index} value={option}  >
                                                                    {option}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button className='bulk_outer_btn btn'>
                                                    <Form.Group controlId="formBasicCheckbox">
                                                        <Form.Check
                                                            required
                                                            type="checkbox"
                                                            name="agreedToTerms"
                                                            className={` d-flex align-items-center gap-2 `}
                                                            label={
                                                                <>
                                                                    <span
                                                                        className={`black-text`}
                                                                        style={{ fontSize: "14px" }}
                                                                    >
                                                                        Cheapest
                                                                    </span>
                                                                </>
                                                            }
                                                            feedbackType="invalid"
                                                            onChange={handleChangecheckbox}
                                                            checked={isChecked}

                                                        />
                                                    </Form.Group>
                                                </Button>
                                                <Button className='primarybtn btn height-44'>
                                                    Apply For All
                                                </Button>
                                            </div>

                                            <div className=' w-100'>
                                                <Button className='secondry w-100 btn height-44' onClick={handleDeleteAll}>
                                                    {isbulkorderDeleteLoading ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" />
                                                        </>
                                                    ) : (<>
                                                        {`Delete all ${key && key}`}
                                                    </>)}
                                                </Button>
                                            </div>
                                            {/* </> */}
                                            {/* } */}
                                            <div className='d-flex flex-column  gap-3 '>
                                                <div className="d-flex d-lg-flex align-items-center">
                                                    <div className="me-3">
                                                        <p className="collectoinh5 blue-text">
                                                            <span>Orders Details</span>
                                                        </p>
                                                    </div>
                                                    <div className="hrlinedivdelivery">
                                                        <hr className="hrlinedelivery" />
                                                    </div>
                                                </div>

                                                <div className=" user-Wallet">
                                                    <Tabs id="fill-tab-example" className="border-0" fill activeKey={key} onSelect={handleTabChange}  >
                                                        <Tab eventKey="pending" title="Pending" className={`user-tab-content p-1 p-sm-3 ${BulkOrderPreviewDataPending[0] && "pt-3"} `} style={{ flex: "1" }}>
                                                            <div className='d-flex flex-column gap-3'>
                                                                {getCurrentPageData() && getCurrentPageData().length ? (
                                                                    <>

                                                                        {getCurrentPageData() && getCurrentPageData()?.map((bulkData, index) => (
                                                                            <Card className='card-border'>
                                                                                <div className=''>
                                                                                    <Card.Body style={{ background: bulkData?.orderdetail_id < 1 && bulkData?.cart_details ? '#E5F2E5' : (bulkData?.cart_details ? '#E9E9E9' : 'initial') }}>
                                                                                        <Form onSubmit={formik.handleSubmit}>
                                                                                            <div className='d-flex flex-column  gap-2 '>
                                                                                                <div className='d-flex flex-column flex-lg-row gap-2 gap-lg-4 '>
                                                                                                    <div className='d-flex justify-content-between' style={{ flex: "2" }}>
                                                                                                        <div>
                                                                                                            <div className='d-flex gap-2'>
                                                                                                                <p className='bulk-head-text'>To Name</p>:
                                                                                                                <p className='m-0 bulk-description-text'>{bulkData?.first_name}{" "}{bulkData?.last_name}</p>
                                                                                                            </div>
                                                                                                            {bulkData?.company_name && <div className='d-flex gap-2'>
                                                                                                                <p className='bulk-head-text'>Company Name</p> :
                                                                                                                <p className='m-0 bulk-description-text'>{bulkData?.company_name}</p>
                                                                                                            </div>}
                                                                                                            <div className='d-flex gap-2'>
                                                                                                                <p className='bulk-head-text'>Address</p>:
                                                                                                                <p className='m-0 bulk-description-text'>{bulkData?.address_1}{" "}{bulkData?.address_2},{"  "}{bulkData?.city},{"  "}{bulkData?.state_or_county},{" "}{bulkData?.postcode},{bulkData?.country?.code}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='d-flex d-lg-none'>
                                                                                                            <img style={{ width: "30px", height: "30px" }} className="" alt="icon" src={`${window.location.origin}/assets/address-icons/excelicon.svg`} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='d-flex flex-column gap-3 ' style={{ flex: "3" }}>
                                                                                                        <div className="d-flex flex-column flex-md-row ">
                                                                                                            <div style={{ flex: "3" }}>
                                                                                                                <div className="shipment-details-text-p  d-flex gap-4 gap-lg-4 justify-content-between justify-content-lg-start ">
                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">Weight</p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p className="d-flex gap-1 bulk-description-text"><p>{bulkData?.parcel_weight}</p>{" "} <p>{bulkData?.parcel_weight_measure ? bulkData?.parcel_weight_measure : "LBS"}</p></p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Length
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p>{bulkData?.parcel_length}</p>{" "}
                                                                                                                            <p>&nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-4 gap-lg-4 justify-content-between justify-content-lg-start">

                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Width
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p>{bulkData?.parcel_width}</p>{" "}
                                                                                                                            <p>&nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Height
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p>{bulkData?.parcel_height}</p>{" "}
                                                                                                                            <p>&nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    <p className="bulk-head-text"> Parcel Description</p>:
                                                                                                                    <p className="bulk-description-text">
                                                                                                                        {bulkData?.parcel_description}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    <p className="bulk-head-text">
                                                                                                                        Value
                                                                                                                    </p>:
                                                                                                                    <p className="bulk-description-text">
                                                                                                                        {formatPrice(bulkData?.parcel_value)}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='d-none d-lg-flex ' >
                                                                                                                <img style={{ width: "30px", height: "30px" }} className="" alt="icon" src={`${window.location.origin}/assets/address-icons/excelicon.svg`} />
                                                                                                            </div>
                                                                                                        </div >
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={`d-flex w-100 bulk_button_outer `}>
                                                                                                    {/* ${bulkData?.orderdetail_id < 1 && !bulkData?.cart_details ? "" : "flex-column"} */}
                                                                                                    {bulkData?.cart_details && bulkData?.orderdetail_id < 1 &&
                                                                                                        <div className='' style={{ flex: "3" }}>
                                                                                                            <>
                                                                                                                <div className='d-flex flex-wrap'>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                                                                        <div className=' ' >
                                                                                                                            <img style={{ width: "30px", height: "30px" }} className="" alt="icon" src={bulkData?.cart_details?.courier?.courier_image} />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1 text-nowrap me-3">
                                                                                                                        <p className="bulk-head-text">{bulkData?.cart_details?.courier_service_name}{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.base_price)}</p></p>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1 text-nowrap me-3">
                                                                                                                        <p className="bulk-head-text">Collection Charge{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.collection_charge)}</p></p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='d-flex flex-wrap'>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                                                                        <p className="bulk-head-text">Insurance{" "}</p> :{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{bulkData?.cart_details?.is_enhanced_cover === "Yes" ? ` ${formatPrice(bulkData?.cart_details?.enhanced_cover_cost)} ` : ` ${formatPrice(0.00)} `}</p></p>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                                                                        <p className="bulk-head-text">Total{" "}</p>:{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.total)}</p></p>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                                                                        <p className="bulk-head-text">Collection Date{" "}</p>:{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{bulkData?.cart_details?.collection_date}</p></p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='d-flex gap-2 justify-content-start justify-content-md-end bulk_button_outer'>
                                                                                                    <div className=' w-100'></div>
                                                                                                    <div className=' w-100'>
                                                                                                        {bulkData?.orderdetail_id < 1 && !bulkData?.cart_details ? (
                                                                                                            <>
                                                                                                                <Button className='bulk-button w-100 text-nowrap' variant="outlined" disabled={showModalService && bookserviceid === formData[index]?.id}
                                                                                                                    onClick={() => { if (!(showModalService && bookserviceid === formData[index]?.id)) { handleBookClick(bulkData, index) } }}  >
                                                                                                                    {bookServiceLoad && bookingId === formData[index]?.id ? (
                                                                                                                        <>
                                                                                                                            <Spinner animation="border" size="sm" />
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/book.svg`} />
                                                                                                                            Book a Service
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <div className='w-100'>
                                                                                                                    <Button className='bulk-button-second w-100 text-nowrap' variant="outlined"
                                                                                                                        onClick={() => handlecancelService(bulkData, index)}>
                                                                                                                        {isDeleteCardLoading && cancelServiceid === formData[index]?.id ? (
                                                                                                                            <><Spinner animation="border" size="sm" /> </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/cancel.svg`} />
                                                                                                                                Cancel Select Service
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                </div>

                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {!bulkData?.cart_details &&
                                                                                                        <div className=' w-100'>
                                                                                                            <div className='w-100'>
                                                                                                                <Button className='bulk-button w-100 text-nowrap' variant="outlined"
                                                                                                                    onClick={() => handleEditClick(bulkData, index)}>
                                                                                                                    <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/editbulk.svg`} />
                                                                                                                    Edit
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div className=' w-100'>
                                                                                                        <Button className={` w-100 text-nowrap ${bulkData?.orderdetail_id < 1 && !bulkData?.cart_details ? "bulk-button" : "bulk-button-second"}`} variant="outlined" onClick={() => handleDelete(bulkData, index)}  >
                                                                                                            {isbulkorderDeleteLoading && deleteId === formData[index]?.id ? (
                                                                                                                <><Spinner animation="border" size="sm" /> </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/deletebulk.svg`} />
                                                                                                                    Delete
                                                                                                                </>
                                                                                                            )}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {showModalService && bookserviceid === formData[index]?.id && (
                                                                                                <div className={`${(bookserviceloading || ispickupdropLoading) && "bulkloading_service "} `}>
                                                                                                    <BulkOrderBookServiceModal bulkData={selectedBulkData} userAddressList={collectionAddress} setAllerrors={setAllerrors} allerrors={allerrors}
                                                                                                        setshowpickupdrop={setshowpickupdrop} setisinsuranceopen={setisinsuranceopen} setBookserviceloading={setBookserviceloading} setshowAddressModal={setshowAddressModal}
                                                                                                        bookServiceLoad={bookServiceLoad} setbookServiceLoadingPopup={setbookServiceLoadingPopup} bookServiceLoadingPopup={bookServiceLoadingPopup}
                                                                                                        bookserviceloading={bookserviceloading} ispickupdropLoading={ispickupdropLoading}
                                                                                                    />
                                                                                                </div>

                                                                                            )}
                                                                                            {/* {(bookserviceloading || ispickupdropLoading) && (
                                                                        <>
                                                                            <Box className="gap-3 d-flex align-items-center">
                                                                                <Skeleton animation="wave" className='w-25' variant="rounded" />
                                                                                <Skeleton animation="wave" className='w-75' height="7px" variant="rounded" />
                                                                            </Box>
                                                                            <Box className="mt-2 d-flex align-items-center">
                                                                                <Skeleton animation="wave" className='w-100' height="40px" variant="rounded" />
                                                                            </Box>
                                                                        </>)} */}
                                                                                            {allerrors && bookserviceid === formData[index]?.id && <p className='text-danger'>{allerrors}</p>}
                                                                                        </Form>

                                                                                    </Card.Body>
                                                                                    {/* {formData.length - 1 !== index && <hr className='m-0' />} */}
                                                                                </div>
                                                                            </Card>
                                                                        ))}
                                                                        {filteredDataCount >= itemsPerPage && (
                                                                            <div className=" d-flex justify-content-end">
                                                                                <Pagination
                                                                                    count={Math.ceil(
                                                                                        filteredDataCount / itemsPerPage
                                                                                    )}
                                                                                    variant="outlined"
                                                                                    shape="rounded"
                                                                                    page={currentPage}
                                                                                    onChange={(event, page) => {
                                                                                        handlePageChange(event, page);
                                                                                        scrollToTop();
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <>
                                                                            <h6 className='text-center'>Data not found...!</h6>
                                                                        </>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Tab>

                                                        <Tab disabled={BulkOrderPreviewDataCompleted.length < 1} eventKey="completed" title="Complete" className="user-tab-content p-3" style={{ flex: "1" }}>
                                                            <div className='d-flex flex-column gap-3'>
                                                                {getCurrentPageDatacomplete() && getCurrentPageDatacomplete().length ? (
                                                                    <>
                                                                        {getCurrentPageDatacomplete()?.map((bulkData, index) => (
                                                                            <Card className='card-border overflow-hidden'>
                                                                                <div className=''>
                                                                                    <Card.Body style={{ background: bulkData?.orderdetail_id < 1 && bulkData?.cart_details ? '#E5F2E5' : (bulkData?.cart_details ? 'white' : 'initial') }}>
                                                                                        <Form onSubmit={formik.handleSubmit}>
                                                                                            <div className='d-flex flex-column gap-2'>
                                                                                                <div className='d-flex flex-column flex-md-row gap-4'>
                                                                                                    <div className='' style={{ flex: "2" }}>
                                                                                                        <div className='d-flex gap-2'>
                                                                                                            <p className='bulk-head-text'>To Name</p>:
                                                                                                            <p className='m-0 bulk-description-text'>{bulkData?.first_name}{" "}{bulkData?.last_name}</p>
                                                                                                        </div>
                                                                                                        {bulkData?.company_name && <div className='d-flex gap-2'>
                                                                                                            <p className='bulk-head-text'>Company Name</p> :
                                                                                                            <p className='m-0 bulk-description-text'>{bulkData?.company_name}</p>
                                                                                                        </div>}
                                                                                                        <div className='d-flex gap-2'>
                                                                                                            <p className='bulk-head-text'>Address</p>:
                                                                                                            <p className='m-0 bulk-description-text'>{bulkData?.address_1}{" "}{bulkData?.address_2},{"  "}{bulkData?.city},{"  "}{bulkData?.state_or_county},{" "}{bulkData?.postcode},{bulkData?.country?.code}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='d-flex flex-column gap-3 ' style={{ flex: "3" }}>
                                                                                                        <div className="d-flex flex-column flex-md-row ">
                                                                                                            <div style={{ flex: "3" }}>
                                                                                                                <div className="shipment-details-text-p d-flex gap-2 justify-content-between justify-content-md-start">
                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Weight
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{bulkData?.parcel_weight}</p>{" "} <p>{bulkData?.parcel_weight_measure ? bulkData?.parcel_weight_measure : "LBS"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Length
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p>{bulkData?.parcel_length}</p>{" "}
                                                                                                                            <p>&nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-4 justify-content-between justify-content-md-start">

                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Width
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p>{bulkData?.parcel_width}</p>{" "}
                                                                                                                            <p>&nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex gap-2">
                                                                                                                        <p className="bulk-head-text">
                                                                                                                            Height
                                                                                                                        </p>:
                                                                                                                        <p className="d-flex bulk-description-text">
                                                                                                                            <p>{bulkData?.parcel_height}</p>{" "}
                                                                                                                            <p>&nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}</p>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    <p className="bulk-head-text"> Parcel Description</p>:
                                                                                                                    <p className="bulk-description-text">
                                                                                                                        {bulkData?.parcel_description}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    <p className="bulk-head-text">
                                                                                                                        Value
                                                                                                                    </p>:
                                                                                                                    <p className="bulk-description-text">
                                                                                                                        {formatPrice(bulkData?.parcel_value)}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='d-none d-md-flex' >
                                                                                                                <img style={{ width: "30px", height: "30px" }} className="" alt="icon" src={`${window.location.origin}/assets/address-icons/excelicon.svg`} />
                                                                                                            </div>
                                                                                                        </div >
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='d-flex w-100 '>
                                                                                                    <div className='' style={{ flex: "3" }}>
                                                                                                        {bulkData?.cart_details && bulkData?.orderdetail_id < 1 &&
                                                                                                            <>
                                                                                                                <div className='d-flex gap-3'>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1">
                                                                                                                        <div className=' ' >
                                                                                                                            <img style={{ width: "30px", height: "30px" }} className="" alt="icon" src={bulkData?.cart_details?.courier?.courier_image} />
                                                                                                                        </div>
                                                                                                                        {/* <p className="bulk-head-text">{bulkData?.cart_details?.courier_service_name}{" "}:</p>{" "}
                                                                                                    <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.base_price)}</p></p> */}
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1">
                                                                                                                        <p className="bulk-head-text">{bulkData?.cart_details?.courier_service_name}{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.base_price)}</p></p>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1">
                                                                                                                        <p className="bulk-head-text">Collection Charge{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.collection_charge)}</p></p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='d-flex gap-3'>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1">
                                                                                                                        <p className="bulk-head-text">Insurance{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{bulkData?.cart_details?.is_enhanced_cover === "Yes" ? ` ${formatPrice(bulkData?.cart_details?.enhanced_cover_cost)} ` : ` ${formatPrice(0.00)} `}</p></p>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1">
                                                                                                                        <p className="bulk-head-text">Total{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{formatPrice(bulkData?.cart_details?.total)}</p></p>
                                                                                                                    </div>
                                                                                                                    <div className="shipment-details-text-p d-flex gap-1">
                                                                                                                        <p className="bulk-head-text">Collection Date{" "}:</p>{" "}
                                                                                                                        <p className="d-flex gap-1 bulk-description-text"><p>{bulkData?.cart_details?.collection_date}</p></p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className=' d-flex justify-content-end ' style={{ flex: "2" }}>
                                                                                                        <div className=' d-flex gap-2  justify-content-start justify-content-sm-end w-100 '>
                                                                                                            <div className=' '>
                                                                                                                <Button className={` w-100 text-nowrap bulk-button`} variant="outlined" onClick={() => handleDelete(bulkData, index)}  >
                                                                                                                    {isbulkorderDeleteLoading && deleteId === formData[index]?.id ? (
                                                                                                                        <><Spinner animation="border" size="sm" /> </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/deletebulk.svg`} />
                                                                                                                            Delete
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* {showModalService && bookserviceid === formData[index]?.id && (
                                                                                            <div className={`${(bookserviceloading || ispickupdropLoading) && "bulkloading_service"} `}>
                                                                                                <BulkOrderBookServiceModal bulkData={selectedBulkData} userAddressList={collectionAddress} setAllerrors={setAllerrors} allerrors={allerrors}
                                                                                                    setshowpickupdrop={setshowpickupdrop} setisinsuranceopen={setisinsuranceopen} setBookserviceloading={setBookserviceloading}
                                                                                                    bookServiceLoad={bookServiceLoad} setbookServiceLoadingPopup={setbookServiceLoadingPopup} bookServiceLoadingPopup={bookServiceLoadingPopup}
                                                                                                    bookserviceloading={bookserviceloading} ispickupdropLoading={ispickupdropLoading}
                                                                                                />
                                                                                            </div>
    
                                                                                        )} */}
                                                                                            {/* {(bookserviceloading || ispickupdropLoading) && (
                                                                            <>
                                                                                <Box className="gap-3 d-flex align-items-center">
                                                                                    <Skeleton animation="wave" className='w-25' variant="rounded" />
                                                                                    <Skeleton animation="wave" className='w-75' height="7px" variant="rounded" />
                                                                                </Box>
                                                                                <Box className="mt-2 d-flex align-items-center">
                                                                                    <Skeleton animation="wave" className='w-100' height="40px" variant="rounded" />
                                                                                </Box>
                                                                            </>)} */}
                                                                                            {/* {allerrors && bookserviceid === formData[index]?.id && <p className='text-danger'>{allerrors}</p>} */}
                                                                                        </Form>

                                                                                    </Card.Body>
                                                                                    {/* {formData.length - 1 !== index && <hr className='m-0' />} */}
                                                                                </div>
                                                                            </Card>
                                                                        ))}
                                                                        {filteredDataCountcomplete >= itemsPerPage && (
                                                                            <div className=" d-flex justify-content-end">
                                                                                <Pagination
                                                                                    count={Math.ceil(
                                                                                        filteredDataCountcomplete / itemsPerPage
                                                                                    )}
                                                                                    variant="outlined"
                                                                                    shape="rounded"
                                                                                    page={currentPagecomplete}
                                                                                    onChange={(event, page) => {
                                                                                        handlePageChangecomplete(event, page);
                                                                                        scrollToTop();
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h6 className='text-center'>Data not found...!</h6>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>


                                                <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}
                                                    className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"   >
                                                    <Modal.Header closeButton className="closebutton d-flex justify-content-end" style={{ backgroundColor: "white" }}  ></Modal.Header>
                                                    <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                                                        <div className="user-modal-wallet w-100" style={{ backgroundColor: "white" }}>
                                                            <div className="px-4 pb-4">
                                                                <div className="d-flex flex-column gap-2">
                                                                    <BulkOrderPreviewModal bulkData={selectedBulkData} setShowModal={setShowModal} setShowModalService={setShowModalService} userData={userData} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                                <Modal show={showAddressModal} onHide={handleCloseModal} backdrop="static" keyboard={false}
                                                    className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"   >
                                                    <Modal.Header closeButton className="closebutton d-flex justify-content-end" style={{ backgroundColor: "white" }}  ></Modal.Header>
                                                    <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                                                        <div className="user-modal-wallet w-100" style={{ backgroundColor: "white" }}>
                                                            <div className="px-4 pb-4">
                                                                <div className="d-flex flex-column gap-2">
                                                                    {/* <BulkOrderPreviewModal bulkData={selectedBulkData} setShowModal={setShowModal} setShowModalService={setShowModalService} /> */}
                                                                    <AddressCard userId={useriD} bulkDatamodel={true} country_Name={country_Name} bulkUserAddRow={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                                <Modal show={showpickupdrop} onHide={handleCloseModal} backdrop="static" keyboard={false}
                                                    className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"   >
                                                    <Modal.Header closeButton className="closebutton d-flex justify-content-end" style={{ backgroundColor: "white" }}  ></Modal.Header>
                                                    <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                                                        <div className="user-modal-wallet w-100 h-100" style={{ backgroundColor: "white" }}>
                                                            <div className="px-4 pb-4">
                                                                <div className="d-flex flex-column gap-2">
                                                                    {/* <BulkOrderPickupDrop /> */}
                                                                    <SelectDateTime
                                                                        // serviceData={service_Data}
                                                                        // shipContent={shipContent}
                                                                        selectOpen={showpickupdrop}
                                                                        setSelectOpen={setshowpickupdrop}
                                                                        collectionDates={collectionDates}
                                                                        setCollectionDates={setCollectionDates}
                                                                        dropupDates={dropupDates}
                                                                        setDropupDates={setDropupDates}
                                                                        collectionValues={collectionValues}
                                                                        setCollectionValues={setCollectionValues}
                                                                        selectedCollectionService={selectedCollectionService}
                                                                        setSelectedCollectionService={
                                                                            setSelectedCollectionService
                                                                        }
                                                                        // dropDates={dropDates}
                                                                        setPickupValidation={setPickupValidation}
                                                                        // pickupDates={pickupLabels}
                                                                        // dropdates={dropOffLabels}
                                                                        user_id={useriD}
                                                                    />
                                                                    {showpickupdrop && (
                                                                        <Button
                                                                            className="address_btn border height-44"
                                                                            onClick={!continueBulk ? () => handleContinueClick() : null}
                                                                            variant="link"
                                                                            disabled={dropoffdata?.error?.error && selectedCollectionService === "Drop"}
                                                                        >
                                                                            {continueBulk ? <Spinner animation="border" size="sm" /> : 'Continue'}
                                                                        </Button>
                                                                    )}
                                                                    {isinsuranceopen && <>

                                                                        <p className='d-flex flex-column gap-2'>
                                                                            <p>
                                                                                <b>Parcel Content</b> : {selectedBulkData?.parcel_description}
                                                                            </p>
                                                                            <p>
                                                                                <b>Value of Parcel</b> : {formatPrice(selectedBulkData?.parcel_value)}
                                                                            </p>
                                                                        </p>
                                                                        <p className="d-flex justify-content-between flex-wrap" >
                                                                            <p className="d-inline-flex">
                                                                                <b> Insurance Cost : </b>&nbsp;
                                                                                <span>
                                                                                    {shipmentDataNew?.cart_details?.insurance_cost === "0.00" || !shipmentDataNew?.cart_details?.insurance_cost
                                                                                        ? "Free"
                                                                                        :
                                                                                        formatPrice(shipmentDataNew?.cart_details?.insurance_cost)
                                                                                        // `$${shipmentDataNew?.cart_details?.insurance_cost}`
                                                                                    }

                                                                                </span>
                                                                            </p>
                                                                            <div className="radiobtnAddress radio-parcel d-flex flex-row align-items-center gap-2 mt-2 mt-md-0 ">
                                                                                <p className="lh-1 ">Do you want to add an insurance? </p>
                                                                                <div className="radio-holderaddress row">
                                                                                    <div className="col custom_radio  d-flex gap-2">
                                                                                        <input
                                                                                            className="radio-check-my-ship-details d-flex align-items-center gap-2"
                                                                                            type="radio"
                                                                                            id="yesRadio"
                                                                                            name="insurance"
                                                                                            value="Yes"
                                                                                            checked={selectedRadio === "Yes"}
                                                                                            // disabled={isEmpty}
                                                                                            //   disabled={isInsuranceDataIncomplete()}
                                                                                            // label={"Yes"}
                                                                                            onChange={(e) => {
                                                                                                handleRadioChange(e);
                                                                                            }}
                                                                                        />
                                                                                        <label for="yesRadio">Yes</label>

                                                                                        {/* <span className="ms-1 me-3">Yes</span> */}
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="radio-check-my-ship-details d-flex align-items-center gap-2 "
                                                                                            id="noRadio"
                                                                                            name="insurance"
                                                                                            value="No"
                                                                                            checked={selectedRadio === "No"}
                                                                                            // disabled={isEmpty}
                                                                                            //   disabled={isInsuranceDataIncomplete()}

                                                                                            // label={"No"}

                                                                                            onChange={(e) => {
                                                                                                handleRadioChange(e);
                                                                                            }}
                                                                                        />
                                                                                        <label for="noRadio">No</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </p>
                                                                        {bookservicedata?.cart_details
                                                                            ?.collection_country_id !== bookservicedata?.cart_details
                                                                                ?.delivery_country_id && <>
                                                                                <AccordianCustomDetails
                                                                                    dropdowndata={dropdowndata}
                                                                                    vatdata={vatdata}
                                                                                    setVatdata={setVatdata}
                                                                                />
                                                                            </>

                                                                        }
                                                                        {(bookserviceError || bookserviceErrors) && (
                                                                            <>
                                                                                <p className='text-danger'>{bookserviceError}</p>
                                                                                <p className='text-danger'>{bookserviceErrors}</p>
                                                                            </>
                                                                        )}
                                                                        <Button className='primarybtn btn height-44' onClick={!submitBulk ? () => finalsubmit() : null} >
                                                                            {submitBulk ? <Spinner animation="border" size="sm" /> : 'Submit'}
                                                                        </Button>
                                                                    </>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </div >
                                        </div >
                                        <div style={{ flex: "2" }} className=''>
                                            <div className='d-flex flex-column gap-3 bulk_orderCollect'>
                                                <Card className='card-border'>
                                                    <Card.Body className='p-2 p-sm-3'>
                                                        <div className='d-flex flex-column gap-2 '>
                                                            <div className="d-flex d-flex d-lg-flex justify-content-between align-items-center gap-2">
                                                                <div className="">
                                                                    <p className="collectoinh5 blue-text">
                                                                        <span>Collection Details</span>
                                                                    </p>
                                                                </div>
                                                                <div className="hrlinedivdelivery d-none d-sm-block">
                                                                    <hr className="hrlinedelivery" />
                                                                </div>
                                                                <div className=''>
                                                                    <Button className='address_btn border text-nowrap w-100' variant="link" onClick={() => { setopenaddressmodel() }}>
                                                                        {!hasDefaultAddress ? (
                                                                            <>
                                                                                Add Address
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Change Address
                                                                            </>
                                                                        )}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {userAddressList && userAddressList?.map((i, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        {i?.is_default === "Yes" && (
                                                                            <div className=''>
                                                                                <Card.Text>
                                                                                    <p style={{ margin: "0px", fontWeight: "500" }}>
                                                                                        {`${i.first_name} ${i.last_name} ${i?.contact_no !== null ? i?.contact_no : ""}`}
                                                                                    </p>
                                                                                    <p className="m-0">
                                                                                        {`${i.address_1}, ${i.city}, ${i?.state} - ${i?.postcode}`}
                                                                                    </p>
                                                                                </Card.Text>
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                            {!hasDefaultAddress && (<>
                                                                <div>
                                                                    <p className='text-danger cursor-pointer mb-3'>
                                                                        Add or Select default Your Collection Address
                                                                    </p>
                                                                </div>
                                                            </>)}
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                {cartData?.total > 0 && (
                                                    <>
                                                        <Card className='card-border'>
                                                            <Card.Body>
                                                                <div className="d-flex d-none d-lg-flex align-items-center gap-3">
                                                                    <div className="">
                                                                        <p className="collectoinh5 blue-text">
                                                                            <span>Price Details {`( ${cartData?.cart_details?.length} )`}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="hrlinedivdelivery">
                                                                        <hr className="hrlinedelivery" />
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <li className="d-flex justify-content-between align-items-center border-0 px-0">
                                                                        <div>
                                                                            <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                Sub Total
                                                                            </p>
                                                                        </div>
                                                                        <span>
                                                                            <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                <b>${cartData?.subtotal}</b>
                                                                            </p>
                                                                        </span>
                                                                    </li>
                                                                    {cartData?.pickup_charge > 0 && (
                                                                        <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                                                                            <div>
                                                                                <p className="mb-0 paragraph2 order-confirm-p-2 text-nowrap">
                                                                                    Pickup Charge{" "}
                                                                                </p>
                                                                            </div>
                                                                            <div className=" d-flex justify-content-end insurance ">
                                                                                <span>
                                                                                    <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                        <b>
                                                                                            {formatPrice(cartData?.pickup_charge)}
                                                                                        </b>
                                                                                    </p>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                        <div>
                                                                            <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                TAX{" "}
                                                                            </p>
                                                                        </div>
                                                                        <span>
                                                                            <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                <b>{formatPrice("0.00")}</b>
                                                                            </p>
                                                                        </span>
                                                                    </li>
                                                                    {Number(cartData?.total_insurance) > 0 && (
                                                                        <li className="list-group-item d-flex  align-items-center border-0 px-0">
                                                                            <div className="d-flex">
                                                                                <div>
                                                                                    <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                        Insurance
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" d-flex justify-content-end insurance">
                                                                                <span>
                                                                                    <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                        <b>
                                                                                            {cartData?.total_insurance > 0 && formatPrice(cartData?.total_insurance)}
                                                                                        </b>
                                                                                    </p>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                    {cartData?.discount_amount > 0 && (
                                                                        <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                                                                            <div>
                                                                                <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                    Discount{" "}
                                                                                </p>
                                                                            </div>
                                                                            <span className="px-2">
                                                                                <div
                                                                                    style={{ color: "#113468" }}
                                                                                    className="discount-label-payment"
                                                                                >
                                                                                    {" "}
                                                                                </div>
                                                                            </span>
                                                                            <div className=" d-flex justify-content-end insurance ">
                                                                                <span>
                                                                                    <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                        <b>-{" "}
                                                                                            {formatPrice(cartData?.discount_amount)}
                                                                                        </b>
                                                                                    </p>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                    <div>
                                                                        <hr className="doted-hr m-1" />
                                                                    </div>
                                                                    <div>
                                                                        <li className="d-flex justify-content-between align-items-center border-0 px-0">
                                                                            <div>
                                                                                <span>
                                                                                    <p className="paragraph1 mb-0 p-0">
                                                                                        <b>Total</b>
                                                                                    </p>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <span>
                                                                                    <p className="paragraph1 mb-0">
                                                                                        {/* <b>{formatPrice(cartData?.discount_amount)}</b> */}
                                                                                        <b>{formatPrice(cartData?.total)}</b>

                                                                                    </p>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                        <Button disabled={cartData?.discount_amount === "0"} className='primarybtn btn height-40 w-100'>
                                                            <Link style={{ color: "white", textDecoration: "none" }} className='w-100' to={"/cartpreview"}>
                                                                Go to Cart
                                                            </Link>
                                                        </Button>
                                                    </>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                {/* ) : ( */}
                                {/* <>
                                        <div className="gap-5 d-flex justify-content-center py-5 "  >
                                            <Spinner
                                                animation="grow"
                                                style={{ background: "#113468" }}
                                                color="#113468"
                                            />
                                            <Spinner
                                                animation="grow"
                                                style={{ background: "#113468" }}
                                                color="#113468"
                                            />
                                            <Spinner
                                                animation="grow"
                                                style={{ background: "#113468" }}
                                                color="#113468"
                                            />
                                        </div>
                                    </> */}
                                {/* )} */}

                            </div>
                        </>
                    ) : (
                        <>
                            {isbulkorderListLoading || iscartloading ? (
                                <>
                                    <BulkOrderPreviewSkeleton />
                                </>
                            ) : (
                                <>
                                    <div
                                        className="div1 w-100 d-flex gap-3 align-items-center justify-content-center"
                                        style={{ flex: 2, height: "70vh", flexDirection: "column" }}
                                    >
                                        <h1>Bulk Order Data Not Found....!</h1>
                                        <p>if you want to add bulk data ? </p>
                                        <Button className='primarybtn btn height-40 px-4'>
                                            <Link style={{ color: "white", textDecoration: "none" }} className='w-100' to={"/bulk-orders"}>
                                                Add Bulk data
                                            </Link>
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )
                    }
                </Container >
            </div >
        </>
    );
};

export default BulkOrderPreview;
