import React, { useState, useRef, useEffect } from 'react';
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import "../../../assets/styles/bulkOrders/bulkOrders.css";
import { useDispatch, useSelector } from 'react-redux';
import { bulkOrders, bulkOrdersList } from '../../../redux/services/ServicesThunk';
import { Box, Skeleton } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';

const BulkOrders = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const isbulkorderLoading = useSelector(
        (state) => state?.servicedata?.isbulkorderLoading
    );
    const BulkOrderPreviewData = useSelector(
        (state) => state?.servicedata?.bulkorderDataList?.BulkOrderData?.data
    );
    const fileInputRef = useRef(null);
    const useriD = JSON.parse(localStorage.getItem("user"));
    const [error, setError] = useState()

    useEffect(() => {
        dispatch(bulkOrdersList({
            customer_id: useriD?.id,
        }))
    }, []);

    const handleFile = (e) => {
        setError('')
        let formData = new FormData();
        formData.append('bulk_order_file', e.target.files[0]);
        formData.append('customer_id', useriD?.id);
        dispatch(bulkOrders(formData))
            .unwrap()
            .then((response) => {
                if (response?.BulkOrder?.status === "success" && response?.BulkOrder?.code === "200") {
                    navigate("/bulkorderPreview");
                }
                if (response?.BulkOrder?.code === "500" && response?.BulkOrder?.status === "fail") {
                    setError(response?.BulkOrder?.message)
                }
            })
    }

    const haldlePreviewClick = () => {
        navigate("/bulkorderPreview");
    }

    const handleButtonClick = () => {
        if (fileInputRef?.current) {
            fileInputRef?.current?.click();
        }
    };

    return (
        <>
            <div className="pickupdrop-outer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Card className="pickupdrop-parent-outer my-5 card-border py-5">
                                <div className='d-flex flex-column gap-3'>
                                    <div className='text-center '>
                                        <span className="blue-text m-0 " style={{ fontSize: "32px", fontWeight: "500" }}>Bulk Orders</span>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='text-center  packaging-holder'>
                                            <p className='paragraph1 '>Streamline your shipments with our bulk order magic, and watch savings soar!</p>
                                            <p className='paragraph1 '>Choose from top courier partners and ship multiple packages effortlessly.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center gap-5 align-items-center'>
                                        <a className=' blue-text' href={`${window.location.origin}/assets/excelData/LSN_Sample_File.xls`} style={{ cursor: "pointer" }}>
                                            <div className=' d-flex gap-3 justify-content-center'>
                                                <img src={`${window.location.origin}/assets/multishipment/file_save.svg`} alt="file_save" />
                                                <span className='paragraph1 blue-text text-nowrap text-decoration-none' style={{ cursor: "pointer" }}>Download sample file</span>
                                            </div>
                                        </a>
                                        {(BulkOrderPreviewData?.pending?.length > 0 || BulkOrderPreviewData?.completed?.length > 0) && (
                                            <>
                                                <div>
                                                    <Button className='primarybtn btn height-44' onClick={haldlePreviewClick}>View Bulk Order</Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {!isbulkorderLoading ? (
                                        <>
                                            <div className='d-flex justify-content-center '>
                                                <Form className='' style={{ width: "80%" }}>
                                                    <div className=''>
                                                        <input ref={fileInputRef} type={"file"} id={"input-file-upload"}
                                                            accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} onChange={(e) => handleFile(e)} />
                                                        <label id="label-file-upload" htmlFor="input-file-upload" className="doted_border doted_border-1 py-5">
                                                            <div className='w-50 gap-2 d-flex flex-column justify-content-center'>
                                                                <div className='text-center '>
                                                                    <img src={`${window.location.origin}/assets/multishipment/backup.svg`} alt="backup" />
                                                                </div>
                                                                <div>
                                                                    <span className='d-flex packaging-holder gap-1 justify-content-center '>
                                                                        <p className='text-nowrap '>Drag and drop</p>
                                                                        <img src={`${window.location.origin}/assets/multishipment/msexcel.svg`} alt="msexcel" />
                                                                        <p className='text-nowrap '>excel file here</p>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className='d-flex packaging-holder gap-2 align-items-center  justify-content-center '>
                                                                        <hr className='w-50 m-0' />
                                                                        <p>or</p>
                                                                        <hr className='w-50 m-0' />
                                                                    </span>
                                                                </div>
                                                                <div className='text-center'>
                                                                    <Button className="primarybtn px-5 py-2" onClick={handleButtonClick} >
                                                                        Browse File
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </Form>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Card className="border-0 order-details-card">
                                                <Card.Body>
                                                    <Box className="w-100 justify-content-center d-flex ">
                                                        <Skeleton variant="rounded" width={"84%"} height={256} />
                                                    </Box>
                                                </Card.Body>
                                            </Card>
                                        </>
                                    )}
                                    {/* <br /> */}
                                    {/* <div className='overflow-x-scroll'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    {headerKeys.map((key, index) => (
                                                        <th key={index}>{key}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {array?.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {headerKeys.map((key, cellIndex) => (
                                                            <td key={cellIndex}>{row[key]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> */}
                                </div>
                                {error && <div className='text-danger pt-5 ps-5 pe-5' dangerouslySetInnerHTML={{ __html: error }} />}

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BulkOrders;
