/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "../../assets/styles/herosection.css";
import { Spinner, Image } from "react-bootstrap";
import { Form } from "react-bootstrap";
import "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCountryDetails,
  fetchPostalDetails,
  fetchPostalDetailsTo,
  fetchShippingRates,
} from "../../redux/landing/landingThunk";
import { useDebounce } from 'use-debounce';
import { Button, IconButton, InputBase, Paper } from "@mui/material";
import Swal from "sweetalert2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomAutocomplete from "../common/CustomCountryPostal";
import CustomAlert from "../common/CustomAlert";
import CustomServerAlert from "../common/CustomServerAlert";
import CustomWeight from "../common/CustomWeight";
import { useModel } from "../../utils/modalContext";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";


const HeroSection = ({ FooterHeroSection, packageToFooter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Landing_Hero = useSelector(
    (state) => state.landingReducer?.landingHeroDetails?.data
  );
  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );

  const errors = useSelector((state) => state?.landingReducer?.rates_error);
  const { isSliderLogin, isHeroLoading } = useSelector((state) => ({
    isSliderLogin: state?.landingReducer?.isSliderLogin,
    isHeroLoading: state?.landingReducer?.isHeroLoading,
  }));
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const requiredValidation = useSelector(
    (state) => state?.landingReducer?.error
  );
  const country_Postal = useSelector(
    (state) => state.landingReducer?.countryPostal?.data
  );
  const country_PostalTo = useSelector(
    (state) => state.landingReducer?.countryPostalTo?.data
  );
  const isLoading = useSelector(
    (state) => state.landingReducer?.isLoading?.isShippingLoading
  );
  const isPostalLoading = useSelector(
    (state) => state.landingReducer.isPostalLoading
  );
  const isPostalToLoading = useSelector(
    (state) => state.landingReducer.isPostalToLoading
  );
  const isCountryLoading = useSelector(
    (state) => state.landingReducer.isCountryLoading
  );

  const useriD = JSON.parse(localStorage.getItem("user"));
  const weight_options = ["LBS", "KG"];
  const size_option = ["IN", "CM"];
  const document_weigth = ["Oz", "KG"];
  const [countryInput, setCountryInput] = useState({
    packageFrom: "United States",
    packageTo: "United States",
    docFrom: "",
    docTo: "",
    freightFrom: "",
    freightTo: "",
  });
  const [postalCode, setPostalCode] = useState({
    packageFrom: "",
    packageTo: "",
    docFrom: "",
    docTo: "",
    freightFrom: "",
    freightTo: "",
  });
  const [debouncedText] = useDebounce(postalCode, 1000);
  const [countryName, setCountryName] = useState();
  const [selectedRadio, setSelectedRadio] = useState("Package");
  const [postalOptions, setPostalOptions] = useState([]);
  const [postalToOptions, setPostalToOptions] = useState([]);
  const [countryDataService, setcountryDataService] = useState({
    fromCountryId: "40",
    fromCountryCode: "US",
    toCountryId: "40",
    toCountryCode: "US",
  });
  const [CityNames, setCityNames] = useState({
    fromCity: "",
    toCity: "",
  });

  const [radioFormData, setRadioFormData] = useState({
    fromcountryCode: "",
    frompostalCode: "",
    tocountryCode: "",
    topostalCode: "",
    weight: "",
    packageWeight: "",
    documentWeight: "",
    freightWeight: "",
    length: "",
    packageLength: "",
    freightLength: "",
    width: "",
    packageWidth: "",
    freightWidth: "",
    height: "",
    packageHeight: "",
    freightHeight: "",
    unit: "",
    weight_in: "LBS",
    size_in: "IN",
    document_weigth: "",
    packaging: "",
    formstate: "",
    tostate: "",
  });
  const [error, setError] = useState({
    countryInput: {
      freightFrom: false,
      freightTo: false,
      docFrom: false,
      docTo: false,
      packageFrom: false,
      packageTo: false,
    },
    postalCode: {
      freightFrom: false,
      freightTo: false,
      docFrom: false,
      docTo: false,
      packageFrom: false,
      packageTo: false,
    },
    radioFormData: {
      unit: false,
      freightWeight: false,
      freightLength: false,
      freightWidth: false,
      freightHeight: false,
      packageWeight: false,
      packageLength: false,
      packageWidth: false,
      packageHeight: false,
      documentWeight: false,
    },
    serverError: ""
  });
  const [countryDetails, setCountryDetails] = useState({
    collection_country: "United States",
    delivery_country: "United States",
  });
  const [id, setId] = useState({ ip_address: "", unique_address: "" })
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setId({ ip_address: res?.data?.ip })
    // localStorage.setItem("ipaddress", res?.data?.ip);
  };
  useEffect(() => {
    if (packageToFooter) {
      setCountryDetails({ ...countryDetails, delivery_country: packageToFooter })
      setCountryInput({ ...countryInput, packageTo: packageToFooter })
      let data = country_Name?.find((i) => {
        return i?.name === packageToFooter
      })
      setRadioFormData((prevData) => ({
        ...prevData,
        tocountryCode: data?.code
      }));
    }
  }, [packageToFooter, country_Name])

  useEffect(() => {
    dispatch(fetchCountryDetails());
    getData()
    const uniquesessionid = localStorage.getItem("uniquesessionid");
    if (!uniquesessionid) {
      const newUniqueID = uuidv4();
      localStorage.setItem("uniquesessionid", newUniqueID);
      setId({ unique_address: newUniqueID })
    }
  }, []);

  useEffect(() => {
    const tocity = country_PostalTo?.find((data) => {
      return (
        data.pincode_no === postalCode?.packageTo ||
        postalCode?.docTo ||
        postalCode?.freightTo
      );
    });
    const datad = country_Postal?.find((data) => {
      return [
        postalCode?.packageFrom,
        postalCode?.docFrom,
        postalCode?.freightFrom,
      ].some((field) => field === data.pincode_no);
    });

    if (datad?.pincode_no || tocity?.pincode_no) {
      setCityNames({
        fromCity: datad?.city_name || "",
        toCity: tocity?.city_name || "",
      });
      const city_data = localStorage.setItem(
        "cityName",
        JSON.stringify(CityNames)
      );
    }
  }, [country_Postal, country_PostalTo]);

  useEffect(() => {
    if (country_Name) {

      const countryNames = country_Name

      const topCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries === 1
      );
      const remainingCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries !== 1
      );
      const filteredCountries = [...topCountries, ...remainingCountries];
      setCountryName(filteredCountries);
    }
  }, [country_Name]);

  useEffect(() => {
    localStorage.removeItem("searchData");
    // localStorage.removeItem("multiship_data");
    // localStorage.removeItem("pickupDates");
    // localStorage.removeItem("dropOffDates");
    localStorage.removeItem("Address_Details");
    localStorage.removeItem("cartdetail_id");
    localStorage.removeItem("courierdata");
    localStorage.removeItem("serviceData");
    localStorage.removeItem("orderConfirmData");
    localStorage.removeItem("cityName");
  }, []);

  useEffect(() => {
    if (
      requiredValidation &&
      typeof requiredValidation === "object" &&
      Object.keys(requiredValidation).length > 0
    ) {
      if (Object.keys(requiredValidation).length > 0) {
        // CustomServerAlert(requiredValidation);
      }
    }
    if (errors?.fromPostalCode) {
      setError((prevError) => ({
        ...prevError,
        postalCode: {
          ...prevError.postalCode,
          packageFrom: true,
          docFrom: true,
          freightFrom: true,
        },
      }));
    }
    if (errors?.toPostalCode) {
      setError((prevError) => ({
        ...prevError,
        postalCode: {
          ...prevError.postalCode,
          packageTo: true,
          docTo: true,
          freightTo: true,
        },
      }));
    }
    if (errors?.invalid_data) {
      setError((prevError) => ({
        ...prevError,
        serverError: errors?.invalid_data
      }));
    }
    if (errors?.dimensions) {
      setError((prevError) => ({
        ...prevError,
        radioFormData: {
          ...prevError.radioFormData,
          freightWeight: false,
          freightLength: true,
          freightWidth: true,
          freightHeight: true,
          packageWeight: false,
          packageLength: true,
          packageWidth: true,
          packageHeight: true,
          documentWeight: false,
        },
      }));
    }

    // if (errors && errors.length > 0) {
    //   Swal.fire({
    //     html: `
    //     <div class="custom-container-swal snic" style="justify-content: space-between; ">
    //     <div as={col}>
    //       <img src="assets/error.png" class="img-fluid" style="object-fit: cover; max-width: 35%; height: auto;" alt="Error Image">
    //   </div>
    //   <div style="width: -webkit-fill-available; display: flex; align-items: center; justify-content: center;">
    //   <div style="text-align: center;">
    //           <ul class="dynamic-width-alert">${errors
    //             .map((error) => `<li class="text-danger ">${error}</li>`)
    //             .join("")}
    //             </ul>
    //           </div>
    //         </div>
    //       </div>`,
    //     customClass: {
    //       popup: "custom-swal-hero",
    //       content: "dynamic-width-alert",
    //       confirmButton: "custom-confirm-button",
    //       actions: "custom-actions-class m-0",
    //     },
    //     width: 400,
    //   });
    // }
  }, [requiredValidation, errors]);

  useEffect(() => {
    let dynamicFromCountry = countryInput.packageFrom;
    // eslint-disable-next-line default-case
    switch (selectedRadio) {
      case "Package":
        dynamicFromCountry = countryInput.packageFrom;
        break;
      case "Document":
        dynamicFromCountry = countryInput.docFrom;
        break;
      case "LTL Freight":
        dynamicFromCountry = countryInput.freightFrom;
        break;
    }
    if (dynamicFromCountry && country_Name) {
      const datad = country_Name?.find((i) => {
        return i.name.toLowerCase() === dynamicFromCountry.toLowerCase();
      });
      // setcountryData(datad?.id);
      setcountryDataService({
        ...countryDataService,
        fromCountryId: datad?.id || '40',
        fromCountryCode: datad?.code || "US",
      });
      setCountryDetails({
        ...countryDetails,
        collection_country: datad?.name,
      });
    }
  }, [countryInput.packageFrom, countryInput.docFrom, countryInput.freightFrom, selectedRadio, country_Name]);

  useEffect(() => {
    let dynamicToCountry = countryInput.packageTo;
    // eslint-disable-next-line default-case
    switch (selectedRadio) {
      case "Package":
        dynamicToCountry = countryInput.packageTo;
        break;
      case "Document":
        dynamicToCountry = countryInput.docTo;
        break;
      case "LTL Freight":
        dynamicToCountry = countryInput.freightTo;
        break;
    }

    if (dynamicToCountry && country_Name) {
      setTimeout(() => {
        const todata = country_Name?.find((cntr) => {
          return cntr.name.toLowerCase() === dynamicToCountry.toLowerCase();
        });
        // setcountryToData(todata?.id);
        setcountryDataService({
          ...countryDataService,
          toCountryId: todata?.id || '40',
          toCountryCode: todata?.code || "US",
        });
        setCountryDetails({
          ...countryDetails,
          delivery_country: todata?.name,
        });
      }, 1000);

    }
  }, [countryInput.packageTo, countryInput.docTo, countryInput.freightTo, selectedRadio, country_Name]);

  useEffect(() => {
    if (countryInput?.packageFrom !== "United States") {
      setCountryInput({ ...countryInput, packageTo: "United States", });
    }
  }, [countryInput.packageFrom]);

  useEffect(() => {
    if (countryInput?.packageTo !== "United States") {
      setCountryInput({ ...countryInput, packageFrom: "United States", });
    }
  }, [countryInput.packageTo]);

  useEffect(() => {
    if (country_Postal !== undefined && country_Postal !== null) {
      setPostalOptions(country_Postal);
    } else {
      setPostalOptions([]);
    }
  }, [country_Postal]);

  useEffect(() => {
    if (country_PostalTo !== undefined && country_PostalTo !== null) {
      setPostalToOptions(country_PostalTo);
    } else {
      setPostalToOptions([]);
    }
  }, [country_PostalTo]);

  useEffect(() => {
    let dynamicFromPostal = postalCode?.packageFrom;
    // let dynamicToPostal = "";

    switch (selectedRadio) {
      case "Package":
        dynamicFromPostal = postalCode?.packageFrom;
        // dynamicToPostal = postalCode?.packageTo;
        break;
      case "Document":
        dynamicFromPostal = postalCode?.docFrom;
        // dynamicToPostal = postalCode?.docTo;
        break;
      case "LTL Freight":
        dynamicFromPostal = postalCode?.freightFrom;
        // dynamicToPostal = postalCode?.freightTo;
        break;
      default:
        break;
    }

    if (dynamicFromPostal) {
      const from_Pincode_Format = country_Name?.filter(
        (data) => data.code === countryDataService?.fromCountryCode
      );
      const count =
        from_Pincode_Format && from_Pincode_Format[0]?.postcode_format?.length;
      const inputcount = dynamicFromPostal.length;
      const condition = inputcount <= count;
      if (condition === false) {
        if (count === 0 || count === undefined) {
          dispatch(
            fetchPostalDetails({
              country_id: countryDataService?.fromCountryId || '40',
              search_pincode:
                selectedRadio === "Package"
                  ? debouncedText?.packageFrom
                  : "" || selectedRadio === "Document"
                    ? debouncedText?.docFrom
                    : "" || selectedRadio === "LTL Freight"
                      ? debouncedText?.freightFrom
                      : "",
              uniquesessionid: uniquesessionid,
            })
          )
          // }, 2000)

        } else {
          // CustomAlert("The entered postcode format is invalid");
          // if (errors?.fromPostalCode) {
          setError((prevError) => ({
            ...prevError,
            postalCode: {
              ...prevError.postalCode,
              packageFrom: true,
              docFrom: true,
              freightFrom: true,
            },
          }));
        }
      } else {
        dispatch(
          fetchPostalDetails({
            country_id: countryDataService?.fromCountryId || '40',
            search_pincode:
              selectedRadio === "Package"
                ? debouncedText?.packageFrom
                : "" || selectedRadio === "Document"
                  ? debouncedText?.docFrom
                  : "" || selectedRadio === "LTL Freight"
                    ? debouncedText?.freightFrom
                    : "",
            uniquesessionid: uniquesessionid,
          })
        )
      }
    }

  }, [selectedRadio, country_Name, countryDataService, debouncedText?.packageFrom, debouncedText?.docFrom, debouncedText?.freightFrom]);

  useEffect(() => {
    let dynamicToPostal = postalCode?.packageTo;

    switch (selectedRadio) {
      case "Package":
        dynamicToPostal = postalCode?.packageTo;
        break;
      case "Document":
        dynamicToPostal = postalCode?.docTo;
        break;
      case "LTL Freight":
        dynamicToPostal = postalCode?.freightTo;
        break;
      default:
        break;
    }
    if (dynamicToPostal) {
      const To_Pincode_Format = country_Name?.filter(
        (data) => data.code === countryDataService?.toCountryCode
      );
      const count =
        To_Pincode_Format && To_Pincode_Format[0]?.postcode_format?.length;
      const inputcount = dynamicToPostal.length;
      const condition = inputcount <= count;
      if (condition === false) {
        if (count === 0 || count === undefined) {
          dispatch(
            fetchPostalDetailsTo({
              country_id: countryDataService?.toCountryId || '40',
              search_pincode:
                selectedRadio === "Package"
                  ? debouncedText?.packageTo
                  : "" || selectedRadio === "Document"
                    ? debouncedText?.docTo
                    : "" || selectedRadio === "LTL Freight"
                      ? debouncedText?.freightTo
                      : "",
              uniquesessionid: uniquesessionid,
            })
          );
        } else {
          // CustomAlert("The entered postcode format is invalid");
          // if (errors?.toPostalCode) {
          setError((prevError) => ({
            ...prevError,
            postalCode: {
              ...prevError.postalCode,
              packageTo: true,
              docTo: true,
              freightTo: true
            },
          }));

        }
      } else {
        dispatch(
          fetchPostalDetailsTo({
            country_id: countryDataService?.toCountryId || "40",
            search_pincode:
              selectedRadio === "Package"
                ? debouncedText?.packageTo
                : "" || selectedRadio === "Document"
                  ? debouncedText?.docTo
                  : "" || selectedRadio === "LTL Freight"
                    ? debouncedText?.freightTo
                    : "",
            uniquesessionid: uniquesessionid,
          })
        );
      }
    }
  }, [selectedRadio, country_Name, countryDataService, debouncedText?.packageTo, debouncedText?.docTo, debouncedText?.freightTo]);

  const handleRadioChangeHeroSection = (event) => {
    setError({});
    let from_country_Format = {};
    let to_country_Format = {};
    switch (event.target.value || "Package") {
      case "Package":
        from_country_Format = country_Name?.filter(
          (data) => data.name === countryInput?.packageFrom
        );
        to_country_Format = country_Name?.filter(
          (data) => data.name === countryInput?.packageTo
        );
        break;
      case "Document":
        from_country_Format = country_Name?.filter(
          (data) => data.name === countryInput?.docFrom
        );
        to_country_Format = country_Name?.filter(
          (data) => data.name === countryInput?.docTo
        );
        break;
      case "LTL Freight":
        from_country_Format = country_Name?.filter(
          (data) => data.name === countryInput?.freightFrom
        );
        to_country_Format = country_Name?.filter(
          (data) => data.name === countryInput?.freightTo
        );
        break;
      default:
        break;
    }
    setcountryDataService({
      fromCountryId: from_country_Format[0]?.id,
      fromCountryCode: from_country_Format[0]?.code,
      toCountryId: to_country_Format[0]?.id,
      toCountryCode: to_country_Format[0]?.code,
    });
    setPostalOptions([]);
    setPostalToOptions([]);
    setSelectedRadio(event.target.value);
  };

  const handleCompareSave = () => {
    setError({})
    setRadioFormData((prevData) => ({
      ...prevData,
      fromcountryCode: countryDataService?.fromCountryCode
        ? countryDataService?.fromCountryCode
        : "",
      fromPostalCode:
        selectedRadio === "Package"
          ? postalCode?.packageFrom
          : "" || selectedRadio === "Document"
            ? postalCode?.docFrom
            : "" || selectedRadio === "LTL Freight"
              ? postalCode?.freightFrom
              : "",
      toPostalCode:
        selectedRadio === "Package"
          ? postalCode?.packageTo
          : "" || selectedRadio === "Document"
            ? postalCode?.docTo
            : "" || selectedRadio === "LTL Freight"
              ? postalCode?.freightTo
              : "",
      tocountryCode: countryDataService?.toCountryCode
        ? countryDataService?.toCountryCode
        : "",
      height:
        selectedRadio === "Package"
          ? radioFormData?.packageHeight
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightHeight
            : "",
      length:
        selectedRadio === "Package"
          ? radioFormData?.packageLength
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightLength
            : "",
      width:
        selectedRadio === "Package"
          ? radioFormData?.packageWidth
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightWidth
            : "",
      packaging: radioFormData?.packaging ? radioFormData?.packaging : "",
      unit: radioFormData?.unit ? radioFormData?.unit : "",
      formstate: postalCode?.fromPostal ? postalCode?.fromPostal : "",
    }));

    const measurmemnt = {
      unit: [radioFormData?.unit],
      weight: [
        selectedRadio === "Package"
          ? radioFormData?.packageWeight
          : "" || selectedRadio === "Document"
            ? radioFormData?.documentWeight
            : "" || selectedRadio === "LTL Freight"
              ? radioFormData?.freightWeight
              : "",
      ],
      height: [
        selectedRadio === "Package"
          ? radioFormData?.packageHeight
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightHeight
            : "",
      ],
      length: [
        selectedRadio === "Package"
          ? radioFormData?.packageLength
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightLength
            : "",
      ],
      width: [
        selectedRadio === "Package"
          ? radioFormData?.packageWidth
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightWidth
            : "",
      ],
      weight_in: [radioFormData.weight_in],
      size_in: [radioFormData.size_in],
      qty: ["1"],
    };
    let data = {
      fromCountryCode: countryDataService.fromCountryCode
        ? countryDataService.fromCountryCode
        : "US",
      fromPostalCode:
        selectedRadio === "Package"
          ? postalCode?.packageFrom
          : "" || selectedRadio === "Document"
            ? postalCode?.docFrom
            : "" || selectedRadio === "LTL Freight"
              ? postalCode?.freightFrom
              : "",
      toPostalCode:
        selectedRadio === "Package"
          ? postalCode?.packageTo
          : "" || selectedRadio === "Document"
            ? postalCode?.docTo
            : "" || selectedRadio === "LTL Freight"
              ? postalCode?.freightTo
              : "",
      toCountryCode: countryDataService.toCountryCode
        ? countryDataService.toCountryCode
        : "US",
      fromCountryId: countryDataService.fromCountryId || '40',
      toCountryId: countryDataService.toCountryId || '40',
      package_type: selectedRadio ? selectedRadio : "Package",
      coupon_code: "",
      customer_id: useriD ? useriD?.id : "",
      fromcountry: countryDetails.collection_country,
      tocountry: countryDetails.delivery_country,
      document_weigth: radioFormData?.document_weigth,
      unit: [radioFormData?.unit],
      weight: [
        selectedRadio === "Package"
          ? radioFormData?.packageWeight
          : "" || selectedRadio === "Document"
            ? radioFormData?.documentWeight
            : "" || selectedRadio === "LTL Freight"
              ? radioFormData?.freightWeight
              : "",
      ],
      height: [
        selectedRadio === "Package"
          ? radioFormData?.packageHeight
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightHeight
            : "",
      ],
      length: [
        selectedRadio === "Package"
          ? radioFormData?.packageLength
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightLength
            : "",
      ],
      width: [
        selectedRadio === "Package"
          ? radioFormData?.packageWidth
          : "" || selectedRadio === "LTL Freight"
            ? radioFormData?.freightWidth
            : "",
      ],
      weight_in: [radioFormData.weight_in],
      size_in: [radioFormData.size_in],
      qty: ["1"],
    };

    if (
      selectedRadio === "Package" &&
      (countryInput.packageFrom.length <= 0 ||
        countryInput.packageTo.length <= 0 ||
        postalCode.packageFrom.length <= 0 ||
        postalCode.packageTo.length <= 0 ||
        radioFormData.packageWeight.length <= 0 ||
        radioFormData?.packageLength.length <= 0 ||
        radioFormData?.packageWidth.length <= 0 ||
        radioFormData?.packageHeight.length <= 0 ||
        error?.postalCode?.packageFrom ||
        error?.postalCode?.packageTo)
    ) {
      setError((prevError) => {
        return {
          ...prevError,
          countryInput: {
            ...prevError.countryInput,
            packageFrom: countryInput.packageFrom.length <= 0,
            packageTo: countryInput.packageTo.length <= 0,
          },
          postalCode: {
            ...prevError.postalCode,
            packageFrom: postalCode.packageFrom.length <= 0 || error?.postalCode?.packageFrom,
            packageTo: postalCode.packageTo.length <= 0 || error?.postalCode?.packageTo,
          },
          radioFormData: {
            ...prevError.radioFormData,
            packageWeight: !(radioFormData.packageWeight.length > 0),
            packageLength: !(radioFormData?.packageLength.length > 0),
            packageWidth: !(radioFormData?.packageWidth.length > 0),
            packageHeight: !(radioFormData?.packageHeight.length > 0),
          },
        };
      });
    } else if (
      selectedRadio === "Document" &&
      (countryInput.docFrom.length <= 0 ||
        countryInput.docTo.length <= 0 ||
        postalCode.docFrom.length <= 0 ||
        postalCode.docTo.length <= 0 ||
        radioFormData?.documentWeight <= 0)
    ) {
      setError((prevError) => ({
        ...prevError,
        countryInput: {
          ...prevError.countryInput,
          docFrom: countryInput.docFrom.length <= 0,
          docTo: countryInput.docTo.length <= 0,
        },
        postalCode: {
          ...prevError.postalCode,
          docFrom: postalCode.docFrom.length <= 0,
          docTo: postalCode.docTo.length <= 0,
        },
        radioFormData: {
          ...prevError.radioFormData,
          documentWeight: !(radioFormData.documentWeight.length > 0),
        },
      }));
    } else if (
      selectedRadio === "LTL Freight" &&
      (countryInput.freightFrom.length <= 0 ||
        countryInput.freightTo.length <= 0 ||
        postalCode.freightFrom.length <= 0 ||
        postalCode.freightTo.length <= 0 ||
        radioFormData?.unit.length <= 0 ||
        radioFormData.freightWeight.length <= 0 ||
        radioFormData?.freightLength.length <= 0 ||
        radioFormData?.freightWidth.length <= 0 ||
        radioFormData?.freightHeight.length <= 0)
    ) {
      setError((prevError) => ({
        ...prevError,
        countryInput: {
          ...prevError.countryInput,
          freightFrom: countryInput.freightFrom.length <= 0,
          freightTo: countryInput.freightTo.length <= 0,
        },
        postalCode: {
          ...prevError.postalCode,
          freightFrom: postalCode.freightFrom.length <= 0,
          freightTo: postalCode.freightTo.length <= 0,
        },
        radioFormData: {
          ...prevError.radioFormData,
          unit: !(radioFormData.unit.length > 0),
          freightWeight: !(radioFormData.freightWeight.length > 0),
          freightLength: !(radioFormData.freightLength.length > 0),
          freightWidth: !(radioFormData.freightWidth.length > 0),
          freightHeight: !(radioFormData.freightHeight.length > 0),
        },
      }));
    } else if (countryDataService?.fromCountryCode === undefined) {
      setError((prevError) => ({
        ...prevError,
        countryInput: {
          ...prevError.countryInput,
          freightFrom: true,
          docFrom: true,
          packageFrom: true,
        },
      }));
    } else if (countryDataService?.toCountryCode === undefined) {
      setError((prevError) => ({
        ...prevError,
        countryInput: {
          ...prevError.countryInput,
          freightTo: true,
          docTo: true,
          packageTo: true,
        },
      }));
    } else {
      const uniquesessionid = localStorage.getItem("uniquesessionid");
      if (!uniquesessionid) {
        const newUniqueID = uuidv4();
        localStorage.setItem("uniquesessionid", newUniqueID);
      }
      if (uniquesessionid) {
        dispatch(
          fetchShippingRates({
            fromCountryCode: countryDataService.fromCountryCode
              ? countryDataService.fromCountryCode
              : "",
            fromPostalCode:
              selectedRadio === "Package"
                ? postalCode?.packageFrom
                : "" || selectedRadio === "Document"
                  ? postalCode?.docFrom
                  : "" || selectedRadio === "LTL Freight"
                    ? postalCode?.freightFrom
                    : "",
            toPostalCode:
              selectedRadio === "Package"
                ? postalCode?.packageTo
                : "" || selectedRadio === "Document"
                  ? postalCode?.docTo
                  : "" || selectedRadio === "LTL Freight"
                    ? postalCode?.freightTo
                    : "",

            toCountryCode: countryDataService.toCountryCode
              ? countryDataService.toCountryCode
              : "",
            fromCountryId: countryDataService?.fromCountryId,
            toCountryId: countryDataService?.toCountryId,
            package_type: selectedRadio ? selectedRadio : "Package",
            coupon_code: "",
            customer_id: useriD ? useriD?.id : "",
            unit: [radioFormData?.unit],
            document_weigth: radioFormData?.document_weigth,
            weight: [
              selectedRadio === "Package"
                ? radioFormData?.packageWeight
                : "" || selectedRadio === "Document"
                  ? radioFormData?.documentWeight
                  : "" || selectedRadio === "LTL Freight"
                    ? radioFormData?.freightWeight
                    : "",
            ],
            height: [
              selectedRadio === "Package"
                ? radioFormData?.packageHeight
                : "" || selectedRadio === "LTL Freight"
                  ? radioFormData?.freightHeight
                  : "",
            ],
            length: [
              selectedRadio === "Package"
                ? radioFormData?.packageLength
                : "" || selectedRadio === "LTL Freight"
                  ? radioFormData?.freightLength
                  : "",
            ],
            width: [
              selectedRadio === "Package"
                ? radioFormData?.packageWidth
                : "" || selectedRadio === "LTL Freight"
                  ? radioFormData?.freightWidth
                  : "",
            ],
            weight_in: [radioFormData.weight_in],
            size_in: [radioFormData.size_in],
            qty: ["1"],
            uniquesessionid: uniquesessionid,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.status === true && response.statusCode === 200) {
              if (response.data.length > 0) {

                // localStorage.setItem("countryNames", JSON.stringify(countryNames));
                localStorage.setItem("searchData", JSON.stringify(data));
                localStorage.setItem(
                  "multiship_data",
                  JSON.stringify(measurmemnt)
                );
                navigate("/service");
              }
            }
            if (response.status === false && response.statusCode === 503) {
              setError((prevError) => ({
                ...prevError,
                serverError: response?.errors?.invalid_data[0]
              }));
            }
          })
          .catch((error) => {
            if (error?.status === 429 || error?.status === 500) {
              // CustomAlert("Something went wrong! please try some time latter");
              setError((prevError) => ({
                ...prevError,
                serverError: "Something went wrong! please try some time latter"
              }));
            }
          });
      } else {
        const newUniqueID = uuidv4();
        localStorage.setItem("uniquesessionid", newUniqueID);
        if (newUniqueID) {
          dispatch(
            fetchShippingRates({
              fromCountryCode: countryDataService.fromCountryCode
                ? countryDataService.fromCountryCode
                : "",
              fromPostalCode:
                selectedRadio === "Package"
                  ? postalCode?.packageFrom
                  : "" || selectedRadio === "Document"
                    ? postalCode?.docFrom
                    : "" || selectedRadio === "LTL Freight"
                      ? postalCode?.freightFrom
                      : "",
              toPostalCode:
                selectedRadio === "Package"
                  ? postalCode?.packageTo
                  : "" || selectedRadio === "Document"
                    ? postalCode?.docTo
                    : "" || selectedRadio === "LTL Freight"
                      ? postalCode?.freightTo
                      : "",

              toCountryCode: countryDataService.toCountryCode
                ? countryDataService.toCountryCode
                : "",
              fromCountryId: countryDataService?.fromCountryId,
              toCountryId: countryDataService?.toCountryId,
              package_type: selectedRadio ? selectedRadio : "Package",
              coupon_code: "",
              customer_id: useriD ? useriD?.id : "",
              unit: [radioFormData?.unit],
              document_weigth: radioFormData?.document_weigth,
              weight: [
                selectedRadio === "Package"
                  ? radioFormData?.packageWeight
                  : "" || selectedRadio === "Document"
                    ? radioFormData?.documentWeight
                    : "" || selectedRadio === "LTL Freight"
                      ? radioFormData?.freightWeight
                      : "",
              ],
              height: [
                selectedRadio === "Package"
                  ? radioFormData?.packageHeight
                  : "" || selectedRadio === "LTL Freight"
                    ? radioFormData?.freightHeight
                    : "",
              ],
              length: [
                selectedRadio === "Package"
                  ? radioFormData?.packageLength
                  : "" || selectedRadio === "LTL Freight"
                    ? radioFormData?.freightLength
                    : "",
              ],
              width: [
                selectedRadio === "Package"
                  ? radioFormData?.packageWidth
                  : "" || selectedRadio === "LTL Freight"
                    ? radioFormData?.freightWidth
                    : "",
              ],
              weight_in: [radioFormData.weight_in],
              size_in: [radioFormData.size_in],
              qty: ["1"],
              uniquesessionid: uniquesessionid,
            })
          )
            .unwrap()
            .then((response) => {
              if (response.status === true && response.statusCode === 200) {
                if (response.data.length > 0) {
                  localStorage.setItem("searchData", JSON.stringify(data));
                  localStorage.setItem(
                    "multiship_data",
                    JSON.stringify(measurmemnt)
                  );
                  navigate("/service");
                }
              }
            })
            .catch((error) => {
              if (error?.status === 429 || error?.status === 500) {
                // CustomAlert("Something went wrong! please try some time latter");
                setError((prevError) => ({
                  ...prevError,
                  serverError: "Something went wrong! please try some time latter"
                }));
              }
            });
        } else {
          const newUniqueID = uuidv4();
          localStorage.setItem("uniquesessionid", newUniqueID);
        }

      }
    }
  };

  const handleWeightChange = (event) => {
    setRadioFormData((prevData) => ({
      ...prevData,
      weight_in: event.target.value,
    }));
    setError((prevError) => ({
      ...prevError,
      radioFormData: {
        ...prevError.radioFormData,
        documentWeight: false,
      },
    }));
  };

  const handleSizeChange = (event) => {
    setRadioFormData({ ...radioFormData, size_in: event.target.value });
  };

  const handleChange = (fieldName, event, newValue) => {
    // if (event !== null) {
    // const { name, value, type, checked } = e.target;
    setPostalCode((prevState) => ({
      ...prevState,
      // [name]: value,
      [fieldName]: newValue && newValue,
    }));
    setError((prevError) => ({
      ...prevError,
      postalCode: {
        ...prevError.postalCode,
        [fieldName]: false,
      },
    }));
  };

  const handleChangeform = (e) => {
    const { name, value, type, checked } = e.target;
    setError((prevError) => ({
      ...prevError,
      radioFormData: {
        ...prevError.radioFormData,
        [name]: false,
      },
    }));
    const validationRules = {
      packageWeight: /^\d{0,2}(\.\d{0,2})?$/,
      documentWeight: /^\d{0,2}(\.\d{0,2})?$/,
      freightWeight: /^\d{0,2}(\.\d{0,2})?$/,
      unit: /^\d{0,2}$/,
      packageLength: /^\d{0,3}$/,
      packageWidth: /^\d{0,3}$/,
      packageHeight: /^\d{0,3}$/,
      freightLength: /^\d{0,3}$/,
      freightWidth: /^\d{0,3}$/,
      freightHeight: /^\d{0,3}$/,
    };
    if (validationRules.hasOwnProperty(name)) {
      if (!validationRules[name].test(value)) {
        return;
      }
    }
    let updatedValue = value;
    if (type === "number" && parseFloat(value) <= 0) {
      updatedValue = "";
    }
    let dataUpdated = {
      ...radioFormData,
      [name]: type === "checkbox" ? checked : updatedValue,
    };
    setRadioFormData((prevFormData) => {
      let updatedData = {
        ...prevFormData,
        ...dataUpdated,
      };
      return updatedData;
    });
  };

  return (
    <>
      {Landing_Hero && Landing_Hero && (
        <div className={` ${FooterHeroSection ? "herofooter  " : "herosection container-fluid"}`}>
          <div className={` ${FooterHeroSection ? "herofooterinner container p-0" : "back-globe container-fluid"}`}>
            {Landing_Hero.map((data, index) => {
              return (
                <>
                  <div className={` ${FooterHeroSection ? " p-4 w-100 " : " herocontainer container-fluid px-4 px-sm-0 px-lg-0"}`}>
                    <div className={`d-flex  ${FooterHeroSection ? " gap-4" : "gutter-x justify-content-between container "}`}>
                      <div className="hero-a  hero-a-tab " style={{ flex: FooterHeroSection ? 3 : 1 }}>
                        <div className={`d-flex flex-column gap-3  ${FooterHeroSection ? "" : ""}`}>
                          {!FooterHeroSection && (
                            <>
                              <div className="d-flex flex-column gap-3 hero-input-sm">
                                <div className="get-budget-holder">
                                  <div className="">
                                    <h4 className="m-0 ">
                                      {data.heading
                                        ? data.heading
                                        : "Shipping to and from anywhere"}
                                    </h4>
                                    <h3 className="m-0 get-budgeth">
                                      {data.description
                                        ? data.description
                                        : "Get the Budget-friendly Shipping Rates"}
                                    </h3>
                                  </div>
                                </div>

                                <div className="">
                                  <Form className="d-flex">
                                    {["Package"].map(
                                      (type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radio-buttons mb-0 text-nowrap"
                                        >
                                          <Form.Check
                                            inline
                                            label={type}
                                            className=""
                                            name="packaging"
                                            type="radio"
                                            id={`inline-${type}-1`}
                                            checked={selectedRadio === type}
                                            value={type}
                                            onChange={handleRadioChangeHeroSection}
                                          />
                                        </div>
                                      )
                                    )}
                                  </Form>
                                </div>
                              </div>
                            </>
                          )}

                          {selectedRadio === "Package" && (
                            <>
                              <div className="d-flex flex-column gap-3 hero-input-sm">
                                <div className="">
                                  <div className="p-0 d-flex gap-3 flex-column flex-lg-row">
                                    <div className="p-0 " style={{ flex: "1" }}>
                                      <label className="px-1">
                                        <b>From</b>
                                      </label>
                                      <div className="d-flex gap-3 gap-sm-3 gap-lg-2  ">
                                        <div
                                          className="p-0 flex-input-mobile"
                                          style={{ flex: "3" }}
                                        >
                                          <div>
                                            <div className="paper-from-country">
                                              <Paper
                                                className={`paddingclasshero-package-form-to w-100 ${error?.countryInput?.packageFrom ? "is-invalid-paper" : FooterHeroSection ? "is-valid-paper my-paper" : "hero-paper"}`}
                                                sx={{ borderRadius: "6px" }}
                                              >
                                                <CustomAutocomplete
                                                  options={countryName?.map(
                                                    (data) => data.name
                                                  )}
                                                  value={
                                                    countryInput.packageFrom
                                                  }
                                                  loading={isCountryLoading}
                                                  onInputChange={(
                                                    event,
                                                    newInputValue
                                                  ) => {
                                                    setPostalOptions([]);
                                                    setPostalToOptions([]);
                                                    setPostalCode({
                                                      ...postalCode,
                                                      packageFrom: "",
                                                    });
                                                    setCountryInput({
                                                      ...countryInput,
                                                      packageFrom:
                                                        newInputValue,
                                                    });
                                                    setError((prevError) => ({
                                                      ...prevError,
                                                      countryInput: {
                                                        ...prevError.countryInput,
                                                        packageFrom: false,
                                                      },
                                                    }));
                                                  }}
                                                  placeholder={"Country"}
                                                />
                                              </Paper>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="p-0"
                                          style={{ flex: "2" }}
                                        >
                                          <div className="paper-from-postalcode">
                                            <Paper
                                              className={`paddingclasshero-package-form-to ${error?.postalCode?.packageFrom ? "is-invalid-paper" : FooterHeroSection ? "is-valid-paper my-paper" : "hero-paper"}`}
                                              sx={{
                                                width: "100%",
                                                borderRadius: "6px",
                                              }}
                                            >
                                              <CustomAutocomplete
                                                value={postalCode.packageFrom}
                                                disabled
                                                loading={isPostalLoading}
                                                name={"packageFrom"}
                                                options={postalOptions.map(
                                                  (pincodes) =>
                                                    pincodes.pincode_no
                                                )}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  handleChange(
                                                    "packageFrom",
                                                    event,
                                                    newInputValue
                                                  );
                                                }}
                                                placeholder={"Postal"}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="p-0 to-input"
                                      style={{ flex: "1" }}
                                    >
                                      <label className="px-1 px-lg-1">
                                        <b>To</b>
                                      </label>
                                      <div className="d-flex gap-3 gap-sm-3 gap-lg-2">
                                        <div
                                          className="p-0 flex-input-mobile"
                                          style={{ flex: "3" }}
                                        >
                                          <div>
                                            <div className="paper-from-country">
                                              <Paper
                                                className={`paddingclasshero-package-form-to w-100 ${error?.countryInput?.packageTo ? "is-invalid-paper" : FooterHeroSection ? "is-valid-paper my-paper" : "hero-paper"}`}
                                                sx={{ borderRadius: "6px" }}
                                              >
                                                <CustomAutocomplete
                                                  options={countryName?.map(
                                                    (data) => data.name
                                                  )}
                                                  loading={isCountryLoading}
                                                  value={countryInput.packageTo}
                                                  onInputChange={(
                                                    event,
                                                    newInputValue
                                                  ) => {
                                                    setPostalOptions([]);
                                                    setPostalToOptions([]);
                                                    setPostalCode({
                                                      ...postalCode,
                                                      packageTo: "",
                                                    });
                                                    setCountryInput({
                                                      ...countryInput,
                                                      packageTo: newInputValue,
                                                    });
                                                    setError((prevError) => ({
                                                      ...prevError,
                                                      countryInput: {
                                                        ...prevError.countryInput,
                                                        packageTo: false,
                                                      },
                                                    }));
                                                  }}
                                                  placeholder={"Country"}
                                                />
                                              </Paper>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="p-0"
                                          style={{ flex: "2" }}
                                        >
                                          <div className="paper-to-postal">
                                            <Paper
                                              className={`paddingclasshero-package-form-to ${error?.postalCode?.packageTo ? "is-invalid-paper" : FooterHeroSection ? "is-valid-paper my-paper" : "hero-paper"}`}
                                              sx={{
                                                width: "100%",
                                                borderRadius: "6px",
                                              }}
                                            >
                                              <CustomAutocomplete
                                                options={postalToOptions?.map(
                                                  (pincodes) =>
                                                    pincodes.pincode_no
                                                )}
                                                placeholder={"Postal"}
                                                name={"packageTo"}
                                                value={postalCode.packageTo}
                                                loading={isPostalToLoading}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  handleChange(
                                                    "packageTo",
                                                    event,
                                                    newInputValue
                                                  );
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className=" p-0">
                                  <div className="d-flex weight-size-col flex-column flex-lg-row gap-3 ">
                                    <div className="" style={{ flex: "2" }}>
                                      <label className="px-1">
                                        <b>Weight</b>
                                      </label>
                                      <div>
                                        {/* <Paper className={` ${error?.radioFormData?.packageWeight ? "is-invalid-paper" : ""}`} > */}
                                        <CustomWeight className="p-0"
                                          name={"packageWeight"}
                                          value={radioFormData.packageWeight}
                                          handleChangeform={handleChangeform}
                                          document_weight={weight_options}
                                          handleDocWeight={handleWeightChange}
                                          error={error?.radioFormData?.packageWeight}
                                          FooterHeroSection={FooterHeroSection}
                                        />
                                        {/* </Paper> */}
                                      </div>
                                    </div>
                                    <div className="" style={{ flex: "5" }}>
                                      <label className="px-1 px-lg-1">
                                        <b>Size</b>
                                      </label>
                                      <div className="d-flex">
                                        <Paper
                                          className={`d-flex paddingclasshero-size w-100 align-items-center py-1 pe-1 ${error?.radioFormData?.packageLength || error?.radioFormData?.packageHeight || error?.radioFormData?.packageWidth ? "is-invalid-paper" : FooterHeroSection ? "is-valid-paper my-paper" : "hero-paper"} `}
                                          sx={{ borderRadius: "6px" }}
                                        >
                                          <InputBase
                                            type="Number"
                                            name="packageLength"
                                            onChange={handleChangeform}
                                            placeholder="Length"
                                            value={radioFormData.packageLength}
                                            className="heroinputs input-text input-placeholder px-2"
                                          />
                                          {/* <div>
                                            
                                          </div> */}
                                          {/* <IconButton name="close" disabled>
                                          </IconButton> */}
                                          <CloseIcon className="dimention-icon my-2 mx-2" />
                                          <InputBase
                                            type="Number"
                                            onChange={handleChangeform}
                                            name="packageWidth"
                                            placeholder="Width"
                                            value={radioFormData.packageWidth}
                                            className="heroinputs input-text input-placeholder px-2"
                                          />
                                          <CloseIcon className="dimention-icon my-2 mx-2" />
                                          {/* <IconButton name="close" disabled>
                                          </IconButton> */}
                                          <InputBase
                                            type="Number"
                                            name="packageHeight"
                                            value={radioFormData.packageHeight}
                                            onChange={handleChangeform}
                                            placeholder="Height"
                                            className="heroinputs input-text input-placeholder px-2"
                                          />
                                          <label
                                            htmlFor="size"
                                            className="d-flex "
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;
                                          </label>
                                          <select
                                            id="size"
                                            aria-label="Select size"
                                            className="custom-weigth-select-field custom-weigth-select-field"
                                            onChange={handleSizeChange}
                                          >
                                            {size_option.map(
                                              (option, index) => {
                                                return (
                                                  <option key={index}>
                                                    {option}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </Paper>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {selectedRadio === "Document" && (
                            <>
                              <div className="d-flex flex-column gap-3 ">
                                <div className="">
                                  <div className="p-0 d-flex gap-3 flex-column flex-lg-row">
                                    <div className="p-0" style={{ flex: "1" }}>
                                      <label className="px-1">
                                        <b>From</b>
                                      </label>
                                      <div className="d-flex gap-3 gap-sm-3 gap-lg-2 ">
                                        <div
                                          className="p-0 flex-input-mobile"
                                          style={{ flex: "3" }}
                                        >
                                          <div className="paper-from-country">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100   ${error?.countryInput?.docFrom
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={countryName?.map(
                                                  (data) => data.name
                                                )}
                                                loading={isCountryLoading}
                                                value={countryInput.docFrom}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  setPostalOptions([]);
                                                  setPostalToOptions([]);
                                                  setPostalCode({
                                                    ...postalCode,
                                                    docFrom: "",
                                                  });
                                                  setCountryInput({
                                                    ...countryInput,
                                                    docFrom: newInputValue,
                                                  });
                                                  setError((prevError) => ({
                                                    ...prevError,
                                                    countryInput: {
                                                      ...prevError.countryInput,
                                                      docFrom: false,
                                                    },
                                                  }));
                                                }}
                                                placeholder={"Country"}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                        <div
                                          className="p-0"
                                          style={{ flex: "2" }}
                                        >
                                          <div className="paper-from-postalcode">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100  ${error?.postalCode?.docFrom
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={postalOptions?.map(
                                                  (pincodes) =>
                                                    pincodes.pincode_no
                                                )}
                                                name={"docFrom"}
                                                placeholder={"Postal"}
                                                value={postalCode.docFrom}
                                                loading={isPostalLoading}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  handleChange(
                                                    "docFrom",
                                                    event,
                                                    newInputValue
                                                  );
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-0" style={{ flex: "1" }}>
                                      <label className="px-1">
                                        <b>To</b>
                                      </label>
                                      <div className="d-flex gap-3 gap-sm-3 gap-lg-2">
                                        <div
                                          className="p-0 flex-input-mobile"
                                          style={{ flex: "3" }}
                                        >
                                          <div className="paper-to-country">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100  ${error?.countryInput?.docTo
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{
                                                borderRadius: "6px",
                                                fontFamily:
                                                  "Kanit sans-serif !important",
                                              }}
                                            >
                                              <CustomAutocomplete
                                                options={countryName?.map(
                                                  (data) => data.name
                                                )}
                                                loading={isCountryLoading}
                                                value={countryInput.docTo}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  setPostalOptions([]);
                                                  setPostalToOptions([]);
                                                  setPostalCode({
                                                    ...postalCode,
                                                    docTo: "",
                                                  });
                                                  setCountryInput({
                                                    ...countryInput,
                                                    docTo: newInputValue,
                                                  });
                                                  setError((prevError) => ({
                                                    ...prevError,
                                                    countryInput: {
                                                      ...prevError.countryInput,
                                                      docTo: false,
                                                    },
                                                  }));
                                                }}
                                                placeholder={"Country"}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                        <div
                                          className="p-0"
                                          style={{ flex: "2" }}
                                        >
                                          <div className="paper-to-postal">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100  ${error?.postalCode?.docTo
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={postalToOptions?.map(
                                                  (pincodes) =>
                                                    pincodes.pincode_no
                                                )}
                                                placeholder={"Postal"}
                                                name={"docTo"}
                                                value={postalCode.docTo}
                                                loading={isPostalToLoading}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  handleChange(
                                                    "docTo",
                                                    event,
                                                    newInputValue
                                                  );
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="">
                                  <div className="p-0 d-flex gap-3">
                                    <div className="p-0" style={{ flex: "1" }}>
                                      <label className="px-1">
                                        <b>Weight</b>
                                      </label>
                                      <div className="">
                                        <div className="p-0">
                                          <div className="paper-from-country">
                                            <Paper
                                              className={` ${error?.radioFormData
                                                ?.documentWeight
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                            >
                                              <CustomWeight
                                                name={"documentWeight"}
                                                value={
                                                  radioFormData.documentWeight
                                                }
                                                handleChangeform={
                                                  handleChangeform
                                                }
                                                document_weight={
                                                  document_weigth
                                                }
                                                handleDocWeight={
                                                  handleWeightChange
                                                }
                                                errorDoc={
                                                  error?.radioFormData
                                                    ?.documentWeight
                                                }
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-0" style={{ flex: "1" }}>
                                      <label className="px-1">
                                        <b>Packaging</b>
                                      </label>
                                      <div className="">
                                        <div className="p-0">
                                          <div className="paper-to-country d-flex">
                                            <Paper
                                              className="paddingclasshero-weight w-100"
                                              sx={{
                                                borderRadius: "6px",
                                                padding: "8px",
                                              }}
                                            >
                                              <IconButton
                                                disabled
                                                name="close"
                                                type="icon"
                                                aria-label="search"
                                                className="px-0"
                                              >
                                                <MoreVertIcon
                                                  style={{
                                                    color: "white",
                                                    width: "0px",
                                                  }}
                                                />
                                              </IconButton>
                                              <select
                                                name="country"
                                                className="w-100 px-2 custom-weigth-select-field custom-weigth-select-field"
                                                style={{ color: "black" }}
                                              >
                                                <option
                                                  value="City1"
                                                  className=""
                                                >
                                                  Private
                                                </option>
                                                <option
                                                  value="City2"
                                                  className=""
                                                >
                                                  Pre-printed Envelope
                                                </option>
                                              </select>
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {selectedRadio === "LTL Freight" && (
                            <>
                              <div className="d-flex flex-column gap-3">
                                <div className="">
                                  <div className="p-0 d-flex gap-3 flex-column flex-lg-row">
                                    <div className="p-0 ">
                                      <label className="px-1">
                                        <b>From</b>
                                      </label>
                                      <div className="d-flex gap-3 gap-sm-3 gap-lg-2">
                                        <div
                                          className="p-0 flex-input-mobile"
                                          style={{ flex: "3" }}
                                        >
                                          <div className="paper-from-country">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100  ${error?.countryInput?.freightFrom
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={countryName?.map(
                                                  (data) => data.name
                                                )}
                                                loading={isCountryLoading}
                                                value={countryInput.freightFrom}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  setPostalOptions([]);
                                                  setPostalToOptions([]);
                                                  setPostalCode({
                                                    ...postalCode,
                                                    freightFrom: "",
                                                  });
                                                  setCountryInput({
                                                    ...countryInput,
                                                    freightFrom: newInputValue,
                                                  });
                                                  setError((prevError) => ({
                                                    ...prevError,
                                                    countryInput: {
                                                      ...prevError.countryInput,
                                                      freightFrom: false,
                                                    },
                                                  }));
                                                }}
                                                placeholder={"Country"}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                        <div
                                          className="p-0"
                                          style={{ flex: "2" }}
                                        >
                                          <div className="paper-from-postalcode">
                                            <Paper
                                              className={`paddingclasshero-package-form-to  ${error?.postalCode?.freightFrom
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={postalOptions?.map(
                                                  (pincodes) =>
                                                    pincodes.pincode_no
                                                )}
                                                name={"freightFrom"}
                                                placeholder={"Postal"}
                                                value={postalCode.freightFrom}
                                                // inputValue={postalCode.pincode}
                                                loading={isPostalLoading}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  handleChange(
                                                    "freightFrom",
                                                    event,
                                                    newInputValue
                                                  );
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-0 to-input">
                                      <label className="px-1 px-lg-1">
                                        <b>To</b>
                                      </label>
                                      <div className="d-flex gap-3 gap-sm-3 gap-lg-2">
                                        <div
                                          className="p-0 flex-input-mobile"
                                          style={{ flex: "3" }}
                                        >
                                          <div className="paper-to-country">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100  ${error?.countryInput?.freightTo
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={countryName?.map(
                                                  (data) => data.name
                                                )}
                                                loading={isCountryLoading}
                                                value={countryInput.freightTo}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  setPostalOptions([]);
                                                  setPostalToOptions([]);
                                                  setPostalCode({
                                                    ...postalCode,
                                                    freightTo: "",
                                                  });
                                                  setCountryInput({
                                                    ...countryInput,
                                                    freightTo: newInputValue,
                                                  });
                                                  setError((prevError) => ({
                                                    ...prevError,
                                                    countryInput: {
                                                      ...prevError.countryInput,
                                                      freightTo: false,
                                                    },
                                                  }));
                                                }}
                                                placeholder={"Country"}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                        <div
                                          className="p-0"
                                          style={{ flex: "2" }}
                                        >
                                          <div className="paper-to-postal d-flex">
                                            <Paper
                                              className={`paddingclasshero-package-form-to w-100  ${error?.postalCode?.freightTo
                                                ? "is-invalid-paper"
                                                : "hero-paper"
                                                }`}
                                              sx={{ borderRadius: "6px" }}
                                            >
                                              <CustomAutocomplete
                                                options={postalToOptions?.map(
                                                  (pincodes) =>
                                                    pincodes.pincode_no
                                                )}
                                                name={"freightTo"}
                                                value={postalCode.freightTo}
                                                placeholder={"Postal"}
                                                loading={isPostalToLoading}
                                                onInputChange={(
                                                  event,
                                                  newInputValue
                                                ) => {
                                                  handleChange(
                                                    "freightTo",
                                                    event,
                                                    newInputValue
                                                  );
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="p-0">
                                  <div className="d-flex d-lg-flex gap-3 flex-column flex-lg-row">
                                    <div className="d-flex gap-3">
                                      <div
                                        className="p-0 paper-unit"
                                        style={{ flex: "2" }}
                                      >
                                        <label className="px-1">
                                          <b>Unit(Nos)</b>
                                        </label>
                                        <div className="">
                                          <div className="p-0">
                                            <div className="paper-to-country d-flex">
                                              <Paper
                                                className={`paddingclasshero-weight w-100  ${error?.radioFormData?.unit
                                                  ? "is-invalid-paper"
                                                  : "hero-paper"
                                                  }`}
                                                sx={{ borderRadius: "6px" }}
                                              >
                                                <IconButton
                                                  disabled
                                                  name="close"
                                                  type="icon"
                                                  aria-label="search"
                                                  className="px-0"
                                                >
                                                  <MoreVertIcon
                                                    style={{
                                                      color: "white",
                                                      width: "0px",
                                                    }}
                                                  />
                                                </IconButton>
                                                <InputBase
                                                  name="unit"
                                                  onChange={handleChangeform}
                                                  value={radioFormData?.unit}
                                                  type="number"
                                                  placeholder="E.g. 20"
                                                  autoComplete="off"
                                                  className="px-2"
                                                />
                                              </Paper>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="paper-ltl-weight"
                                        style={{ flex: "5" }}
                                      >
                                        <label className="px-1">
                                          <b>Weight</b>
                                        </label>
                                        <div className="">
                                          <Paper
                                            className={` ${error?.radioFormData
                                              ?.freightWeight
                                              ? "is-invalid-paper"
                                              : "hero-paper"
                                              }`}
                                          >
                                            <CustomWeight
                                              name={"freightWeight"}
                                              value={
                                                radioFormData.freightWeight
                                              }
                                              handleChangeform={
                                                handleChangeform
                                              }
                                              document_weight={weight_options}
                                              handleDocWeight={
                                                handleWeightChange
                                              }
                                            />
                                          </Paper>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="" style={{ flex: "8" }}>
                                        <label className="px-1">
                                          <b>Size</b>
                                        </label>
                                        <div className="d-flex">
                                          <Paper
                                            className={`d-flex paddingclasshero-size w-100  hero-paper${error?.radioFormData
                                              ?.freightLength ||
                                              error?.radioFormData
                                                ?.freightWidth ||
                                              error?.radioFormData
                                                ?.freightHeight
                                              ? "is-invalid-paper"
                                              : "hero-paper"
                                              }`}
                                            sx={{ borderRadius: "6px" }}
                                          >
                                            <InputBase
                                              type="Number"
                                              name="freightLength"
                                              onChange={handleChangeform}
                                              value={
                                                radioFormData.freightLength
                                              }
                                              placeholder="Length"
                                              className="heroinputs input-text input-placeholder px-2"
                                            />
                                            <IconButton name="close" disabled>
                                              <CloseIcon className="dimention-icon" />
                                            </IconButton>
                                            <InputBase
                                              type="Number"
                                              onChange={handleChangeform}
                                              name="freightWidth"
                                              value={radioFormData.freightWidth}
                                              placeholder="Width"
                                              className="heroinputs input-text input-placeholder px-2"
                                            />
                                            <IconButton name="close" disabled>
                                              <CloseIcon className="dimention-icon" />
                                            </IconButton>
                                            <InputBase
                                              type="Number"
                                              name="freightHeight"
                                              onChange={handleChangeform}
                                              value={
                                                radioFormData.freightHeight
                                              }
                                              placeholder="Height"
                                              className="heroinputs input-text input-placeholder px-2"
                                            />
                                            <select
                                              className="custom-weigth-select-field custom-weigth-select-field"
                                              onChange={handleWeightChange}
                                            >
                                              {size_option.map(
                                                (option, index) => {
                                                  return (
                                                    <option key={index}>
                                                      {option}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </Paper>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <p className="error-text">{error?.serverError}</p>
                          {/* {errors?.invalid_data && <p className="error-text">{errors?.invalid_data}</p>} */}
                          <div lg={7} className="herosection-btn g-0 mt-3">
                            {!isLoading ? (
                              <Button className="compareandsave" onClick={handleCompareSave} type="button" style={{ backgroundColor: "#007bff", color: "#ffffff" }} >
                                COMPARE & SAVE
                              </Button>
                            ) : (
                              <Button className="compareandsave">
                                <Spinner animation="border" size="sm" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      {!FooterHeroSection && (
                        <>
                          <div className="hero-b d-none d-sm-none d-lg-flex hero-b-tab " style={{ flex: "1" }}  >
                            <div className="herosectionmain justify-content-center">
                              <div className="hero-image">
                                <Image
                                  src={
                                    data.image_with_path ||
                                    `${window.location.origin}/assets/herosection.png`
                                  }
                                  width={495.86}
                                  height={480}
                                  alt="delivery-boy"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {FooterHeroSection && <>
                        <div className="hero-b d-none d-sm-none d-lg-flex hero-b-tab " style={{ flex: FooterHeroSection ? 2 : 1 }} >
                          <div className="justify-content-center w-100">
                            <div className=" w-100 h-100">
                              <img alt="footer-hero" src={`${window.location.origin}/assets/footer/footer-hero.png`} />
                            </div>
                          </div>
                        </div>
                      </>}
                    </div>
                  </div >
                </>
              );
            })}
          </div>
        </div >
      )}
    </>
  );
};

export default HeroSection;
