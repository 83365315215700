import React, { useEffect, useState } from "react";
import { Card, Col, Button, Row } from "react-bootstrap";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import UserTrackShip from "./UserTrackShip";
import "../../assets/styles/userProfile/userMyOrderDetails.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Skeleton } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { formatPrice } from "../../utils/formatPrice";
import { trackshipment, trackshipmentsingle } from "../../redux/trackshipment/TrackshipThunk";
import { Spinner } from "react-bootstrap/esm";

const UserMyOrderDetailsChildDetails = ({
  item,
  setSelectedCard,
  getOrderStatusColor,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uniquesessionid = localStorage.getItem("uniquesessionid");

  const isOrderLoading = useSelector(
    (state) => state.profileData?.isOrderLoading
  );
  const trackdata = useSelector(
    (state) => state?.Trackshipdetails?.TrashipDatasingle?.data
  );
  const isTrackshipLoading = useSelector(
    (state) => state?.Trackshipdetails?.isTrackshipLoadingsingle
  );
  const [order, setOrder] = useState();
  const [tracking, setTracking] = useState(false)
  const [indexNumber, setindexNumber] = useState()
  const url = window.location.href;
  const parts = url.split('/');
  const dynamicPart = parts[parts.length - 1];
  const queryParams = dynamicPart.split('?');
  const orderId = queryParams[1];
  const orderList = useSelector(
    (state) => state.profileData?.orderDetailsData?.Orders?.data
  );
  const [isLoading, setIsLoading] = useState(Array(order?.order_package?.length).fill(false));

  useEffect(() => {
    if (orderList) {
      if (orderId) {
        const data = orderList?.find((i) => {
          return i?.id === Number(orderId);
        });
        setOrder(data)
        // handleViewMore(data);
      }
    }
  }, [orderId, orderList]);

  const myorderclick = () => {
    setSelectedCard(false);
    navigate("/user/myorders");
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleTrackClick = (index) => {
    setindexNumber(index)
    if (order && order.order_package && order.order_package.length > 0) {
      const trackingNumber = order.order_package[index]?.tracking_number;
      if (trackingNumber) {
        setIsLoading(prevState => {
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });

        dispatch(
          trackshipmentsingle({
            trackNumber: trackingNumber,
            uniquesessionid: uniquesessionid,
            courier_id: order.courier_id,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.status === true && response.statusCode === 200) {
              setTracking(true)
            }
            setIsLoading(prevState => {
              const newState = [...prevState];
              newState[index] = false;
              return newState;
            });
          });
      }
    }
  }


  return (
    <>
      {!isOrderLoading ? (
        <>
          <div className="order-details d-flex" style={{ backgroundColor: "#f5f5f5", paddingBottom: "10px" }}  >
            <div className="d-flex align-items-center">
              <p
                className="paragraph1"
                onClick={() => myorderclick()}
                style={{
                  color: "#999999",
                  // cursor: "pointer",
                  marginRight: "5px",
                }}
              >
                &nbsp;My Account
              </p>
              <NavigateNextIcon
                style={{ fontSize: "22px", marginTop: "2px", color: "#999999" }}
              />
            </div>
            <div className="">
              <p
                className="MyOrders paragraph1"
                aria-current="page"
              >
                My Orders
              </p>
            </div>
          </div>

          <Card className="border-0 rounded-3">
            <Card.Body className="d-flex flex-column gap-lg-2 gap-3 gap-lg-3">
              <>
                <div className="d-flex gap-2 gap-lg-3 flex-column flex-lg-row">
                  <div className="d-flex gap-3 flex-row">
                    <div className="">
                      <div
                        className="text-nowrap"
                        style={{
                          color: "#113468",
                          width: "fit-content",
                          borderRadius: "4px",
                          backgroundColor: getOrderStatusColor(
                            item?.orderdetails?.order_status
                          ),
                        }}    >
                        <p className="m-0 fw400 px-2" style={{ fontSize: "12px" }}  >
                          {/* {order?.order_status} */}
                          {order?.has_drop_off_service === "Yes" ? "Drop-off" : "Pickup"}
                        </p>
                      </div>
                    </div>
                    <div className="text-nowrap">
                      <p className="paragraph2 text-center">
                        {" "}
                      </p>
                    </div>
                  </div>

                  <div className=" d-lg-flex gap-lg-3 d-sm-flex gap-sm-3 flex-sm-row">
                    <div className="">
                      <p className="paragraph2">
                        <b>Order ID:</b> {order?.id}
                      </p>
                    </div>
                    <div className="">
                      <p className="paragraph2">
                        <b>Order Date & Time:</b> {order?.created_at}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="m-0">
                  <hr className="payment-confirm-hrline w-100" />
                </div>
                <div className="d-flex gap-lg-5 gap-3 flex-column flex-lg-row flex-sm-column ">
                  <div
                    className="  d-flex flex-column gap-3 gap-lg-2"
                    style={{ flex: "3" }}
                  >
                    <div className="d-flex gap-3  gap-lg-5 flex-column flex-sm-row  flex-lg-row">
                      <div className="" style={{ flex: "1" }}>
                        <p className="paragraph2 py-2">
                          <b>
                            <span className="order-confirm-p-head paragraph2 blue-text">
                              Shipper Details:
                            </span>
                          </b>
                        </p>
                        <div>
                          <hr className="payment-confirm-hrline w-100" />
                        </div>

                        <div className="order-confirm-p-head">
                          <p className="paragraph2">
                            <span className="order-confirm-p-head paragraph2">
                              {order?.collection_first_name}{" "}
                              {order?.collection_last_name}
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <p className="paragraph2 order-confirm-p-2">
                            {order?.collection_address1}
                          </p>
                          <p className="paragraph2 order-confirm-p-2">
                            {order?.collection_address2}
                          </p>
                          <p className="paragraph2 order-confirm-p-2">
                            {order?.collection_city}
                          </p>
                          <p className="paragraph2 order-confirm-p-2 gap-2 d-flex ">
                            <span>{order?.collection_state} -</span>
                            <span>{order?.collection_postcode}</span>
                            <span>{order?.collection_country?.name}</span>
                          </p>
                        </div>

                      </div>
                      <div className="" style={{ flex: "1" }}>
                        <p className="paragraph2 py-2">
                          <b>
                            <span className="order-confirm-p-head paragraph2 blue-text">
                              Receiver Details:
                            </span>
                          </b>
                        </p>
                        <div>
                          <hr className="payment-confirm-hrline w-100" />
                        </div>
                        <>
                          <div className="order-confirm-p-head">
                            <p className="paragraph2">
                              {" "}
                              <span className="order-confirm-p-head paragraph2">
                                {order?.delivery_first_name}{" "}
                                {order?.delivery_last_name}
                              </span>
                            </p>
                          </div>
                          <div className="">
                            <p className="paragraph2 order-confirm-p-2">
                              {order?.delivery_address1}
                            </p>
                            <p className="paragraph2 order-confirm-p-2">
                              {order?.delivery_address2}
                            </p>
                            <p className="paragraph2 order-confirm-p-2">
                              {order?.delivery_city}
                            </p>
                            <p className="paragraph2 order-confirm-p-2 d-flex gap-2">
                              <span>{order?.delivery_state} - </span>
                              <span>{order?.delivery_postcode}</span>
                              <span>{order?.delivery_country?.name}</span>
                            </p>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      {order?.order_package?.map((shipment, index) => (
                        <div key={index}>
                          <>
                            <div className="">
                              <div className="blue-text order-confirm-p-head py-1">
                                <p className="paragraph2">
                                  <b>Shipment : {index + 1}</b>
                                </p>
                              </div>
                              <div>
                                <hr className="payment-confirm-hrline w-100" />
                              </div>
                            </div>

                            <div className="d-flex flex-column flex-md-row">
                              <div style={{ flex: "3" }}>
                                <div className="w-75 d-flex gap-0 gap-lg-4  flex-column flex-lg-row flex-sm-row usermyorder-weight-holder">
                                  <div className=" d-flex gap-4 gap-lg-4 justify-content-between  ">
                                    <div className="shipment-details-text-p d-flex gap-4 gap-lg-3  ">
                                      <div className="d-flex gap-2 align-items-center ">
                                        <p className="order-confirm-p-head font-sm">
                                          Weight:
                                        </p>{" "}
                                        <p className="d-flex">
                                          <p>{shipment?.parcel_weight}</p>&nbsp;
                                          <p>{shipment?.parcel_weight_measure}</p>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="shipment-details-text-p d-flex gap-2 align-items-center ">
                                      <p className="order-confirm-p-head font-sm">
                                        Height:
                                      </p>{" "}
                                      <p className="d-flex">
                                        <p>{shipment?.parcel_height}</p>&nbsp;
                                        <p>{shipment?.parcel_dimension_in}</p>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex gap-4 gap-lg-4 justify-content-between  ">
                                    <div className="shipment-details-text-p d-flex gap-5 gap-lg-3 flex-row ">
                                      <div className="shipment-details-text-p d-flex gap-2 align-items-center ">
                                        <p className="order-confirm-p-head font-sm">
                                          Width:
                                        </p>{" "}
                                        <p className="d-flex">
                                          <p>{shipment?.parcel_width}</p>&nbsp;
                                          <p>{shipment?.parcel_dimension_in}</p>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="shipment-details-text-p d-flex  gap-2 align-items-center ">
                                      <p className="order-confirm-p-head font-sm">
                                        Length:
                                      </p>{" "}
                                      <p className="d-flex">
                                        <p>{shipment?.parcel_length}</p>&nbsp;
                                        <p>{shipment?.parcel_dimension_in}</p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-75 order-confirm-w">
                                  <div
                                    className="d-flex flex-column flex-sm-row flex-lg-row  justify-content-sm-start gap-sm-3 "
                                    style={{ flex: "2" }}
                                  >
                                    <div className="text-nowrap">
                                      <p className="order-confirm-p-head d-flex  font-sm">
                                        Parcel Contents:&nbsp;{" "}
                                        <p className="paragraph2">
                                          {shipment?.parcel_description}
                                        </p>
                                      </p>{" "}
                                    </div>
                                    <div className="">
                                      <p className="order-confirm-p-head d-flex font-sm">
                                        Value:&nbsp;{" "}
                                        <p className="paragraph2">
                                          {formatPrice(shipment?.value_of_items)}
                                        </p>
                                      </p>{" "}
                                    </div>
                                  </div>
                                  {shipment?.tracking_number && (
                                    <>
                                      <div className="text-nowrap">
                                        <p className="order-confirm-p-head d-flex font-sm">
                                          Tracking Number:&nbsp;{" "}
                                          <p className="paragraph2">
                                            {shipment?.tracking_number}
                                          </p>
                                        </p>{" "}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex align-items-center" style={{ flex: "1" }}>
                                <div className="w-100 d-flex justify-content-center">
                                  <div>
                                    {shipment?.tracking_number && (
                                      <>
                                        <Button
                                          className="text-decoration-none d-flex gap-2 w-100 p-0"
                                          variant="link"
                                          // onClick={() => handleTrackClick(index)}
                                          onClick={isTrackshipLoading ? null : () => handleTrackClick(index)}

                                          disabled={isTrackshipLoading && !isLoading[index]}
                                        >
                                          <span className="paragraph1 text-decoration-none m-0 text-nowrap w-100" style={{ color: "#113468", fontWeight: "400", fontSize: "18px" }}>
                                            {isLoading[index] ? (
                                              <div className="text-center">
                                                <Spinner animation="border" size="sm" />
                                              </div>
                                            ) : (
                                              <div className="d-flex align-items-center gap-2">
                                                Track Shipment <EastIcon />
                                              </div>
                                            )}
                                          </span>
                                        </Button>
                                      </>
                                    )}

                                  </div>
                                </div>
                              </div>
                            </div >
                          </>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-column" style={{ flex: "1" }}>
                    <p className="paragraph2 py-2">
                      <b>
                        <span className="order-confirm-p-head paragraph2 blue-text">
                          Payment Details:
                        </span>
                      </b>
                    </p>
                    <div className="mb-2">
                      <hr className="payment-confirm-hrline w-100" />
                    </div>
                    <div class="d-flex flex-column  align-items-center justify-content-center flex-wrap gap-2">
                      <img
                        src={order?.cardlogo}
                        alt="USPS Logo"
                        class="mr-md-auto"
                      />
                      <p class="mb-0">
                        {order?.courier_service_name}
                      </p>
                    </div>
                    <div className="w-100">
                      <Col className="d-flex justify-content-between align-items-center border-0 px-0">
                        <div>
                          <p className="mb-0 paragraph2 order-confirm-p-2">
                            Sub Total
                          </p>
                        </div>
                        <span>
                          <p className="mb-0 paragraph2 order-confirm-p-2">
                            <b>{formatPrice(order?.subtotal)}
                            </b>
                          </p>
                        </span>
                      </Col>
                      {order?.collection_charge && (
                        <Col className="d-flex justify-content-between align-items-center border-0 px-0">
                          <div>
                            <p className="mb-0 paragraph2 order-confirm-p-2">
                              Pickup Charge{" "}
                            </p>
                          </div>
                          <Col className="d-flex justify-content-end insurance">
                            <span>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                <b>{formatPrice(order?.collection_charge)}</b>
                              </p>
                            </span>
                          </Col>
                        </Col>
                      )}
                      {order?.orderdetails?.discount_amount && (
                        <Col className="d-flex justify-content-between align-items-center border-0 px-0">
                          <div>
                            <p className="mb-0 paragraph2 order-confirm-p-2">
                              Discount{" "}
                            </p>
                          </div>
                          <Col className="d-flex justify-content-end insurance">
                            <span>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                <b>{formatPrice(order?.orderdetails?.discount_amount)}</b>
                              </p>
                            </span>
                          </Col>
                        </Col>
                      )}
                      <Col className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                        <div>
                          <p className="mb-0 paragraph2 order-confirm-p-2">
                            TAX{" "}
                          </p>
                        </div>
                        <span>
                          <p className="mb-0 paragraph2 order-confirm-p-2">
                            <b>{formatPrice("0.00")}</b>
                          </p>
                        </span>
                      </Col>
                      {order?.is_enhanced_cover === "Yes" && (
                        <Col className="list-group-item d-flex align-items-center border-0 px-0">
                          <div className="d-flex">
                            <div>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                Insurance
                              </p>
                            </div>
                          </div>
                          <Col className="d-flex justify-content-end insurance">
                            <span>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                {order?.enhanced_cover_cost !== "0.00" ? (
                                  <b>{formatPrice(order?.enhanced_cover_cost)}</b>
                                ) : (
                                  <b>{formatPrice("0.00")}</b>
                                )}

                              </p>
                            </span>
                          </Col>
                        </Col>
                      )}
                      <hr className="doted-hr mt-1 mb-1" />
                      <Col className="list-group-item d-flex align-items-center border-0 px-0">
                        <div className="d-flex">
                          <div>
                            <span>
                              <p className="paragraph1 mb-0 p-0 blue-text">
                                <b>Amount Paid</b>
                              </p>
                            </span>
                          </div>
                        </div>
                        <Col className="d-flex justify-content-end insurance">
                          <span>
                            <span>
                              <p className="paragraph1 mb-0 blue-text">
                                <b>{formatPrice(order?.total)}</b>
                              </p>
                            </span>
                          </span>
                        </Col>
                      </Col>
                      <hr className=" mt-2 mb-2" />
                      <Col className="list-group-item d-flex align-items-center border-0 px-0">
                        <div>
                          <span>
                            <p className="paragraph2 mb-0 p-0 card-payment-text">
                              <b>Payment Paid By</b>
                            </p>
                          </span>
                        </div>
                        <Col className="d-flex justify-content-end">
                          <span>
                            <span>
                              <p
                                className="paragraph2 mb-0  card-payment-text"
                                style={{ color: "#6a6a6a" }}
                              >
                                <b>Wallet</b>
                              </p>
                            </span>
                          </span>
                        </Col>
                      </Col>
                    </div>
                  </div>
                </div>
                {tracking && !isTrackshipLoading && (
                  <>
                    <div className="">
                      <div className="blue-text order-confirm-p-head py-1">
                        <p className="paragraph2">
                          <b>Tracking Details of Shipment {indexNumber + 1}</b>
                        </p>
                      </div>
                      <div md={12}>
                        <hr className="payment-confirm-hrline" />
                      </div>
                    </div>
                    <div className="w-100 trackbox">
                      {trackdata &&<UserTrackShip isshowfirst={false} trackdataold={trackdata[0]} />}
                    </div>

                  </>
                )}
                {isTrackshipLoading && (
                  <>
                    <div className="">
                      <Card className="service-card-holder w-100">
                        <div className="px-3 py-5">
                          <Row className="d-flex align-items-center py-2">
                            <Col className="pe-0">
                              <Skeleton variant="text" />
                            </Col>
                            <Col lg={1} className="d-flex justify-content-center p-0 align-items-center">
                              <Skeleton variant="circular" width={30} />
                            </Col>
                            <Col className="p-0">
                              <Skeleton variant="text" />
                            </Col>
                            <Col lg={1} className="d-flex justify-content-center p-0 align-items-center">
                              <Skeleton variant="circular" width={30} />
                            </Col>
                            <Col className="p-0">
                              <Skeleton variant="text" />
                            </Col>
                            <Col lg={1} className="d-flex justify-content-center p-0 align-items-center">
                              <Skeleton variant="circular" width={30} />
                            </Col>
                            <Col className="p-0">
                              <Skeleton variant="text" />
                            </Col>
                            <Col lg={1} className="d-flex justify-content-center p-0 align-items-center">
                              <Skeleton variant="circular" width={30} />
                            </Col>
                            <Col className="p-0">
                              <Skeleton variant="text" />
                            </Col>
                            <Col lg={1} className="d-flex justify-content-center p-0 align-items-center">
                              <Skeleton variant="circular" width={30} />
                            </Col>
                            <Col className="ps-0">
                              <Skeleton variant="text" />
                            </Col>
                          </Row>
                        </div>
                        <div className="mx-2 py-2">
                          <Skeleton
                            variant="rounded"
                            height={150}
                            className="shipping-logo-img-div"
                          />
                        </div>
                      </Card>
                    </div>
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <div className="d-flex flex-column gap-1 gap-xxl-3">
            <Card className="border-0 order-details-card order-detail-child">
              <Card.Body>
                <div className=" w-100 border border-black d-flex flex-column gap-2 border border-danger">
                  <div className="d-flex gap-2 align-items-center  ">
                    <div className="flex-1">
                      <Skeleton
                        variant="square"
                        height={25}
                        className="rounded w-100"
                      />
                    </div>
                    <div className="flex-2 px-4">
                      <Skeleton
                        variant="square"
                        height={25}
                        className="rounded w-75"
                      />
                    </div>
                  </div>
                  <div className="d-flex w-100 gap-3 ">
                    <div className="flex-2 d-flex flex-column  gap-3">
                      <div className=" d-flex justify-content-between gap-3">
                        <div className=" flex-1 h-100 address_details d-flex flex-column gap-3 border border-danger ">
                          <Skeleton
                            variant="square"
                            height={15}
                            className="rounded"
                          />
                          <Skeleton
                            variant="square"
                            height={100}
                            className="rounded "
                          />
                        </div>
                        <div className="flex-1 h-100 d-flex flex-column gap-3">
                          <Skeleton
                            variant="square"
                            height={15}
                            className="rounded "
                          />
                          <Skeleton
                            variant="square"
                            height={100}
                            className="rounded "
                          />
                        </div>
                      </div>
                      <div className="w-75">
                        <Skeleton
                          variant="square"
                          height={60}
                          className="rounded w-20"
                        />
                      </div>
                    </div>
                    <div className="flex-1 p-3 ">
                      <Skeleton
                        variant="square"
                        className="rounded w-20 payment_details"
                      />
                    </div>
                    <div className="border-danger-subtle ">
                      <Skeleton
                        variant="square"
                        height={100}
                        className="rounded w-100 border border-1"
                      />
                    </div>
                  </div>
                  <div>
                    <Skeleton
                      variant="square"
                      height={100}
                      className="rounded "
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </>
      )
      }
    </>
  );
};

export default UserMyOrderDetailsChildDetails;
