import React, { useEffect, useState } from "react";
import { Card, Form, Modal, Tab, Tabs, Spinner } from "react-bootstrap";
import "../../assets/styles/userProfile/userWallet.css";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import OrderStripePayment from "../../pages/afterlogin/OrderStripePayment";
import { UserAutoreloadWallet, UserWallet, UserWallettransaction } from "../../redux/wallet/walletThunk";
import SwalFireComponent from "../common/SwalFireComponent";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, IconButton, Pagination, Skeleton, Tooltip } from "@mui/material";
import { getuserdetail } from "../../redux/user_profile/ProfileThunk";
import { formatPrice } from "../../utils/formatPrice";
import { resetErrors } from "../../redux/wallet/walletSlice"
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import ReactDatePicker from "react-datepicker";

const Wallet = () => {
  const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("user"));
  const cardDetails = useSelector(
    (state) => state?.addressValidation?.userCard?.WalletCard?.data
  );
  const isWalletLoading = useSelector(
    (state) => state?.WalletData?.isWalletLoading
  );
  // const isWallettransactionLoading = useSelector(
  //   (state) => state?.WalletData?.wallettransactionLoading
  // );
  const userData = useSelector(
    (state) => state.profileData?.getuserdetail
  );
  const wallettransaction = useSelector(
    (state) => state?.WalletData?.wallettransaction?.CustomerWallet?.data
  );
  const wallettransactionloading = useSelector(
    (state) => state?.WalletData?.wallettransactionLoading
  );
  const walleterror = useSelector(
    (state) => state?.WalletData?.walletData?.WalletCard?.errors
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [showError, setShowError] = useState({
    add: false,
    auto: false,
    autoamount: false,
    dropdown: false
  });
  const [key, setKey] = useState("addmoney");
  const [editMode, setEditMode] = useState({
    balance: userid?.autopayment_amount ? true : false,
    reload: userid?.autopayment_limit ? true : false,
  });
  const [formData, setFormData] = useState({
    below_amount: userid?.autopayment_limit || "",
    reload_amount: userid?.autopayment_amount || "",
    add_amount: "",
    is_autopay: false
  });
  const [showRadioForm, setShowRadioForm] = useState(userid?.is_autopay === "Yes" ? "On" : "Off");
  const [transactions, setTransactions] = useState([]);
  const [useridnew, setUser] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [transactionType, setTransactionType] = useState('ALL');
  const [showDatePickerstart, setShowDatePickerstart] = useState(false);
  const [showDatePickerend, setShowDatePickerend] = useState(false);
  const [loader, setloader] = useState(false);
  const rowsPerPage = 10; // Number of rows per page
  const [page, setPage] = useState(1); // Current page
  const [transactionnew, setnewtransaction] = useState();
  const [download, setdownload] = useState(false);


  useEffect(() => {
    if (userid) {
      setUser(userid)
      // setShowRadioForm(userid?.is_autopay === "Yes" ? "On" : "Off");
    }
    if (userData) {
      setUser(userData)
      // setShowRadioForm(userData?.is_autopay === "Yes" ? "On" : "Off");
    }
  }, [userData, userid])

  useEffect(() => {
    dispatch(
      getuserdetail({
        customer_id: userid?.id,
      })
    )
    if (wallettransaction) {
      setTransactions(wallettransaction)
    } else {
      dispatch(
        UserWallettransaction({
          customer_id: userid?.id,
          store: "list",
        })
      )
    }
  }, [wallettransaction])

  useEffect(() => {
    setPage(1)
    if (!startDate && !endDate) {
      if (transactionType === "DEBIT") {
        const filteredTransactions = wallettransaction?.filter(transaction => {
          return transaction?.transaction_type === "DEBIT";
        });
        setTransactions(filteredTransactions);
      } else if (transactionType === "CREDIT") {
        const filteredTransactions = wallettransaction?.filter(transaction => {
          return transaction?.transaction_type === "CREDIT";
        });
        setTransactions(filteredTransactions);
      } else {
        setTransactions(wallettransaction);
      }
    } else {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const filterEndDate = endDate || today;
      const filterStartDate = startDate ? startDate : new Date(filterEndDate.getTime() - (24 * 60 * 60 * 1000));

      // Filter transactions based on startDate, endDate, and transactionType
      const filteredTransactions = wallettransaction?.filter(transaction => {
        const transactionDate = new Date(transaction?.created_at);
        const isDateInRange = transactionDate >= filterStartDate && transactionDate <= filterEndDate;
        const isTransactionTypeMatch = transactionType === "ALL" || transaction?.transaction_type === transactionType;
        return isDateInRange && isTransactionTypeMatch;
      });

      setTransactions(filteredTransactions);
    }
  }, [startDate, endDate, transactionType, wallettransaction]);

  useEffect(() => {
    if (transactions?.length) {
      const trans = transactions?.map((data, index) => {
        return { ...data, transactionNumber: index + 1 }
      })
      setnewtransaction(trans)
    }

  }, [transactions])

  const handleSelectChange = (event) => {
    setShowError({})
    const selectedCardLast4 = event.target.value;
    if (selectedCardLast4 === "Addcard") {
      setSelectedValue("Addcard");
      setSelectedCard(null);
    } else {
      const card = cardDetails.find((card) => card.last4 === selectedCardLast4);
      setSelectedCard(card);
      setSelectedValue(selectedCardLast4);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRadioChange = (e) => {
    if (e.target.value === "Off") {
      Swal.fire({
        title: "Are you sure?",
        text: "This action will disable auto-reload.",
        icon: "warning",
        showCancelButton: true,
        // iconColor:"red",
        confirmButtonColor: "#113468",
        cancelButtonColor: "#d33",
        confirmButtonText: "Disable",
      }).then((result) => {
        if (result.isConfirmed) {
          setShowRadioForm(e.target.value);
          dispatch(
            UserAutoreloadWallet({
              customer_id: userid?.id,
              is_autopay: "No"
            })
          )
          setFormData({
            below_amount: "",
            reload_amount: "",

          });
          setEditMode(false);
        }
      });
    } else {
      setShowRadioForm(e.target.value);
      setFormData({
        below_amount: "",
        reload_amount: "",
      });
      setEditMode(false);
    }
  };

  const handleChange = (e) => {
    dispatch(resetErrors())
    setShowError({ auto: false, add: false, autoamount: false, dropdown: false });
    const { name, value } = e.target;
    if (value && value?.length > 5) {
      const truncatedValue = value.slice(0, 5);
      setFormData({
        ...formData,
        [name]: truncatedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    if (formData.below_amount === "" && formData.reload_amount === "") {
      setShowError({ auto: true, autoamount: true });
    } else if (formData.below_amount === "") {
      setShowError({ auto: true, autoamount: false });
    } else if (formData.reload_amount === "") {
      setShowError({ auto: false, autoamount: true });
    } else {
      setShowError({ auto: false, autoamount: false });
      setEditMode({
        balance: true,
        reload: true,
      });
    }
    dispatch(
      UserAutoreloadWallet({
        customer_id: userid?.id,
        // card_id: selectedCard?.id,
        autopayment_limit: formData?.below_amount,
        autopayment_amount: formData?.reload_amount,
        is_autopay: "Yes"
      })

    )
      .unwrap()
      .then((response) => {
        if (response?.autoPayment?.status === "success" && response?.autoPayment?.code === "200") {
          SwalFireComponent({ icon: "success", title: "Auto Reload set successfully" })
          dispatch(
            UserWallettransaction({
              customer_id: userid?.id,
              store: "list",
            })
          )
          dispatch(
            getuserdetail({
              customer_id: userid?.id,
            })
          )
        }
      })
  };

  const handleSubmitAddMoney = () => {
    if (formData.add_amount === "") {
      setShowError({ add: true });
    } else {
      setShowError({ add: false });
      if (selectedValue === "Addcard") {
        setShowModal(true);
        setSelectedValue("");
      } else {
        if (selectedCard) {
          setloader(true)
          dispatch(
            UserWallet({
              customer_id: userid?.id,
              card_id: selectedCard?.id,
              wallet_amount: formData?.add_amount,
              store: "topup"
            })
          )
            .unwrap()
            .then((response) => {
              if (response?.WalletCard?.status === "success" && response?.WalletCard?.code === "200") {
                setloader(false)
                SwalFireComponent({ icon: "success", title: "Wallet credited successfully" })
                setSelectedCard(null);
                setFormData({ add_amount: '' })
                dispatch(
                  UserWallettransaction({
                    customer_id: userid?.id,
                    store: "list",
                  })
                )
                dispatch(
                  getuserdetail({
                    customer_id: userid?.id,
                  })
                )
              }
              setloader(false)

              setSelectedCard(null);
            })
        } else {
          setShowError({ ...showError, dropdown: true });
          setloader(false)
        }

      }

    }
  };

  const handleEdit = (field) => {
    if (field === "balance") {
      setEditMode({ ...editMode, balance: false });
    } else if (field === "reload") {
      setEditMode({ ...editMode, reload: false });
    }
  };

  const handleTabChange = (selectedKey) => {
    setShowError({ auto: false, add: false, autoamount: false, dropdown: false });
    setKey(selectedKey);
  };

  const handleclearDate = (name) => {
    if (name === "startDate") {
      setStartDate("");
    }
    if (name === "endDate") {
      setEndDate("");
    }
  };

  const handleShowDatePicker = (name) => {
    if (name === "startDate") {
      setShowDatePickerstart(!showDatePickerstart);
      setShowDatePickerend(false);
    } else if (name === "endDate") {
      setShowDatePickerend(!showDatePickerend);
      setShowDatePickerstart(false);
    }
  };

  const downloadStatement = () => {
    setdownload(true)
    dispatch(
      UserWallet({
        customer_id: userid?.id,
        start_date: startDate ? formatDatedownload(startDate) : "",
        end_date: endDate ? formatDatedownload(endDate) : "",
        transaction_type: transactionType ? transactionType : "ALL",
        store: "download-statement"
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.CustomerWallet?.status === "success" && response?.CustomerWallet?.code === "200") {
          setdownload(false)

          // Get the download URL from the response
          const downloadUrl = response.CustomerWallet.downloadFile;

          // Fetch the PDF file
          fetch(downloadUrl)
            .then(response => response.blob())
            .then(blob => {
              // Create a URL for the blob
              const url = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'statement.pdf');

              // Append the link to the body
              document.body.appendChild(link);

              // Trigger the download
              link.click();

              // Clean up
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch(error => {
              console.error('Error downloading PDF:', error);
              // Handle error, show error message, etc.
            });
        }
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
        // Handle error, show error message, etc.
      });
  }

  const formatDatedownload = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleTransactionTypeChange = (type) => {
    setTransactionType(type);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Array of month names
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const month = monthNames[monthIndex].slice(0, 3);

    return `${day} ${month} ${year}`;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlepageClick = () => {
    window.scrollTo({
      top: 360,
      behavior: "smooth",
    });
  }


  const indexOfLastTransaction = page * rowsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - rowsPerPage;
  const currentTransactions = transactionnew?.slice(indexOfFirstTransaction, indexOfLastTransaction);

  return (
    <>
      <div className="userParcel-main">
        <Card className="px-4 py-4 ps-4 userParcel-card d-flex flex-column gap-4" border="0"  >
          <div className="d-flex gap-3 justify-content-between">
            <div className="d-flex align-items-center">
              <h5 className="personal-text m-0">Wallet</h5>
            </div>
            <div className="  rounded-3 blue-text">
              <div className="blue-text d-flex px-3 py-1 align-items-baseline gap-1">
                <h5 className="h-100 mb-0">$</h5>{" "}
                <h5 className="personal-text m-0 ">{userid?.ewallet_balance}</h5>
              </div>
            </div>
          </div>
          <div className=" user-Wallet">
            <Tabs
              id="fill-tab-example"
              className="border-0"
              fill
              activeKey={key}
              onSelect={handleTabChange}
            >
              <Tab
                eventKey="addmoney"
                title="Add Money"
                className="user-tab-content p-3 p-lg-5"
                style={{ flex: "1" }}
              >
                <div className="wallet-addmoney d-flex gap-3 gap-lg-5 flex-column flex-md-row ">
                  <div className=" " style={{ flex: "2" }}>
                    <Form.Group>
                      <Form.Label className="paragraph2 p-multihead">
                        Enter Amount
                      </Form.Label>

                      <Form.Control
                        className="is-valid-paper wallet-input"
                        type="number"
                        placeholder="Amount"
                        name="add_amount"
                        value={formData?.add_amount}
                        onChange={handleChange}
                        isInvalid={showError.add}
                      />
                    </Form.Group>
                    {walleterror && <p className="text-danger">{walleterror?.stripe_payment_method}</p>}

                  </div>
                  <div className="" style={{ flex: "3" }}>
                    <Form.Group>
                      <Form.Label className="paragraph2 p-multihead">
                        Select Card
                      </Form.Label>
                      <div className="">
                        <Form.Select
                          className="is-valid-paper wallet-input"
                          as="select"
                          style={{
                            color:
                              selectedCard === null &&
                                selectedValue !== "Addcard"
                                ? "#bababa"
                                : "#222222",
                            fontSize:
                              selectedCard === null &&
                              selectedValue !== "Addcard" &&
                              "14px",
                            fontWeight:
                              selectedCard === null &&
                                selectedValue !== "Addcard"
                                ? "300"
                                : "400",
                          }}
                          value={
                            selectedCard === null && selectedValue === "Addcard"
                              ? "Addcard"
                              : selectedCard?.last4 || ""
                          }
                          isInvalid={showError?.dropdown}
                          onChange={handleSelectChange}
                          disabled={cardDetails?.length === 0}
                        >
                          <option value="" disabled={true}>
                            Select Card
                          </option>
                          {cardDetails?.map((card, index) => (
                            <option
                              className="walletoption"
                              key={index}
                              value={card?.last4}
                            >
                              **** **** **** {card?.last4}
                            </option>
                          ))}
                          <option
                            className="walletoption"
                            key="Addcard"
                            value="Addcard"
                          >
                            Add New Card
                          </option>
                        </Form.Select>
                      </div>
                      {showError?.dropdown && <p className="text-danger">please select card</p>}
                    </Form.Group>

                    <Modal
                      show={showModal}
                      onHide={handleCloseModal}
                      backdrop="static"
                      keyboard={false}
                      className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"
                    >
                      <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                        <div className="user-modal-wallet w-100"
                          style={{ backgroundColor: "white", borderRadius: "10px" }}   >
                          <Modal.Header
                            closeButton
                            className="closebutton d-flex justify-content-end"
                          ></Modal.Header>
                          <div className="px-4 pb-4">
                            <div className="d-flex flex-column gap-4">
                              <h5>Top Up Amount : ${formData?.add_amount}</h5>
                              <OrderStripePayment
                                userid={userid}
                                setFormData={setFormData}
                                formData={formData}
                                handleCloseModal={handleCloseModal}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className=" " style={{ flex: "1" }}>
                    <Form.Group>
                      <Form.Label className="paragraph2 p-multihead d-none d-md-block">
                        &nbsp;
                      </Form.Label>
                      <div className="">
                        <Button
                          className="primarybtn btn w-100  wallet-input"
                          onClick={isWalletLoading ? null : handleSubmitAddMoney}  >
                          {loader ? (
                            <>
                              <Spinner animation="border" size="sm" />
                            </>
                          ) : (
                            <>

                              Add
                            </>
                          )}
                        </Button>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {showError.add && (
                  <p className="text-danger">Please fill in the fields</p>
                )}
              </Tab>

              <Tab
                eventKey="autoreload"
                title="Auto Reload"
                className="user-tab-content p-3 p-lg-5"
                style={{ flex: "1" }}
              >
                <div className="reload-wallet-tab d-flex gap-3 flex-column ">
                  <div className="radiobtnAddress radio-parcel d-flex flex-row align-items-center gap-2 justify-content-center">
                    <p className="lh-1">Auto reload wallet money status : </p>
                    <div className="radio-holderaddress row">
                      <div className="col custom_radio  d-flex gap-1">
                        <input
                          className="radio-check-my-ship-details d-flex align-items-center gap-2"
                          type="radio"
                          id="yesRadio"
                          name="insurance"
                          value="On"
                          checked={showRadioForm === "On"}
                          onChange={handleRadioChange}
                          disabled
                        />
                        <label htmlFor="yesRadio">On</label>
                        <input
                          type="radio"
                          className="radio-check-my-ship-details d-flex align-items-center gap-2"
                          id="noRadio"
                          name="insurance"
                          value="Off"
                          checked={showRadioForm === "Off"}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor="noRadio">Off</label>
                      </div>
                    </div>
                  </div>
                  {showRadioForm === "On" && (
                    <>
                      <div className="d-flex gap-3 gap-lg-5 flex-column flex-md-row">
                        <div className="" style={{ flex: "2" }}>
                          <Form.Group>
                            <Form.Label className="paragraph2 p-multihead">
                              When My Balance Falls Below
                            </Form.Label>
                            <div className="position-relative ">
                              <Form.Control
                                className="is-valid-paper wallet-input"
                                type="number"
                                name="below_amount"
                                placeholder="Amount"
                                value={formData.below_amount}
                                onChange={handleChange}
                                disabled={editMode.balance}
                                isInvalid={showError?.auto}
                              />
                              {editMode.balance && (
                                <>
                                  {/* <Button
                                    className="position-absolute blue-text primary_btn_link"
                                    style={{ top: "07px", right: "10px" }}
                                    onClick={() => handleEdit("balance")}
                                  >
                                    Edit
                                  </Button> */}
                                  <Tooltip title="Edit" className="pointer-icon position-absolute blue-text primary_btn_link"    >
                                    <IconButton className="p-0" onClick={() => handleEdit("balance")} style={{ top: "13px", right: "10px" }}>
                                      <>
                                        <img className="pointer-icon" alt="icon" src={`${window.location.origin}/assets/address-icons/edit.svg`} />
                                      </>
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="" style={{ flex: "2" }}>
                          <Form.Group>
                            <Form.Label className="paragraph2 p-multihead">
                              Reload Amount
                            </Form.Label>
                            <div className="position-relative ">
                              <Form.Control
                                className="is-valid-paper wallet-input"
                                type="number"
                                name="reload_amount"
                                placeholder="Reload Amount"
                                value={formData.reload_amount}
                                onChange={handleChange}
                                disabled={editMode.reload}
                                isInvalid={showError?.autoamount}
                              />
                              {editMode.reload && (
                                <>
                                  {/* <Button
                                    className="position-absolute blue-text primary_btn_link"
                                    style={{ top: "7px", right: "10px" }}
                                    onClick={() => handleEdit("reload")}
                                  >
                                    Edit
                                  </Button> */}
                                  <Tooltip title="Edit" className="pointer-icon position-absolute blue-text primary_btn_link"    >
                                    <IconButton className="p-0" onClick={() => handleEdit("reload")} style={{ top: "13px", right: "10px" }}>
                                      <>
                                        <img className="pointer-icon" alt="icon" src={`${window.location.origin}/assets/address-icons/edit.svg`} />
                                      </>
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="" style={{ flex: "1" }}>
                          <Form.Group>
                            <Form.Label className="paragraph2 p-multihead d-none d-md-block ">
                              &nbsp;
                            </Form.Label>
                            <div className="">
                              <Button
                                className="primarybtn btn w-100  wallet-input"
                                onClick={handleSubmit}
                                disabled={editMode.balance && editMode.reload}
                              >
                                Submit
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      {showError.auto || showError.autoamount ? (
                        <p className="text-danger">Please fill in all fields</p>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="d-flex gap-3 my-order-date-picker w-100 flex-column  flex-md-row " style={{ flex: "1" }}  >
            <div className="d-flex gap-3" style={{ flex: "3" }}>

              <div className=" w-50">
                <div className=" w-100 my-order-date-picker position-relative">
                  <ReactDatePicker
                    className="py-1 m-0 myorder-datepicker my-order-search-input px-2 w-100"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select Start Date"
                    dateFormat="dd-MM-yyyy"
                  />
                  {startDate ? (
                    <>
                      <img alt="icon"
                        className="position-absolute"
                        style={{
                          right: "15px",
                          top: "15px",
                          cursor: "pointer",
                        }}
                        src={`${window.location.origin}/assets/address-icons/close.svg`}
                        onClick={() => handleclearDate("startDate")}
                      />
                    </>
                  ) : (
                    <>
                      <DateRangeOutlinedIcon
                        className="bg-transparent position-absolute top-0 my-2"
                        style={{ color: "#113468", right: "5" }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className=" w-50">
                <div
                  className=" w-100 my-order-date-picker position-relative"
                  onClick={() => handleShowDatePicker("endDate")}
                >
                  <ReactDatePicker
                    className="py-1 m-0 myorder-datepicker my-order-search-input w-100 px-2"
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select End Date"
                    dateFormat="dd-MM-yyyy"
                  />
                  {endDate ? (
                    <>
                      <img alt="icon"
                        className="position-absolute"
                        style={{
                          right: "15px",
                          top: "15px",
                          cursor: "pointer",
                        }}
                        src={`${window.location.origin}/assets/address-icons/close.svg`}
                        onClick={() => handleclearDate("endDate")}
                      />
                    </>
                  ) : (
                    <>
                      <DateRangeOutlinedIcon
                        className="bg-transparent position-absolute top-0 my-2"
                        style={{ color: "#113468", right: "5" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block user-wallet-none" style={{ flex: "2" }}></div>
            <div className="d-flex align-items-end justify-content-center  gap-3 " style={{ flex: "1" }}>
              <div className="d-md-none" style={{ flex: "1" }}></div>
              <div className=" user-wallet-type" style={{ flex: "4", height: "40px" }}>
                <select className=" px-2 wallet_select card-border is-valid-paper w-100" style={{ borderRadius: "6px", height: "40px" }} value={transactionType} onChange={(e) => handleTransactionTypeChange(e.target.value)}>
                  <option value="ALL">All</option>
                  <option value="CREDIT">Credit</option>
                  <option value="DEBIT">Debit</option>
                </select>
              </div>
              <div className=" w-100 user-wallet-type-download" style={{ flex: "2", height: "40px" }}>
                <Button style={{ width: "40px" }} className="  card-border is-valid-paper  h-100 p-0 bg-white" onClick={() => downloadStatement()}>
                  {!download ? (
                    <>
                      < Tooltip title="Download Statement" className="pointer-icon"  >
                        <img alt="pdf_icon" width={"17px"} height={"17px"} src={`${window.location.origin}/assets/user-profile/walletDownload.svg`} />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <div style={{ color: "#113468" }}>
                        <Spinner style={{ zIndex: "999" }} animation="border" size="sm" />
                      </div>
                    </>
                  )
                  }
                </Button>
              </div>
            </div>
          </div>
          <div className="card-border-wallet rounded-2" style={{ overflowX: "auto" }}>
            <table style={{ minWidth: "100%" }}>
              <thead className="user-table-head">
                <tr className="user-table-row w-100">
                  <th className="user-id-head">No</th>
                  <th>Date</th>
                  <th>Order Details</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th className="user-action-head">Action</th>
                </tr>
              </thead>
              <tbody>
                {!wallettransactionloading ? (
                  <>
                    {currentTransactions?.length > 0 ? (
                      <>
                        {currentTransactions?.map((transaction, index) => (
                          <tr className="user-wallet-data data-tr-wallet" key={index}>
                            <td>{transaction?.transactionNumber}</td>
                            <td>{formatDate(transaction?.updated_at)}</td>
                            <td>{transaction?.transaction_detail}</td>
                            <td style={{ color: transaction?.transaction_status === 'Success' ? 'green' : 'red' }}>{transaction.transaction_status}</td>
                            <td style={{ color: transaction?.transaction_type === 'CREDIT' ? 'green' : 'red' }}>
                              {transaction?.transaction_type === 'CREDIT' ? `+${formatPrice(transaction?.wallet_amount)}` : `-${formatPrice(transaction?.wallet_amount)}`}
                            </td>
                            <td>{formatPrice(transaction.closing_balance)}</td>
                            <td className="text-center" style={{ cursor: "pointer" }}>
                              {transaction?.receipt_invoice &&
                                <a href={transaction?.receipt_invoice} target="_blank" rel="noreferrer" ><img alt="pdf_icon" src={`${window.location.origin}/assets/user-profile/pdf.svg`} /></a>
                              }
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr className="user-wallet-data text-center w-100">
                          <td className="text-center py-5" colSpan={"7"}>No transactions...!</td>
                        </tr>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <tr className="user-wallet-data text-center w-100">
                      <td className="text-center p-0" colSpan={"7"}> <Skeleton animation="wave" height={50} /></td>
                    </tr>
                    <tr className="user-wallet-data text-center w-100">
                      <td className="text-center p-0" colSpan={"7"}> <Skeleton animation="wave" style={{ backgroundColor: "#F4F4F4" }} height={50} /></td>
                    </tr>
                    <tr className="user-wallet-data text-center w-100">
                      <td className="text-center p-0" colSpan={"7"}> <Skeleton animation="wave" height={50} /></td>
                    </tr>
                    <tr className="user-wallet-data text-center w-100">
                      <td className="text-center p-0" colSpan={"7"}> <Skeleton animation="wave" style={{ backgroundColor: "#F4F4F4" }} height={50} /></td>
                    </tr>
                  </>
                )}

              </tbody>
            </table>
          </div>

          <Pagination className="justify-content-end d-flex"
            count={Math.ceil(transactionnew?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            onClick={handlepageClick}
            variant="outlined"
            shape="rounded"
          />


        </Card >

      </div >
    </>
  );
};
export default Wallet;
