import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqsDetails } from "../redux/landing/landingThunk";
import "../assets/styles/FAQquestionPage.css";
import { Box, Skeleton } from "@mui/material";

const FAQquestionPage = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const FAQquestion = useSelector(
    (state) => state.landingReducer?.landingFaqsDetails?.data
  );
  const FAQquestionLoading = useSelector(
    (state) => state.landingReducer?.faqLoading
  );

  useEffect(() => {
    dispatch(fetchFaqsDetails());
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFaqs = FAQquestion?.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [expandedItems, setExpandedItems] = useState(
    new Array(filteredFaqs?.length).fill(false)
  );

  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = prevExpandedItems.map((item, i) =>
        i === index ? !item : false
      );
      return newExpandedItems;
    });
  };

  return (
    <div className="faq-main-holder py-5">
      <Container className="px-4 px-sm-0 px-lg-0">
        <div className="faqfolder">
          {!FAQquestionLoading ? (
            <>
              {filteredFaqs &&
                <div className="d-flex flex-column gap-3">
                  <h3 className="footer-mobile-head m-0">Frequently Asked Questions</h3>
                  <div className="search-bar">
                    <input
                      className="input-elevated is-valid-paper"
                      type="text"
                      placeholder="Search Questions"
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              }
              <Accordion className="p-0">
                {filteredFaqs?.map((faq, index) => (
                  <Accordion.Item
                    className="shadow-none"
                    style={{ backgroundColor: "#f5f5f5" }}
                    key={faq.id}
                    eventKey={index}
                    expanded={expandedItems[index]}
                  >
                    <div className="faq-que-ans">
                      <Accordion.Header className="py-3">
                        <div className="faq-que" onClick={() => toggleExpand(index)}>
                          <p className="paragraph1 faq-que">{faq.question}</p>
                        </div>
                        <div className="faq-icon">
                          <span className="add-icon" onClick={() => toggleExpand(index)}>
                            {expandedItems[index] ? (
                              <RemoveCircleOutlineIcon style={{ color: "#222222" }} />
                            ) : (
                              <ControlPointIcon style={{ color: "#222222" }} />
                            )}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ padding: "0px 0px 0px 20px" }}>
                        <div className="faq-ans">
                          <p className="paragraph1 py-2" key={index}>
                            <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                          </p>
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                ))}
              </Accordion>
            </>
          ) : (
            <>
              <Box className="d-flex flex-column gap-3 w-100">
                <Box className='d-flex justify-content-center'>
                  <Skeleton animation="wave" className='w-50 height-44' height="px" variant=" rounded" />
                </Box>
                <Box className='d-flex justify-content-center'>
                  <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                </Box>
                <Skeleton animation="wave" className='w-100 height-44' height="px" variant=" rounded" />
                <Skeleton animation="wave" className='w-100 height-44' height="px" variant=" rounded" />
                <Skeleton animation="wave" className='w-100 height-44' height="px" variant=" rounded" />
                <Skeleton animation="wave" className='w-100 height-44' height="px" variant=" rounded" />
                <Skeleton animation="wave" className='w-100 height-44' height="px" variant=" rounded" />
              </Box>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default FAQquestionPage;
