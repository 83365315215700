import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form } from "react-bootstrap";
import ServiveProviderAboutPopup from "../addressBook/ServiveProviderAboutPopup";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { resetErrors } from "../../redux/services/ServicesSlice";
import { formatPrice } from "../../utils/formatPrice";

const AccordianShipmentDetails = ({
  serviceData,
  setFormShipData,
  formShipData,
  shipContent,
  setShipContent,
  setShipDetailsError,
  selectedRadio,
  setSelectedRadio,
  ShipmentError,
  contentArray,
  valueArray,
  // onchangeinsurance
}) => {
  const isServiceLoading = useSelector(
    (state) => state?.servicedata.isServiceLoading
  );
  const shipmentDataNew = useSelector(
    (state) => state?.servicedata.ShippingService?.data
  );
  const Shipping_Rates = useSelector(
    (state) => state.landingReducer?.landingShipServices?.data
  );
  const useriD = JSON.parse(localStorage.getItem("user"));
  const multishipData = JSON.parse(localStorage.getItem("multiship_data"));




  const [shippingErrors, setShippingErrors] = useState([
    {
      parcel: [
        {
          contents: "",
          ship_value: "",
        },
      ],
    },
  ]);
  // const [shipContent, setShipContent] = useState([
  //   {
  //     parcel: [
  //       {
  //         contents: "",
  //         ship_value: "",
  //       },
  //     ],
  //   },
  // ]);

  const [showServiveAboutPopup, setShowServiveAboutPopup] = useState(false);
  const [insuranceData, setInsuranceData] = useState();
  const [iframeLink, setIframeLink] = useState("");
  const sending = {
    ups_one: "Including Lithium Batteries",
    ups_two: "Include Dry Ice (+$)",
    ups_three: "Signature Options (+$)",
    ups_four: "C.O.D. (+$)",
    fedex_one: "Including Lithium Batteries",
    fedex_two: "Include Dry Ice (+$)",
    fedex_three: "Signature Options (+$)",
  };
  const shipValueLength = shipContent?.ship_value?.length;
  const iframeArray = [
    "https://pe.usps.com/text/pub52/pub52c4_017.htm?_gl=1*es3opj*_gcl_au*MTczMTMxMDMyMC4xNjk1ODgwMjM5*_ga*OTU3OTE4OTUuMTY5NTg4MDI0MA..*_ga_3NXP3C8S9V*MTY5NTk2MTIyNC41LjEuMTY5NTk2MjIxNC4wLjAuMA..#ep290280",

    "https://pe.usps.com/text/pub52/pub52c5_003.htm?_gl=1*nm1cbg*_gcl_au*MTczMTMxMDMyMC4xNjk1ODgwMjM5*_ga*OTU3OTE4OTUuMTY5NTg4MDI0MA..*_ga_3NXP3C8S9V*MTY5NTk2MTIyNC41LjEuMTY5NTk2MjIzNC4wLjAuMA..",

    "https://pe.usps.com/text/pub52/pub52c5_008.htm?_gl=1*18qh5vd*_gcl_au*MTczMTMxMDMyMC4xNjk1ODgwMjM5*_ga*OTU3OTE4OTUuMTY5NTg4MDI0MA..*_ga_3NXP3C8S9V*MTY5NTk2MTIyNC41LjEuMTY5NTk2MjI2Mi4wLjAuMA..#ep184002",

    "https://pe.usps.com/text/pub52/pub52c3_001.htm?_gl=1*194xig6*_gcl_au*MTczMTMxMDMyMC4xNjk1ODgwMjM5*_ga*OTU3OTE4OTUuMTY5NTg4MDI0MA..*_ga_3NXP3C8S9V*MTY5NTk2MTIyNC41LjEuMTY5NTk2MjI3OC4wLjAuMA..",
  ];

  useEffect(() => {
    const data = shipContent.flatMap((item, index) => {
      const parcel = item.parcel.map(p => ({
        contents: p.contents,
        ship_value: p.ship_value,
      }));
      return parcel;
    });
    setInsuranceData(data)
  }, [shipContent]);
  const selectedData = {
    height: multishipData?.height,
    length: multishipData?.length,
    qty: multishipData?.qty,
    size_in: multishipData?.size_in,
    unit: multishipData?.unit,
    weight: multishipData?.weight,
    weight_in: multishipData?.weight_in,
    width: multishipData?.width,
  };
  const isEmpty =
    contentArray.some(
      (item) => item === "" || item === null || item === undefined
    ) ||
    valueArray.some(
      (item) => item === "" || item === null || item === undefined
    );
  // const handleChange = (e, index, i) => {
  //   const { name, value } = e.target;
  //   setShippingErrors("");

  //   setShipContent((prevShipContent) => {
  //     const newShipContent = [...prevShipContent];
  //     newShipContent[index].parcel[i] = {
  //       ...newShipContent[index].parcel[i],
  //       [name]: value,
  //     };
  //     return newShipContent;
  //   });
  // };
  const handleChange = (e, parcelIndex, contentIndex) => {
    const { name, value } = e.target;
    setShippingErrors("");
    setShipDetailsError("");
    setShipContent((prevShipContent) => {
      const newShipContent = [...prevShipContent];
      // Ensure the parcel array exists for the given parcelIndex
      if (!newShipContent[parcelIndex]) {
        newShipContent[parcelIndex] = {
          parcel: [],
        };
      }

      // Ensure the content object exists for the given contentIndex
      if (!newShipContent[parcelIndex].parcel[contentIndex]) {
        newShipContent[parcelIndex].parcel[contentIndex] = {
          contents: "",
          ship_value: "",
        };
      }

      newShipContent[parcelIndex].parcel[contentIndex] = {
        ...newShipContent[parcelIndex].parcel[contentIndex],
        [name]: value,
      };

      return newShipContent;
    });
  };
  const handleBlur = (e, parcelIndex, contentIndex) => {
    const { name, value } = e.target;
    setShippingErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      // Ensure the parcel array exists for the given parcelIndex
      if (!newErrors[parcelIndex]) {
        newErrors[parcelIndex] = {
          parcel: [],
        };
      }

      // Ensure the content object exists for the given contentIndex
      if (!newErrors[parcelIndex].parcel[contentIndex]) {
        newErrors[parcelIndex].parcel[contentIndex] = {
          contents: "",
          ship_value: "",
        };
      }

      newErrors[parcelIndex].parcel[contentIndex] = {
        ...newErrors[parcelIndex].parcel[contentIndex],
        [name]: value.trim() ? "" : "Field is required",
      };

      return newErrors;
    });
  };

  const handleSending = (e) => {
    const { name, value, checked } = e.target;
    const newValue = checked ? sending[name] : "";
    setFormShipData({
      ...formShipData,
      [name]: newValue,
    });
  };
  const toggleModalService = (e) => {
    setIframeLink(iframeArray[e - 1]);
    setShowServiveAboutPopup(true ? true : false);
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setSelectedRadio(value);

    // If 'Yes' is selected, update formShipData with relevant data
    if (value === "Yes") {
      setFormShipData({
        ...formShipData,
        insuranceRadio: "Yes",
      });

      // setShipContent({
      //   ...shipContent,
      //   insurance: "Yes",
      // })
    } else {
      setFormShipData({
        ...formShipData,
        insuranceRadio: "No",
      });
      // setShipContent({
      //   ...shipContent,
      //   insurance: "No",
      // });
      // setShipContent({
      //   ...shipContent,
      //   insurance: "Yes",
      // })
    }
  };

  const renderItems = () => {
    const elements = [];
    let counter = 0
    multishipData?.qty?.forEach((item, index) => {
      const itemValue = parseInt(item);
      for (let i = 0; i < itemValue; i++) {
        counter += 1;
        elements.push(
          <>
            <b key={`p-${counter}`}>
              Parcel no {counter}
            </b>
            <div className="row mb-4 d-flex gap-2 ">
              {/* parcel{`${index + 1}`} */}
              <div className="row w-100 mb-2">
                <div className="parcel-first-col ">
                  <div className="justify-content-between my-ship-details">
                    <div className="myshipment-weight-height d-flex gap-3 flex-column flex-sm-row flex-lg-row">
                      <div className=" d-flex gap-4 gap-lg-3 justify-content-between ">
                        <div className="shipment-details-text-p d-flex gap-4 gap-lg-3  ">
                          <div>
                            <p
                              className="paragraph1"
                              style={{
                                whiteSpace: "nowrap",
                                fontWeight: 400,
                              }}
                            >
                              Weight :{" "}
                              <span style={{ fontWeight: 300 }}>
                                {selectedData?.weight[index]}
                              </span>
                              <span style={{ fontWeight: 300 }}>
                                {" "}
                                {selectedData?.weight_in[index]}
                              </span>
                            </p>
                          </div>
                          <div className="d-none d-lg-block ">
                            <span>|</span>
                          </div>
                        </div>

                        <div className="shipment-details-text-p">
                          <p
                            className="paragraph1"
                            style={{
                              whiteSpace: "nowrap",
                              fontWeight: 400,
                            }}
                          >
                            Length :{" "}
                            <span style={{ fontWeight: 300 }}>
                              {selectedData?.length[index]}
                            </span>
                            <span style={{ fontWeight: 300 }}>
                              {" "}
                              {selectedData?.size_in[index]}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className=" d-flex gap-4 gap-lg-3 justify-content-between  ">
                        <div className="shipment-details-text-p d-flex gap-5 gap-lg-3 flex-row ">
                          <div className="shipment-details-text-p">
                            <p
                              className="paragraph1"
                              style={{
                                whiteSpace: "nowrap",
                                fontWeight: 400,
                              }}
                            >
                              Width :{" "}
                              <span style={{ fontWeight: 300 }}>
                                {selectedData?.width[index]}
                              </span>
                              <span style={{ fontWeight: 300 }}>
                                {" "}
                                {selectedData?.size_in[index]}
                              </span>
                            </p>
                          </div>

                          <div className="d-none d-lg-none">
                            <span>|</span>
                          </div>
                        </div>
                        <div className="shipment-details-text-p">
                          <p
                            className="paragraph1"
                            style={{
                              whiteSpace: "nowrap",
                              fontWeight: 400,
                            }}
                          >
                            Height :{" "}
                            <span style={{ fontWeight: 300 }}>
                              {selectedData?.height[index]}
                            </span>
                            <span style={{ fontWeight: 300 }}>
                              {" "}
                              {selectedData?.size_in[index]}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row w-100 d-flex gap-3  flex-sm-row" >
                <div className=" pe-0" key={`parcel-${index}-content-${i}`} style={{ flex: "2" }}>
                  <Form.Group className=" d-flex justify-content-start flex-column flex-md-row flex-lg-column  align-items-start align-items-sm-start align-items-md-center align-items-lg-start" controlId="Parcelcontents">
                    {/* <Form.Label className="shipment-details-text-p d-flex align-items-center "> */}
                    <p
                      className="paragraph1 m-0 me-2 text-nowrap d-flex "
                      style={{ fontWeight: 400 }}
                    >
                      <span>Parcel Contents</span>
                    </p>
                    {/* </Form.Label> */}
                    <div className="w-100">
                      <Form.Control
                        className={`${shippingErrors[index]?.contents &&
                          shippingErrors[index]?.contents
                          ? "is-invalid-paper"
                          : "is-valid-paper"
                          }`}
                        type="text"
                        placeholder="Enter details"
                        name={`contents`}
                        value={shipContent[index]?.parcel[i]?.contents || ""}
                        isInvalid={
                          (shippingErrors[index]?.parcel[i]?.contents && shippingErrors[index]?.parcel[i]?.contents) ||
                          (shippingErrors[index]?.contents && shippingErrors[index]?.contents)}
                        onChange={(e) => {
                          handleChange(e, index, i);
                        }}
                      // onBlur={(e) => {
                      //   handleBlur(e, index, i);
                      // }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <span>
                          {shippingErrors && shippingErrors[index]?.parcel[i]?.contents}
                        </span>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>

                <div className=" pe-0" style={{ flex: "1" }}>
                  <Form.Group
                    className=" d-flex flex-column flex-md-row flex-lg-column  justify-content-start align-items-start align-items-sm-start align-items-md-center align-items-lg-start"
                    controlId="formHorizontalPassword"
                  >
                    {/* <Form.Label className="shipment-details-text-p d-flex ms-0 ms-lg-3 align-items-center"> */}
                    <p
                      className="paragraph1 m-0 me-2 text-nowrap "
                      style={{ fontWeight: 400 }}
                    >
                      Value of Parcel
                    </p>
                    {/* </Form.Label> */}
                    <div className="w-100">
                      <Form.Control
                        value={shipContent[index]?.parcel[i]?.ship_value || ""}
                        name={`ship_value`}
                        isInvalid={
                          shippingErrors[index]?.parcel[i]?.ship_value &&
                          shippingErrors[index]?.parcel[i]?.ship_value
                        }
                        className={`${shippingErrors[index]?.ship_value &&
                          shippingErrors[index]?.ship_value
                          ? "is-invalid-paper"
                          : "is-valid-paper"
                          }`}
                        type="number"
                        placeholder="$"
                        // onChange={(e) => {
                        //   // setShipContent({
                        //   //   ...shipContent,
                        //   //   insurance: "Yes",
                        //   // })
                        //   // setFormShipData({
                        //   //   ...formShipData,
                        //   //   insuranceRadio: "No",
                        //   // });
                        //   handleChange(e, index, i);
                        // }}
                        // onChange={(e) => {
                        //   const { value } = e.target;
                        //   if (value.length <= 5) {
                        //     handleChange(e, index, i);
                        //   }
                        // }}

                        onChange={(e) => {
                          const { value } = e.target;
                          const regex = /^\d{0,4}(\.\d{0,2})?$/;
                          if (regex.test(value)) {
                            handleChange(e, index, i);
                          }
                        }}


                        onBlur={(e) => {
                          handleBlur(e, index, i);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <span>
                          {shippingErrors && shippingErrors[index]?.parcel[i]?.ship_value}
                        </span>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </>
        );
      }
    });

    return elements;
  };
  function isInsuranceDataIncomplete() {
    // Check if any object in insuranceData has missing values
    return insuranceData?.some((item) => !item.contents || !item.ship_value);
  }
  return (
    <div className="my-shipment-outer address-outer p-0">
      <div>
        <div className="d-flex flex-column gap-4 pt-4">
          <Card className="d-flex flex-column gap-3 border-0 box-shadow p-0 p-sm-4">
            <Form className="d-flex gap-3">
              <div className="d-flex flex-column ">{renderItems()}</div>
            </Form>

            {ShipmentError && (
              <div>
                <p className="error-text">{ShipmentError}</p>
              </div>
            )}

            {/* **********insurance********** */}
            <div className="d-flex justify-content-between flex-column flex-sm-column flex-md-row flex-lg-row gap-3">
              <div>
                {!isInsuranceDataIncomplete() && (
                  <div className="shipment-details-text-p d-flex">
                    <p className="" style={{ whiteSpace: "nowrap" }}>
                      Insurance Cost :
                      {!isServiceLoading && (
                        <p className="d-inline-flex mx-2">
                          <span>
                            {shipmentDataNew?.cart_details?.insurance_cost === "0.00" || !shipmentDataNew?.cart_details?.insurance_cost
                              ? "Free"
                              :
                              formatPrice(shipmentDataNew?.cart_details?.insurance_cost)
                              // `$${shipmentDataNew?.cart_details?.insurance_cost}`
                            }
                            <p className="mb-0">
                            </p>
                          </span>
                        </p>
                      )}
                    </p>

                    {isServiceLoading && (
                      <Box
                        className="flex"
                        sx={{ width: 90 }}
                        style={{ margin: "0px 10px" }}
                      >
                        <Skeleton animation="wave" height={30} />
                      </Box>
                    )}
                  </div>
                )}
              </div>

              <div className="radiobtnAddress radio-parcel d-flex flex-row align-items-center gap-2  ">
                <p className="lh-1">Do you want to add an insurance? </p>
                <div className="radio-holderaddress row">
                  <div className="col custom_radio  d-flex gap-2">
                    <input
                      className="radio-check-my-ship-details d-flex align-items-center gap-2"
                      type="radio"
                      id="yesRadio"
                      name="insurance"
                      value="Yes"
                      checked={selectedRadio === "Yes"}
                      // disabled={isEmpty}
                      disabled={isInsuranceDataIncomplete()}
                      // label={"Yes"}
                      onChange={(e) => {
                        handleRadioChange(e);
                      }}
                    />
                    <label for="yesRadio">Yes</label>

                    {/* <span className="ms-1 me-3">Yes</span> */}
                    <input
                      type="radio"
                      className="radio-check-my-ship-details d-flex align-items-center gap-2 "
                      id="noRadio"
                      name="insurance"
                      value="No"
                      checked={selectedRadio === "No"}
                      // disabled={isEmpty}
                      disabled={isInsuranceDataIncomplete()}

                      // label={"No"}

                      onChange={(e) => {
                        handleRadioChange(e);
                      }}
                    />
                    <label for="noRadio">No</label>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {serviceData?.cart_details?.courier_id === 3 && (
            <Card className="d-flex flex-column gap-3 gap-lg-2 w-100 border-0   px-4 pb-4 ">
              <div className="what-text ">
                <p className="paragraph1">
                  <b>What are you Sending?</b>
                </p>
              </div>
              <div className="d-flex chexbx align-items-start align-items-lg-center  flex-column flex-lg-row lh-1">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-center "
                  type="checkbox"
                  id="checkbox2"
                  label={
                    <span className="px-lg-2 lh-1">
                      <span
                        className="text-nowrap lh-1"
                        style={{ color: "#6B6B6B", fontSize: "14px" }}
                      >
                        Climate Remains -{" "}
                      </span>
                    </span>
                  }
                />
                <span
                  className="text-nowrap lh-1 ms-4 ms-lg-0 "
                  style={{ color: "#113468", fontSize: "14px" }}
                  onClick={() => toggleModalService("1")}
                >
                  Learn about mailing Live Animals
                </span>
              </div>
              <div className="d-flex chexbx align-items-start align-items-lg-center flex-column flex-lg-row lh-1">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-center "
                  type="checkbox"
                  id="checkbox2"
                  label={
                    <span className=" px-lg-2 lh-1">
                      <span
                        className="text-nowrap "
                        style={{ color: "#6B6B6B", fontSize: "14px" }}
                      >
                        Live Animal(s) other than Day-Old Poultry -{" "}
                      </span>
                    </span>
                  }
                />
                <span
                  className="lh-1 ms-4 ms-lg-0"
                  style={{ color: "#113468", fontSize: "14px" }}
                  onClick={() => toggleModalService("2")}
                >
                  Learn about mailing Live Animals
                </span>
              </div>
              <div className="d-flex chexbx  align-items-start align-items-lg-center flex-column flex-lg-row lh-1">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-center "
                  type="checkbox"
                  id="checkbox3"
                  label={
                    <span
                      style={{ color: "#6B6B6B", fontSize: "14px" }}
                      className="px-lg-2 lh-1"
                    >
                      Day-Old Poultry -{" "}
                    </span>
                  }
                />
                <span
                  className="lh-1 ms-4 ms-lg-0"
                  style={{ color: "#113468", fontSize: "14px" }}
                  onClick={() => toggleModalService("3")}
                >
                  Learn about mailing Day Old Poultry
                </span>
              </div>
              <div className="d-flex chexbx align-items-start align-items-lg-center flex-column flex-lg-row lh-1">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-center "
                  type="checkbox"
                  id="checkbox4"
                  label={
                    <span className="px-lg-2 lh-1 ">
                      <span
                        className="lh-1"
                        style={{ color: "#6B6B6B", fontSize: "14px" }}
                      >
                        Hazardous Materials -
                      </span>
                    </span>
                  }
                />
                <span
                  className="lh-1 ms-4 ms-lg-0"
                  style={{ color: "#113468", fontSize: "14px" }}
                  onClick={() => toggleModalService("4")}
                >
                  Learn about mailing hazardous Materials
                </span>
              </div>
              <div className="text-bold-myship align-items-center d-flex">
                <p
                  className="blue-text paragraph2"
                  style={{ display: "inline-block", marginRight: "10px" }}
                >
                  <p className="lh-1">
                    Read rules about mailing lithium batteries
                  </p>
                </p>
                <img
                  src={`${window.location.origin}/assets/ltrarrow.svg`}
                  alt="Link to Window Location Origin"
                  style={{ display: "inline-block" }}
                />
              </div>

              <div className="text-bold-myship align-items-center">
                <p className="blue-text paragraph2  mb-0">
                  <p className=" lh-1">
                    Does the mailpiece require Ground Transportation?
                  </p>
                </p>
              </div>
              <div className="d-flex chexbx align-items-center">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-center "
                  type="checkbox"
                  id="checkbox5"
                  label={
                    <span className="px-2">
                      <span style={{ color: "#6B6B6B", fontSize: "14px" }}>
                        Requires Ground Transportation
                      </span>
                    </span>
                  }
                />
              </div>

              {iframeLink && (
                <ServiveProviderAboutPopup
                  iframelink={iframeLink}
                  showServiveAboutPopup={showServiveAboutPopup}
                  setShowServiveAboutPopup={setShowServiveAboutPopup}
                />
              )}
            </Card>
          )}
          {serviceData?.cart_details?.courier_id === 2 && (
            <Card className=" d-flex flex-column gap-2 border-0 w-100  px-4 pb-4">
              <div className="what-text ">
                <p className="paragraph1">
                  <b>What are you Sending?</b>
                </p>
              </div>
              <div className="d-flex chexbx">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-start "
                  type="checkbox"
                  id="ups_one"
                  name="ups_one"
                  value={sending.ups_one}
                  onChange={handleSending}
                  label={
                    <>
                      {" "}
                      <div>
                        <p
                          className="px-2 upswhat-you-send-p m-0 lh-1 "
                          style={{
                            color: "#222222",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Including Lithium Batteries
                        </p>
                        <p
                          className="px-2 upswhat-you-send-p m-0 lh-1  "
                          style={{ color: "#6B6B6B", fontSize: "14px" }}
                        >
                          All lithium ion or lithium metal cell batteries and
                          powered devices
                        </p>
                      </div>{" "}
                    </>
                  }
                />
              </div>
              {/* <div className="d-flex chexbx">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-start "
                  type="checkbox"
                  id="ups_two"
                  name="ups_two"
                  value={"Include Dry Ice (+$)"}
                  onChange={handleSending}
                  label={
                    <>
                      <div>
                        <p
                          className="px-2 upswhat-you-send-p m-0 lh-1 "
                          style={{
                            color: "#222222",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Include Dry Ice (+$)
                        </p>
                        <p
                          className="px-2 upswhat-you-send-p m-0 lh-1 "
                          style={{ color: "#6B6B6B", fontSize: "14px" }}
                        >
                          Dry ice is a safe and effective way of shipping goods
                          that need to stay frozen`
                        </p>
                      </div>{" "}
                    </>
                  }
                />
              </div>
              <div className="d-flex chexbx">
                <Form.Check
                  className="chexbx  myship-chbox d-flex align-items-start "
                  type="checkbox"
                  id="ups_three"
                  name="ups_three"
                  value={" Signature Options (+$)"}
                  onChange={handleSending}
                  label={
                    <>
                      {" "}
                      <div>
                        <p
                          className="px-2 upswhat-you-send-p m-0 lh-1 "
                          style={{
                            color: "#222222",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Signature Options (+$)
                        </p>
                        <p
                          className="px-2 upswhat-you-send-p m-0 lh-1"
                          style={{ color: "#6B6B6B", fontSize: "14px" }}
                        >
                          Verify delivery of this shipment.
                        </p>
                      </div>{" "}
                    </>
                  }
                />
              </div> */}
            </Card>
          )}
          {serviceData?.cart_details?.courier_id === 1 && (
            <Card className="d-flex flex-column gap-2 border-0 w-100 px-4 pb-4">
              <div className="what-text ">
                <p className="paragraph1">
                  <b>What are you Sending?</b>
                </p>
              </div>
              <div className="">
                <p
                  className="px-2 fedexwhat-you-send-p m-0 "
                  style={{ color: "#6B6B6B", fontSize: "14px" }}
                >
                  Copy FedEx Express (parcel and freight) and FedEx Ground
                  Collect on Delivery (including
                </p>
                <p
                  className="px-2 fedexwhat-you-send-p m-0"
                  style={{ color: "#6B6B6B", fontSize: "14px" }}
                >
                  {" "}
                  E.C.O.D.) is discontinued for shipments within the U.S. and
                  from Canada to the U.S.
                </p>
              </div>
              <div className="d-flex chexbx ">
                <Form.Check
                  className="chexbx  myship-chbox align-items-center d-flex "
                  type="checkbox"
                  id="checkbox1"
                  name="fedex_one"
                  value={sending?.fedex_one}
                  onChange={handleSending}
                  label={
                    <>
                      {" "}
                      <div>
                        <p
                          className="px-2 upswhat-you-send-p m-0"
                          style={{
                            color: "#222222",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Including Lithium Batteries
                        </p>
                      </div>{" "}
                    </>
                  }
                />
              </div>
              {/* <div className="d-flex chexbx">
                <Form.Check
                  className="chexbx  myship-chbox align-items-center d-flex "
                  type="checkbox"
                  id="checkbox2"
                  name="fedex_two"
                  value={sending.fedex_two}
                  onChange={handleSending}
                  label={
                    <>
                      {" "}
                      <div>
                        <p
                          className="px-2 upswhat-you-send-p"
                          style={{
                            color: "#222222",
                            fontSize: "14px",
                            margin: "0px",
                            fontWeight: "400",
                          }}
                        >
                          Include Dry Ice (+$)
                        </p>
                      </div>{" "}
                    </>
                  }
                />
              </div>
              <div className="d-flex chexbx">
                <Form.Check
                  className="chexbx  myship-chbox align-items-center d-flex "
                  type="checkbox"
                  id="checkbox3"
                  name="fedex_three"
                  value={sending.fedex_three}
                  onChange={handleSending}
                  label={
                    <>
                      {" "}
                      <div>
                        <p
                          className="px-2 upswhat-you-send-p"
                          style={{
                            color: "#222222",
                            fontSize: "14px",
                            margin: "0px",
                            fontWeight: "400",
                          }}
                        >
                          Signature Options (+$)
                        </p>
                      </div>{" "}
                    </>
                  }
                />
              </div> */}
            </Card>
          )}
        </div>
      </div >
    </div >
  );
};

export default AccordianShipmentDetails;
