/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Card, Col, Dropdown, Form, Modal, Row, Spinner } from "react-bootstrap";
import "../../assets/styles/userProfile/userAddressBook.css";
import "../../assets/styles/userProfile/userSavedCard.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAddress,
  fetchUserAddressDetails,
  fetchAddressList,
  bulkAddress,
} from "../../redux/address/adressThunk";
import { fetchCountryDetails, fetchPostalDetails, fetchStateName } from "../../redux/landing/landingThunk";
import { Button, Autocomplete, IconButton, Paper, Skeleton, Tooltip, Box, Pagination } from "@mui/material";
import {
  resetErrors,
  resetErrorsAddress,
} from "../../redux/address/addressSlice";
import Swal from "sweetalert2";
import CustomAutocomplete from "../common/CustomCountryPostal";
import UserSkeleton from "./UserSkeleton";
import { Logger } from "ag-grid-community";
import { bulkOrders } from "../../redux/services/ServicesThunk";
import { useNavigate } from "react-router-dom";
import SwalFireComponent from "../common/SwalFireComponent";

const adressValidation = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  postal: Yup.string().required("This field is required"),
  address01: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  contact_extention: Yup.string().required("required"),
  contactNo: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits")
    .required("This field is required"),
  email: Yup.string()
    .email("The email must be a valid email address.")
    .required("This field is required"),
  // state: Yup.string().required("This field is required"),
  // state_sort_name: Yup.string().required("This field is required"),
  // state: Yup.string().when("state_sort_name", {
  //   is: (val) => !val || val.length === 0,
  //   then: Yup.string().required("Either State or State Sort Name is required"),
  //   otherwise: Yup.string(),
  // }),
  // state_sort_name: Yup.string().when("state", {
  //   is: (val) => !val || val.length === 0,
  //   then: Yup.string().required("Either State or State Sort Name is required"),
  //   otherwise: Yup.string(),
  // }),
});

const AddressCard = ({ userId, country_Name, bulkUserAddRow, bulkDatamodel }) => {

  const contentRef = useRef(null);

  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const addressErrors = useSelector(
    (state) => state?.addressValidation?.profileFieldError
  );
  const deleteLoading = useSelector(
    (state) => state?.addressValidation
  );
  const country_Postal = useSelector(
    (state) => state.landingReducer?.countryPostal?.data
  );
  const listAddress = useSelector(
    (state) => state.addressValidation?.addressList?.CustomerAddress?.data
  );
  const isPostalLoading = useSelector(
    (state) => state.landingReducer.isPostalLoading
  );
  const isSaveLoading = useSelector(
    (state) => state.addressValidation?.isSaveLoading
  );
  const isAddressLoading = useSelector(
    (state) => state.addressValidation?.isAddressLoading
  );
  const isBulkAddressLoading = useSelector(
    (state) => state?.servicedata?.isbulkAddressLoading
  );

  const state_name = useSelector(
    (state) => state.landingReducer?.stateName?.data
  );
  const dispatch = useDispatch();
  const [countryName, setCountryName] = useState();
  const [selectedOption, setSelectedOption] = useState("Home");
  const [filteredAddress, setFilterAddress] = useState(listAddress);
  const [showForm, setShowForm] = useState(false);
  const [isFormCollectionOpen, setIsFormCollectionOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [postalOptions, setPostalOptions] = useState([]);
  const [deleteId, setDeleteID] = useState(null);
  const [allErros, setAllErrors] = useState();
  const [lineErrors, setLineErrors] = useState();
  const [postalCode, setPostalCode] = useState({ pincode: "" });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    Postcode: "",
    companyName: "",
    address01: "",
    address02: "",
    state: "",
    city: "",
    email: "",
    contactNo: "",
    contact_extention: "",
    contact_prefix: "",
    country_id: "",
    country_code: "",
    addressType: selectedOption,
    address_id: "",
    addressInput: "",
    state_sort_name: ''
  });

  const [formErrors, setFormErrors] = useState({
    country: "",
    Postcode: "",
  });

  const initialValues = {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    companyName: formData?.companyName,
    country: formData?.country,
    postal: formData?.Postcode,
    address01: formData?.address01,
    address02: formData?.address02,
    state: formData?.state_sort_name || formData?.state,
    state_sort_name: formData?.state_sort_name || formData?.state,
    city: formData?.city,
    email: formData?.email,
    contactNo: formData?.contactNo,
    contact_extention: formData?.contact_extention,
    country_id: formData?.country_id,

  };

  const Send = (values) => {
    const countryid = country_Name?.find(
      (cntr) => cntr?.name.toLowerCase() === values?.country.toLowerCase()
    );
    const addressData = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      company_name: values?.companyName,
      email: values?.email,
      contact_extention: values?.contact_extention ? values?.contact_extention : "",
      contact_no: values?.contactNo,
      address_1: values?.address01,
      address_2: values?.address02 ? values?.address02 : "",
      city: values?.city,
      state: formData?.state_sort_name ? formData?.state_sort_name : values?.state,
      address_validation: false,
      address_type: selectedOption,
      country: values?.country,
      customer_id: userId?.id,
      postcode: values?.postal,
      country_id: countryid?.id,
      address_id: formData?.address_id,
      uniquesessionid: uniquesessionid,
    }
    dispatch(
      fetchUserAddressDetails({
        addressData, store: `${addressData?.address_id ? "update" : "store"}`
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.CustomerAddress?.status === "success" && response?.CustomerAddress?.code === "200") {
          dispatch(
            fetchAddressList({
              // address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
          SwalFireComponent({ icon: "success", title: `${addressData?.address_id ? "Address Update Successfully" : "Address Saved Successfully"}` })
          setPostalCode({ pincode: "" });
          setFormData({
            country: "",
            country_id: "",
            countryCode: "",
            Postcode: "",
            firstName: "",
            lastName: "",
            companyName: "",
            address01: "",
            address02: "",
            state: "",
            city: "",
            email: "",
            contactNo: "",
            contact_prefix: "",
            addressType: selectedOption,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
        setShowForm(!showForm);
        setIsFormCollectionOpen(!isFormCollectionOpen);
      })
      .catch((error) => {
        dispatch(
          fetchAddressList({
            address_id: formData?.address_id,
            customer_id: userId?.id,
            uniquesessionid: uniquesessionid,

          })
        );
      });
  };

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchAddressList({
          customer_id: userId?.id,
          uniquesessionid: uniquesessionid,
        })
      );
      dispatch(fetchCountryDetails());
      dispatch(fetchStateName());

    }
  }, []);

  useEffect(() => {
    if (formData?.country_id) {
      const stateName = formData?.country_id;
      dispatch(fetchStateName(stateName));
    }
  }, [formData?.country_id, dispatch]);

  useEffect(() => {
    setFilterAddress(listAddress);
  }, [listAddress]);

  useEffect(() => {
    if (country_Name) {
      const topCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries === 1
      );
      const remainingCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries !== 1
      );
      const filteredCountries = [...topCountries, ...remainingCountries];
      setCountryName(filteredCountries);
    }
  }, [country_Name]);

  const toggleFormCollection = () => {
    setShowForm(!showForm);
    setIsFormCollectionOpen(!isFormCollectionOpen);
    setFormData({
      ...formData,
      firstName: "",
      lastName: "",
      country: "",
      Postcode: "",
      companyName: "",
      address01: "",
      address02: "",
      state: "",
      city: "",
      email: "",
      contactNo: "",
      country_id: "",
      contact_prefix: "",
    });
    setPostalCode({ pincode: "" });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      country: "",
      Postcode: "",
    }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMakeDefault = (address, selectedIndex) => {
    // setDeleteID(address?.id);
    // const addressDelete = filteredAddress[selectedIndex]?.id
    const addressData = {
      address_id: address.id,
    }
    dispatch(
      fetchUserAddressDetails({
        addressData, store: `${addressData?.address_id ? "default" : "store"}`
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.CustomerAddress?.status === "success" && response?.CustomerAddress?.code === "200") {
          dispatch(
            fetchAddressList({
              address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
          SwalFireComponent({ icon: "success", title: "Address Default set Successfully" })

        }
      })
      .catch((error) => { });

  }

  const handleEditDetail = (address, selectedIndex, e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let data = country_Name?.find((i) => {
      return address?.country_id === i?.id
    })
    let statedata = state_name?.find((i) => {
      return address?.state === i?.code
    })
    dispatch(resetErrors());
    setIsFormCollectionOpen(true);
    setFormData({
      ...formData,
      country: data?.name,
      Postcode: address?.postcode,
      firstName: address?.first_name,
      lastName: address?.last_name,
      companyName: address?.company_name,
      address01: address?.address_1,
      address02: address?.address_2 ? address.address_2 : "",
      country_id: address?.country_id,
      state: address?.state,
      city: address?.city,
      email: address?.email,
      contact_extention: address?.contact_extention,
      contactNo: address?.contact_no,
      addressType: selectedOption,
      countryCode: address?.country_code,
      address_id: address?.id,
      state_sort_name: statedata?.code
    });
    setSelectedOption(address?.address_type);
    setShowForm(true ? true : true);
  };

  const handleDeleteAddress = (address, index) => {
    setDeleteID(address?.id);
    const addressDelete = filteredAddress[index]?.id
    const addressData = {
      address_id: address.id,
      customer_id: userId?.id,
      uniquesessionid: uniquesessionid,
    }
    dispatch(fetchUserAddressDetails({ addressData, store: "delete" }))
      .unwrap()
      .then((response) => {
        if (response?.CustomerAddress?.status === "success" && response?.CustomerAddress?.code === "200") {
          dispatch(
            fetchAddressList({
              address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
          SwalFireComponent({ icon: "success", title: "Address Deleted Successfully" })

        }
      })
      .catch((error) => { });
  };

  const handleaddress = (e) => {
    setFormData({
      ...formData,
      addressInput: e.target.value,
    });
    if (e.target.value === "") {
      setFilterAddress(listAddress);
    }
  };

  const searchAddresses = () => {
    if (formData?.addressInput) {
      let address = listAddress?.filter(
        (address) =>
          address.address_1
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.city
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.contact_no
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.first_name
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.last_name
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.postcode
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase())
      );
      setFilterAddress(address);
    } else {
      setFilterAddress(listAddress);
    }
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCountry = (setFieldValue, newValue) => {
    setFieldValue("postal", "");

    setFieldValue("country", newValue);

    if ((country_Name, newValue)) {
      const data = country_Name.find((cntr) => cntr.name === newValue);
      setFormData({
        ...formData,
        country_code: data?.code ? data?.code : "",
        country_id: data?.id,
        Postcode: "",
      });
    }
  };

  const handlepostal = (setFieldValue, newInputValue) => {
    setFieldValue("postal", newInputValue);
    dispatch(
      fetchPostalDetails({
        country_id: formData?.country_id,
        search_pincode: newInputValue,
        uniquesessionid: uniquesessionid,
      })
    );
    if (country_Postal !== undefined && country_Postal !== null) {
      setPostalOptions(country_Postal);
      const stateName = country_Postal.find((postal) => postal.state_name);
      const cityName = country_Postal.find((postal) => postal.city_name);
      if (!formData?.address_id) {
        setFieldValue("state", stateName?.state_name);
        setFieldValue("city", cityName?.city_name);

        setFormData({
          ...formData,
          state: stateName ? stateName.state_name : "",
          city: cityName ? cityName.city_name : "",
        });
      }
    }
  };

  const handleSelectChange = (event) => {
    const selectedState = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      state_sort_name: selectedState,
      state: selectedState
    }));
  };

  const importaddressfromexcel = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fileInputRef = useRef(null);
  const useriD = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate()

  const handleFile = (e) => {
    setLineErrors('')
    setAllErrors("")
    let formData = new FormData();
    formData.append('address_file', e.target.files[0]);
    // formData.append('customer_id', useriD?.id);
    // dispatch(bulkOrders(formData))
    dispatch(
      bulkAddress({
        formData, store: `import`
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.CustomerAddress?.status === "success" && response?.CustomerAddress?.code === "200") {
          SwalFireComponent({ icon: "success", title: `${response?.CustomerAddress?.message}` });
          handleCloseModal()
          dispatch(
            fetchAddressList({
              // address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );

        }
        if (response?.CustomerAddress?.code === "500" && response?.CustomerAddress?.status === "fail") {
          setLineErrors(response?.CustomerAddress?.errors)
        }
        if (response?.CustomerAddress?.code === "422" && response?.CustomerAddress?.status === "fail") {
          setAllErrors(response?.CustomerAddress?.errors)
        }
      })
  }
  const handleButtonClick = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current?.click();
    }
  };

  const formatKey = (key) =>
    key.split('_').map((word, index) =>
      (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.charAt(0).toLowerCase() + word.slice(1))).join(' ') + ',';

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // const getCurrentPageData = () => {
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   return filteredAddress?.slice(startIndex, endIndex);
  // };
  // const filteredDataCount = filteredAddress?.length;
  // const handlePageChange = (event, newPage) => {
  //   setCurrentPage(newPage);
  // };
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };
  return (
    <div className={`user-address-outer ${bulkUserAddRow ? "mb-0" : "mb-5"}`}>
      <>
        <Card style={{ border: "none" }}>
          {bulkDatamodel && <p className="mb-3">
            If you want to change your collection address than you have to select default address
          </p>}
          <Card.Body className={` ${bulkUserAddRow ? "p-0" : "p-4"}`}>
            <div>
              {!bulkDatamodel && <div className="mb-3 d-flex justify-content-between">
                <div>
                  {!bulkUserAddRow && <div>
                    {isFormCollectionOpen ? (
                      <h5>Add New Address</h5>
                    ) : (
                      <h5>Address Book</h5>
                    )}
                  </div>}

                  {
                    isFormCollectionOpen && bulkUserAddRow && <h5>Edit Address</h5>
                  }
                </div>

                {!isFormCollectionOpen && < Button variant="outlined" className="outer_btn btn d-flex gap-3" style={{ borderColor: "#113468", height: "40px" }} onClick={importaddressfromexcel}>
                  <img src={`${window.location.origin}/assets/multishipment/msexcel.svg`} alt="msexcel" />
                  Import</Button>}
              </div>}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="user-wallet-payment-modal d-flex justify-content-center align-items-center ">
                <Modal.Body className="p-0 overflow-auto d-flex align-items-start rounded-3">
                  <div
                    className="user-modal-wallet w-100"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    <Modal.Header style={{ right: "0px" }}
                      closeButton
                      className="closebutton d-flex justify-content-end position-sticky  z-3 bg-white"
                    ></Modal.Header>
                    <div className="px-4 pb-4 d-flex flex-column gap-3">
                      <div className="d-flex flex-column gap-4">
                        <h5>Import Excel</h5>
                        <div className='d-flex justify-content-center gap-5 align-items-center'>
                          <a className=' blue-text' href={`${window.location.origin}/assets/excelData/Address_Sample_File.xls`} style={{ cursor: "pointer" }}>
                            <div className=' d-flex gap-3 justify-content-center'>
                              <img src={`${window.location.origin}/assets/multishipment/file_save.svg`} alt="file_save" />
                              <span className='paragraph1 blue-text text-nowrap text-decoration-none' style={{ cursor: "pointer" }}>Download sample file</span>
                            </div>
                          </a>
                        </div>
                        {!isBulkAddressLoading ? (
                          <>
                            <div className='d-flex justify-content-center '>
                              <Form className='' style={{ width: "80%" }}>
                                <div className=''>
                                  <input ref={fileInputRef} type={"file"} id={"input-file-upload"}
                                    accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} onChange={(e) => handleFile(e)} />
                                  <label id="label-file-upload" htmlFor="input-file-upload" className="doted_border doted_border-1 py-5">
                                    <div className='w-50 gap-2 d-flex flex-column justify-content-center'>
                                      <div className='text-center '>
                                        <img src={`${window.location.origin}/assets/multishipment/backup.svg`} alt="backup" />
                                      </div>
                                      <div>
                                        <span className='d-flex packaging-holder gap-1 justify-content-center '>
                                          <p className='text-nowrap '>Drag and drop</p>
                                          <img src={`${window.location.origin}/assets/multishipment/msexcel.svg`} alt="msexcel" />
                                          <p className='text-nowrap '>excel file here</p>
                                        </span>
                                      </div>
                                      <div>
                                        <span className='d-flex packaging-holder gap-2 align-items-center  justify-content-center '>
                                          <hr className='w-50 m-0' />
                                          <p>or</p>
                                          <hr className='w-50 m-0' />
                                        </span>
                                      </div>
                                      <div className='text-center'>
                                        <Button className="primarybtn btn px-5 py-2" onClick={handleButtonClick} >
                                          Browse File
                                        </Button>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </Form>
                            </div>
                          </>
                        ) : (
                          <>
                            <Card className="border-0 order-details-card">
                              <Card.Body>
                                <Box className="w-100 justify-content-center d-flex ">
                                  <Skeleton variant="rounded" width={"84%"} height={256} />
                                </Box>
                              </Card.Body>
                            </Card>
                          </>
                        )}
                      </div>
                      {/* <p className="text-danger px-5 mx-5">{allErros}</p> */}

                      <div className="text-danger px-5 mx-5" ref={contentRef} dangerouslySetInnerHTML={{ __html: allErros }} />
                      {lineErrors && (
                        <div className="text-danger px-3">
                          {lineErrors.map((error, index) => (
                            <>
                              <div className="d-flex gap-2">
                                <div className="text-nowrap">
                                  In Row No. {error?.line_no + 2}
                                </div>
                                <div className="d-flex gap-1" key={index}>
                                  {Object.keys(error)
                                    .filter(key => Array.isArray(error[key]))
                                    .map((key) => (
                                      <div className="text-nowrap" key={key}>
                                        {formatKey(key)}
                                      </div>
                                    ))}
                                </div>
                                <div>
                                  <div>is missing or invalid.</div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      )}

                      {/* {lineErrors && (
                        <div className="text-danger px-5 mx-5">
                          {lineErrors.map((error, index) => (
                            <div key={index}>

                              {error?.city?.map((cityError, cityIndex) => (
                                <div className="d-flex gap-2" key={cityIndex}>
                                  <span>address line no  {error.line_no + 2}</span>
                                  <span>{cityError}</span>
                                </div>
                              ))}
                              {error?.state?.map((stateError, stateIndex) => (
                                <div className="d-flex gap-2" key={stateIndex}>
                                  <span>address line no  {error.line_no + 2}</span>
                                  <span>{stateError}</span>
                                </div>
                              ))}
                              {error?.country?.map((countryError, countryIndex) => (
                                <div className="d-flex gap-2" key={countryIndex}>
                                  <span className=""> address line no  {error.line_no + 2} &nbsp;{countryError}</span>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      )} */}

                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {!isFormCollectionOpen && (
                <Row className="user-address-row mb-3">
                  <Col sm={6} md={6} lg={6} xl={5}>
                    <Card className=" mb-2 card-border">
                      <div className={`address-bg d-flex px-4 card-add-address ${isFormCollectionOpen ? "open" : ""
                        }`} onClick={toggleFormCollection} style={{ height: "50px" }} >
                        <img src={`${window.location.origin}/assets/${isFormCollectionOpen ? "minusblue" : "plusblue"}.svg`} alt="Toggle Icon" />
                        {(!isFormCollectionOpen || isFormCollectionOpen) && (
                          <p className=" mb-0 px-2 d-flex align-items-center">
                            Add New Address
                          </p>
                        )}
                      </div>
                    </Card>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={5}>
                    <div class="input-group" style={{ height: "50px" }}>
                      <input
                        type="text"
                        class="form-control  user-address-placeholder py-2 is-valid-paper position-relative  user-address-padding"
                        name="addressInput"
                        placeholder="Enter your text"
                        onChange={handleaddress}
                        value={formData?.addressInput}
                        aria-label="Text input with button"
                      />
                      <Button style={{ right: "5px", top: "5px", zIndex: "999", borderRadius: "6px", height: "40px" }}
                        className="blue-text primarybtn btn savebtn-user position-absolute "
                        onClick={searchAddresses}>
                        Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {showForm && (
                <Col md="12" className="mb-2 ">
                  <Card
                    className={` shadow-none border-0 user-address-form-card w-75 ${bulkUserAddRow ? "mb-0 py-0" : "mb-4 py-3"}`}
                    style={{ borderRadius: "10px" }}
                  >
                    <Card.Body
                      className="w-90 w-sm-100 "
                      style={{ padding: "0px" }}
                    >
                      <Formik
                        // enableReinitialize
                        initialValues={initialValues}
                        validationSchema={adressValidation}
                        onSubmit={Send}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0 gap-xxl-0">
                              <Col md="6 " lg="5">
                                <Paper
                                  className={`my-paper-UserAddress ${(errors.country &&
                                    touched.country && errors.country)
                                    || formErrors?.country
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{ borderRadius: "6px" }}
                                >
                                  <CustomAutocomplete
                                    options={countryName && countryName?.map(
                                      (data) => data?.name
                                    )}
                                    value={formData.country}
                                    onChange={(event, newValue) => {
                                      dispatch(resetErrors(""));
                                      setFormErrors({
                                        ...formErrors,
                                        country: ""
                                      });
                                      handleCountry(setFieldValue, newValue);
                                      setFieldValue("postal", "");
                                    }}
                                    placeholder={"Country"}
                                  />
                                </Paper>
                                {errors.country && (
                                  <Form.Text className="text-danger mt-0">
                                    {errors.country &&
                                      touched.country &&
                                      errors.country}
                                  </Form.Text>
                                )}
                                {/* {formErrors?.country && (
                                  <Form.Text className="text-danger mt-0">
                                    {formErrors.country}
                                  </Form.Text>
                                )} */}
                              </Col>
                              <Col md="4" lg="3">
                                <Paper
                                  className={`my-paper-UserAddress ${(errors.postal &&
                                    touched.postal &&
                                    errors.postal)
                                    // || formErrors?.Postcode
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{ borderRadius: "6px" }}
                                >
                                  <CustomAutocomplete
                                    value={values.postal}
                                    loading={isPostalLoading}
                                    placeholder={"Postal"}
                                    options={
                                      country_Postal &&
                                      country_Postal?.map(
                                        (pincodes) => pincodes?.pincode_no
                                      )
                                    }
                                    onInputChange={(event, newInputValue) => {
                                      handlepostal(
                                        setFieldValue,
                                        newInputValue
                                      );
                                      if (newInputValue) {
                                        setFormErrors((prevErrors) => ({
                                          ...prevErrors,
                                          Postcode: "",
                                        }));
                                      } else {
                                        setFormErrors((prevErrors) => ({
                                          ...prevErrors,
                                          Postcode: "This field is required",
                                        }));
                                      }
                                    }}
                                    // onBlur={(event) => {
                                    //   if (!event.target.value) {
                                    //     setFormErrors((prevErrors) => ({
                                    //       ...prevErrors,
                                    //       Postcode: "This field is required",
                                    //     }));
                                    //   }
                                    // }}
                                    isInvalid={
                                      errors.postal &&
                                      touched.postal &&
                                      errors.postal
                                    }
                                  />
                                </Paper>
                                {errors?.postal && (
                                  <Form.Text
                                    className="text-danger mt-0"
                                    type="invalid"
                                  >
                                    {errors.postal &&
                                      touched.postal &&
                                      errors.postal}
                                  </Form.Text>
                                )}
                                {/* {formErrors?.Postcode && (
                                  <Form.Text className="text-danger mt-0">
                                    {formErrors.Postcode}
                                  </Form.Text>
                                )} */}
                              </Col>
                            </Row>
                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    value={values.firstName}
                                    placeholder="First name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    placeholder="Last name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.companyName &&
                                      touched.companyName &&
                                      errors.companyName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="companyName"
                                    value={values.companyName}
                                    placeholder="Company Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Form.Group>
                              <Row className="mb-3 gap-3 gap-lg-0">
                                <Col md="12">
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.address01 &&
                                      touched.address01 &&
                                      errors.address01
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="address01"
                                    value={values.address01}
                                    placeholder="Address01"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.address01 &&
                                      touched.address01 &&
                                      errors.address01
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.address01 &&
                                        touched.address01 &&
                                        errors.address01}
                                    </span>
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group>
                              <Row className="mb-3 gap-3 gap-lg-0">
                                <Col md="12">
                                  <Form.Control
                                    className="user-address-placeholder input-main-all is-valid-paper my-paper-UserAddress"
                                    type="text"
                                    name="address02"
                                    value={values.address02}
                                    placeholder="Address02"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                              <Col md="6" className="collect-form-address">
                                {!state_name &&
                                  <Form.Group>
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.state &&
                                        touched.state &&
                                        errors.state
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="text"
                                      name="state"
                                      placeholder="State"
                                      value={values.state}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.state && touched.state}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.state &&
                                          touched.state &&
                                          errors.state}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                }
                                {state_name && <Form.Group controlId="formGridfirstname">
                                  <Form.Select aria-label="Default select example"
                                    placeholder='Select' onBlur={handleBlur}
                                    className={`Pickup-select-input shadow-none  ${errors.state_sort_name && touched.state_sort_name && errors.state_sort_name ? "is-invalid-paper" : "is-valid-paper" && formData?.state_sort_name === "Select" && "pickup-placeholder-select"}`} onChange={handleSelectChange} value={formData.state_sort_name}
                                    isInvalid={
                                      errors.state_sort_name &&
                                      touched.state_sort_name &&
                                      errors.state_sort_name
                                    }>
                                    {state_name?.map((option, index) => {
                                      return (
                                        <option key={index} value={option?.code} >
                                          {option?.default_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                  {errors?.state && (
                                    <Form.Text className="text-danger mt-0" type="invalid">
                                      {errors.state_sort_name &&
                                        touched.state_sort_name &&
                                        errors.state_sort_name}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                }
                              </Col>
                              <Col md="6">
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.city && touched.city && errors.city
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="city"
                                    placeholder="Enter City"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.city && touched.city && errors.city
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.city &&
                                        touched.city &&
                                        errors.city}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-4 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.email &&
                                      touched.email &&
                                      errors.email
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.email &&
                                      touched.email &&
                                      errors.email
                                    }
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.email &&
                                        touched.email &&
                                        errors.email}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Col md={3} xs={3} className="pe-0">
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.contact_extention &&
                                        touched.contact_extention &&
                                        errors.contact_extention
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="number"
                                      name="contact_extention"
                                      value={values.contact_extention}
                                      placeholder="+91"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      isInvalid={
                                        errors.contact_extention &&
                                        touched.contact_extention &&
                                        errors.contact_extention
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.contact_extention &&
                                          touched.contact_extention &&
                                          errors.contact_extention}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Col>
                                  <Col md={9} xs={9}>
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.contactNo &&
                                        touched.contactNo &&
                                        errors.contactNo
                                        ? "is-invalid-paper"
                                        : "is-valid-paperz"
                                        }`}
                                      type="number"
                                      placeholder="contact No"
                                      name="contactNo"
                                      value={values.contactNo}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.contactNo &&
                                        touched.contactNo &&
                                        errors.contactNo
                                      }
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.contactNo &&
                                          touched.contactNo &&
                                          errors.contactNo}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                                <p className="profile_address-error">
                                  {addressErrors?.phone_number}
                                </p>
                              </Col>
                            </Row>

                            <div className="radiobtnAddress mb-3 text-nowrap d-flex flex-row align-content-center  gap-2  gap-lg-3 gap-sm-2">
                              <p className="me-1">Select address type : </p>
                              <div className="radio-holderaddress custom_radio d-flex align-content-center gap-2 flex-row text-nowrap">
                                <div className=" homeRadio d-flex flex-row gap-1 p-1">
                                  <input
                                    className="chek"
                                    type="radio"
                                    id="Home"
                                    name="select"
                                    value="Home"
                                    checked={selectedOption === "Home"}
                                    onChange={handleOptionChange}
                                  />
                                  <label for="Home">Home</label>
                                </div>
                                <div className="d-flex flex-row gap-1 p-1 ">
                                  <input
                                    type="radio"
                                    id="Work"
                                    name="select"
                                    value="Work"
                                    checked={selectedOption === "Work"}
                                    onChange={handleOptionChange}
                                  />
                                  <label for="Work">Work</label>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex gap-2">
                              <Button style={{ height: "40px" }} className="blue-text primarybtn btn user-save-address" type="submit" >
                                {!isSaveLoading ? (
                                  <>save</>
                                ) : (
                                  <>
                                    <Spinner animation="border" size="sm" />
                                  </>
                                )}
                              </Button>
                              {!bulkUserAddRow &&
                                < Button variant="link" className="primary_btn_link btn reset-btn" onClick={toggleFormCollection}>
                                  Cancel
                                </Button>
                              }
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              <div className="d-flex flex-wrap gap-2">
                {isAddressLoading ? (
                  <>
                    <UserSkeleton width="83%" twoLine={false} tworound={false} />
                  </>
                ) : (
                  <>

                    {filteredAddress && filteredAddress.length && !showForm ? (
                      <>
                        <div className="w-100">
                          <div className="d-flex flex-wrap gap-4 flex-column flex-sm-row flex-md-row">
                            {filteredAddress?.map((address, index) => (
                              <div className="mapped-cards" key={index} style={{ flex: "0 0 50%", maxWidth: "40.3%" }}  >
                                <Card className="h-100 w-100 card-border">
                                  <Card.Body>

                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex gap-1">

                                        <div
                                          className="default-address-label mb-2 text-center"
                                          style={{
                                            color: "#113468", backgroundColor: "#E9E9E9",
                                            inlineSize: "min-content", padding: "0px 12px ", borderRadius: "3px",
                                          }}  >
                                          <span>{address?.address_type}</span>
                                        </div>
                                        {address?.is_default === "Yes" && <div
                                          className="default-address-label mb-2 text-center"
                                          style={{
                                            color: "#113468", backgroundColor: "#E9E9E9",
                                            inlineSize: "min-content", padding: "0px 12px ", borderRadius: "3px",
                                          }}  >
                                          <span>Default</span>
                                        </div>}
                                      </div>



                                      <div className="d-flex gap-3 align-items-start  ">
                                        <Dropdown
                                          className="top-dropdown text-end drpdwn-header"
                                          style={{ padding: "0px" }}
                                        >
                                          {(address.id === deleteId && deleteLoading) ?
                                            (
                                              <>
                                                <div className="d-flex justify-content-end">
                                                  <Spinner animation="border" size="sm" />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <Dropdown.Toggle
                                                  variant="light"
                                                  id="dropdown-basic"
                                                  className="user-custom-toggle p-0"
                                                >
                                                  <img
                                                    src={`${window.location.origin}/assets/user-profile/editdots.svg`}
                                                    alt="Dropdown"
                                                  />
                                                </Dropdown.Toggle>
                                              </>
                                            )}
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              disabled={address?.is_default === "Yes"}
                                              onClick={() => handleMakeDefault(address, index)}
                                            >
                                              Make Default
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => handleEditDetail(address, index)}
                                            >
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              disabled={address?.is_default === "Yes"}
                                              onClick={() => handleDeleteAddress(address, index)}
                                            >
                                              Delete
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <div className="" style={{ flex: "1" }}>
                                          <Tooltip title="Edit" className="pointer-icon"    >
                                            <IconButton className="p-0" onClick={() => handleEditDetail(address, index)}>
                                              <>
                                                <img className="pointer-icon" alt="icon" src={`${window.location.origin}/assets/address-icons/edit.svg`} />
                                              </>
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                        <div className="" style={{ flex: "1" }}>
                                          <Tooltip title="Delete" className="pointer-icon"    >
                                            <IconButton className="p-0 w-100 " onClick={() => address.id === deleteId && deleteLoading ? null : handleDeleteAddress(address, index)} >
                                              {address.id === deleteId && deleteLoading ? (
                                                <>
                                                  <Spinner animation="border" className="userParcel-spinner" size="sm" />
                                                </>
                                              ) : (
                                                <>
                                                  <img className="pointer-icon" alt="icon" src={`${window.location.origin}/assets/address-icons/delete.svg`} />
                                                </>

                                              )}
                                            </IconButton>
                                          </Tooltip>
                                        </div> */}
                                      </div>
                                    </div>
                                    <Card.Text>
                                      <p
                                        style={{ margin: "0px", fontWeight: "500" }}
                                      >
                                        {`${address.first_name} ${address.last_name
                                          }   ${address?.contact_no !== null
                                            ? address?.contact_no
                                            : ""
                                          }`}
                                      </p>
                                      <p className="m-0">
                                        {`${address.address_1}, ${address.city}`}
                                      </p>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            ))}
                            
                          </div>
                        </div>
                        {/* {filteredDataCount >= itemsPerPage && (
                              <div className=" d-flex justify-content-end w-100">
                                <Pagination
                                  count={Math.ceil(
                                    filteredDataCount / itemsPerPage
                                  )}
                                  variant="outlined"
                                  shape="rounded"
                                  page={currentPage}
                                  onChange={(event, page) => {
                                    handlePageChange(event, page);
                                    scrollToTop();
                                  }}
                                />
                              </div>
                            )} */}
                      </>
                    ) : (
                      <>
                        {!showForm && (
                          <>
                            <Card as={Col} className="col-lg-4 col-md-12">
                              <Card.Body>
                                <Card.Text>No Address Saved..</Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </>
    </div >
  );
};

export default AddressCard;
