/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal, Form, Row, Col, Container, Spinner, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/authentication.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoginRegistration, LoginUser, EmailVerification, forgetemailaddress, forgetemailotppassword } from "../../redux/login_registration/LoginRegistrationThunk";
import { useModel } from "../../utils/modalContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { resetErrors } from "../../redux/login_registration/LoginRegistrationSlice";
import { Paper, Button } from "@mui/material";
import SwalFireComponent from "../../components/common/SwalFireComponent";

const Authentication = () => {
  const {
    modalname,
    setModalname,
    showAuthenticationModal,
    setShowAuthenticationModal,
  } = useModel();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const passworderror = useSelector((state) => state.profileData.error);
  const verifyEmail = useSelector((state) => state.loginRegistration);
  const verifyEmailforgotpassword = useSelector((state) => state?.loginRegistration?.forgetemailaddress?.errors);
  const loginData = useSelector(
    (state) => state.loginRegistration?.error?.ForgotPassword?.errors
  );
  const loginError = useSelector((state) => state.loginRegistration?.LoginUserData?.Login?.errors);
  const otperror = useSelector((state) => state?.loginRegistration?.forgetemailotppassword?.VerifyOtp?.errors?.otp);
  const otperrorconfirmation = useSelector((state) => state?.loginRegistration?.forgetemailotppassword?.VerifyOtp?.errors?.password_confirmation);
  const signupotperror = useSelector((state) => state?.loginRegistration?.SignupOtp?.errors?.otp);
  const signupotperrorall = useSelector((state) => state?.loginRegistration);
  let isLoading = useSelector((state) => state.loginRegistration.isLoading);
  const {
    isLoadingSignUp,
    isLoadingLogin,
    isVerification,
    isLoadingForgot,
    isLoadingEmail,
    isLoadingOtp,
  } = useSelector((state) => ({
    isLoadingSignUp: state.loginRegistration.isLoadingSignUp,
    isLoadingLogin: state.loginRegistration.isLoadingLogin,
    isVerification: state.loginRegistration?.error,
    isLoadingForgot: state.loginRegistration.isLoadingForgot,
    isLoadingEmail: state.loginRegistration.isLoadingEmail,
    // isLoadingForgot: state.loginRegistration.isLoadingForgot,
    isLoadingOtp: state.loginRegistration.isLoadingOtp,
  }));

  const whereYouFindOption = [
    "Please Enter where you find us:",
    "Facebook",
    "Twitter",
    "Youtube",
    "Instagram",
    "Linkedin",
    "Other",
  ];
  const signUpError = useSelector((state) => state?.loginRegistration?.LoginRegistration?.Registration?.errors

  );
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const [isChecked, setIsChecked] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("PERSONAL");
  const [validated, setValidated] = useState(false);
  const [showValidation, setShowValidation] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [validOtp, setValidOtp] = useState();
  const validateArray = (arr) => {
    return arr.every((element) => element !== "");
  };
  const emailRegex =
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;

  const setotppopup = () => {
    setPasswordVisible(false);
    setModalname("otp");
    setShowAuthenticationModal(true);
  };
  const setloginotppopup = () => {
    setPasswordVisible(false);
    setModalname("loginotp");
    setShowAuthenticationModal(true);
  };

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [formData, setFormData] = useState({
    account_type: selectedOption ? selectedOption : "PERSONAL",
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    password: "",
    phone_prefix: "",
    phone: "",
    // referral_code: "",
    agreedToTerms: isChecked,
    otp: "",
    social: whereYouFindOption[0],
  });
  const [loginErrorForm, setLoginErrorForm] = useState({
    email: "",
    password: "",
  });

  const [forgotErrorForm, setForgotErrorForm] = useState({
    new_password: "",
    re_new_password: "",
    opt: "",
  });

  const [errorForm, setErrorForm] = useState({
    account_type: selectedOption ? selectedOption : "PERSONAL",
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    emailValid: "",
    password: "",
    passwordValid: "",
    prefix: "",
    contact_no: "",
    // referral_code: "",
    agreedToTerms: "accept",
    social: whereYouFindOption[0],
  });
  const [passwordForm, setPasswordForm] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    otp: "",
  });

  const [forgotPasswordVisible, setForgotPasswordVisible] = useState({
    password: true,
    password_confirmation: true,
  });

  const handleChange = (e) => {
    // const selectOption = e.target.value;
    // setFormData(prevFormData => ({
    //   ...prevFormData,
    //   social: whereYouFindOption[0]
    // }));

    setIsFormSubmitted(false);

    dispatch(resetErrors());
    setErrorForm({
      account_type: selectedOption ? selectedOption : "PERSONAL",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      emailValid: "",
      password: "",
      passwordValid: "",
      prefix: "",
      contact_no: "",
      // referral_code: "",
      agreedToTerms: isChecked,
      social: whereYouFindOption[0],
    });
    setForgotErrorForm({
      new_password: "",
      re_new_password: "",
      otp: "",
    });

    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    setLoginForm({
      ...loginForm,
      [name]: value,
    });
    setPasswordForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setLoginErrorForm({
      email: "",
      password: "",
    });
    // setLoginErrorForm({ ...loginErrorForm, [name]: "" });
    setIsChecked(e.target.checked);
    setShowValidation(false);
  };

  const forgotValidation = () => {
    const forgot_errors = {};

    let formIsValid = true;
    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;

    if (validateArray(otp)) {
      forgot_errors.otp = "";
    } else {
      forgot_errors.otp = "6 digits OTP is reqiuired";
    }
    if (passwordForm?.password.trim() === "") {
      forgot_errors.password = "Password is required";
      formIsValid = false;
    } else if (
      passwordForm?.password.length > 0 &&
      !passwordRegex.test(passwordForm?.password)
    ) {
      forgot_errors.password =
        "Password should be in 8-15 chars, 1 lowercase, 1 uppercase, 1 digit, 1 special character. ";
      formIsValid = false;
    }

    if (passwordForm.password_confirmation.trim() === "") {
      forgot_errors.re_new_password = "Password is required";
      formIsValid = false;
    }
    setForgotErrorForm(forgot_errors);
    return formIsValid;
  };

  const loginValidation = () => {
    const login_errors = {};

    let formIsValid = true;

    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    if (loginForm?.email?.length < 1) {
      login_errors.email = "Please Enter Your Email";
      formIsValid = false;
    }
    if (emailRegex.test(loginForm?.email) === false) {
      login_errors.emailValid = "Invalid email address";
      formIsValid = false;
    }
    if (loginForm?.password?.trim() === "") {
      login_errors.password = "Password is required";
      formIsValid = false;
    }

    setLoginErrorForm(login_errors);
    return formIsValid;
  }
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;
    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    if (isChecked !== true) {
      formIsValid = false;
    }
    if (formData.first_name.length < 1) {
      errors.first_name = "First Name is required";
      formIsValid = false;
    }
    if (!formData?.phone_prefix) {
      errors.prefix = "Enter Country code";
      formIsValid = false;
    }
    else if (formData?.phone_prefix?.length > 4) {
      errors.prefix = "Too long";
      formIsValid = false;
    }
    if (!formData?.phone) {
      errors.contact_no = "Contact is required";
      formIsValid = false;
    }
    else if (formData?.phone?.length > 10) {
      errors.contact_no = "Too long";
      formIsValid = false;
    }
    else if (formData?.phone?.length < 10) {
      errors.contact_no = "Too Short";
      formIsValid = false;
    }
    if (formData.last_name.length < 1) {
      errors.last_name = "Last Name is required";
      formIsValid = false;
    }
    if (selectedOption === "BUSINESS" && formData.company_name.length < 1) {
      errors.company_name = "Business Name is required";
      formIsValid = false;
    }
    // if (selectedOption === "BUSINESS" && formData?.prefix?.length < 1) {
    //   errors.prefix= "Country code is required";
    //   formIsValid = false;
    // }
    if (formData?.email.length < 1) {
      errors.email = "Email is required";
      formIsValid = false;
    } else if (!emailRegex.test(formData?.email)) {
      errors.email = "Invalid email address";
      formIsValid = false;
    }

    if (formData?.password?.trim() === "") {
      errors.password = "Password is required";
      formIsValid = false;
    } else if (
      formData.password.length > 0 &&
      !passwordRegex.test(formData?.password)
    ) {
      errors.password =
        "Password should be in 8-15 chars, 1 lowercase, 1 uppercase, 1 digit, 1 special character. ";
      formIsValid = false;
    }

    setErrorForm(errors);

    return formIsValid;
  };

  const handleOptionChange = (e) => {
    dispatch(resetErrors());
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    const { name, value } = e.target;
    setSelectedOption(value);

    setIsChecked(false);
    setIsFormSubmitted(false);

    setFormData({
      account_type: value ? value : "PERSONAL",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      phone: "",
      phone_prefix: "",
      // referral_code: "",
      agreedToTerms: false,
      social: whereYouFindOption[0],
    });
    setErrorForm({
      account_type: value ? value : "PERSONAL",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      contact_no: "",
      prefix: "",
      // referral_code: "",
      agreedToTerms: "",
      social: whereYouFindOption[0],
    });
    setLoginErrorForm({
      email: "",
      password: "",
    });
  };

  const handleLogin = async (e) => {

    e?.preventDefault();

    setErrorForm({ ...errorForm, password: "", email: "" });
    const user_name = loginForm?.email;
    const password = loginForm?.password;
    if (loginValidation()) {

      dispatch(
        LoginUser({ user_name, password, uniquesessionid: uniquesessionid })
      )
        .unwrap()
        .then((response) => {
          if (response?.Login?.status === "success" && response?.Login?.code === "200") {
            localStorage.setItem("user", JSON.stringify(response?.Login?.data));
            Swal.fire({
              toast: true,
              icon: "success",
              title: "logged in successfully",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 2000,
              timerProgressBar: true,
              customClass: {
                timerProgressBar: "custom-progress-bar",
              },
            });
            setLoginForm({
              email: "",
              password: "",
            });
            setLoginErrorForm({
              email: "",
              password: "",
            });
            setIsChecked(false);
            setIsFormSubmitted(false);
            setPasswordVisible(false);
            setForgotPasswordVisible({
              forgotOtp: "",
              password: true,
              password_confirmation: true,
            });
            setShowAuthenticationModal(false);
          }
          if (response?.Login.status === "fail" && response?.Login?.code === "404" && response?.Login?.errors?.otp_verification === false && !response?.Login?.errors?.password) {
            setloginotppopup()
          }

        })
        .catch((error) => {
          console.error("Login failed:", error);
        });
    }
  };

  const handleregister = (e) => {
    setValidated(true);
    e?.preventDefault();
    setIsFormSubmitted(true);

    if (validateForm()) {
      dispatch(
        LoginRegistration({
          ...formData,
          uniquesessionid: uniquesessionid,
        })
      )
        .unwrap()
        .then((response) => {
          if (response?.Registration.status === "success" && response?.Registration?.code === "200") {
            setPasswordVisible(false);
            setForgotPasswordVisible({
              password: false,
              password_confirmation: false,
            });
            setotppopup();
          }
          if (response?.Registration.status === "success" && response?.Registration?.code === "202") {
            setPasswordVisible(false);
            setForgotPasswordVisible({
              password: true,
              password_confirmation: true,
            });
            setShowAuthenticationModal(true);
            setModalname("otp");
          }
        })

        .catch((error) => {
          console.error("Registration failed:", error);
        });

      setErrorForm({
        account_type: selectedOption ? selectedOption : "PERSONAL",
        first_name: "",
        last_name: "",
        company_name: "",
        email: "",
        password: "",
        passwordValid: "",
        // referral_code: "",
        agreedToTerms: "",
        social: whereYouFindOption[0],
      });
    }
    setSelectedOption(selectedOption ? selectedOption : "PERSONAL");
  };
  const resendEmailOtp = () => {
    dispatch(resetErrors());
    handleregister();
    setOtp(["", "", "", "", "", ""]);
  };
  const resendloginEmailOtp = () => {
    dispatch(resetErrors());
    handleLogin();
    setOtp(["", "", "", "", "", ""]);
  };
  const resendEmailOtpforget = () => {
    dispatch(resetErrors());
    setForgotErrorForm({
      new_password: "",
      re_new_password: "",
      opt: "",
    });
    setOtp(["", "", "", "", "", ""]);
    handleSendotp("resend");
  };
  const onhidemoidel = () => {
    dispatch(resetErrors());
    setErrorForm({
      account_type: selectedOption,
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      // referral_code: "",
      agreedToTerms: "",
      social: whereYouFindOption[0],
    });
    setFormData({
      account_type: selectedOption ? selectedOption : "PERSONAL",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      // referral_code: "",
      agreedToTerms: isChecked,
      social: whereYouFindOption[0],
    });
    setLoginForm({
      email: "",
      password: "",
    });
    setPasswordForm({
      ...passwordForm,
      password: "",
      password_confirmation: "",
      otp: "",
    });
    setForgotErrorForm({
      new_password: "",
      re_new_password: "",
      otp: "",
    });
    setOtp(["", "", "", "", "", ""]);

    setLoginErrorForm({
      email: "",
      password: "",
    });
    setFormData({
      account_type: "PERSONAL",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      // referral_code: "",
      agreedToTerms: false,
      social: whereYouFindOption[0],
    });

    setIsChecked(false);
    setIsFormSubmitted(false);
    setPasswordVisible(false);
    setForgotPasswordVisible({
      password: false,
      password_confirmation: false,
    });
    setSelectedOption("PERSONAL");
    setShowAuthenticationModal(false);
    setModalname("");
  };
  const content = [
    {
      title: "Competitive Rates",
      img: `${window.location.origin}/assets/signUp/competitive_rates.svg`,
    },
    {
      title: "Fast & Reliable Shipping Added",
      img: `${window.location.origin}/assets/signUp/discount_for.svg`,
    },
    {
      title: "Discount for High Volume Shippers",
      img: `${window.location.origin}/assets/signUp/fast_and_reliable.svg`,
    },
  ];

  const images = content.map((text, i) => (
    <div className="regforp d-flex justify-content-center align-items-center">
      <div className="d-flex regmainloop">
        <div className="parentpopupsub">
          <Row>
            <div key={i} className="regiconmain">
              <Col>
                <img
                  className="regicon"
                  src={text.img}
                  alt={`modalimage${i}`}
                />
              </Col>
            </div>
          </Row>
        </div>
      </div>
      <Col>
        <p>{text?.title}</p>
      </Col>
    </div>
  ));

  const toggleLoginModal = (e) => {
    dispatch(resetErrors());

    setFormData({
      account_type: selectedOption ? selectedOption : "PERSONAL",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      // referral_code: "",
      agreedToTerms: false,
      social: whereYouFindOption[0],
    });
    setPasswordForm({
      ...passwordForm,
      password: "",
      password_confirmation: "",
      otp: "",
    });

    setIsFormSubmitted({
      isFormSubmitted: false,
    });
    setErrorForm({
      account_type: selectedOption,
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      // referral_code: "",
      agreedToTerms: false,
      social: whereYouFindOption[0],
    });
    setLoginErrorForm({
      email: "",
      password: "",
    });
    setIsChecked(false);
    setIsFormSubmitted(false);
    setModalname(e);
  };
  const handleCheckotpforget = () => {
    if (forgotValidation() && validateArray(otp)) {
      const otpString = otp.join("");
      const data = {
        ...passwordForm,
        otp: otpString,
        uniquesessionid: uniquesessionid,
      };
      dispatch(forgetemailotppassword(data))
        .unwrap()
        .then((response) => {
          if (response?.VerifyOtp?.status === "success" && response?.VerifyOtp?.code === "200") {
            handleLogin();
            setTimeout(() => {
              setPasswordForm({
                ...passwordForm,
                password: "",
                password_confirmation: "",
                otp: "",
              });
              setOtp(["", "", "", "", "", ""]);
            }, 3000);
          }
        })
        .catch((error) => {
        });
    }
  };
  const handleCheckOtp = () => {
    const otpString = otp.join("");
    const updatedFormData = {
      ...formData,
      otp: otpString,
    };
    setFormData(updatedFormData);
    if (otp?.length === 6) {
      setValidOtp(otpString);
      dispatch(
        EmailVerification({
          email: formData?.email,
          otp: otpString,
          uniquesessionid: uniquesessionid,
        })
      )
        .unwrap()
        .then((response) => {
          if (response?.Registration?.status === 'success' && response?.Registration?.code === "200") {
            handleLogin();
            setFormData({
              account_type: selectedOption ? selectedOption : "PERSONAL",
              first_name: "",
              last_name: "",
              company_name: "",
              email: "",
              password: "",
              // referral_code: "",
              agreedToTerms: false,
              social: whereYouFindOption[0],
            });
            setPasswordForm({
              ...passwordForm,
              password: "",
              password_confirmation: "",
              otp: "",
            });
            setOtp(["", "", "", "", "", ""]);
          }
        })
        .catch((error) => {
          console.error(
            "An unexpected error occurred. Please try again later."
          );
          // }
        });
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    dispatch(resetErrors());
    setForgotErrorForm({ ...forgotErrorForm, otp: "" });
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      if (index > 0 && otp[index - 1] !== "") {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    } else if (!isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;

      if (index < newOtp.length - 1 && value !== "") {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }

      setOtp(newOtp);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleForgotPasswordVisibility = (name) => {
    setForgotPasswordVisible({
      ...forgotPasswordVisible,
      [name]: !forgotPasswordVisible[name],
    });
  };

  const handleSendotp = (from) => {
    dispatch(resetErrors());
    setPasswordVisible(false);
    dispatch(
      forgetemailaddress({
        email: passwordForm?.email,
        uniquesessionid: uniquesessionid,
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.status === "success" && response?.code === "200") {
          const message =
            from === "resend"
              ? "OTP resend successfully"
              : "OTP send successfully";
          SwalFireComponent({ icon: "success", title: message });
          toggleLoginModal("forgotpasswordotp");
        }
      })
      .catch((error) => {
        console.error("profile failed:", error);
      });
  };

  return (
    <div className="authen-login-sign-holder">
      <>
        <Container>
          <div className="mainform">
            <Modal
              show={showAuthenticationModal}
              onHide={() => onhidemoidel()}
              backdrop="static"
              keyboard={false}
            >
              <div className="modelpopup">
                {modalname === "login" ? (
                  <Modal.Body className="modalbodylogin">
                    <div className="loginmodelbody mt-5">
                      <Modal.Header
                        closeButton
                        className="closebutton d-flex justify-content-end"
                      ></Modal.Header>
                      <div className="loginmodelcontent ">
                        <Form onSubmit={handleLogin} className="login-fields">
                          <div className="modeltext text-center">
                            <h3 className="signtxt">Login</h3>
                            <h5 className="unlocktxt">
                              Welcome Back! Get Back to Shipping Excellence with{" "}
                              <b>Just One Click.</b>
                            </h5>
                          </div>
                          <Form.Group
                            className="mb-2 mt-4"
                            controlId="formGridemailAddress1"
                          >
                            <Form.Label className="formlabel">Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={loginForm?.email}
                              onChange={handleChange}
                              placeholder="Enter Email Address"
                              isInvalid={
                                (loginErrorForm?.email !== "" &&
                                  loginForm?.email === "") ||
                                (loginError?.email &&
                                  loginError?.email.length > 0) ||
                                (loginError?.user_name &&
                                  loginError?.user_name?.length > 0) ||
                                (loginErrorForm?.emailValid &&
                                  loginErrorForm?.emailValid?.length > 0)
                              }
                              // className="no-background-image"
                              className={`no-background-image ${loginErrorForm?.email || loginErrorForm?.emailValid || loginError?.user_name || loginError?.email
                                ? "is-invalid-paper mb-0"
                                : "is-valid-paper"
                                }`}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className={` ${loginError?.email ? "mb-2 mt-0" : "mb-0 mt-0"
                                }`}
                            >
                              {loginErrorForm?.email && (
                                <p>{loginErrorForm?.email}</p>
                              )}
                              {loginErrorForm?.emailValid && (
                                <p>{loginErrorForm?.emailValid}</p>
                              )}
                              {loginError?.email &&
                                loginError?.email?.map((msg, index) => (
                                  <p className="error-text">{msg}</p>
                                ))
                              }
                              {loginError?.user_name &&
                                loginError?.user_name?.map((msg, index) => (
                                  <p className="error-text">{msg}</p>
                                ))
                              }
                            </Form.Control.Feedback>

                          </Form.Group>

                          <Form.Group
                            className="mb-2"
                            controlId="formGridpassword"
                          >
                            <Form.Label className="formlabel w-100">
                              Password
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                value={loginForm?.password}
                                onChange={handleChange}
                                style={{ position: "relative" }}
                                placeholder="At least 8 characters"
                                isInvalid={
                                  (loginForm?.password === "" &&
                                    loginErrorForm?.password !== "") ||
                                  (loginError &&
                                    loginError?.password &&
                                    loginError?.password?.length > 0)
                                }
                                className={`no-background-image ${loginErrorForm?.password ||
                                  loginError?.password
                                  ? "is-invalid-paper mb-0"
                                  : "is-valid-paper"
                                  }`}
                              />
                              <InputGroup.Text
                                onClick={togglePasswordVisibility}
                                style={{ cursor: "pointer" }}
                                className="eyeauthen"
                              >
                                {passwordVisible ? (
                                  <VisibilityOffIcon
                                    style={{
                                      fontSize: "large",
                                      color: "#6b6b6b",
                                    }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{
                                      fontSize: "large",
                                      color: "#6b6b6b",
                                    }}
                                  />
                                )}
                              </InputGroup.Text>
                              {loginErrorForm?.password?.length > 1 ? (
                                <p
                                  className={`form-error-color ${loginErrorForm?.password?.length
                                    ? "mb-2 mt-0"
                                    : "mb-0"
                                    }`}
                                >
                                  {loginErrorForm?.password}
                                </p>
                              ) : (
                                <p
                                  className={`form-error-color ${loginErrorForm?.password
                                    ? "mb-2 mt-0"
                                    : "mb-0"
                                    }`}
                                >
                                  {loginError?.password}
                                </p>
                              )}

                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-1" controlId="formHorizontalCheck" ></Form.Group>
                          <Button type="submit" className="createbtn" style={{ height: "40px" }}>
                            {!isLoadingLogin ? (
                              <>
                                LOGIN
                              </>
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </Button>

                          <div>
                            <p
                              className=" paragraph2 text-end blue-text"
                              style={{ cursor: "pointer" }}
                            >
                              <a
                                href="#"
                                onClick={() => {
                                  toggleLoginModal("forgotpassword");
                                  setPasswordVisible(false);
                                  // setLoginForm({
                                  //   email: "",
                                  //   password: ""
                                  // })
                                  // setFormData({
                                  //   email: "",
                                  //   password: "",
                                  // });
                                  setPasswordForm({
                                    email: "",
                                    password: "",
                                    password_confirmation: "",
                                    otp: "",
                                  });
                                }}
                                className="login-link"
                              >
                                Forgot Password?
                              </a>
                            </p>
                          </div>
                          <p className="alreadytext">
                            Don't have an account?{" "}
                            <a
                              href="#"
                              onClick={() => {
                                toggleLoginModal("signup");
                                setPasswordVisible(false);
                              }}
                              className="login-link"
                            >
                              Signup
                            </a>
                          </p>
                        </Form>
                      </div>
                    </div>
                  </Modal.Body>
                ) : modalname === "signup" ? (
                  <Modal.Body>
                    <div className="modelbody">
                      <div className="parentpopup">
                        <div className="parentpoupsubmain">{images}</div>
                      </div>
                      <div className="modelcontent ">
                        <Modal.Header
                          closeButton
                          className="closebutton d-flex justify-content-end"
                        ></Modal.Header>
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleregister}
                        >
                          <div className="modeltext text-center">
                            <h3 className="signtxt">Signup</h3>
                            <h5 className="unlocktxt">
                              "Unlock Exclusive Shipping Discounts!"
                            </h5>
                            <h5 className="join-text">Join for Free Now!</h5>
                          </div>
                          <div className="px-4 authe-radio-holder px-4">
                            <div className="radiobtn px-4">
                              <p className="register-text">Register as:</p>
                              <div className="radio-holder">
                                <Form.Group className=" d-flex">
                                  <Form.Check
                                    className="signup-radio register-text"
                                    type="radio"
                                    id="customerRadio"
                                    name="userType"
                                    value="PERSONAL"
                                    label="Individual"
                                    checked={selectedOption === "PERSONAL"}
                                    onChange={handleOptionChange}
                                  />
                                  <Form.Check
                                    className="signup-radio register-text"
                                    type="radio"
                                    id="businessRadio"
                                    name="userType"
                                    value="BUSINESS"
                                    label="Business"
                                    checked={selectedOption === "BUSINESS"}
                                    onChange={handleOptionChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            {selectedOption === "PERSONAL" ? (
                              <Row className="formGridfirstname">
                                <Form.Group as={Col} controlId="formGridfirstname">
                                  <Form.Label className="formlabel">
                                    First Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="first_name"
                                    placeholder="Enter First Name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    isInvalid={
                                      errorForm.first_name !== "" &&
                                      formData.first_name === ""
                                    }
                                    className={` ${(errorForm.first_name) || (signUpError?.first_name && signUpError?.first_name[0]) ? "is-invalid-paper mb-0" : "is-valid-paper"}`} />
                                  <Form.Control.Feedback
                                    type="invalid" className={` ${errorForm.first_name ? "mb-2 mt-0" : "mb-0"}`}  >
                                    <p>{errorForm?.first_name}</p>
                                  </Form.Control.Feedback>
                                  <p className="error-text">
                                    {signUpError?.first_name &&
                                      signUpError?.first_name.map((msg, index) => (
                                        <p key={index}>{msg}</p>
                                      ))}
                                  </p>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridlastname">
                                  <Form.Label className="formlabel">
                                    Last Name
                                  </Form.Label>
                                  <Form.Control type="text"
                                    name="last_name"
                                    placeholder="Enter Last Name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    isInvalid={errorForm?.last_name !== ""}
                                    className={` ${errorForm?.last_name || signUpError?.last_name ? "is-invalid-paper  mb-0" : "is-valid-paper"}`}
                                  />
                                  <Form.Control.Feedback type="invalid"
                                    className={` ${errorForm.last_name ? "mb-2 mt-0" : "mb-0"}`}  >
                                    {errorForm.last_name && (
                                      <p>{errorForm.last_name}</p>
                                    )}
                                  </Form.Control.Feedback>
                                  {signUpError?.last_name &&
                                    signUpError?.last_name.map((msg, index) => (
                                      <p className="error-text" key={index}>{msg}</p>
                                    ))}
                                </Form.Group>
                              </Row>
                            ) : (
                              <>
                                <Row>
                                  <Form.Group as={Col}
                                    controlId="formGridfirstname"
                                    className="formGridfirstname">
                                    <Form.Label className="formlabel">
                                      First Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="first_name"
                                      placeholder="Enter First Name"
                                      value={formData.first_name}
                                      onChange={handleChange}
                                      isInvalid={errorForm?.first_name !== "" && formData?.first_name === ""}
                                      className={` ${errorForm?.first_name ? "is-invalid-paper mb-0" : "is-valid-paper"}`} />
                                    <Form.Control.Feedback type="invalid"
                                      className={` ${errorForm.first_name ? "mb-2 mt-0" : "mb-0"}`}  >
                                      {errorForm.first_name && (
                                        <p>{errorForm.first_name}</p>
                                      )}
                                      {signUpError?.email &&
                                        signUpError?.email.map((msg, index) => (
                                          <p key={index}>{msg}</p>
                                        ))}
                                    </Form.Control.Feedback>



                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    controlId="formGridlastname"
                                  >
                                    <Form.Label className="formlabel">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="last_name"
                                      placeholder="Enter Last Name"
                                      value={formData.last_name}
                                      onChange={handleChange}
                                      // pattern="[A-Za-z]+"
                                      isInvalid={
                                        errorForm.last_name !== "" &&
                                        formData.last_name === "" ||
                                        signUpError?.last_name &&
                                        signUpError?.last_name
                                      }
                                      className={` ${errorForm.last_name ||
                                        signUpError?.last_name &&
                                        signUpError?.last_name
                                        ? "is-invalid-paper mb-0"
                                        : "is-valid-paper"
                                        }`}
                                    />

                                    {errorForm.last_name && (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className={` ${errorForm.last_name
                                          ? "mb-2 mt-0"
                                          : "mb-0"
                                          }`}
                                      >
                                        <p>{errorForm.last_name}</p>
                                      </Form.Control.Feedback>
                                    )}
                                    {signUpError?.last_name &&
                                      signUpError?.last_name.map((msg, index) => (
                                        <p className="error-text" key={index}>{msg}</p>
                                      ))}
                                  </Form.Group>
                                </Row>
                                <Form.Group
                                  className="mb-2"
                                  controlId="formGridBusinessName"
                                >
                                  <Form.Label className="formlabel">
                                    Business Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="company_name"
                                    placeholder="Enter Business Name"
                                    value={formData.company_name}
                                    onChange={handleChange}
                                    isInvalid={
                                      errorForm.company_name !== "" &&
                                      formData.company_name === ""
                                    }
                                    className={` ${errorForm.company_name
                                      ? "is-invalid-paper mb-0"
                                      : "is-valid-paper"
                                      }`}
                                  />
                                  {errorForm.company_name && (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className={` ${errorForm.company_name
                                        ? "mb-2 mt-0"
                                        : "mb-0"
                                        }`}
                                    >
                                      <p>{errorForm.company_name}</p>
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Group>
                              </>
                            )}

                            <Form.Group
                              className="mb-2 form-email "
                              controlId="formGridemailAddress1"
                            >
                              <Form.Label className="formlabel">
                                Email Address
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Enter Email Address"
                                value={formData?.email}
                                onChange={handleChange}
                                isInvalid={
                                  (errorForm?.email !== "" &&
                                    formData?.email === "") ||
                                  (signUpError &&
                                    signUpError?.email &&
                                    signUpError?.email?.length > 0)
                                }
                                className={`no-background-image ${signUpError?.email ||
                                  errorForm?.email ||
                                  errorForm.emailValid
                                  ? "is-invalid-paper mb-0"
                                  : "is-valid-paper"
                                  }`}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className={` ${errorForm.email ? "mb-0 mt-0" : "mb-0"
                                  }`}
                              >
                                {signUpError?.email &&
                                  signUpError?.email.map((msg, index) => (
                                    <p key={index}>{msg}</p>
                                  ))}
                              </Form.Control.Feedback>

                              {errorForm?.email?.length > 1 ? (
                                <p
                                  className={`form-error-color ${errorForm.email ? "mb-2 mt-0" : "mb-0"
                                    }`}
                                >
                                  {errorForm?.email}
                                </p>
                              ) : (
                                <p
                                  className={`form-error-color ${errorForm.email ? "mb-2 mt-0" : "mb-0"
                                    }`}
                                >
                                  {errorForm?.emailValid}
                                </p>
                              )}
                            </Form.Group>
                            <div className="row">
                              <div className="col-5 col-sm-4 ">
                                <Form.Group
                                  className="mb-2 form-email "
                                  controlId="formGridemailAddress1" >
                                  <Form.Label className="formlabel text-nowrap">
                                    Country Code
                                  </Form.Label>
                                  {/* <div style={{ position: 'relative', display: 'inline-block' }}>
    <span style={{ position: 'absolute', top: '40%', transform: 'translateY(-50%)', left: '5px', pointerEvents: 'none' }}>+</span> */}
                                  <Form.Control
                                    type="number"
                                    name="phone_prefix"
                                    placeholder="Enter prefix number"
                                    value={formData?.phone_prefix}
                                    onChange={handleChange}
                                    isInvalid={
                                      (errorForm?.prefix !== "" &&
                                        formData?.phone_prefix === "") ||
                                      (signUpError &&
                                        signUpError?.prefix &&
                                        signUpError?.prefix?.length > 0)
                                    }
                                    className={`no-background-image ${signUpError?.prefix ||
                                      errorForm?.prefix ||
                                      errorForm.emailValid
                                      ? "is-invalid-paper mb-0"
                                      : "is-valid-paper"
                                      }`}
                                  />
                                  {/* </div> */}
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className={` ${errorForm.prefix ? "mb-0 mt-0" : "mb-0"
                                      }`}
                                  >
                                    {signUpError?.prefix &&
                                      signUpError?.prefix.map((msg, index) => (
                                        <p key={index}>{msg}</p>
                                      ))}
                                  </Form.Control.Feedback>

                                  {errorForm?.prefix?.length > 1 ? (
                                    <p
                                      className={`form-error-color ${errorForm.prefix ? "mb-2 mt-0" : "mb-0"
                                        }`}
                                    >
                                      {errorForm?.prefix}
                                    </p>
                                  ) : (
                                    <p
                                      className={`form-error-color ${errorForm.prefix ? "mb-2 mt-0" : "mb-0"
                                        }`}
                                    >
                                      {errorForm?.emailValid}
                                    </p>
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-7 col-sm-8">
                                <Form.Group
                                  className="mb-2 form-email "
                                  controlId="formGridemailAddress1"
                                >
                                  <Form.Label className="formlabel">
                                    Contact
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="phone"
                                    placeholder="Enter contact no"
                                    value={formData?.phone}
                                    onChange={handleChange}
                                    isInvalid={
                                      (errorForm?.contact_no !== "" &&
                                        formData?.phone === "") ||
                                      (signUpError &&
                                        signUpError?.contact_no &&
                                        signUpError?.contact_no?.length > 0)
                                    }
                                    className={`no-background-image ${signUpError?.contact_no ||
                                      errorForm?.contact_no ||
                                      errorForm.emailValid
                                      ? "is-invalid-paper mb-0"
                                      : "is-valid-paper"
                                      }`}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className={` ${errorForm.contact_no
                                      ? "mb-0 mt-0"
                                      : "mb-0"
                                      }`}
                                  >
                                    {signUpError?.contact_no &&
                                      signUpError?.contact_no.map(
                                        (msg, index) => <p key={index}>{msg}</p>
                                      )}
                                  </Form.Control.Feedback>

                                  {errorForm?.contact_no?.length > 1 ? (
                                    <p
                                      className={`form-error-color ${errorForm.contact_no
                                        ? "mb-2 mt-0"
                                        : "mb-0"
                                        }`}
                                    >
                                      {errorForm?.contact_no}
                                    </p>
                                  ) : (
                                    <p
                                      className={`form-error-color ${errorForm.contact_no
                                        ? "mb-2 mt-0"
                                        : "mb-0"
                                        }`}
                                    >
                                      {errorForm?.emailValid}
                                    </p>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                            <Form.Group className="form-password">
                              <Form.Label className="formlabel">
                                Password
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type={passwordVisible ? "text" : "password"}
                                  name="password"
                                  placeholder="At least 8 characters"
                                  value={formData?.password}
                                  onChange={handleChange}
                                  isInvalid={
                                    (errorForm?.password !== "" &&
                                      formData?.password === "") ||
                                    (loginError &&
                                      loginError?.password &&
                                      loginError?.password.length > 0)
                                  }
                                  className={` ${errorForm?.password || loginError?.password
                                    ? "is-invalid-paper mb-0"
                                    : "is-valid-paper"
                                    }`}
                                />
                                <InputGroup.Text
                                  onClick={togglePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                  className="eyeauthen"
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon
                                      style={{
                                        fontSize: "large",
                                        color: "#6b6b6b",
                                      }}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      style={{
                                        fontSize: "large",
                                        color: "#6b6b6b",
                                      }}
                                    />
                                  )}
                                </InputGroup.Text>
                                {errorForm?.password?.length > 1 ? (
                                  <p className="form-error-color">
                                    {errorForm?.password}
                                  </p>
                                ) : (
                                  <p className="form-error-color">
                                    {loginError?.password}
                                  </p>
                                )}
                              </InputGroup>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label className="formlabel">
                                How did you know about Let's Ship Now ?
                              </Form.Label>
                              <Form.Select
                                className={`select-sign-option no-background-image social-select  ${formData?.social ===
                                  "Please Enter where you find us:" &&
                                  "signup-placeholder"
                                  }`}
                                name="social"
                                value={formData.social}
                                onChange={handleChange}
                              >
                                {whereYouFindOption.map((option, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={option}
                                      disabled={index === 0}
                                    >
                                      {option}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                            {formData.social === "Other" && (
                              <Form.Group>
                                <Form.Label className="formlabel"></Form.Label>
                                <Form.Control
                                  type="text"
                                  autoFocus
                                  name="otherInfo"
                                  placeholder="Please Enter where you find us:"
                                  value={formData.otherInfo}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            )}
                            <Form.Group
                              as={Row}
                              className="mb-2 mt-2"
                              controlId="formHorizontalCheck"
                            >
                              <Col className="d-flex chexbx">
                                <Form.Group controlId="formBasicCheckbox">
                                  <Form.Check
                                    required
                                    type="checkbox"
                                    name="agreedToTerms"
                                    className={` d-flex align-items-center gap-2 ${errorForm?.agreedToTerms
                                      ? "custom-checkbox-error  "
                                      : "custom-checkbox "
                                      }`}
                                    id={`${errorForm?.agreedToTerms === undefined
                                      ? "erroragreedToTerms"
                                      : "agreedToTerms"
                                      }`}
                                    label={
                                      <>
                                        <span
                                          className={`black-text${isFormSubmitted && !isChecked
                                            ? "simple-red-text"
                                            : ""
                                            }`}
                                          style={{ fontSize: "14px" }}
                                        >
                                          By creating an account, you agree to
                                          the
                                          <span
                                            target="_blank"
                                            className={`blue-text ms-1 ${isFormSubmitted && !isChecked
                                              ? "red-text"
                                              : ""
                                              }`}
                                          >
                                            <Link
                                              target="_blank"
                                              to="/privacy-policy"
                                            >
                                              terms{" "}
                                            </Link>
                                          </span>
                                          <span
                                            className={`black-text${isFormSubmitted && !isChecked
                                              ? "simple-red-text"
                                              : ""
                                              }`}
                                          >
                                            of use and our{" "}
                                          </span>
                                          <span
                                            className={`blue-text ${isFormSubmitted && !isChecked
                                              ? "red-text"
                                              : ""
                                              }`}
                                          >
                                            <Link
                                              target="_blank"
                                              to="/privacy-policy"
                                            >
                                              privacy policy
                                            </Link>
                                          </span>
                                        </span>
                                      </>
                                    }
                                    feedbackType="invalid"
                                    onChange={handleChange}
                                    checked={isChecked}
                                    isInvalid={
                                      errorForm.isChecked !== "" &&
                                      formData.isChecked === ""
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Form.Group>
                            <Button type="submit" className="createbtn" style={{ height: "40px" }}>
                              {!isLoadingSignUp ? (
                                <>
                                  CREATE
                                </>
                              ) : (
                                <>
                                  <Spinner animation="border" size="sm" />
                                </>
                              )}
                            </Button>
                            <p className="alreadytext">
                              Already have an account?{" "}
                              <a
                                onClick={() => {
                                  toggleLoginModal("login");
                                  setPasswordVisible(false);
                                }}
                                className="login-link"
                              >
                                Login
                              </a>
                            </p>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Modal.Body>
                ) : modalname === "otp" ? (
                  <Modal.Body className="modalbodylogin">
                    <div className="loginmodelbody mt-5">
                      <Modal.Header
                        closeButton
                        className="closebutton d-flex justify-content-end"
                      ></Modal.Header>
                      <div className="loginmodelcontent py-0 ">
                        <Form onSubmit={handleLogin} className="login-fields">
                          <div className="modeltext text-center">
                            <h3 className="signtxt">Verify Email</h3>
                            <div
                              className=" m-auto py-2 mb-2"
                              style={{ width: "90%" }}
                            >
                              <h5 className="unlocktxt">
                                We have sent you a code to your email address.
                              </h5>
                            </div>
                          </div>
                          <Form.Label className="mb-2 paragraph2">
                            Please enter verification code
                          </Form.Label>
                          <Form.Group controlId="formOtp" className="mb-3">
                            <div className="d-flex">
                              {otp.map((digit, index) => (
                                <Form.Control
                                  key={index}
                                  id={`otp-input-${index}`}
                                  type="text"
                                  maxLength="1"
                                  value={digit}
                                  onChange={(e) => handleOtpChange(e, index)}
                                  onKeyUp={(e) => handleOtpChange(e, index)}
                                  className={`mx-1 text-center mb-0 ${signupotperror || otperror
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{ height: "56px", width: "62%" }}
                                />
                              ))}
                            </div>
                            <p className="form-error-color">
                              {verifyEmail && verifyEmail?.error}
                            </p>
                            <p className="form-error-color">
                              {signupotperror && signupotperror[0]}
                            </p>
                          </Form.Group>
                          <Button className="createbtn" onClick={handleCheckOtp} style={{ height: "40px" }} >
                            {!isLoadingEmail ? (
                              <>
                                VERIFY
                              </>
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </Button>
                          <p className="alreadytext">
                            Didn’t receive a code?{" "}
                            <a
                              href="#"
                              onClick={() => resendEmailOtp()}
                              className="login-link"
                            >
                              Resend
                            </a>
                          </p>
                        </Form>
                      </div>
                    </div>
                  </Modal.Body>
                ) : modalname === "forgotpassword" ? (
                  <Modal.Body className="modalbodylogin">
                    <div className="loginmodelbody mt-5">
                      <Modal.Header
                        closeButton
                        className="closebutton d-flex justify-content-end"
                      ></Modal.Header>
                      <div className="loginmodelcontent ">
                        <Form onSubmit={handleLogin} className="login-fields">
                          <div className="modeltext text-center">
                            <h3 className="signtxt">Forgot Password</h3>
                            <h5 className="unlocktxt">
                              Welcome Back! Get Back to Shipping Excellence with{" "}
                              <b>Just One Click.</b>
                            </h5>
                          </div>
                          <Form.Group
                            className="mb-2 mt-4"
                            controlId="formGridemailAddress1"
                          >
                            <Form.Label className="formlabel">Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={passwordForm?.email}
                              onChange={handleChange}
                              placeholder="Enter Email Address"
                              isInvalid={
                                errorForm?.email !== "" ||
                                (loginError?.email &&
                                  loginError?.email.length > 0)
                              }
                              className={`no-background-image ${errorForm?.email || loginError?.email || verifyEmailforgotpassword?.email
                                ? "is-invalid-paper mb-0"
                                : "is-valid-paper"
                                }`}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className={`form-error-color ${errorForm?.email ? "mb-2 mt-0" : "mb-0 mt-0"
                                }`}
                            >

                              {errorForm?.email && <p>{errorForm?.email}</p>}
                              {loginError?.email &&
                                loginError?.email?.map((msg, index) => (
                                  <p>{msg}</p>
                                ))}
                            </Form.Control.Feedback>
                            <p className="error-text">{verifyEmailforgotpassword?.email[0]}</p>
                          </Form.Group>
                          <p className="error-text">{loginData}</p>
                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId="formHorizontalCheck"
                          ></Form.Group>
                          <Button className="createbtn" onClick={handleSendotp} style={{ height: "40px" }}  >
                            {!isLoadingForgot ? (
                              <>
                                Send OTP
                              </>
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </Button>
                          <p className="alreadytext">
                            <a
                              onClick={() => {
                                toggleLoginModal("login");
                                setPasswordVisible(false);
                              }}
                              className="login-link"
                            >
                              Login?
                            </a>
                          </p>
                        </Form>
                      </div>
                    </div>
                  </Modal.Body>
                ) : modalname === "forgotpasswordotp" ? (
                  <Modal.Body className="modalbodylogin">
                    <div className="loginmodelbody mt-5">
                      <Modal.Header
                        closeButton
                        className="closebutton d-flex justify-content-end"
                      ></Modal.Header>
                      <div className="loginmodelcontent py-0 ">
                        <Form onSubmit={handleLogin} className="login-fields">
                          <div className="modeltext text-center">
                            <h3 className="signtxt">Verify Email Forgot</h3>
                            <div
                              className=" m-auto py-2 mb-2"
                              style={{ width: "90%" }}
                            >
                              <h5 className="unlocktxt">
                                We have sent you a code to your email address.
                              </h5>
                            </div>
                          </div>
                          <Form.Label className="mt-2 paragraph2">
                            &nbsp;Please enter verification code
                          </Form.Label>
                          <Form.Group controlId="formOtp" className="mt-2">
                            <div className="d-flex">
                              {otp.map((digit, index) => (
                                <Form.Control
                                  key={index}
                                  id={`otp-input-${index}`}
                                  type="text"
                                  maxLength="1"
                                  value={digit}
                                  onChange={(e) => handleOtpChange(e, index)}
                                  onKeyUp={(e) => handleOtpChange(e, index)}
                                  className={`mx-1 text-center mb-0 ${forgotErrorForm?.otp || loginError || otperror || signupotperror
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{
                                    height: "56px",
                                    width: "62%",
                                  }}
                                  isInvalid={
                                    (loginError &&
                                      loginError?.password &&
                                      loginError?.otp.length > 0) || (
                                      otperrorconfirmation &&
                                      otperrorconfirmation?.otp
                                    )
                                  }
                                />
                              ))}
                            </div>
                          </Form.Group>
                          <p className="error-text">{otperror && otperror}</p>
                          <p className="error-text">{signupotperror && signupotperror[0]}</p>
                          <p className="error-text">{otperrorconfirmation && otperrorconfirmation?.password_confirmation}</p>
                          <div className="error-text p.paragraph2">
                            {loginError?.otp ? (
                              loginError?.otp?.map((msg, index) => (
                                <p key={index} className="p.paragraph2  mt-1 ">
                                  &nbsp; {msg}
                                </p>
                              ))
                            ) : (
                              <p>{loginError}</p>
                            )}
                            <p>{forgotErrorForm?.otp}</p>
                          </div>
                          <div className="mt-2 w-100">
                            <div className="mx-1">
                              <Form.Group
                                className={`  ${forgotErrorForm?.password ? "mb-2" : "mb-0"}`}  >
                                <Form.Label className="formlabel w-100">
                                  Enter New Password
                                </Form.Label>
                                <InputGroup className="bg-transparent shadow-none">
                                  <Paper className="w-100 bg-transparent shadow-none  border-0">
                                    <Form.Control
                                      className={` ${forgotErrorForm?.password
                                        ? "is-invalid-paper mb-0"
                                        : "is-valid-paper"
                                        }`}
                                      type={
                                        forgotPasswordVisible?.password
                                          ? "password"
                                          : "text"
                                      }
                                      name="password"
                                      placeholder="Enter new password"
                                      value={passwordForm?.password}
                                      onChange={handleChange}
                                      isInvalid={
                                        (forgotErrorForm?.new_password !== "" &&
                                          passwordForm?.password === "") ||
                                        (loginError &&
                                          loginError?.password &&
                                          loginError?.password.length > 0) || (
                                          otperrorconfirmation &&
                                          otperrorconfirmation
                                        )
                                      }
                                    />
                                  </Paper>
                                  <Form.Control.Feedback type="invalid">
                                    {forgotErrorForm?.new_password && (
                                      <p
                                        className={`form-error-color ${forgotErrorForm?.password
                                          ? "mb-2 mt-0"
                                          : "mb-0"
                                          }`}
                                      >
                                        {forgotErrorForm?.new_password}
                                      </p>
                                    )}
                                  </Form.Control.Feedback>
                                  <InputGroup.Text
                                    onClick={() =>
                                      toggleForgotPasswordVisibility("password")
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="eyeauthen"
                                  >
                                    {forgotPasswordVisible?.password ? (
                                      <VisibilityIcon
                                        style={{
                                          fontSize: "large",
                                          color: "#6b6b6b",
                                        }}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        style={{
                                          fontSize: "large",
                                          color: "#6b6b6b",
                                        }}
                                      />
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                                {forgotErrorForm?.password && (
                                  <p className="form-error-color">
                                    {forgotErrorForm?.password}
                                  </p>
                                )}
                              </Form.Group>
                            </div>
                            <div className="mx-1">
                              <Form.Group
                                className={`${forgotErrorForm?.re_new_password
                                  ? "mb-2"
                                  : "mb-0"
                                  }`}
                              >
                                <Form.Label className="formlabel w-100">
                                  Re-Enter New Password
                                </Form.Label>
                                <InputGroup>
                                  <Paper className="border border-2 w-100 bg-transparent shadow-none ">
                                    <Form.Control
                                      className={` ${forgotErrorForm?.re_new_password
                                        ? "is-invalid-paper mb-0"
                                        : "is-valid-paper"
                                        }`}
                                      type={
                                        forgotPasswordVisible?.password_confirmation
                                          ? "password"
                                          : "text"
                                      }
                                      name="password_confirmation"
                                      placeholder="Re-enter new password"
                                      value={
                                        passwordForm["password_confirmation"]
                                      }
                                      onChange={handleChange}
                                      isInvalid={
                                        (forgotErrorForm?.re_new_password !==
                                          "" &&
                                          passwordForm?.password_confirmation ===
                                          "") ||
                                        (loginError &&
                                          loginError?.password &&
                                          loginError?.password.length > 0) || (
                                          otperrorconfirmation &&
                                          otperrorconfirmation
                                        )
                                      }
                                    />
                                  </Paper>

                                  <Form.Control.Feedback
                                    type="invalid"
                                    className=""
                                  >
                                    {loginError && loginError?.password?.map(
                                      (msg, index) => (
                                        <span
                                          key={index}
                                          className="p.paragraph2 mb-2"
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {msg}
                                        </span>
                                      )
                                    )
                                    }
                                  </Form.Control.Feedback>
                                  {forgotErrorForm?.re_new_password && (
                                    <p className="form-error-color">
                                      {forgotErrorForm?.re_new_password}
                                    </p>
                                  )}
                                  <InputGroup.Text
                                    onClick={() =>
                                      toggleForgotPasswordVisibility(
                                        "password_confirmation"
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="eyeauthen"
                                  >
                                    {forgotPasswordVisible?.password_confirmation ? (
                                      <VisibilityIcon
                                        style={{
                                          fontSize: "large",
                                          color: "#6b6b6b",
                                        }}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        style={{
                                          fontSize: "large",
                                          color: "#6b6b6b",
                                        }}
                                      />
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </div>
                          </div>
                          <p className="error-text">{verifyEmail && verifyEmail?.error}</p>
                          <p className="error-text">{verifyEmail && verifyEmail?.error?.password}</p>
                          <p className="error-text">{otperrorconfirmation && otperrorconfirmation}</p>
                          <Button className="createbtn" onClick={handleCheckotpforget} style={{ height: "40px" }}  >
                            {!isLoadingOtp ? (
                              <>
                                VERIFY
                              </>
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </Button>
                          <p className="alreadytext">
                            Didn’t receive a code?{" "}
                            <a
                              href="#"
                              onClick={() => resendEmailOtpforget()}
                              className="login-link"
                            >
                              Resend
                            </a>
                          </p>
                        </Form>
                      </div>
                    </div>
                  </Modal.Body>
                ) : modalname === "loginotp" ? (
                  <Modal.Body className="modalbodylogin">
                    <div className="loginmodelbody mt-5">
                      <Modal.Header
                        closeButton
                        className="closebutton d-flex justify-content-end"
                      ></Modal.Header>
                      <div className="loginmodelcontent py-0 ">
                        <Form onSubmit={handleLogin} className="login-fields">
                          <div className="modeltext text-center">
                            <h3 className="signtxt">Verify Email</h3>
                            <div
                              className=" m-auto py-2 mb-2"
                              style={{ width: "90%" }}
                            >
                              <h5 className="unlocktxt">
                                We have sent you a code to your email address.
                              </h5>
                            </div>
                          </div>
                          <Form.Label className="mb-2 paragraph2">
                            Please enter verification code
                          </Form.Label>
                          <Form.Group controlId="formOtp" className="mb-3">
                            <div className="d-flex">
                              {otp.map((digit, index) => (
                                <Form.Control
                                  key={index}
                                  id={`otp-input-${index}`}
                                  type="text"
                                  maxLength="1"
                                  value={digit}
                                  onChange={(e) => handleOtpChange(e, index)}
                                  onKeyUp={(e) => handleOtpChange(e, index)}
                                  className={`mx-1 text-center mb-0 ${signupotperror || otperror
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{ height: "56px", width: "62%" }}
                                />
                              ))}
                            </div>
                            <p className="form-error-color">
                              {verifyEmail && verifyEmail?.error}
                            </p>
                            <p className="form-error-color">
                              {signupotperror && signupotperror[0]}
                            </p>
                          </Form.Group>
                          <Button className="createbtn" onClick={handleCheckOtp} style={{ height: "40px" }} >
                            {!isLoadingEmail ? (
                              <>
                                VERIFY
                              </>
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </Button>
                          <p className="alreadytext">
                            Didn’t receive a code?{" "}
                            <a
                              href="#"
                              onClick={() => resendloginEmailOtp()}
                              className="login-link"
                            >
                              Resend
                            </a>
                          </p>
                        </Form>
                      </div>
                    </div>
                  </Modal.Body>
                )
                  :
                  null}
              </div>
            </Modal>
          </div>
        </Container>
      </>
    </div>
  );
};

export default Authentication;
