import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, InputGroup, Spinner, } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Swal from "sweetalert2";
import * as Yup from "yup";
import { changePassword } from "../../redux/user_profile/ProfileThunk";
import { resetErrors } from "../../redux/user_profile/ProfileSlice";
import "../../assets/styles/userProfile/userChangePassword.css";
import { Formik } from "formik";
import { Button, IconButton } from "@mui/material";

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("This field is required"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/\d/, "Password must contain at least one digit.")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character."
    )
    .required("This field is required"),

  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

const UserChangePassword = () => {
  const dispatch = useDispatch();
  const passworderror = useSelector((state) => state.profileData.error);
  const isPasswordLoading = useSelector(
    (state) => state.profileData.isPasswordLoading
  );
  const userData = JSON.parse(localStorage.getItem("user"));
  const user_id = userData?.id;

  useEffect(() => {
    dispatch(resetErrors());
  }, [dispatch]);

  const [passwordVisible, setPasswordVisible] = useState({
    old_password: true,
    password: true,
    password_confirmation: true,
  });
  const uniquesessionid = localStorage.getItem("uniquesessionid");

  const togglePasswordVisibility = (name) => {
    setPasswordVisible({
      ...passwordVisible,
      [name]: !passwordVisible[name],
    });
  };

  return (
    <div className="user-changepassword-holder mb-5">
      <Card className="px-3 py-4 ps-4" style={{ border: "none" }}>
        <div className="d-flex flex-column gap-4">
          <Row className="">
            <h5 className="personal-text m-0">Change Password</h5>
          </Row>

          <Formik
            initialValues={{
              old_password: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              dispatch(
                changePassword({
                  old_password: values.old_password,
                  password: values.password,
                  password_confirmation: values.password_confirmation,
                  user_id: user_id,
                  uniquesessionid: uniquesessionid,
                })
              )
                .unwrap()
                .then((response) => {
                  if (response.statusCode === 200 && response.status === true) {
                    Swal.fire({
                      toast: true,
                      icon: "success",
                      title: "Password Changed Successfully",
                      animation: true,
                      position: "top-right",
                      showConfirmButton: false,
                      showCloseButton: true,
                      timer: 2000,
                      timerProgressBar: true,
                      customClass: {
                        timerProgressBar: "custom-progress-bar",
                      },
                    });
                    resetForm();
                    dispatch(resetErrors());
                  }
                })
                .catch((error) => {
                  console.error("Change Password failed:", error);
                })
                .finally(() => {
                  setSubmitting(false);
                });
              // }
            }}

            onReset={() => {
              dispatch(resetErrors());
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, }) => (
              <Form className="gap-4 d-flex flex-column" onSubmit={handleSubmit} onReset={handleReset}  >
                <div className="row gap-4 gap-lg-0  ">
                  <div className="col-lg-4 col-12">
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type={
                            passwordVisible.old_password ? "password" : "text"
                          }
                          name="old_password"
                          className={`m-0 d-flex input-main-all-change-password ${errors?.old_password || passworderror?.old_password ? "is-invalid-paper" : "is-valid-paper"}`}
                          placeholder="Enter current password"
                          value={values.old_password}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            if (
                              passworderror?.old_password &&
                              passworderror?.old_password.length > 0
                            ) {
                              dispatch(resetErrors());
                            }
                          }}
                          style={{ position: "relative" }}
                          isInvalid={
                            (passworderror?.old_password &&
                              passworderror?.old_password.length > 0) ||
                            (errors.old_password &&
                              touched.old_password &&
                              errors.old_password)
                          }
                        />
                        <InputGroup.Text
                          onClick={() =>
                            togglePasswordVisibility("old_password")
                          }
                          style={{ cursor: "pointer" }}
                          className="eyeuser"
                        >
                          <IconButton aria-label="eve" size="small">
                            {passwordVisible.old_password ? (
                              <>
                                {/* <VisibilityIcon style={{ fontSize: "large", color: "#6b6b6b" }} /> */}
                                <VisibilityIcon fontSize="inherit" />
                              </>
                            ) : (
                              <>
                                {/* <IconButton aria-label="delete" size="small"> */}
                                <VisibilityOffIcon fontSize="inherit" />
                                {/* </IconButton> */}
                                {/* <VisibilityOffIcon style={{ fontSize: "large", color: "#6b6b6b" }} /> */}
                              </>
                            )}
                          </IconButton>
                        </InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                          {passworderror?.old_password &&
                            passworderror?.old_password?.map((msg, index) => (
                              <span className="p.paragraph2" key={index}>
                                {msg}
                              </span>
                            ))}

                          {errors.old_password &&
                            touched.old_password &&
                            errors.old_password}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type={passwordVisible.password ? "password" : "text"}
                          name="password"
                          className={`input-main-all-change-password ps-3 ${errors.password && touched.password && errors.password ? "is-invalid-paper" : "is-valid-paper"}`}
                          placeholder="Enter new password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                        />
                        <InputGroup.Text
                          onClick={() => togglePasswordVisibility("password")}
                          style={{ cursor: "pointer" }}
                          className="eyeuser"
                        >
                          <IconButton aria-label="eye" size="small">
                            {passwordVisible.password ? (
                              // <VisibilityIcon style={{ fontSize: "large", color: "#6b6b6b" }} />
                              <VisibilityIcon fontSize="inherit" />

                            ) : (
                              <VisibilityOffIcon fontSize="inherit" />
                              // <VisibilityOffIcon style={{ fontSize: "large", color: "#6b6b6b" }} />
                            )}
                          </IconButton>
                        </InputGroup.Text>
                        <Form.Control.Feedback type="invalid">

                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type={
                            passwordVisible.password_confirmation
                              ? "password"
                              : "text"
                          }
                          name="password_confirmation"
                          placeholder="Re-enter new password"
                          value={values.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`input-main-all-change-password ${errors.password_confirmation && touched.password_confirmation && errors.password_confirmation ? "is-invalid-paper" : "is-valid-paper"}`}
                          isInvalid={
                            (passworderror?.password &&
                              passworderror?.password.length > 0) ||
                            (errors.password_confirmation &&
                              touched.password_confirmation &&
                              errors.password_confirmation)
                          }
                        />
                        <InputGroup.Text
                          onClick={() =>
                            togglePasswordVisibility("password_confirmation")
                          }
                          style={{ cursor: "pointer" }}
                          className="eyeuser"
                        >
                          <IconButton aria-label="eye" size="small">
                            {passwordVisible.password_confirmation ? (
                              <VisibilityIcon fontSize="inherit" />
                              // <VisibilityIcon style={{ fontSize: "large", color: "#6b6b6b" }} />
                            ) : (
                              <VisibilityOffIcon fontSize="inherit" />
                              // <VisibilityOffIcon style={{ fontSize: "large", color: "#6b6b6b" }} />
                            )}
                          </IconButton>
                        </InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                          {passworderror && passworderror
                            ? passworderror.password?.map((msg, index) => (
                              <span key={index} className="p.paragraph2">
                                {msg}
                              </span>
                            ))
                            : errors.password_confirmation &&
                            touched.password_confirmation &&
                            errors.password_confirmation}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
                <div className="d-flex w-50 gap-2">
                  <>
                    <div className=" w-25 change-password-save">
                      <Button style={{ height: "40px" }}
                        type="submit"
                        className="blue-text primarybtn btn savebtn-user px-4 w-100"
                      >
                        {!isPasswordLoading ? (
                          <>
                            <span>Save</span>
                          </>
                        ) : (
                          <Spinner animation="border" size="sm" className="" />
                        )}
                      </Button>
                    </div>
                  </>
                  <Button style={{ height: "40px", color: "#113468" }}
                    variant="text"
                    type="reset"
                    className="reset-btn btn blue-text"
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </div>
  );
};

export default UserChangePassword;
