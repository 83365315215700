/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, useMemo } from "react";
import AccordianCollectionDelivery from "../components/accordianAddressbook/AccordianCollectionDelivery";
import AccordianOrderConfirm from "../components/accordianAddressbook/AccordianOrderConfirm";
import { useDispatch, useSelector } from "react-redux";
import { fetchShippingServices } from "../redux/services/ServicesThunk";
import { useNavigate } from "react-router";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import { Skeleton } from "@mui/material";

const AccordianAddressBook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const countrydetails = JSON.parse(localStorage.getItem("searchData"));
  const measurement = JSON.parse(localStorage.getItem("searchData"));
  const useriD = JSON.parse(localStorage.getItem("user"));
  const serviceData = JSON.parse(localStorage.getItem("serviceData"));
  const cityName = JSON.parse(localStorage.getItem("cityName"));

  const newdata = useSelector((state) => state?.servicedata.ShippingService?.data);
  const datacountry = useSelector(
    (state) => state?.servicedata?.ShippingService
  );
  const contactPrefixCollection = serviceData?.cart_details?.collection_country?.contact_prefix_code;
  const contactPrefixDelivery = serviceData?.cart_details?.delivery_country?.contact_prefix_code
  const [collectionDates, setCollectionDates] = useState();
  const [collectionValues, setCollectionValues] = useState();
  const [dropupDates, setDropupDates] = useState();
  const [selectedCollectionService, setSelectedCollectionService] = useState("Drop");
  const [address_details, setAddressDetails] = useState({});
  const [country_details, setCountry_details] = useState();
  const [selectedOption, setSelectedOption] = useState("Home");
  const [selectedOptionDel, setSelectedOptionDel] = useState("Home");
  const [DynamicPricing, setDynamicPricing] = useState();
  const [showOrderMobile, setShowOrderMobile] = useState(false);
  const [finalGoLoader, setFinalGoLoader] = useState(false)
  const [formShipData, setFormShipData] = useState({
    insuranceRadio: serviceData?.cart_details?.is_enhanced_cover === "Yes" ? "Yes" : "No",
    ups_one: "",
    ups_two: "",
    ups_three: "",
    ups_four: "",
    fedex_one: "",
    fedex_two: "",
    fedex_three: "",
  });
  const [selectedRadio, setSelectedRadio] = useState(serviceData?.cart_details?.is_enhanced_cover === "Yes" ? "Yes" : "No");
  const [shipContent, setShipContent] = useState([
    {
      parcel: [
        {
          contents: "",
          ship_value: "",
        },
      ],
    },
  ]);
  const [debounceShipContent] = useDebounce(shipContent, 1000);

  useEffect(() => {
    const countrydetails = JSON.parse(localStorage.getItem("searchData"));
    if (countrydetails) {
      setCountry_details(countrydetails);
    }
  }, []);

  // ADDRESS VALIDATION CHECK
  const [isCollectionValidate, setIsCollectionValidate] = useState(false);
  const [isDeliveryValidate, setIsDeliveryValidate] = useState(false);
  // COLLECTION ADDRESS FORM

  const [formData, setFormData] = useState({
    country: countrydetails?.fromcountry || serviceData?.collection_country?.name,
    Postcode: countrydetails?.fromPostalCode || serviceData?.collection_postcode,
    firstName: serviceData?.cart_details?.collection_first_name ? serviceData?.cart_details?.collection_first_name : "",
    lastName: serviceData?.cart_details?.collection_last_name ? serviceData?.cart_details?.collection_last_name : "",
    companyName: serviceData?.cart_details?.collection_company_name ? serviceData?.cart_details?.collection_company_name : "",
    address01: serviceData?.cart_details?.collection_address1 ? serviceData?.cart_details?.collection_address1 : "",
    address02: serviceData?.cart_details?.collection_address2 ? serviceData?.cart_details?.collection_address2 : "",
    state: serviceData?.cart_details?.collection_state || serviceData?.collection_state,
    city: cityName?.fromCity || serviceData?.cart_details?.collection_city ? serviceData?.cart_details?.collection_city : "",
    email: serviceData?.cart_details?.collection_email ? serviceData?.cart_details?.collection_email : "",
    contactNo: serviceData?.cart_details?.collection_contact_no ? serviceData?.cart_details?.collection_contact_no : "",
    contact_prefix: serviceData?.cart_details?.collection_contact_extention ? serviceData?.cart_details?.collection_contact_extention : "",
    addressType: serviceData?.cart_details?.collection_address_type ? serviceData?.cart_details?.collection_address_type : "Home",
    address_id: "",
    contactcontrycode: contactPrefixCollection || (serviceData?.cart_details?.collection_contact_extention ? serviceData?.cart_details?.collection_contact_extention : ""),
    verifyFields: "",
  });
  const [formDataDelivery, setFormDataDelivery] = useState({
    country: countrydetails?.tocountry || serviceData?.delivery_country?.name || countrydetails?.fromcountry || serviceData?.delivery_country?.name,
    Postcode: countrydetails?.toPostalCode || serviceData?.delivery_postcode,
    firstName: serviceData?.cart_details?.delivery_first_name ? serviceData?.cart_details?.delivery_first_name : "",
    lastName: serviceData?.cart_details?.delivery_last_name ? serviceData?.cart_details?.delivery_last_name : "",
    companyName: serviceData?.cart_details?.delivery_company_name ? serviceData?.cart_details?.delivery_company_name : "",
    address01: serviceData?.cart_details?.delivery_address1 ? serviceData?.cart_details?.delivery_address1 : "",
    address02: serviceData?.cart_details?.delivery_address2 ? serviceData?.cart_details?.delivery_address2 : "",
    state: serviceData?.cart_details?.delivery_state || serviceData?.delivery_state,
    city: cityName?.toCity || serviceData?.cart_details?.delivery_city ? serviceData?.cart_details?.delivery_city : "",
    email: serviceData?.cart_details?.delivery_email ? serviceData?.cart_details?.delivery_email : "",
    contactNo: serviceData?.cart_details?.delivery_contact_no ? serviceData?.cart_details?.delivery_contact_no : "",
    contact_prefix: serviceData?.cart_details?.delivery_contact_extention ? serviceData?.cart_details?.delivery_contact_extention : "",
    addressType: serviceData?.cart_details?.delivery_address_type ? serviceData?.cart_details?.delivery_address_type : "Home",
    address_id: "",
    contactcontrycode: contactPrefixDelivery || (serviceData?.cart_details?.delivery_contact_extention ? serviceData?.cart_details?.delivery_contact_extention : ""),
  });
  const [collectionEventChange, setCollectionEventChange] = useState(false);
  const [deliveryEventChange, setDeliveryEventChange] = useState(true);

  useEffect(() => {
    const serviceDetails = JSON.parse(localStorage.getItem("serviceData"));
    const orderConfirmData = JSON.parse(
      localStorage.getItem("orderConfirmData")
    );
    if (serviceDetails !== null) {
      setAddressDetails(serviceDetails);
    } else {
      navigate("/");
    }
  }, []);

  let contentArray = [];
  let valueArray = [];
  let data =
    shipContent &&
    shipContent?.map((item) => {
      contentArray.push(item?.contents);
      valueArray.push(item?.ship_value);
    });
  const shipValues = useMemo(() =>
    shipContent.flatMap(item => item.parcel.map(parcel => parcel.ship_value)),
    [shipContent]
  );

  useEffect(() => {
    if (countrydetails) {
      localStorage.setItem("cart_id", serviceData?.cart_details?.cart_id);
      localStorage.setItem("cartdetail_id", serviceData?.cart_details?.id);
      const cartPackage = serviceData?.cart_details?.cart_package;
      let idIndex = 0;
      const data = debounceShipContent?.flatMap((item, index) => {
        return item?.parcel?.map((p, parcelIndex) => {
          const id = cartPackage?.[idIndex]?.id || null; // Get the ID from cartPackage
          idIndex++; // Increment the index for the next ID assignment
          return {
            contents: p.contents,
            ship_value: p.ship_value,
            id: id,
          };
        });
      });
      const DropOffData = JSON.parse(localStorage.getItem("DropOffData"));
      dispatch(fetchShippingServices({
        ...countrydetails,
        customer_id: useriD?.id,
        insurance: formShipData?.insuranceRadio,
        cart_id: serviceData?.cart_details?.cart_id,
        courier_id: serviceData?.courier_details?.courier_id,
        cartdetail_id: serviceData?.cart_details?.id,
        shipContent: data && data,
        uniquesessionid: uniquesessionid,
        is_batteries:
          formShipData.ups_one || formShipData.fedex_one ? "Yes" : "",
        is_dry_ice:
          formShipData.ups_two || formShipData.fedex_two ? "Yes" : "",
        is_signature:
          formShipData.ups_three || formShipData.fedex_three ? "Yes" : "",
        has_drop_off_service: serviceData?.cart_details?.edit === true ?
          selectedCollectionService === "Drop" ? "Yes" : "No" : "",
        collection_date:
          selectedCollectionService !== "Drop" ? DropOffData?.collection_date : "",
        collection_value:
          selectedCollectionService !== "Drop" ? DropOffData?.collection_value : "",
        drop_off_date: selectedCollectionService === "Drop" ? DropOffData?.drop_off_date : "",
      })
      )
        .unwrap()
        .then((response) => {
          if (response.statusCode === 440 && response.status === false) {
            navigate("/service");
          }
        })
        .catch((error) => {
          console.error(error)
        });

    }
    // }, [shipContent?.map((item) => item?.ship_value).join(","), formShipData]);
  }, [debounceShipContent, formShipData]);

  useEffect(() => {
    if (address_details) {
      setDynamicPricing(address_details);
    }
    if (newdata) {
      setDynamicPricing(newdata);
    }
  }, [newdata, address_details]);

  useEffect(() => {
    if (serviceData?.cart_details?.edit === true) {
      const measurementData = serviceData?.cart_details?.cart_package?.map(packageItem => ({
        parcel: [{
          contents: packageItem.parcel_description,
          ship_value: packageItem.value_of_items
        }],
      }));
      setShipContent(measurementData);
    }
  }, []);

  const showtogleOrderDetails = () => {
    setShowOrderMobile(!showOrderMobile);
  };
  return (
    <>

      {!finalGoLoader ? (
        <>
          <Container className="p-0">
            <div className="d-flex justify-content-between gap-4 flex-column flex-lg-row">
              <div className="div1" style={{ flex: 2 }}>
                <AccordianCollectionDelivery
                  collectionDates={collectionDates}
                  setCollectionDates={setCollectionDates}
                  dropupDates={dropupDates}
                  setDropupDates={setDropupDates}
                  collectionValues={collectionValues}
                  setCollectionValues={setCollectionValues}
                  selectedCollectionService={selectedCollectionService}
                  setSelectedCollectionService={setSelectedCollectionService}
                  setFormShipData={setFormShipData}
                  formShipData={formShipData}
                  shipContent={shipContent}
                  setShipContent={setShipContent}
                  contentArray={contentArray}
                  valueArray={valueArray}
                  formData={formData}
                  setFormData={setFormData}
                  formDataDelivery={formDataDelivery}
                  setFormDataDelivery={setFormDataDelivery}
                  collectionEventChange={collectionEventChange}
                  setCollectionEventChange={setCollectionEventChange}
                  deliveryEventChange={deliveryEventChange}
                  setDeliveryEventChange={setDeliveryEventChange}
                  isCollectionValidate={isCollectionValidate}
                  setIsCollectionValidate={setIsCollectionValidate}
                  isDeliveryValidate={isDeliveryValidate}
                  setIsDeliveryValidate={setIsDeliveryValidate}
                  country_data={country_details}
                  selectedRadio={selectedRadio}
                  setSelectedRadio={setSelectedRadio}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  selectedOptionDel={selectedOptionDel}
                  setSelectedOptionDel={setSelectedOptionDel}
                  setFinalGoLoader={setFinalGoLoader}
                  finalGoLoader={finalGoLoader}
                />
              </div>
              <div className="div2" style={{ flex: 1 }}>
                <div
                  className="d-none d-lg-block"
                  style={{ position: "sticky", top: "6rem" }}
                >
                  <AccordianOrderConfirm address_details={address_details} />
                </div>
              </div>
            </div>
          </Container>

          <div className="d-flex flex-column ">
            <div className="sticky-payment d-flex p-0 z-3 position-fixed  bottom-0 bg-white d-block d-lg-none sticky-order-serviceDetails w-100 flex-column">
              <Container className="d-flex flex-column ">
                {showOrderMobile && (
                  <div
                    className={`d-block d-lg-none transition-style-wipe-up`}
                    style={{ position: "sticky", top: "6rem" }}
                  >
                    <AccordianOrderConfirm address_details={address_details} />
                  </div>
                )}
              </Container>
              <div className=" border-top border-2">
                <Container className="px-4 px-sm-0 px-md-0">
                  <div className="d-flex ">
                    <div className="d-flex py-4 " style={{ flex: "3" }}>
                      <div className="me-2 d-flex align-items-center">
                        <p className="text-nowr ap" style={{ color: "#4E4E4E" }}>
                          Total :
                        </p>
                      </div>
                      <div>
                        <h5 className="m-0" style={{ color: "#4E4E4E" }}>
                          ${DynamicPricing && DynamicPricing?.cart_details?.total}
                        </h5>
                      </div>
                      <div className="ms-2" onClick={showtogleOrderDetails}>
                        <Image
                          src={`${window.location.origin}/assets/iicon.svg`}
                        ></Image>
                      </div>
                    </div>
                    <div
                      className=" d-flex align-items-center"
                      style={{ flex: "2" }}
                      onClick={showtogleOrderDetails}
                    >
                      {!showOrderMobile ? (
                        <Button className="primarybtn w-100">Show Details</Button>
                      ) : (
                        <Button className="primarybtn w-100">Hide Details</Button>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Container>
            <div className="d-flex gap-4">
              <div className="d-flex flex-column gap-4" style={{ flex: "2" }}>
                <Card className="service-card-holder w-100 card-border">
                  <div className="d-flex gap-1 py-2 flex-column ">
                    <div className="mx-2 d-flex justify-content-center ">
                      <Skeleton animation={"wave"}
                        variant="rounded"
                        height={120}
                        width={"100%"}
                        className="shipping-logo-img-div"
                      />
                    </div>
                  </div>
                </Card>
                <Card className="service-card-holder w-100 card-border">
                  <div className="d-flex gap-1 py-2 flex-column ">
                    <div className="mx-2 d-flex justify-content-center ">
                      <Skeleton animation={"wave"}
                        variant="rounded"
                        height={120}
                        width={"100%"}
                        className="shipping-logo-img-div"
                      />
                    </div>
                  </div>
                </Card>
                <Card className="service-card-holder w-100 card-border">
                  <div className="d-flex gap-1 py-2 flex-column ">
                    <div className="mx-2 d-flex justify-content-center ">
                      <Skeleton animation={"wave"}
                        variant="rounded"
                        height={220}
                        width={"100%"}
                        className="shipping-logo-img-div"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="d-none d-lg-block" style={{ flex: "1" }}>
                <Card className="service-card-holder w-100 card-border">
                  <div className="d-flex gap-1 py-2 flex-column ">
                    <div className="mx-2 py-2 mb-1 d-flex justify-content-center ">
                      <Skeleton animation={"wave"}
                        variant="rounded"
                        height={100}
                        width={150}
                        className="shipping-logo-img-div"
                      />
                    </div>
                    <div className="d-flex mx-5 px-5">
                      <Skeleton animation={"wave"} variant="text" className="w-100" />
                    </div>
                    <div className="d-flex ">
                      <Skeleton animation={"wave"} height={5} variant="text" className="w-100 " />
                    </div>
                    <div className="d-flex mx-5 px-5">
                      <Skeleton animation={"wave"} variant="text" className="w-100" />
                    </div>
                    <div className="mx-3">
                      <Row className="d-flex justify-content-between gap-3">
                        <Col className="mb-1 me-1">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                        <Col className="ms-5">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                      </Row>
                    </div>
                    <div className="mx-3">
                      <Row className="d-flex justify-content-between gap-3">
                        <Col className="mb-1 me-1">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                        <Col className="ms-5">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                      </Row>
                    </div>
                    <div className="mx-3">
                      <Row className="d-flex justify-content-between gap-3">
                        <Col className="mb-1 me-1">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                        <Col className="ms-5">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex  py-1">
                      <Skeleton height={5} animation={"wave"} variant="text" className="w-100 " />
                    </div>
                    <div className="mx-3">
                      <Row className="d-flex justify-content-between gap-3">
                        <Col className="mb-1 me-1">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                        <Col className="ms-5">
                          <Skeleton animation={"wave"} variant="text" />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Container>
        </>
      )}

    </>
  );
};

export default AccordianAddressBook;
