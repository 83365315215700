import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const fetchProfileDetails = createAsyncThunk(
  "profile/fetchProfileDetails",
  async (profile_data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `update-customer-profile/${profile_data?.user_id}`,
        profile_data
      );
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const changePassword = createAsyncThunk(
  "profile/UserChangePassword",

  async (change_password, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `change-password/${change_password?.user_id}`,
        change_password
      );
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const deactiveAccount = createAsyncThunk(
  "profile/deactiveAccount",

  async (deactive, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `user-account-deactivate/${deactive?.user_id}`,
        deactive
      );
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const contactusinfo = createAsyncThunk(
  "profile/contactusinfo",

  async (contactus, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `add-inquiry-details`,
        contactus
      );
      return response.data;
    } catch (errors) {
      // if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
      //   localStorage.removeItem("user");
      //   localStorage.removeItem("cartdetail_id");
      //   localStorage.removeItem("cart_id");
      //   localStorage.removeItem("token");
      //       CustomAlert('Please log in and try again.')

      //   localStorage.clear()
      // }
      // else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const orderDetails = createAsyncThunk(
  "profile/orderDetails",

  async (orders, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/get-orders/${orders}`
        // ${orders}`,
        , orders
      );
      return response.data;

    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const customerParcel = createAsyncThunk(
  "profile/customerParcel",
  async (parcel, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/customer-parcel/${parcel?.store}`,
        parcel.data
      );

      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors?.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const getParcels = createAsyncThunk(
  "profile/getParcels",

  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/customer-parcel/list/${id?.customer_id}`, id);

      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors?.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const getcart = createAsyncThunk(
  "profile/getcart",

  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/fetch-cart-details/${id?.customer_id}`);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors?.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const getuserdetail = createAsyncThunk(
  "profile/getuserdetail",

  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/get-customer-profile/${id?.customer_id}`, id);
      let data = response.data.CustomerProfile.data;
      localStorage.setItem("user", JSON.stringify(data));
      return data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (errors?.response) {
        return rejectWithValue(errors.response.data);
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);