import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const trackshipment = createAsyncThunk(
    "Trackship_details/trackshipment",

    async (trackshipmentNumer, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`get-tracking-details`, trackshipmentNumer);
            return response.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {

                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors) {
                    return rejectWithValue(errors);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);

export const trackshipmentsingle = createAsyncThunk(
    "Trackship_details/trackshipmentsingle",



    async ({ courier_id, trackNumber }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/get-single-tracking-detail/${courier_id}/${trackNumber}`);
            return response.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors) {
                    return rejectWithValue(errors);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);