import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const UserWallet = createAsyncThunk(
  "UserWallet/topup",
  async (wallet, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/customer-wallet/${wallet?.store}`, wallet);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')
        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);

export const UserWallettransaction = createAsyncThunk(
  "UserWallettransaction/topup",
  async (wallet, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/customer-wallet/${wallet?.store}/${wallet?.customer_id}`);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);

export const UserAutoreloadWallet = createAsyncThunk(
  "UserWallettransaction/auto",
  async (autoreload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/auto-payment/${autoreload?.customer_id}`, autoreload);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);
