import React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputBase from "@mui/material/InputBase";

const CustomWeight = ({
  radioFormData,
  handleChangeform,
  document_weight,
  handleDocWeight,
  value,
  name,
  error,
  FooterHeroSection
}) => {
  return (
    <>
      <Paper className={`d-flex paddingclasshero-weight shadow-none p-0 ${error ? "is-invalid-paper" : FooterHeroSection ? "is-valid-paper my-paper" : "hero-paper"}`}
        sx={{
          borderRadius: "6px",
          padding: "20px",
        }}
      >
        <IconButton disabled type="icon" aria-label="search" className="px-0">
          <MoreVertIcon style={{ color: "white", width: "0px" }} />
        </IconButton>
        <div className="d-flex w-100 justify-content-between pe-1 py-1">
          <InputBase
            name={name}
            value={value}
            onChange={handleChangeform}
            type="number"
            placeholder="E.g. 20"
            autoComplete="off"
            className="custom-weight-select-field px-2"
          />
          {/* <label htmlFor="weight" className="d-flex "> &nbsp;&nbsp;&nbsp;</label> */}
          <select
            id="weight"
            className="custom-weight-select-field"
            onChange={handleDocWeight}
            aria-label="Select weight"
          >
            {document_weight.map((option, index) => (
              <option key={index}>{option}</option>
            ))}
          </select>
        </div>
      </Paper>
    </>
  );
}

export default CustomWeight;
