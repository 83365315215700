import React, { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { formatPrice } from "../../../utils/formatPrice";
import { useDispatch, useSelector } from "react-redux";
import {
  couponCode,
  orderConfirm,
  placeOrder,
} from "../../../redux/payment/PaymentThunk";
import { resetErrors } from "../../../redux/payment/PaymentSlice";
import CustomAlert from "../../../components/common/CustomAlert";
import SwalFireComponent from "../../../components/common/SwalFireComponent";
import { getuserdetail } from "../../../redux/user_profile/ProfileThunk";
import { Box, Skeleton, Button } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const OrderPreview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useriD = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const couponCodeData = useSelector(
    (state) => state?.createPayment?.couponCodeData?.promoCodeData?.error
  )
  const couponCodeDatasuccess = useSelector(
    (state) => state?.createPayment?.couponCodeData?.promoCodeData
  );
  const isCouponCodeLoading = useSelector(
    (state) => state?.createPayment?.iscouponCodeLoading
  );
  const isplaceOrderLoading = useSelector(
    (state) => state?.createPayment?.isplaceOrderLoading
  );
  const placeOrderDataerror = useSelector(
    (state) => state?.createPayment?.placeOrderData?.OrderCheckout?.errors
  );
  const confirmorderloading = useSelector(
    (state) => state?.createPayment?.isorderConfirmLoading
  );
  const [couponcode, setCouponCode] = useState("");
  useEffect(() => {
    // const dataget = JSON.parse(localStorage.getItem("order_data"));
    // if (dataget !== undefined) {
    const useriD = JSON.parse(localStorage.getItem("user"));
    setData({ ...data, wallet_amount: useriD?.ewallet_balance });
    let dataorder = {
      // cart_id: Number(cartId),
      customer_id: useriD?.id,
    };
    dispatch(orderConfirm(dataorder))
      .unwrap()
      .then((response) => {
        if (
          response?.orderData?.code === 200 &&
          response?.orderData?.status === "success" &&
          response?.orderData?.message !== "Session expired!"
        ) {
          if (response?.orderData?.data?.total_cart_item > 0) {

            localStorage.setItem(
              "order_data",
              JSON.stringify(response.orderData.data)
            );
            setData(response.orderData.data);
          } else {
            navigate('/')
          }
        }
        if (
          response?.orderData?.code === 200 &&
          response?.orderData?.status === "success" &&
          response?.orderData?.message === "Session expired!"
        ) {
          localStorage.removeItem("order_data");
          navigate("/cartpreview");
        }
      });
    // }
    // if (orderPreview === 0) {
    //   navigate("/");
    // }
    // else {
    //   navigate("/cartpreview");
    // }
    // return localStorage.removeItem("order_data")
  }, [dispatch]);

  const handleBackClick = () => {
    dispatch(resetErrors());
    localStorage.removeItem("order_data");
    navigate("/cartpreview");
  };

  const handleCoupenchange = (e) => {
    dispatch(resetErrors());
    setCouponCode(e.target.value);
    setError("");
  };
  const couponApply = () => {
    if (!couponcode.trim()) {
      setError("This field is required");
      return;
    }
    let datadata = JSON.parse(localStorage.getItem("order_data"));
    let data1 = {
      cart_id: Number(datadata?.cart_id),
      order_id: datadata?.order_id ? datadata?.order_id : datadata?.order_id,
      promo_code: couponcode,
      customer_id: useriD?.id,
    };
    dispatch(couponCode(data1))
      .unwrap()
      .then((response) => {
        if (
          response?.promoCodedata?.code === 200 &&
          response?.promoCodedata?.status === "success"
        ) {
          setData(response.promoCodeData?.data);
          // setcouponcodemessage(response?.promoCodeData?.message)
          localStorage.setItem(
            "order_data",
            JSON.stringify(response?.promoCodeData?.data)
          );
        }
        if (
          response?.promoCodedata?.code === 400 &&
          response?.promoCodedata?.status === "fail"
        ) {
          setData(response?.promoCodeData?.data);
          // setcouponcodemessage(response?.promoCodeData?.message)
          localStorage.setItem(
            "order_data",
            JSON.stringify(response?.promoCodeData?.data)
          );
        }
      })
      .catch((error) => {
        if (error?.status === 429 || error?.status === 500) {
          CustomAlert("Something went wrong! please try some time latter");
        }
      });
  };
  useEffect(() => {
    // dispatch(resetErrors())
    if (couponCodeDatasuccess?.data) {
      setData(couponCodeDatasuccess?.data);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return localStorage.removeItem("order_data")

  }, [couponCodeDatasuccess?.data, dispatch]);

  const processToCheckout = () => {
    const dataget = JSON.parse(localStorage.getItem("order_data"));
    let data2 = {
      cart_id: Number(data?.cart_id),
      order_id: dataget?.order_id ? dataget?.order_id : data?.order_id,
      // order_id: data?.order_id || dataget?.order_id,
      customer_id: useriD?.id,
    };
    dispatch(placeOrder(data2))
      .unwrap()
      .then((response) => {
        if (
          response.OrderCheckout?.code === "200" &&
          response.OrderCheckout?.status === "success"
        ) {
          // SwalFireComponent({ icon: "success", title: "Order place SuccessFully" })
          localStorage.removeItem("cart_id");
          localStorage.removeItem("order_data");
          localStorage.setItem(
            "orderConfirmData",
            JSON.stringify(response?.OrderCheckout?.orderData)
          );
          navigate("/orderconfirmation");
        }
        if (
          response?.OrderCheckout?.code === "404" &&
          response?.OrderCheckout?.status === "fail" &&
          response?.OrderCheckout?.errors?.order_id
        ) {
          localStorage.removeItem("cart_id");
          localStorage.removeItem("order_data");
          // localStorage.setItem("orderConfirmData", JSON.stringify(response?.OrderCheckout?.orderData))
          navigate("/orderconfirmation");
        }
      })
      .catch((error) => {
        if (error?.status === 429 || error?.status === 500) {
          CustomAlert("Something went wrong! please try some time latter");
        }
      });
  };
  return (
    <>
      <div className="pickupdrop-outer">
        <Container className="p-0">
          <div className="my-5 d-flex justify-content-center px-4 px-sm-0">
            <div className="cart-outer">
              <div>
                <div className="d-flex w-100 d-flex align-items-center mb-3">
                  <div className="me-4">
                    <p
                      className="collectoinh5 blue-text"
                      style={{ width: "max-content" }}
                    >
                      <span>Payment Details</span>
                    </p>
                  </div>

                  <div className="w-100 d-none d-sm-block d-md-block ">
                    <hr
                      className="w-auto"
                      style={{ border: "2px solid #bababa" }}
                    />
                  </div>
                </div>
              </div>

              {!confirmorderloading ? (
                <>
                  <div className="d-flex gap-3 flex-column flex-lg-row">
                    <div style={{ flex: "4" }}>
                      <Card className="border-0">
                        <Card.Body className="p-4">
                          <div className="d-flex flex-column gap-4">
                            <div className="d-flex justify-content-between ">
                              {/* <p
                                className="font-sm d-flex gap-1"
                                style={{ color: "#C8936C", cursor: "pointer" }}
                                onClick={handleBackClick}
                              >
                                <img
                                  src={`${window.location.origin}/assets/user-profile/review.svg`}
                                  alt="icon"
                                  className="nav-icon"
                                />
                                <Button style={{ color: "#C8936C", cursor: "pointer", fontWeight: "300" }} className="ps-0 font-sm" variant="text">Review</Button>
                              </p> */}
                              <Button onClick={handleBackClick} style={{ color: "#C8936C", cursor: "pointer", fontWeight: "300" }} variant="text" startIcon={<KeyboardBackspaceIcon />}>
                                Review
                              </Button>
                              <p className="font-sm d-flex align-items-center">
                                <span> Total Items in Cart:</span>
                                <span
                                  className="blue-text"
                                  style={{ fontSize: "18px", fontWeight: "500" }}
                                >
                                  {" "}
                                  {data?.total_cart_item < 10 ? "0" : ""}
                                  {data?.total_cart_item}
                                </span>
                              </p>
                            </div>
                            <div className="d-flex justify-content-between flex-column flex-sm-row gap-4 ">
                              <p className="font-sm d-flex align-items-center gap-2">
                                <span>wallet Balance:</span>
                                <span
                                  className="blue-text"
                                  style={{ fontSize: "24px", fontWeight: "500" }}
                                >
                                  {formatPrice(data?.wallet_amount)}
                                </span>
                              </p>
                              {Number(data?.required_wallet_amount) +
                                Number(data?.total) >
                                Number(data?.wallet_amount) && (
                                  <Button className="secondry btn px-5" onClick={() => { dispatch(resetErrors()); navigate("/user/wallet"); }} >
                                    Top-up
                                  </Button>
                                )}
                            </div>
                            {placeOrderDataerror && (
                              <p className="text-danger">
                                {placeOrderDataerror?.ewallet_balance}
                              </p>
                            )}
                            <div
                              className="mt-3"
                              style={{ height: "50px", flex: "2" }}
                            >
                              <div
                                className="d-flex input-group promo-input-border flex-nowrap"
                                style={{ height: "50px" }}
                              >
                                <input
                                  style={{ borderRadius: "6px" }}
                                  type="text"
                                  name="couponcode"
                                  className={`form-control promocode-input  px-3 position-relative  ${couponCodeData || error
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  onChange={handleCoupenchange}
                                  value={couponcode}
                                  placeholder="Enter Promocode"
                                  aria-label="Enter Promocode"
                                />
                                <span
                                  className="input-group-btn position-absolute "
                                  style={{
                                    right: "5px",
                                    zIndex: "999",
                                    top: "4px",
                                    color: "#113468",
                                  }}
                                >
                                  <Button
                                    className="Promocode-btn btn py-2 border-0 blue-text"
                                    style={{ height: "40px", color: "#113468" }}
                                    onClick={() =>
                                      isCouponCodeLoading ? null : couponApply()
                                    }
                                  >
                                    <>
                                      {isCouponCodeLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        "Apply"
                                      )}
                                    </>
                                  </Button>
                                </span>
                              </div>
                              {couponCodeData && (
                                <p className="text-danger">
                                  {couponCodeData?.promo_code || couponCodeData}
                                </p>
                              )}
                              {error && (
                                <p className="text-danger">
                                  {error}
                                </p>
                              )}
                              {couponCodeDatasuccess?.code === 200 && (
                                <p className="text-success">
                                  {couponCodeDatasuccess?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div
                      className="d-flex gap-3 flex-column "
                      style={{ flex: "3" }}
                    >
                      <Card className="border-0">
                        <Card.Body className="p-4">
                          <div>
                            <div className="d-flex gap-2 flex-column ">
                              <div className="price-col">
                                <>
                                  <ul className="list-group list-group-flush pricing-card d-flex gap-2">
                                    {data?.sub_total > 0 && (
                                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                          <p className="mb-0">Sub Total</p>
                                        </div>
                                        <span>
                                          <p className="mb-0">
                                            {formatPrice(data?.sub_total)}
                                          </p>
                                        </span>
                                      </li>
                                    )}

                                    {data?.pickup_charge > 0 && (
                                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                          <p className="mb-0">Pickup Charge:</p>
                                        </div>
                                        <span>
                                          <p className="mb-0">
                                            {formatPrice(data?.pickup_charge)}
                                          </p>
                                        </span>
                                      </li>
                                    )}

                                    {data?.insurance > 0 && (
                                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                          <p className="mb-0">Insurance</p>
                                        </div>
                                        <span>
                                          <p className="mb-0">
                                            {formatPrice(data?.insurance)}
                                          </p>
                                        </span>
                                      </li>
                                    )}
                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                      <div>
                                        <p className="mb-0 text-nowrap ">Tax</p>
                                      </div>
                                      <span>
                                        <p className="mb-0 text-nowrap">{formatPrice("0.00")}</p>
                                      </span>
                                    </li>
                                    {data?.discount > 0 && (
                                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                          <p className="mb-0">Discount</p>
                                        </div>
                                        <span>
                                          <p className="mb-0">- {" "}
                                            {formatPrice(data?.discount)}
                                          </p>
                                        </span>
                                      </li>
                                    )}
                                    <li className=" list-group-item border-0 px-0">
                                      <hr className="doted-hr " />
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                      <div className="total-price">
                                        <span>
                                          <p>Total</p>
                                        </span>
                                      </div>
                                      <div className="total-price">
                                        <span>
                                          <p>{formatPrice(data?.total)}</p>
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <div className="w-100 " style={{ height: "50px" }}>
                        <Button style={{ height: "50px" }} className="primarybtn btn w-100 h-100 py-3"
                          onClick={() => { processToCheckout() }}>
                          {isplaceOrderLoading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <>
                              Make Payment
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-column gap-1 gap-xxl-3 justify-content-center align-items-center">
                    <Card className="w-100 border-0 order-details-card">
                      <Card.Body>
                        <div className="">
                          <div className="w-100 d-flex gap-4 flex-column flex-md-row "  >
                            <div className="w-100" style={{ flex: "4" }}>
                              <Skeleton
                                variant="rounded"
                                height={180}
                                className="rounded w-100 load-img-skeleton"
                              />
                            </div>
                            <div className="w-100 " style={{ flex: "3" }}>
                              <Box className="w-100 d-flex flex-column gap-3">
                                <Box className="w-100 ">
                                  <Skeleton variant="rounded" width={"100%"} height={240} />
                                </Box>
                                <Box className="w-100">
                                  <Skeleton variant="rounded" width={"100%"} height={60} />
                                </Box>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OrderPreview;
