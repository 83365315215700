/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import "../assets/styles/order-confirmation.css";
import { useNavigate, useLocation } from "react-router-dom";
import FailedPayment from "./afterlogin/FailedPayment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { formatPrice } from "../utils/formatPrice";
import { orderDetails } from "../redux/user_profile/ProfileThunk";
import { resetErrors } from "../redux/user_profile/ProfileSlice"
import { Button } from "@mui/material";

const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storedLoginDataString = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();
  const [user_id, setUser] = useState();
  // const [alertShown, setAlertShown] = useState(false);
  const selectedSavedCollectionAddress =
    location.state?.selectedSavedCollectionAddress || [];
  const selectedSavedDeliveryAddress =
    location.state?.selectedSavedDeliveryAddress || [];

  const addressesExist =
    selectedSavedCollectionAddress.length > 0 &&
    selectedSavedDeliveryAddress.length > 0;

  const selectorcreatePaymentData = useSelector(
    (state) => state.createPayment.CreatePayment
  );
  const placeOrderData = useSelector(
    (state) => state?.createPayment?.placeOrderData?.OrderCheckout?.orderData
  );

  const [createPaymentData, setCreatePaymentData] = useState();
  const data = JSON.parse(localStorage.getItem("orderConfirmData"));
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome
      return ''; // Required for Firefox
    };

    const handleUnload = () => {
      window.alert('Changes that you made may not be saved. Are you sure you want to leave?');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);



  useEffect(() => {
    if (data !== null) {
      setCreatePaymentData(data);
    } else if (selectorcreatePaymentData) {
      setCreatePaymentData(selectorcreatePaymentData);
    } else {
      localStorage.removeItem("orderConfirmData")
      navigate("/");
    }
  }, [dispatch, selectorcreatePaymentData, navigate]);

  const backtohome = () => {
    localStorage.removeItem("orderConfirmData")
    navigate("/user/myorders");
  };

  useEffect(() => {
    dispatch(resetErrors())
    const useriD = JSON.parse(localStorage.getItem("user"));
    if (useriD) {
      setUser(useriD);
    }
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave this page?";
      event.preventDefault = message;
      return message;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>

      <div className="pickupdrop-outer">
        <Container className="p-0">
          <div className="d-flex justify-content-center my-5 w-100">
            <div className="div1 px-4 px-sm-0 d-flex gap-3 flex-column order-confirm-outer">
              <div className="d-flex flex-column flex-lg-row  gap-1">
                <Card className="pricing-card-outer border-0 thank-you-card " style={{ flex: "3" }} >
                  <Card.Body className="p-4">
                    <div className="d-flex flex-column gap-2">
                      <div className="w-100 d-flex  align-items-center gap-3 mb-3">
                        <img className="" src={`${window.location.origin}/assets/confirmation.svg`} ></img>
                        <h5 className="m-0 p-0 text-start">
                          Thank you for Choosing Let’s Ship Now!
                        </h5>
                      </div>
                      <div>
                        <p className="order-confirm-p-2 paragraph2">
                          <span className="order-confirm-p-head paragraph2 me-1">
                            Hi,
                            <span className="me-1">
                              {" "}
                              {storedLoginDataString?.name}
                            </span>
                            {/* {storedLoginDataString?.invoice_last_name}{" "} */}
                          </span>{" "}
                          Your order has been confirmed.
                        </p>
                      </div>
                      <div>
                        <p className="order-confirm-p-2 paragraph2">
                          <span className="order-confirm-p-head paragraph2 me-1">
                            Tracking ID:
                          </span>{" "}
                          {createPaymentData?.lsn_reference}
                        </p>
                      </div>
                      <div>
                        <p className="order-confirm-p-2 paragraph2">
                          <span className="order-confirm-p-head paragraph2 me-1">
                            Order Date & Time:
                          </span>{" "}
                          {createPaymentData?.created_at}
                        </p>
                      </div>
                    </div>
                    {/* <div className=" mt-3 d-flex d-sm-flex d-md-none gap-lg-4 gap-2 flex-column flex-sm-row flex-lg-row d-lg-flex">
                      <div className="text-nowrap cursor-pointer">
                        <Button
                          className="text-decoration-none d-flex align-items-center p-0 gap-2"
                          href={createPaymentData?.shipping_file && createPaymentData?.shipping_file}
                          target="_blank"
                          variant="link"
                          disabled={!createPaymentData?.shipping_file}
                          
                        >
                          <img alt="icon"
                            src={`${window.location.origin}/assets/user-profile/dlabel.svg`}
                          />
                          <a className="paragraph2 text-decoration-none m-0" target="_blank"
                            style={{ color: "#6B6B6B", fontWeight: "300", }} >
                            Download Label
                          </a>
                        </Button>
                      </div>
                      <div className="text-nowrap cursor-pointer">
                        <Button
                          className="text-decoration-none  d-flex align-items-center p-0 gap-2"
                          variant="link"
                          disabled={!createPaymentData?.invoice_file}
                          // onClick={() => handleViewMore(order?.id)}
                          href={
                            createPaymentData?.invoice_file
                            && createPaymentData?.invoice_file

                          }
                          target="_blank"
                        >
                          <img alt="icon"
                            src={`${window.location.origin}/assets/user-profile/dinvoice.svg`}
                          />
                          <a target="_blank" className="paragraph2 text-decoration-none m-0"
                            style={{
                              color: "#6B6B6B",
                              fontWeight: "300"
                            }}    >
                            Download Invoice
                          </a>
                        </Button>
                      </div>
                    </div> */}
                  </Card.Body>
                </Card>
                <Card className="pricing-card-outer border-0 thank-you-card-second" style={{ flex: "2" }} >
                  <Card.Body className="p-4">
                    <div className="d-flex flex-column  px-0 " style={{ flex: "1" }}   >
                      <div className=" w-100 ">
                        <div className="blue-text">
                          <p className="paragraph2  blue-text">
                            <b>
                              <span className="order-confirm-p-head paragraph2 blue-text">
                                Payment Details:
                              </span>
                            </b>
                          </p>
                        </div>
                        <div className="mb-3">
                          <hr className="payment-confirm-hrline" />
                        </div>

                        <div>
                          <li className="d-flex justify-content-between align-items-center border-0 px-0">
                            <div>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                Sub Total
                              </p>
                            </div>
                            <span>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                <b>${createPaymentData?.subtotal}</b>
                              </p>
                            </span>
                          </li>
                          {createPaymentData?.pickup_charge > 0 && (
                            <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                              <div>
                                <p className="mb-0 paragraph2 order-confirm-p-2 text-nowrap">
                                  Pickup Charge{" "}
                                </p>
                              </div>
                              <div className=" d-flex justify-content-end insurance ">
                                <span>
                                  <p className="mb-0 paragraph2 order-confirm-p-2">
                                    <b>
                                      {formatPrice(createPaymentData?.pickup_charge)}
                                    </b>
                                  </p>
                                </span>
                              </div>
                            </li>
                          )}
                          <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                            <div>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                TAX{" "}
                              </p>
                            </div>
                            <span>
                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                <b>{formatPrice("0.00")}</b>
                              </p>
                            </span>
                          </li>
                          {Number(createPaymentData?.total_insurance) > 0 && (
                            <li className="list-group-item d-flex  align-items-center border-0 px-0">
                              <div className="d-flex">
                                <div>
                                  <p className="mb-0 paragraph2 order-confirm-p-2">
                                    Insurance
                                  </p>
                                </div>
                              </div>
                              <div className=" d-flex justify-content-end insurance">
                                <span>
                                  <p className="mb-0 paragraph2 order-confirm-p-2">
                                    {/* <b>
                                    {createPaymentData?.data?.parcel_value > 100 ? `$${createPaymentData?.data?.insured_value}` : <span>Free</span>}
                                  </b> */}
                                    <b>
                                      {createPaymentData?.total_insurance > 0 && formatPrice(createPaymentData?.total_insurance)}
                                    </b>

                                  </p>
                                </span>
                              </div>
                            </li>
                          )}
                          {createPaymentData?.discount_amount > 0 && (
                            <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                              <div>
                                <p className="mb-0 paragraph2 order-confirm-p-2">
                                  Discount{" "}
                                </p>
                              </div>
                              <span className="px-2">
                                <div
                                  style={{ color: "#113468" }}
                                  className="discount-label-payment"
                                >
                                  {" "}
                                </div>
                              </span>
                              <div className=" d-flex justify-content-end insurance ">
                                <span>
                                  <p className="mb-0 paragraph2 order-confirm-p-2">
                                    <b>-{" "}
                                      {formatPrice(createPaymentData?.discount_amount)}
                                    </b>
                                  </p>
                                </span>
                              </div>
                            </li>
                          )}
                          <div>
                            <hr className="doted-hr m-1" />
                          </div>
                          <div>
                            <li className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                              <div>
                                <span>
                                  <p className="paragraph1 mb-0 p-0">
                                    <b>Total</b>
                                  </p>
                                </span>
                              </div>
                              <div>
                                <span>
                                  <p className="paragraph1 mb-0">
                                    <b>${createPaymentData?.total}</b>
                                  </p>
                                </span>
                              </div>
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className=" w-100">
                        <Button
                          className="primarybtn btn w-100 input-main-all"
                          onClick={() => {
                            backtohome();
                          }}
                        >
                          Go To My Order
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OrderConfirmation;
