/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/userProfile/userSavedCard.css";
import { Card, Dropdown, Col, Row, Modal, Spinner } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserCard, fetchUserCardDetails, makeDefaultUserCard } from "../../redux/address/adressThunk";
import Swal from "sweetalert2";
import { resetErrors } from "../../redux/address/addressSlice";
import FailedPayment from "../../pages/afterlogin/FailedPayment";
import { useModel } from "../../utils/modalContext";
import UserSkeleton from "./UserSkeleton";
import OrderStripePayment from "../../pages/afterlogin/OrderStripePayment";
import { CreateCharge } from "../../redux/payment/PaymentThunk";
import SwalFireComponent from "../common/SwalFireComponent";
import { Button } from "@mui/material";

const UserSavedCard = () => {
  const dispatch = useDispatch();
  const userData = localStorage.getItem("user");
  const userObject = JSON.parse(userData);
  const [cardId, setCardID] = useState({
    makeDefaulCardId: null,
    deleteCardId: null,
  });
  const cardDetails = useSelector(
    (state) => state?.addressValidation?.userCard?.WalletCard?.data
  );
  const isCardLoading = useSelector(
    (state) => state?.addressValidation?.isCardLoading
  );
  const isLoadingPayment = useSelector(
    (state) => state?.createPayment?.isLoadingPayment
  );
  const isDeleteCardLoading = useSelector(
    (state) => state?.addressValidation?.isDeleteCardLoading
  );
  const useriDold = JSON.parse(localStorage.getItem("user"));
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(resetErrors());
    const useriD = JSON.parse(localStorage.getItem("user"));
    if (useriD) {
      dispatch(
        fetchUserCardDetails({
          customer_id: useriD.id,
          uniquesessionid: uniquesessionid,
        })
      );
    }
  }, []);

  const handleMakeDefault = (card) => {
    setCardID({ makeDefaulCardId: card?.id });
    dispatch(
      CreateCharge({
        customer_id: card.customer_id,
        card_id: card.id,
        uniquesessionid: uniquesessionid,
        default: `${card ? "default" : ""}`,
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.WalletCard.code === "200" && response?.WalletCard.status === "success") {
          dispatch(
            fetchUserCardDetails({
              customer_id: useriDold.id,
              uniquesessionid: uniquesessionid,
            })
          );
          SwalFireComponent({ icon: "success", title: "Card Updated sucessfully" })
        }
      })
      .catch((error) => {
        console.error("profile failed:", error);
        SwalFireComponent({ icon: "error", title: "Internal server error" })
      });
  };

  const handleDeleteCard = (card) => {
    setCardID({ deleteCardId: card?.id });
    dispatch(
      CreateCharge({
        customer_id: card.customer_id,
        card_id: card.id,
        uniquesessionid: uniquesessionid,
        delete: "delete",
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.WalletCard.code === "200" && response?.WalletCard.status === "success") {
          dispatch(
            fetchUserCardDetails({
              customer_id: useriDold.id,
              uniquesessionid: uniquesessionid,
            })
          );
          SwalFireComponent({ icon: "success", title: "Card Deleted sucessfully" })
        }
      })
      .catch((error) => {
        console.error("profile failed:", error);
        SwalFireComponent({ icon: "error", title: "Internal server error" })
      });
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const addcard = () => {
    setShowModal(true);
  };

  return (
    <div className="user-saved-card-outer">
      <Card style={{ border: "none" }} className="px-4 py-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="">Saved Cards</h5>
          <Button variant="outlined" className="outer_btn btn px-4 px-sm-5" style={{ borderColor: "#113468", height: "40px" }} onClick={addcard}>Add Card</Button>
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          className="user-wallet-payment-modal d-flex justify-content-center align-items-center">
          <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
            <div
              className="user-modal-wallet w-100"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
            >
              <Modal.Header style={{ right: "0px" }}
                closeButton
                className="closebutton d-flex justify-content-end position-sticky  z-3 bg-white"
              ></Modal.Header>
              <div className="px-4 pb-4">
                <div className="d-flex flex-column gap-4">
                  <h5>Add Card</h5>
                  <OrderStripePayment newcard={true} handleCloseModal={handleCloseModal} />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {isCardLoading ? (
          <div>
            <UserSkeleton width="83%" twoLine={false} tworound={false} />
          </div>
        ) : (
          <div>
            {cardDetails !== undefined && cardDetails?.length !== 0 ? (
              <div>
                <Row>
                  {cardDetails?.map((card, index) => (
                    <Col key={index} lx={5} lg={5} sm={6} md={6} className="mb-3">
                      <>
                        <Card
                          className=" h-100 w-100 d-flex flex-column card-border"
                          style={{
                            background:
                              !card?.card_status === "Active" && "#e9e9e9 ",
                          }}
                        >
                          <Card.Body className="d-flex flex-column justify-content-between p-0 px-2 py-1">
                            <div
                              className="d-flex mt-2 drop-user "
                              style={{
                                justifyContent: "space-between",
                                flexDirection: "row-reverse",
                              }}
                            >
                              <Dropdown
                                className="top-dropdown text-end drpdwn-header"
                                style={{ padding: "0px" }}
                              >
                                {(card?.id === cardId.makeDefaulCardId && isLoadingPayment) || (card?.id === cardId.deleteCardId && isLoadingPayment) ?
                                  (
                                    <>
                                      <div className="d-flex justify-content-end">
                                        <Spinner animation="border" size="sm" />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <Dropdown.Toggle
                                        variant="light"
                                        id="dropdown-basic"
                                        className="user-custom-toggle p-0"
                                      >
                                        <img
                                          src={`${window.location.origin}/assets/user-profile/editdots.svg`}
                                          alt="Dropdown"
                                        />
                                      </Dropdown.Toggle>
                                    </>
                                  )}
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    disabled={card?.is_default_card === "YES"}
                                    onClick={() => handleMakeDefault(card)} >
                                    Make Default
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleDeleteCard(card)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              {card.is_default_card === "YES" && (
                                <div className="">
                                  <div
                                    style={{
                                      color: "#113468",
                                      backgroundColor: "#E9E9E9",
                                      inlineSize: "min-content",
                                      textAlign: "center",
                                      padding: "0px 12px ",
                                      borderRadius: "3px",
                                    }}
                                    className="default-address-label mb-2"
                                  >
                                    {" "}
                                    <span>Default</span>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="px-2 mb-2 ">
                              <div
                                className="d-flex mt-1"
                                style={{ justifyContent: "space-between" }}
                              >
                                <Card.Text>
                                  {" "}
                                  <p className="paragraph1 me-1">
                                    {" "}
                                    **** **** **** {card.last4}
                                  </p>
                                </Card.Text>
                                <Card.Text className="ms-3">
                                  <p className="paragraph1">
                                    <b>{card.brand}</b>
                                  </p>
                                </Card.Text>
                              </div>
                              <div className="d-flex">
                                <Card.Text className="me-1">
                                  {card.exp_month}
                                </Card.Text>
                                <span> /</span>
                                <Card.Text className="ms-1">
                                  {card.exp_year}
                                </Card.Text>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </>
                      {/* )} */}
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <Card className="w-50 h-100 d-flex flex-column px-4 py-4">
                <p>No Card Found......</p>
              </Card>
            )}
          </div>
        )}
      </Card>
      <FailedPayment />
    </div>
  );
};

export default UserSavedCard;
