import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import "../../assets/styles/addressBook/collectionDetails.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "../../redux/address/addressSlice";
import { resetServiceErrors } from "../../redux/services/ServicesSlice";
import Swal from "sweetalert2";
import { fetchAddressDetails, fetchAddressList } from "../../redux/address/adressThunk";
import { Box, Button, Skeleton } from "@mui/material";
import CustomAlert from "../common/CustomAlert";
import { fetchShippingServices, fetchpickupdropdata } from "../../redux/services/ServicesThunk";
import { useNavigate } from "react-router-dom";
import { fetchStateName } from "../../redux/landing/landingThunk";
const adressValidation = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  Postcode: Yup.string().required("This field is required"),
  address01: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  contactcontrycode: Yup.string().required("required"),
  contactNo: Yup.string()
    .matches(/^\d{10,13}$/, "Mobile number must be between 10 and 13 digits")
    .required("This field is required"),
  email: Yup.string()
    .email("The email must be a valid email address.")
    .required("This field is required"),
});
const AccordianCollection = ({
  isCollectionFormOpen,
  setIsCollectionFormOpen,
  country_data,
  userId,
  serviceData,
  addressListf,
  formData,
  setFormData,
  setSelectOpen,
  setIsCollectionValidate,
  setValidations,
  setDeliveryValidations,
  selectedOption,
  setSelectedOption,
  setCollectionEventChange,
  setSelectedCollectionService,
  setSavedDataCollection,
  setNewError,
  fromSteteData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const countrydetails = JSON.parse(localStorage.getItem("searchData"));
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const Countryprefix = JSON.parse(localStorage.getItem("Countryprefix"));

  const addressErrors = useSelector(
    (state) => state?.addressValidation?.colFieldError
  );

  const isAddressLoading = useSelector(
    (state) => state.addressValidation?.isAddressLoading
  );

  const verificationError = useSelector(
    (state) => state?.addressValidation?.collValidationError
  );

  const serverErrors = useSelector((state) => state?.addressValidation);
  const isSaveLoading = useSelector(
    (state) => state.addressValidation?.isSaveLoading
  );
  const isServiceLoading = useSelector(
    (state) => state
  );
  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );

  const [country_details, setCountry_details] = useState();
  const [showForm, setShowForm] = useState(true);
  const [addressList, setaddressList] = useState(true);
  const [isFormCollectionOpen, setIsFormCollectionOpen] = useState(false);
  const [serverError, setServerError] = useState({ cityError: "" });
  const [filterdAddress, setFilterAddress] = useState("");
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (country_data?.fromCountryId) {
      const stateName = country_data?.fromCountryId;
      dispatch(fetchStateName(stateName));
    }
  }, [country_data?.fromCountryId, dispatch]);

  useEffect(() => {
    if (addressListf) {
      if (addressListf?.length > 0) {
        setaddressList(addressListf);
      }
      let arrayOfSearchCountry = [country_data];
      const country_code = arrayOfSearchCountry[0]?.fromCountryCode;
      const filteredAddressList = addressListf.filter(
        (address) => address?.country?.code === country_code
      );
      setFilterAddress(filteredAddressList);

    }
  }, [addressListf, country_data]);

  useEffect(() => {
    if (country_data) {
      setCountry_details(country_data);
      setFormData({
        ...formData,
        country: country_data.fromcountry,
        Postcode: country_data.fromPostalCode,
        state: serviceData?.cart_details?.collection_state,
      });
    }
    if (Countryprefix) {
      setFormData({
        ...formData,
        contact_prefix: `+${Countryprefix?.fromPrefix}`,
      });
    }
  }, [country_data]);


  const initialValues = {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    companyName: formData?.companyName,
    country: formData?.country,
    Postcode: formData?.Postcode,
    address01: formData?.address01,
    address02: formData?.address02,
    state: formData?.state,
    city: formData?.city,
    email: formData?.email,
    contactNo: formData?.contactNo,
    contactcontrycode: formData?.contactcontrycode,
    address_id: formData?.address_id,
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAddresses = (filterdAddress || []).filter((address) => {
    const fullAddress = `${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${address.state}`.toLowerCase();
    return fullAddress.includes(searchQuery.toLowerCase());
  });

  const Send = (values) => {
    setNewError("")
    setSelectOpen(false);
    // localStorage.removeItem("pickupDates")
    // localStorage.removeItem("dropOffDates")
    setSelectedCollectionService("Drop")
    setCollectionEventChange(false);
    setSavedDataCollection({
      firstName: values?.firstName,
      lastName: values?.lastName,
      address01: values?.address01,
      address02: values?.address02,
      city: values?.city,
      state: values?.state,
      Postcode: country_data?.fromPostalCode,
    });
    if (countrydetails) {
      dispatch(
        fetchAddressDetails({
          ...countrydetails,
          country: country_data?.fromcountry,
          postalCode: country_data?.fromPostalCode,
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.companyName,
          email: values.email,
          phone_number: values.contactNo,
          contactcontrycode: values?.contactcontrycode,
          address_line1: values.address01,
          address_line2: values.address02,
          state: values.state || (formData?.state ? formData.state : (serviceData?.cart_details?.collection_state ? serviceData.cart_details.collection_state : values.state)),
          city: values.city,
          state_id: "",
          stateCode: values.state || (formData?.state ? formData.state : (serviceData?.cart_details?.collection_state || values.state)),
          country_id: country_details?.fromCountryId,
          countryCode: country_details?.fromCountryCode,
          fromCountryCode: country_details?.fromCountryCode,
          toCountryCode: country_details?.toCountryCode,
          courier_service: serviceData?.cart_details?.courier_service_name,
          courier_service_code: serviceData?.cart_details?.courier_service_code,
          courier_id: serviceData?.cart_details?.courier_id,
          address_type: selectedOption,
          customer_id: userId?.id,
          cart_id: serviceData?.cart_details?.cart_id,
          cartdetail_id: serviceData?.cart_details?.id,
          address_id: values?.address_id,
          type: "collection",
          uniquesessionid: uniquesessionid,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.status === true && response.statusCode === 200) {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Collection Address Saved Successfully",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                timerProgressBar: "custom-progress-bar",
              },
            });

            setSelectOpen(true);
            dispatch(resetErrors());
            dispatch(resetServiceErrors())
            setValidations({
              collection_validation: "",
            });
            setDeliveryValidations({
              delivery_validation: "",
              delivery_form: "",
            });

            setIsCollectionValidate(true);
            dispatch(
              fetchAddressList({
                customer_id: userId.id,
                address_id: formData?.address_id,
                uniquesessionid: uniquesessionid,
              })
            );

            setFormData({
              ...formData,
              country: country_details?.fromcountry,
              Postcode: country_details?.fromPostalCode,
              state: serviceData?.cart_details?.collection_state,
            });
          } else {
            setFormData({ ...formData, verifyFields: "false" });
          }
        })
        .catch((error) => {
          setServerError({ cityError: error?.data?.collectioncityerror });
          setIsCollectionValidate(false);
          if (error.status === 500) {
            CustomAlert("Something went wrong! please try some time latter");
          }
          if (error?.status === 429) {
            CustomAlert("Something went wrong! please try some time latter");
          }
        });
    }
  };

  const handleDropdownSelect = (address) => {
    setNewError("")
    setSearchQuery('')
    setSelectOpen(false);
    // localStorage.removeItem("pickupDates")
    dispatch(resetErrors());
    dispatch(resetServiceErrors())
    setShowForm(true);
    setIsFormCollectionOpen(!isFormCollectionOpen);

    setFormData({
      ...formData,
      country: country_data?.fromcountry,
      Postcode: country_data?.fromPostalCode,
      firstName: address?.first_name,
      lastName: address?.last_name,
      companyName: address?.company_name,
      address01: address?.address_1,
      address02: address?.address_2,
      state: address?.state ? address?.state : serviceData?.cart_details?.collection_state,
      city: address?.city,
      contactcontrycode: address?.contact_extention,
      contact_prefix: address?.contact_extention,
      email: address?.email,
      contactNo: address?.contact_no,
      addressType: selectedOption,
      address_id: address?.id,
      uniquesessionid: uniquesessionid,
    });
  };

  const toggleFormCollection = () => {
    setFormData({
      ...formData,
      country: country_details?.fromcountry,
      Postcode: country_details?.fromPostalCode,
      state: serviceData?.cart_details?.collection_state,
    });
    setShowForm(!showForm);
    setIsFormCollectionOpen(!isFormCollectionOpen);
    setIsCollectionFormOpen(!isCollectionFormOpen);
  };

  const handleResetFormCollection = () => {
    setSelectOpen(false);
    dispatch(resetErrors());
    dispatch(resetServiceErrors())
    setFormData({
      ...formData,
      firstName: "",
      lastName: "",
      companyName: "",
      address01: "",
      address02: "",
      // state: !fromSteteData && "",
      city: "",
      email: "",
      contact_prefix: "",
      contactcontrycode: "",
      contactNo: "",
      addressType: selectedOption,
    });
    setSelectedOption("Home");
    setIsCollectionValidate(false);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setFormData({
      ...formData,
      addressType: e.target.value ? e.target.value : "Home",
    });
  };

  const handleSelectChange = (event) => {
    const selectedState = event.target.value;
    setFormData({
      ...formData,
      state: selectedState,
    });
  };

  const handleCustomChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFieldValue(name, value);
  };

  return (
    <>
      <div>
        <div className="address-outer py-0 py-lg-0  mt-0 mt-lg-0">
          <div className="addressdetails-holder w-100">
            <div className=" gap-4 d-flex flex-column flex-lg-row">
              <div style={{ flex: "1" }} className="pt-4 pt-lg-0">
                <div style={{ border: "1px solid #e9e9e9" }}
                  className={`address-bg box-shadow d-flex px-4 is-valid-paper ${isFormCollectionOpen ? "open" : ""
                    }`}
                  onClick={toggleFormCollection}
                >
                  <img
                    src={`${window.location.origin}/assets/${isFormCollectionOpen ? "plusblue" : "minusblue"
                      }.svg`}
                    alt="Toggle Icon"
                  />
                  {(!isFormCollectionOpen || isFormCollectionOpen) && (
                    <p className=" mb-0 px-2 font-weight-bold">
                      Add New Address
                    </p>
                  )}
                </div>
              </div>
              <div style={{ flex: "1" }} className="pb-4">
                <div className="address-bg box-shadow is-valid-paper" style={{ border: "1px solid #e9e9e9" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-button-dark-example1"
                      variant="secondary"
                      className="d-flex dropdown-toggle py-0 w-100 justify-content-between align-items-center border-0"
                    >
                      <div>
                        <p className="mb-0 select-input">
                          Select From Saved Address
                        </p>
                      </div>
                      <div className="dropdown-icon">
                        <img src={`${window.location.origin}/assets/dropdownicon.svg`} alt="Dropdown" />
                      </div>
                    </Dropdown.Toggle>
                    {isAddressLoading ? (
                      <Box sx={{ width: 360 }}>
                        <Skeleton />
                        <Skeleton animation="wave" />
                      </Box>
                    ) : (
                      <Dropdown.Menu className="dropdown-menu-collection">
                        <div className="p-3">
                          <Form.Control
                            type="text"
                            placeholder="Search address"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="is-valid-paper"
                          />
                        </div>

                        {filteredAddresses?.length === 0 ? (
                          <Dropdown.Item>No Address Found...</Dropdown.Item>
                        ) : (
                          filteredAddresses?.map((address, index) => (
                            <Dropdown.Item
                              className="address-dropdown-item"
                              key={index}
                              onClick={() => handleDropdownSelect(address)} >
                              {`${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${address.state}`}
                            </Dropdown.Item>
                          ))
                        )}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>

                </div>
              </div>
            </div>

            <div className=" d-lg-flex">
              {showForm && (
                <>
                  <div className="d-flex">
                    <div className="add-form " style={{ flex: "3" }}>
                      <div className="w-sm-75 w-lg-75">
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={adressValidation}
                          onSubmit={Send}

                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            isSubmitting,
                            setFieldValue,
                            isValid,
                          }) => (
                            <Form
                              className="d-flex gap-4 flex-column"
                              onSubmit={handleSubmit}
                              onReset={handleReset}
                            >
                              <Row className="flex-column flex-sm-row flex-lg-row gap-4 gap-sm-4 gap-lg-0 ">
                                <Col md="5" lg="5" className="">
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      name="country"
                                      value={values.country}
                                      className="input-main-all"
                                      placeholder="Enter Country"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="5" lg="3" className="">
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      name="Postcode"
                                      value={values.Postcode}
                                      className="input-main-all"
                                      placeholder="Enter Postcode"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className=" flex-column flex-lg-row flex-sm-row gap-4 gap-md-0 gap-lg-0">
                                <Col md="4" className="">
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      name="firstName"
                                      value={values.firstName}
                                      placeholder="First name"
                                      className={`input-main-all ${errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue)
                                        setCollectionEventChange(true);
                                      }}
                                      // onChange={(e) => {
                                      //   setCollectionEventChange(true);
                                      //   // setFormData({ ...formData, firstName: values.firstName })
                                      //   handleChange(e);

                                      // }}
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.firstName &&
                                          touched.firstName &&
                                          errors.firstName}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col md="4" className="">
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      name="lastName"
                                      value={values.lastName}
                                      className={`input-main-all ${errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      placeholder="Last name"
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue)
                                        setCollectionEventChange(true);
                                      }}
                                      // onChange={(e) => {
                                      //   setCollectionEventChange(true);
                                      //   handleChange(e);
                                      // }}
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.lastName &&
                                          touched.lastName &&
                                          errors.lastName}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col md="4" className="">
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      className={`input-main-all ${errors.companyName &&
                                        touched.companyName &&
                                        errors.companyName
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      name="companyName"
                                      value={values.companyName}
                                      placeholder="Company Name"
                                      // onChange={(e) => {
                                      //   setCollectionEventChange(true);
                                      //   handleChange(e);
                                      // }}
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue)
                                        setCollectionEventChange(true);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className=" flex-column flex-lg-row  gap-4  gap-lg-0">
                                <Col md="12" className="">
                                  <Form.Group>
                                    <Form.Control
                                      className={`input-main-all ${errors.address01 &&
                                        touched.address01 &&
                                        errors.address01
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="text"
                                      name="address01"
                                      value={values.address01}
                                      placeholder="Address01"
                                      // onChange={(e) => {
                                      //   setCollectionEventChange(true);
                                      //   handleChange(e);
                                      // }}
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue)
                                        setCollectionEventChange(true);
                                      }}
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.address01 &&
                                        touched.address01 &&
                                        errors.address01
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.address01 &&
                                          touched.address01 &&
                                          errors.address01}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className=" flex-column flex-lg-row gap-4 gap-lg-0">
                                <Col md="12" className="">
                                  <Form.Group>
                                    <Form.Control
                                      className={`input-main-all ${errors.address02 &&
                                        touched.address02 &&
                                        errors.address02
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="text"
                                      name="address02"
                                      value={values.address02}
                                      placeholder="Address02"
                                      // onChange={(e) => {
                                      //   setCollectionEventChange(true);
                                      //   handleChange(e);
                                      // }}
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue)
                                        setCollectionEventChange(true);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className=" flex-column flex-lg-row flex-sm-row gap-md-0 gap-4 gap-lg-0">
                                <Col md={6} className="">
                                  {/* <Form.Group>
                                    <Form.Control
                                      className={`input-main-all ${errors.state &&
                                        touched.state &&
                                        errors.state
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="state"
                                      name="state"
                                      value={values.state}
                                      placeholder="state"
                                      onChange={(e) => {
                                        setCollectionEventChange(true);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.state &&
                                        touched.state &&
                                        errors.state
                                      }
                                    /> */}

                                  {/* vvdvdvd */}
                                  {!fromSteteData &&
                                    <Form.Group>
                                      <Form.Control
                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.state &&
                                          touched.state &&
                                          errors.state
                                          ? "is-invalid-paper"
                                          : "is-valid-paper"
                                          }`}
                                        type="text"
                                        name="state"
                                        placeholder="State"
                                        value={values.state}
                                        onChange={(e) => { handleCustomChange(e, setFieldValue) }}
                                        onBlur={handleBlur}
                                        isInvalid={errors.state && touched.state}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        <span>
                                          {errors.state &&
                                            touched.state &&
                                            errors.state}
                                        </span>
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  }
                                  {fromSteteData && <Form.Group controlId="formGridfirstname">
                                    <Form.Select aria-label="Default select example"
                                      placeholder='Select' onBlur={handleBlur}
                                      className={`Pickup-select-input shadow-none  ${errors.state && touched.state && errors.state ? "is-invalid-paper" : "is-valid-paper" && formData?.state === "Select" && "pickup-placeholder-select"}`} onChange={handleSelectChange} value={formData.state}
                                      isInvalid={
                                        errors.state &&
                                        touched.state &&
                                        errors.state
                                      }>
                                      {fromSteteData?.map((option, index) => {
                                        return (
                                          <>
                                            <option key={index} value={option?.code} >
                                              {option?.default_name}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </Form.Select>
                                    {errors?.state && (
                                      <Form.Text className="text-danger mt-0" type="invalid">
                                        {errors.state &&
                                          touched.state &&
                                          errors.state}
                                      </Form.Text>
                                    )}
                                    {/* vvdvdvd */}
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.state &&
                                          touched.state &&
                                          errors.state}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  }


                                </Col>
                                <Col md={6} className="">
                                  <Form.Group>
                                    <Form.Control
                                      className={`input-main-all ${errors.city &&
                                        touched.city &&
                                        errors.city
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="text"
                                      name="city"
                                      value={values.city}
                                      placeholder="Enter City"
                                      // onChange={(e) => {
                                      //   setCollectionEventChange(true);
                                      //   handleChange(e);
                                      // }}
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue)
                                        setCollectionEventChange(true);
                                      }}
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.city &&
                                        touched.city &&
                                        errors.city
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.city &&
                                          touched.city &&
                                          errors.city}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className=" flex-column flex-lg-row flex-sm-row gap-4 gap-md-0 gap-lg-0">
                                <Col md={6} className="">
                                  <Form.Group>
                                    <Form.Control
                                      className={`input-main-all ${errors.email &&
                                        touched.email &&
                                        errors.email
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        } ${addressErrors?.email && " is-invalid-paper"} `}
                                      type="text"
                                      name="email"
                                      value={values.email}
                                      placeholder="Enter Email"
                                      onChange={(e) => {
                                        dispatch(resetErrors())
                                        dispatch(resetServiceErrors())

                                        setCollectionEventChange(true);
                                        handleCustomChange(e, setFieldValue);
                                      }}

                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.email &&
                                        touched.email &&
                                        errors.email

                                      }
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.email &&
                                          touched.email &&
                                          errors.email}
                                      </span>
                                    </Form.Control.Feedback>
                                    {addressErrors?.email && <p className="profile_address-error">
                                      {addressErrors?.email[0]}
                                    </p>}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Row>
                                    <Col md={3} xs={3} className="pe-0">
                                      <Form.Control
                                        className={`user-address-placeholder input-main-all ${errors.contactcontrycode &&
                                          touched.contactcontrycode &&
                                          errors.contactcontrycode
                                          ? "is-invalid-paper"
                                          : "is-valid-paper"
                                          }`}
                                        type="number"
                                        minLength={1}
                                        maxLength={4}
                                        name="contactcontrycode"
                                        placeholder="91"
                                        value={values.contactcontrycode}
                                        onChange={(e) => {
                                          setCollectionEventChange(true);
                                          handleCustomChange(e, setFieldValue);
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={
                                          errors.contactcontrycode &&
                                          touched.contactcontrycode &&
                                          errors.contactcontrycode
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        <span>
                                          {errors.contactcontrycode &&
                                            touched.contactcontrycode &&
                                            errors.contactcontrycode}
                                        </span>
                                      </Form.Control.Feedback>
                                    </Col>
                                    <Col md={9} xs={9}>
                                      <Form.Control
                                        className={`user-address-placeholder input-main-all ${errors.contactNo &&
                                          touched.contactNo &&
                                          errors.contactNo
                                          ? "is-invalid-paper"
                                          : "is-valid-paper"
                                          }`}
                                        type="number"
                                        placeholder="contact No"
                                        name="contactNo"
                                        value={values.contactNo}
                                        onChange={(e) => {
                                          setCollectionEventChange(true);
                                          handleCustomChange(e, setFieldValue);
                                        }}
                                        onBlur={handleBlur}
                                        maxLength={13}
                                        isInvalid={
                                          errors.contactNo &&
                                          touched.contactNo &&
                                          errors.contactNo
                                        }
                                      ></Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        <span>
                                          {errors.contactNo &&
                                            touched.contactNo &&
                                            errors.contactNo}
                                        </span>
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <p className="profile_address-error">
                                    {addressErrors?.phone_number}
                                  </p>
                                </Col>
                              </Row>
                              <div className="radiobtnAddress d-flex py-0 py-lg-4 flex-lg-row px-o">
                                <div className="pe-2 text-nowrap">
                                  <p>Select address type :</p>
                                </div>
                                <div className="radio-holderaddress custom_radio d-flex flex-row ps-0 gap-2">
                                  <div className="col d-flex">
                                    <input
                                      className="chek me-1"
                                      type="radio"
                                      id="Home"
                                      name="select"
                                      value="Home"
                                      checked={selectedOption === "Home"}
                                      onChange={handleOptionChange}
                                    />
                                    <label for="Home">Home</label>
                                  </div>
                                  <div className=" col d-flex">
                                    <input
                                      className="me-1"
                                      type="radio"
                                      id="Work"
                                      name="select"
                                      value="Work"
                                      checked={selectedOption === "Work"}
                                      onChange={handleOptionChange}
                                    />
                                    <label for="Work">Work</label>
                                  </div>
                                </div>
                              </div>
                              {(verificationError || serverError?.cityError) && (
                                <p className="error-text text-danger">
                                  {verificationError && verificationError}
                                  {serverError?.cityError}
                                </p>
                              )}

                              <div className="d-flex gap-3 pb-4">
                                <div style={{ flex: "1" }}>
                                  {!isSaveLoading ? (
                                    <Button
                                      className="primarybtn btn w-100 py-2 h-100  px-2 input-main-all text-nowrap"
                                      type="submit"
                                    >
                                      Save and Confirm Address
                                    </Button>
                                  ) : (
                                    <Button className="primarybtn btn w-100 h-100 py-2 px-2">
                                      <Spinner animation="border" size="sm" />
                                    </Button>
                                  )}
                                </div>
                                <div
                                  style={{ flex: "2" }}
                                  className="save-collection"
                                >
                                  <Button
                                    variant="link"
                                    className="primary_btn_link input-main-all "
                                    onClick={handleResetFormCollection}
                                    type="reset"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                    <div style={{ flex: "0" }}></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordianCollection;
