/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Card, Row, Col, Container, Form } from "react-bootstrap";
import "../../assets/styles/addressBook/pricing.css";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { formatPrice } from "../../utils/formatPrice";

const PricingDetails = ({ address_details }) => {
  const newdata = useSelector((state) => state?.servicedata.ShippingService?.data);
  const isServiceLoading = useSelector(
    (state) => state?.servicedata.isServiceLoading
  );
  const [pricingDetail, setPricingDetail] = useState();

  useEffect(() => {
    if (address_details) {
      setPricingDetail(address_details?.cart_details);
    }
    if (newdata) {
      setPricingDetail(newdata?.cart_details);
    }
  }, [newdata, address_details]);
  return (
    <>
      <div className="d-flex d-none d-lg-block">
        <div className="me-3">
          <p className="collectoinh5 blue-text">
            <span>Price & Delivery Details</span>
          </p>
        </div>
        <div className="hrlinedivdelivery">
          <hr className="hrlinedelivery" />
        </div>
      </div>
      <div className="price-col py-2">
        <>
          <div className="price-holder text-center">
            {!isServiceLoading ? (
              <Card className="pricing-card-outer p-2 border-0 box-shadow">
                <Card.Img
                  src={pricingDetail?.cardlogo}
                  alt="Card Image"
                  style={{ maxWidth: "91px", maxHeight: "125px" }}
                  className="mx-auto mt-4"
                />
                <Card.Body>
                  <ul className="list-group list-group-flush pricing-card">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 usps-li">
                      <h5 className="mx-auto custom-h5 mb-0">
                        {pricingDetail?.courier_service_name}
                      </h5>
                    </li>{" "}
                    <hr className="price-hr" />
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center border-0 my-2  mx-auto collect-li">
                      <p>
                        <span>Estimated Delivery by:</span>{" "}
                        {pricingDetail?.delivery_date}{" "}
                        {pricingDetail?.date ? `, ${pricingDetail?.date}` : ""}
                      </p>
                    </li> */}
                    {pricingDetail?.delivery_date && (
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 my-2  mx-auto collect-li">
                        <p>
                          {/* <span>Estimated Delivery by:</span>{" "}
                          {pricingDetail?.delivery_date}{" "}
                          {pricingDetail?.date ? `, ${pricingDetail?.date}` : ""} */}
                        </p>
                        <p><b>
                          Estimated Delivery by: Up to {pricingDetail?.delivery_date} Days
                        </b></p>
                      </li>
                    )}
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                      <div>
                        <p className="mb-0">Sub Total</p>
                      </div>
                      <span>
                        <p className="mb-0">${pricingDetail?.subtotal}</p>
                      </span>
                    </li>
                    {pricingDetail?.discount_amount > 0 ? (
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                        <div>
                          <p className="mb-0">Discount </p>
                        </div>
                        <span className="px-2">
                          <div
                            style={{ color: "#113468" }}
                            className="discount-label"
                          >
                            <span>
                              {pricingDetail?.discount_percentage}&nbsp;%
                            </span>
                          </div>
                        </span>
                        <div className=" d-flex justify-content-end insurance ">
                          <span>
                            <p className="mb-0">
                              <del>

                                &nbsp;$ {pricingDetail?.discount_amount}
                              </del>
                            </p>
                          </span>
                        </div>
                      </li>
                    ) : (
                      <p></p>
                    )}
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 ">
                      <div>
                        <p className="mb-0">Tax </p>
                      </div>
                      <span>
                        <p className="mb-0 ">
                          <b>{formatPrice("0.00")}</b>
                        </p>
                      </span>
                    </li>
                    {pricingDetail?.collection_charge > 0 && (
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 ">
                        <div>
                          <p className="mb-0">Pickup Rate</p>
                        </div>
                        <span>
                          <p className="mb-0">
                            ${pricingDetail?.collection_charge}
                          </p>
                        </span>
                      </li>
                    )}
                    {pricingDetail?.is_enhanced_cover === "Yes" && (
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 ">
                        <div>
                          <p className="mb-0">Insurance&nbsp;&nbsp;</p>
                        </div>
                        <span>
                          <p className="mb-0">
                            {pricingDetail?.insurance_cost === "0.00" || !pricingDetail?.insurance_cost
                              ? "Free"
                              : formatPrice(pricingDetail?.insurance_cost)}
                            {/* `$${pricingDetail?.insurance_cost}`} */}
                          </p>
                        </span>
                      </li>
                    )}
                    <li className=" list-group-item border-0 px-0 mb-3">
                      <hr className="doted-hr mt-4 mb-4" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div className="total-price">
                        <span>
                          <p>Total</p>
                        </span>
                      </div>
                      <div className="total-price">
                        <span>
                          <p>${Number(pricingDetail?.total).toFixed(2)}</p>
                        </span>

                      </div>
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            ) : (
              <Card className="service-card-holder w-100">
                <div className="d-flex gap-1 py-2 flex-column ">
                  <div className="mx-2 py-2 mb-1 d-flex justify-content-center ">
                    <Skeleton
                      variant="rounded"
                      height={100}
                      width={150}
                      className="shipping-logo-img-div"
                    />
                  </div>
                  <div className="d-flex mx-5 px-5">
                    <Skeleton variant="text" className="w-100" />
                  </div>
                  <div className="d-flex ">
                    <Skeleton height={5} variant="text" className="w-100 " />
                  </div>
                  <div className="d-flex mx-5 px-5">
                    <Skeleton variant="text" className="w-100" />
                  </div>
                  <div className="mx-3">
                    <Row className="d-flex justify-content-between gap-3">
                      <Col className="mb-1 me-1">
                        <Skeleton variant="text" />
                      </Col>
                      <Col className="ms-5">
                        <Skeleton variant="text" />
                      </Col>
                    </Row>
                  </div>
                  <div className="mx-3">
                    <Row className="d-flex justify-content-between gap-3">
                      <Col className="mb-1 me-1">
                        <Skeleton variant="text" />
                      </Col>
                      <Col className="ms-5">
                        <Skeleton variant="text" />
                      </Col>
                    </Row>
                  </div>
                  <div className="mx-3">
                    <Row className="d-flex justify-content-between gap-3">
                      <Col className="mb-1 me-1">
                        <Skeleton variant="text" />
                      </Col>
                      <Col className="ms-5">
                        <Skeleton variant="text" />
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex  py-1">
                    <Skeleton height={5} variant="text" className="w-100 " />
                  </div>
                  <div className="mx-3">
                    <Row className="d-flex justify-content-between gap-3">
                      <Col className="mb-1 me-1">
                        <Skeleton variant="text" />
                      </Col>
                      <Col className="ms-5">
                        <Skeleton variant="text" />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default PricingDetails;
