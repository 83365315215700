import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const CreateCharge = createAsyncThunk(
  "Payment/CreateCharge",
  async (charge, { rejectWithValue }) => {
    try {
      const endpoint = charge.store ? charge.store : charge.default ? charge.default : charge.delete;
      const response = await axiosInstance.post(`/customer-card/${endpoint}`, charge);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);

export const orderConfirm = createAsyncThunk(
  "Payment/orderConfirm",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/confirm-order`, data);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);

export const couponCode = createAsyncThunk(
  "Payment/couponCode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/apply-promo-code`, data);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);

export const placeOrder = createAsyncThunk(
  "Payment/placeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/order/checkout`, data);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      return rejectWithValue(errors.response ? errors.response.data : errors);
    }
  }
);