import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Paper, Skeleton } from '@mui/material';
import { Col, Dropdown, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import CustomAutocomplete from '../../../components/common/CustomCountryPostal';
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountryDetails, fetchPostalDetails, fetchStateName } from '../../../redux/landing/landingThunk';
import { getParcels, getcart } from '../../../redux/user_profile/ProfileThunk';
import { fetchAddressList } from '../../../redux/address/adressThunk';
import { bulkOrdersEdit, bulkOrdersList } from '../../../redux/services/ServicesThunk';
import SwalFireComponent from '../../../components/common/SwalFireComponent';
import { resetErrors } from '../../../redux/address/addressSlice';

const BulkOrderPreviewModal = ({ bulkData, setShowModalService, setShowModal, userData }) => {
    const dispatch = useDispatch();
    const useriD = JSON.parse(localStorage.getItem("user"));
    const uniquesessionid = localStorage.getItem("uniquesessionid");

    const parcel_details = useSelector(
        (state) => state?.profileData?.parcelData?.Parcel?.data
    );

    const addressList = useSelector(
        (state) => state.addressValidation.addressList?.CustomerAddress?.data
    );

    const isPostalLoading = useSelector(
        (state) => state.landingReducer.isPostalLoading
    );

    const addressErrors = useSelector(
        (state) => state?.addressValidation?.profileFieldError
    );

    const state_name = useSelector(
        (state) => state?.landingReducer?.stateName?.data
    );

    const country_Name = useSelector(
        (state) => state.landingReducer?.countryDeatils?.data
    );

    const country_Postal = useSelector(
        (state) => state.landingReducer?.countryPostal?.data
    );

    const isbulkorderEditLoading = useSelector(
        (state) => state?.servicedata?.isbulkorderEditLoading
    );

    const [searchQuery, setSearchQuery] = useState("");
    const [filterdAddress, setFilterAddress] = useState("");
    const [selectData, setSelectData] = useState();
    const [parcelData, setParcelData] = useState();
    const [parcelError, setparcelError] = useState();
    const [countryData, setCountryData] = useState();
    const [selectedOption, setSelectedOption] = useState("Home");
    const [formErrors, setFormErrors] = useState({
        country: "",
        postcode: "",
    });

    const [formData, setFormData] = useState({
        country: '',
        postcode: '',
        first_name: '',
        last_name: '',
        company_name: '',
        address_1: '',
        address_2: '',
        state: '',
        city: '',
        email: '',
        contact_no_prefix: '',
        contact_number: '',
        select: 'Home',
        unit_value: '',
        weight: '',
        weight_in: '',
        length: '',
        width: '',
        height: '',
        size_in: '',
        parcel_value: '',
        parcel_description: '',
        country_id: ''
    });

    const initialValues = {
        country: formData?.country || '',
        postcode: formData?.postcode || '',
        first_name: formData?.first_name || '',
        last_name: formData?.last_name || '',
        company_name: formData?.company_name || '',
        address_1: formData?.address_1 || '',
        address_2: formData?.address_2 || '',
        state: formData?.state || '',
        city: formData?.city || '',
        email: formData?.email || '',
        contact_no_prefix: formData?.contact_no_prefix || '',
        contact_number: formData?.contact_number || '',
        select: formData?.select || 'Home',
        unit_value: formData?.unit_value || '',
        weight: formData?.weight || '',
        weight_in: formData?.weight_in || '',
        length: formData?.length || '',
        width: formData?.width || '',
        height: formData?.height || '',
        size_in: formData?.size_in || '',
        parcel_value: formData?.parcel_value || '',
        parcel_description: formData?.parcel_description || '',
        country_id: formData?.country?.id || ''
    }

    const validationSchema = Yup.object({
        country: Yup.string().required('required'),
        postcode: Yup.string().required('required'),
        first_name: Yup.string().required('required'),
        last_name: Yup.string().required('required'),
        // company_name: Yup.string().required('required'),
        address_1: Yup.string().required('required'),
        // address_2: Yup.string().required('required'),
        state: Yup.string().required('required'),
        city: Yup.string().required('required'),
        contact_no_prefix: Yup.string().required('required'),
        contact_number: Yup.string().required('required'),
        // contact_number: Yup.string()
        //     .matches(/^\d{10}$/, "Mobile number must be 10 digits")
        //     .required("This field is required"),
        // email: Yup.string()
        //     .email("The email must be a valid email address.")
        //     .required("This field is required"),
        select: Yup.string().required('required'),
        unit_value: Yup.string().required('required'),
        weight: Yup.string().required('required'),
        weight_in: Yup.string().required('required'),
        length: Yup.string().required('required'),
        width: Yup.string().required('required'),
        height: Yup.string().required('required'),
        size_in: Yup.string().required('required'),
        parcel_value: Yup.string().required('required'),
        parcel_description: Yup.string().required('required'),
    });

    useEffect(() => {
        if (formData?.country_id) {
            const stateName = countryData?.id || formData?.country_id;
            dispatch(fetchStateName(stateName));
        }
    }, [formData?.country_id, countryData, dispatch]);

    useEffect(() => {
        if (bulkData) {
            // const stateName = bulkData?.country_id
            // dispatch(fetchStateName(stateName));
            let statedata = state_name?.find((i) => {
                return bulkData?.state === i?.code
            })
            setFormData({
                ...formData,
                country: bulkData?.country?.name || '',
                postcode: bulkData.postcode || '',
                first_name: bulkData.first_name || '',
                last_name: bulkData.last_name || '',
                company_name: bulkData.company_name || '',
                address_1: bulkData.address_1 || '',
                address_2: bulkData.address_2 || '',
                country_id: bulkData?.country_id || '',
                state: statedata ? statedata?.code : bulkData?.state,
                city: bulkData.city || '',
                email: bulkData.email || '',
                contact_no_prefix: bulkData.contact_no_prefix || '',
                contact_number: bulkData.contact_no || '',
                select: bulkData.select || 'Home',
                unit_value: "01",
                weight: bulkData.parcel_weight || '',
                weight_in: bulkData.parcel_weight_measure || 'LBS',
                length: bulkData.parcel_length || '',
                width: bulkData.parcel_width || '',
                height: bulkData.parcel_height || '',
                size_in: bulkData.parcel_dimension_in || "IN",
                parcel_value: bulkData.value_of_items || '',
                parcel_description: bulkData.parcel_description || '',
                id: bulkData?.id,
                commercial_invoice: bulkData?.commercial_invoice,
            });
        }
    }, [bulkData]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        dispatch(fetchCountryDetails());
        dispatch(getParcels({ customer_id: useriD?.id }));
        dispatch(fetchAddressList({ customer_id: useriD?.id, uniquesessionid: uniquesessionid, }));
    }, [dispatch]);

    useEffect(() => {
        if (parcel_details && parcel_details.length > 0) {
            const select = {
                parcel_name: "Select",
            };
            const updatedParcelDetails = [select, ...parcel_details];
            setSelectData(updatedParcelDetails);
        }
        if (addressList) {
            setFilterAddress(addressList)
        }
    }, [parcel_details, addressList]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredAddresses = (filterdAddress || []).filter((address) => {
        const fullAddress = `${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${address.state}`.toLowerCase();
        return fullAddress.includes(searchQuery.toLowerCase());
    });

    const handleDropdownSelect = (address) => {
        setFormData({
            ...formData,
            country: address?.country?.name,
            country_id: address?.country?.id,
            postcode: address?.postcode,
            first_name: address?.first_name,
            last_name: address?.last_name,
            company_name: address?.company_name,
            address_1: address?.address_1,
            address_2: address?.address_2,
            state: address?.state,
            city: address?.city,
            contactcontrycode: address?.contact_no_prefix,
            contact_no_prefix: address?.contact_no_prefix,
            email: address?.email,
            contact_number: address?.contact_no,
            addressType: address?.address_type,
            address_id: address?.id,
        });
        setCountryData(address?.country)
    };

    const handleParcelSelect = (selectedParcelIndex) => {
        const selectedParcel = selectData[selectedParcelIndex];
        setParcelData(selectedParcel)
        setFormData({
            ...formData,
            unit_value: selectedParcel.unit_value || "1",
            weight: selectedParcel?.parcel_weight,
            length: selectedParcel?.parcel_length,
            width: selectedParcel?.parcel_width,
            height: selectedParcel?.parcel_height,
            size_in: selectedParcel?.parcel_dimension_in,
            weight_in: selectedParcel?.parcel_weight_measure,
            parcel_value: selectedParcel?.value_of_items,
            parcel_description: selectedParcel?.parcel_description,
        });
    };

    const handleCountry = (setFieldValue, newValue) => {
        setFieldValue("postcode", "");
        setFieldValue("country", newValue);
        let dataforcountry;
        if ((country_Name, newValue)) {
            dataforcountry = country_Name?.find((cntr) => {
                return cntr?.name === newValue;
            })
            setCountryData(dataforcountry)
        }
        setFormData({
            ...formData,
            country: dataforcountry?.name,
            countryid: dataforcountry?.id,
            address_1: '',
            address_2: '',
            state: '',
            city: '',
        })

    };

    const handlepostal = (setFieldValue, newInputValue) => {
        setFieldValue("postcode", newInputValue);
        dispatch(
            fetchPostalDetails({
                country_id: countryData?.id || bulkData?.country_id,
                search_pincode: newInputValue,
                uniquesessionid: uniquesessionid,
            })
        );
        // dispatch(fetchStateName(countryData?.id || bulkData?.country_id));
        // if (country_Postal !== undefined && country_Postal !== null) {
        // const stateName = country_Postal.find((postal) => postal?.state_name);

        // setFieldValue("state", stateName?.state_name);
        // setFieldValue("city", stateName?.city_name);
        // }
    };

    const handleSelectChange = (event) => {
        const selectedState = event.target.value;
        setFormData(prevFormData => ({
            ...prevFormData,
            state: selectedState,
        }));
    };

    const handleSave = (values) => {
        const countryid = country_Name?.find(
            (cntr) => cntr?.name.toLowerCase() === values?.country.toLowerCase()
        );
        const data = {
            country: countryid?.name || formData?.country || '',
            country_id: countryid?.id || formData?.country_id || '',
            postcode: values?.postcode || '',
            first_name: values?.first_name || '',
            last_name: values?.last_name || '',
            company_name: values?.company_name || '',
            address_1: values?.address_1 || '',
            address_2: values?.address_2 || '',
            state: values?.state || '',
            city: values?.city || '',
            email: values?.email || '',
            contact_no_prefix: values?.contact_no_prefix || '',
            contact_number: values?.contact_number || '',
            select: values?.select || 'Home',
            unit_value: values?.unit_value || '',
            weight: values?.weight || '',
            weight_in: values?.weight_in || '',
            length: values?.length || '',
            width: values?.width || '',
            height: values?.height || '',
            size_in: values?.size_in || '',
            parcel_value: values?.parcel_value || '',
            parcel_description: values?.parcel_description || '',
            id: formData?.id,
            commercial_invoice: formData?.commercial_invoice,
        }
        dispatch(bulkOrdersEdit({ data }))
            .unwrap()
            .then((response) => {
                if (response?.BulkOrder.statusCode === 200 && response?.BulkOrder.status === "success") {
                    SwalFireComponent({ icon: "success", title: `Bulk data item edited successfully` });
                    setShowModal(false)
                    setShowModalService(true)
                    setShowModalService(false)
                    dispatch(bulkOrdersList({ customer_id: useriD?.id, }))
                    let data = {
                        // cart_id: Number(cartId),
                        customer_id: userData?.id,
                    };
                    dispatch(getcart(data));
                }
                if (response?.BulkOrder?.code === '404' && response?.BulkOrder?.status === "fail") {
                    setparcelError(response?.BulkOrder?.errors)
                }
            });
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    return (
        <>
            <div className=''>
                <Formik enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSave}
                    validationSchema={validationSchema}>
                    {({ values, setValues, handleChange, handleBlur, handleSubmit, touched, errors, setFieldValue }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <div className='d-flex flex-column gap-3'>
                                    <div className='bulk-parcel d-flex gap-2 flex-column'>
                                        <div className="d-flex w-100 d-flex align-items-center">
                                            <div className="me-4">
                                                <p className="collectoinh5 blue-text" style={{ width: "max-content" }} >
                                                    <span>Parcel Details</span>
                                                </p>
                                            </div>
                                            <div className="w-100">
                                                <hr className="w-100" style={{ border: "2px solid #bababa" }} />
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className=" d-flex flex-column gap-3">
                                                <div className='d-flex flex-column flex-lg-row gap-3 '>
                                                    <div style={{ flex: "2" }}  >
                                                        <Form.Group className="w-100" >
                                                            <Form.Label className="paragraph2 p-multihead mt-0 mt-xl-4 mt-xl-0">
                                                                Parcels
                                                            </Form.Label>
                                                            <Form.Select value={selectData?.parcel_name} className={`wallet-input is-valid-paper height-44 ${parcelData?.parcel_name ? "testclass" : "pickup-placeholder-select"}`}
                                                                onChange={(e) => handleParcelSelect(e.target.value)}  >
                                                                {selectData ? (
                                                                    selectData.map((options, index) => (
                                                                        <option key={index} value={index} disabled={index === 0} selected={index === 0} >
                                                                            {options?.parcel_name}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option disabled>
                                                                        No Parcel Saved...
                                                                    </option>
                                                                )}
                                                            </Form.Select>
                                                        </Form.Group >
                                                    </div>
                                                    <div className='d-flex gap-3' style={{ flex: "3" }}  >
                                                        {/* <Form.Group className="w-100 bulkmodel-qu" style={{ flex: '1' }}>
                                                            <Form.Label className="paragraph2 p-multihead mt-0 mt-xl-4 mt-xl-0">
                                                                Quantity
                                                            </Form.Label>
                                                            <FormControl className={`form-control multiship-fields height-44  ${errors?.unit_value && touched?.unit_value ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                type="number" name="unit_value" placeholder="01" value={values?.unit_value} onChange={(e) => {
                                                                    const newValue = e.target.value.slice(0, 3); handleChange({ target: { name: 'unit_value', value: newValue } });
                                                                }} />
                                                            {errors?.unit_value && touched?.unit_value && (
                                                                <div className="text-danger">{errors?.unit_value}</div>
                                                            )}
                                                        </Form.Group> */}
                                                        <Form.Group className="bulkmodel-weight" style={{ flex: '1' }} >
                                                            <Form.Label className="paragraph2 p-multihead mt-0 mt-xl-4 mt-xl-0">
                                                                Weight
                                                            </Form.Label>
                                                            <div className="d-flex flex-column position-relative height-44">
                                                                <FormControl className={`w-100 multiship-fields height-44  ${errors?.weight && touched?.weight ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                    type="number" name="weight" placeholder="240" value={values?.weight} onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        const [integerPart, decimalPart] = inputValue.split(".");
                                                                        const limitedIntegerPart = integerPart.slice(0, 2);
                                                                        const limitedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : "";
                                                                        const limitedValue = limitedDecimalPart ? `${limitedIntegerPart}.${limitedDecimalPart}` : limitedIntegerPart;
                                                                        handleChange({ target: { name: 'weight', value: limitedValue } });
                                                                    }} />
                                                                {errors?.weight && touched?.weight && (
                                                                    <div className="text-danger">{errors?.weight}</div>
                                                                )}
                                                                <Form.Select
                                                                    className="bg-transparent form-control border-0 position-absolute w-auto ps-3 multiship-fields multiship-select-in-lbs height-44"
                                                                    size="sm"
                                                                    name="weight_in" value={values?.weight_in} >
                                                                    <option value="LBS">LBS</option>
                                                                    <option value="KG">KG</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="w-100 bulkmodel-value" style={{ flex: '1' }}>
                                                            <Form.Label className="paragraph2 p-multihead mt-0 mt-xl-4 mt-xl-0">
                                                                Value
                                                            </Form.Label>
                                                            <FormControl className={`form-control multiship-fields height-44 ${errors?.parcel_value && touched?.parcel_value ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                type="number" name="parcel_value" placeholder="01" value={values?.parcel_value} onChange={handleChange} />
                                                            {errors?.parcel_value && touched?.parcel_value && (
                                                                <div className="text-danger">{errors?.parcel_value}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                                <div className='d-flex flex-column flex-xl-row gap-3'>
                                                    <div className="" style={{ flex: '2' }}>
                                                        <Form.Label className="paragraph2 p-multihead mt-0 mt-xl-4 mt-xl-0">
                                                            Size
                                                        </Form.Label>
                                                        <Form.Group className="size-formgroup-multiship height-44">
                                                            <div className="d-flex gap-3">
                                                                <div className="d-flex" style={{ flex: '4' }}>
                                                                    <div className="d-flex" style={{ flex: '1' }}>
                                                                        <div className="w-100">
                                                                            <FormControl className={`multiship-fields height-44  ${errors?.length && touched?.length ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                                type="number" name="length" placeholder="length" value={values?.length} onChange={(e) => {
                                                                                    const newValue = e.target.value.slice(0, 3); handleChange({ target: { name: 'length', value: newValue } });
                                                                                }} />
                                                                            {errors?.length && touched?.length && (
                                                                                <div className="text-danger">{errors?.length}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <CloseIcon style={{ color: '#bababa', paddingLeft: '0px !important', paddingRight: '0px !important', height: '20px !important', width: '20px', marginTop: '14px' }} />
                                                                    </div>
                                                                    <div className="d-flex" style={{ flex: '1' }}>
                                                                        <div className="w-100">
                                                                            <FormControl className={`multiship-fields height-44 ${errors?.width && touched?.width ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                                type="number" name="width" placeholder="width" value={values?.width} onChange={(e) => {
                                                                                    const newValue = e.target.value.slice(0, 3); handleChange({ target: { name: 'width', value: newValue } });
                                                                                }} />
                                                                            {errors?.width && touched?.width && (
                                                                                <div className="text-danger">{errors?.width}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <CloseIcon style={{ color: '#bababa', paddingLeft: '0px !important', paddingRight: '0px !important', height: '20px !important', width: '20px', marginTop: '14px' }} />
                                                                    </div>
                                                                    <div className="d-flex flex-column" style={{ flex: '1' }}>
                                                                        <div className="w-100">
                                                                            <FormControl className={`multiship-fields height-44  ${errors?.height && touched?.height ? "is-invalid-paper" : "is-valid-paper"} `}
                                                                                type="number" name="height" placeholder="height" value={values?.height} onChange={(e) => {
                                                                                    const newValue = e.target.value.slice(0, 3); handleChange({ target: { name: 'height', value: newValue } });
                                                                                }} />
                                                                            {errors?.height && touched?.height && (
                                                                                <div className="text-danger">{errors?.height}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="h-100 ms-3" style={{ flex: '1' }}>
                                                                        <Form.Select className="multiship-fields multiship-select-in-lbh is-valid-paper height-44"
                                                                            size="sm" name="size_in" value={values?.size_in} onChange={handleChange} >
                                                                            <option value="IN">IN</option>
                                                                            <option value="CM">CM</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div style={{ flex: '2' }}>
                                                        <Form.Group className="w-100" style={{ flex: '1' }}>
                                                            <Form.Label className="paragraph2 p-multihead mt-0 mt-xl-4 mt-xl-0">
                                                                Parcel Description
                                                            </Form.Label>
                                                            <FormControl className={`form-control multiship-fields height-44  ${errors?.parcel_description && touched?.parcel_description ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                type="text" name="parcel_description" placeholder="Enter details.." value={values?.parcel_description} onChange={handleChange} />
                                                            {errors?.parcel_description && touched?.parcel_description && (
                                                                <div className="text-danger">{errors?.parcel_description}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/* address component start */}
                                    <div className='bulk-address d-flex flex-column gap-0 gap-md-2'>
                                        {/* <div className="d-flex w-100 d-flex align-items-center">
                                            <div className="me-4">
                                                <p className="collectoinh5 blue-text" style={{ width: "max-content" }} >
                                                    <span>Address Details</span>
                                                </p>
                                            </div>
                                            <div className="w-100">
                                                <hr className="w-100" style={{ border: "2px solid #bababa" }} />
                                            </div>
                                        </div>
                                        <div className='addressbook-outer-holder'>
                                            <div className="address-bg box-shadow is-valid-paper mb-3 height-44 " style={{ border: "1px solid #e9e9e9" }}>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" className="d-flex dropdown-toggle p-0 w-100  border-0"  >
                                                        <div className='d-flex w-100 px-2'>
                                                            <div className='text-start' style={{ flex: "5" }}>
                                                                <p className="mb-0 select-input">
                                                                    Select From Saved Address
                                                                </p>
                                                            </div>
                                                            <div className="dropdown-icon text-end d-flex align-items-center justify-content-end" style={{ flex: "1" }}>
                                                                <img src={`${window.location.origin}/assets/dropdownicon.svg`} alt="Dropdown" />
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-collection">
                                                        <div className="p-3">
                                                            <Form.Control type="text" placeholder="Search address" value={searchQuery} onChange={handleSearchChange} className="is-valid-paper" />
                                                        </div>

                                                        {filteredAddresses?.length === 0 ? (
                                                            <Dropdown.Item>No Address Found...</Dropdown.Item>
                                                        ) : (
                                                            filteredAddresses?.map((address, index) => (
                                                                <>
s                                                                    <Dropdown.Item className="address-dropdown-item" key={index} onClick={() => handleDropdownSelect(address)}  >
                                                                        {`${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${address.state}`}
                                                                    </Dropdown.Item>
                                                                </>
                                                            )))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0 gap-xxl-0">
                                                <Col md="6" lg="5">
                                                    <Paper className={`my-paper-UserAddress ${errors?.country && touched?.country ? "is-invalid-paper" : "is-valid-paper"}`} style={{ borderRadius: "6px" }}>
                                                        <CustomAutocomplete
                                                            options={country_Name?.map(
                                                                (data) => data.name
                                                            )}
                                                            value={formData?.country}
                                                            onChange={(event, newValue) => {
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    country: ""
                                                                });
                                                                handleCountry(setFieldValue, newValue);
                                                            }}
                                                            placeholder={"Country"}
                                                        />
                                                    </Paper>
                                                    {errors?.country && touched?.country && (
                                                        <div className="text-danger">{errors?.country}</div>
                                                    )}
                                                </Col>
                                                <Col md="4" lg="3">
                                                    <Paper className={`my-paper-UserAddress ${(errors?.postcode && touched?.postcode) || formErrors?.postcode ? "is-invalid-paper" : "is-valid-paper"}`} style={{ borderRadius: "6px" }}>
                                                        <CustomAutocomplete
                                                            placeholder={"Postal"}
                                                            name="postcode"
                                                            value={values?.postcode}
                                                            options={country_Postal && country_Postal?.map((pincodes) => pincodes?.pincode_no)}
                                                            onInputChange={(event, newInputValue) => {
                                                                handlepostal(setFieldValue, newInputValue);
                                                                if (newInputValue) {
                                                                    setFormErrors((prevErrors) => ({ ...prevErrors, postcode: "", }));
                                                                } else {
                                                                    setFormErrors((prevErrors) => ({
                                                                        ...prevErrors,
                                                                        postcode: "This field is required",
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </Paper>
                                                    {((errors?.postcode && touched?.postcode) || formErrors?.postcode) && (
                                                        <div className="text-danger">{errors?.postcode}</div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                                                <Col md="4">
                                                    <Form.Group>
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.first_name && touched?.first_name ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text"
                                                            name="first_name"
                                                            placeholder="First name"
                                                            value={values?.first_name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.first_name && touched?.first_name && (
                                                            <div className="text-danger">{errors?.first_name}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="4">
                                                    <Form.Group>
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.last_name && touched?.last_name ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text"
                                                            name="last_name"
                                                            placeholder="Last name"
                                                            value={values?.last_name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.last_name && touched?.last_name && (
                                                            <div className="text-danger">{errors?.last_name}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="4">
                                                    <Form.Group>
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress is-valid-paper ${errors?.company_name && touched?.company_name ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text"
                                                            name="company_name"
                                                            placeholder="Company Name"
                                                            value={values?.company_name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.company_name && touched?.company_name && (
                                                            <div className="text-danger">{errors?.company_name}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Form.Group>
                                                <Row className="mb-3 gap-3 gap-lg-0">
                                                    <Col md="12">
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress is-valid-paper ${errors?.address_1 && touched?.address_1 ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text"
                                                            name="address_1"
                                                            placeholder="Address01"
                                                            value={values?.address_1}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.address_1 && touched?.address_1 && (
                                                            <div className="text-danger">{errors?.address_1}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group>
                                                <Row className="mb-3 gap-3 gap-lg-0">
                                                    <Col md="12">
                                                        <Form.Control className="user-address-placeholder input-main-all is-valid-paper my-paper-UserAddress"
                                                            type="text"
                                                            name="address_2"
                                                            placeholder="Address02"
                                                            value={values.address_2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                                                <Col md="6" className="collect-form-address">
                                                    <Form.Group>
                                                        <Form.Control className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.state && touched?.state ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text" name="state" placeholder="State" value={values?.state}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.state && touched?.state && (
                                                            <div className="text-danger">{errors?.state}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.city && touched?.city ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text" name="city" placeholder="Enter City"
                                                            value={values?.city} onChange={handleChange}
                                                        />
                                                        {errors?.city && touched?.city && (
                                                            <div className="text-danger">{errors?.city}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.email && touched?.email ? "is-invalid-paper" : "is-valid-paper"}`}
                                                            type="text" name="email" placeholder="Enter Email" value={values?.email} onChange={handleChange}
                                                        />
                                                        {errors?.email && touched?.email && (
                                                            <div className="text-danger">{errors?.email}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col md={3} xs={3} className="pe-0">
                                                            <Form.Control
                                                                className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.contact_no_prefix && touched?.contact_no_prefix ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                type="number" name="contact_no_prefix" placeholder="+91" value={values?.contact_no_prefix} onChange={handleChange}
                                                            />
                                                            {errors?.contact_no_prefix && touched?.contact_no_prefix && (
                                                                <div className="text-danger">{errors?.contact_no_prefix}</div>
                                                            )}
                                                        </Col>
                                                        <Col md={9} xs={9}>
                                                            <Form.Control
                                                                className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors?.contact_number && touched?.contact_number ? "is-invalid-paper" : "is-valid-paper"}`}
                                                                type="number" name="contact_number" placeholder="contact No" value={values?.contact_number} onChange={handleChange}
                                                            />
                                                            {errors?.contact_number && touched?.contact_number && (
                                                                <div className="text-danger">{errors?.contact_number}</div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <div className="radiobtnAddress mb-3 text-nowrap d-flex flex-row align-content-center  gap-2  gap-lg-3 gap-sm-2">
                                                <p className="me-1">Select address type : </p>
                                                <div className="radio-holderaddress custom_radio d-flex align-content-center gap-2 flex-row text-nowrap">
                                                    <div className=" homeRadio d-flex flex-row gap-1 p-1">
                                                        <input className="chek" type="radio" id="Home" name="select" value="Home" checked={values?.select === 'Home'} onChange={handleChange} />
                                                        <label htmlFor="Home">Home</label>
                                                    </div>
                                                    <div className="d-flex flex-row gap-1 p-1 ">
                                                        <input type="radio" id="Work" name="select" value="Work" checked={values?.select === 'Work'} onChange={handleChange} />
                                                        <label htmlFor="Work">Work</label>
                                                    </div>
                                                </div>
                                                {errors?.select && touched?.select && (
                                                    <div className="text-danger">{errors?.select}</div>
                                                )}
                                            </div>

                                            <div className="d-flex gap-2 justify-content-end">
                                                <Button className="blue-text height-50 primarybtn btn user-save-address text-nowrap" type="submit" >
                                                    {isbulkorderEditLoading ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            Save and Select Address
                                                        </>
                                                    )}
                                                </Button>
                                            </div>
                                        </div> */}
                                        <div className="d-flex w-100 d-flex align-items-center">
                                            <div className="me-4">
                                                <p className="collectoinh5 blue-text" style={{ width: "max-content" }} >
                                                    <span>Address Details</span>
                                                </p>
                                            </div>
                                            <div className="w-100">
                                                <hr className="w-100" style={{ border: "2px solid #bababa" }} />
                                            </div>
                                        </div>
                                        <div className='addressbook-outer-holder'>
                                            <div className="address-bg box-shadow is-valid-paper mb-3 height-44 " style={{ border: "1px solid #e9e9e9" }}>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" className="d-flex dropdown-toggle p-0 w-100  border-0"  >
                                                        <div className='d-flex w-100 px-2'>
                                                            <div className='text-start' style={{ flex: "5" }}>
                                                                <p className="mb-0 select-input">
                                                                    Select From Saved Address
                                                                </p>
                                                            </div>
                                                            <div className="dropdown-icon text-end d-flex align-items-center justify-content-end" style={{ flex: "1" }}>
                                                                <img src={`${window.location.origin}/assets/dropdownicon.svg`} alt="Dropdown" />
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-collection">
                                                        <div className="p-3">
                                                            <Form.Control type="text" placeholder="Search address" value={searchQuery} onChange={handleSearchChange} className="is-valid-paper" />
                                                        </div>

                                                        {filteredAddresses?.length === 0 ? (
                                                            <Dropdown.Item>No Address Found...</Dropdown.Item>
                                                        ) : (
                                                            filteredAddresses?.map((address, index) => (
                                                                <>
                                                                    <Dropdown.Item className="address-dropdown-item" key={index} onClick={() => handleDropdownSelect(address)}  >
                                                                        {`${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${address.state}`}
                                                                    </Dropdown.Item>
                                                                </>
                                                            )))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0 gap-xxl-0">
                                            <Col md="6 " lg="5">
                                                <Paper
                                                    className={`my-paper-UserAddress ${(errors.country &&
                                                        touched.country && errors.country)
                                                        || formErrors?.country
                                                        ? "is-invalid-paper"
                                                        : "is-valid-paper"
                                                        }`}
                                                    style={{ borderRadius: "6px" }}
                                                >
                                                    <CustomAutocomplete
                                                        options={country_Name?.map(
                                                            (data) => data.name
                                                        )}
                                                        value={values.country}
                                                        onChange={(event, newValue) => {
                                                            dispatch(resetErrors(""));
                                                            setFormErrors({
                                                                ...formErrors,
                                                                country: ""
                                                            });
                                                            handleCountry(setFieldValue, newValue);
                                                            setFieldValue("postcode", "");
                                                        }}
                                                        placeholder={"Country"}
                                                    />
                                                </Paper>
                                                {errors.country && (
                                                    <Form.Text className="text-danger mt-0">
                                                        {errors.country &&
                                                            touched.country &&
                                                            errors.country}
                                                    </Form.Text>
                                                )}
                                                {/* {formErrors?.country && (
                                  <Form.Text className="text-danger mt-0">
                                    {formErrors.country}
                                  </Form.Text>
                                )} */}
                                            </Col>
                                            <Col md="4" lg="3">
                                                <Paper
                                                    className={`my-paper-UserAddress ${(errors.postal &&
                                                        touched.postal &&
                                                        errors.postal)
                                                        // || formErrors?.postcode
                                                        ? "is-invalid-paper"
                                                        : "is-valid-paper"
                                                        }`}
                                                    style={{ borderRadius: "6px" }}
                                                >
                                                    <CustomAutocomplete
                                                        value={values.postcode}
                                                        loading={isPostalLoading}
                                                        placeholder={"Postal"}
                                                        options={
                                                            country_Postal &&
                                                            country_Postal?.map(
                                                                (pincodes) => pincodes?.pincode_no
                                                            )
                                                        }
                                                        onInputChange={(event, newInputValue) => {
                                                            handlepostal(
                                                                setFieldValue,
                                                                newInputValue
                                                            );
                                                            if (newInputValue) {
                                                                setFormErrors((prevErrors) => ({
                                                                    ...prevErrors,
                                                                    postcode: "",
                                                                }));
                                                            } else {
                                                                setFormErrors((prevErrors) => ({
                                                                    ...prevErrors,
                                                                    postcode: "This field is required",
                                                                }));
                                                            }
                                                        }}

                                                        isInvalid={
                                                            errors.postal &&
                                                            touched.postal &&
                                                            errors.postal
                                                        }
                                                    />
                                                </Paper>
                                                {errors?.postal && (
                                                    <Form.Text
                                                        className="text-danger mt-0"
                                                        type="invalid"
                                                    >
                                                        {errors.postal &&
                                                            touched.postal &&
                                                            errors.postal}
                                                    </Form.Text>
                                                )}
                                                {/* {formErrors?.postcode && (
                                  <Form.Text className="text-danger mt-0">
                                    {formErrors.postcode}
                                  </Form.Text>
                                )} */}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                                            <Col md="4">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        name="first_name"
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.first_name &&
                                                            touched.first_name &&
                                                            errors.first_name
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        value={values.first_name}
                                                        placeholder="First name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            errors.first_name &&
                                                            touched.first_name &&
                                                            errors.first_name
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <span>
                                                            {errors.first_name &&
                                                                touched.first_name &&
                                                                errors.first_name}
                                                        </span>
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <Form.Control
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.last_name &&
                                                            touched.last_name &&
                                                            errors.last_name
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        type="text"
                                                        name="last_name"
                                                        value={values.last_name}
                                                        placeholder="Last name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            errors.last_name &&
                                                            touched.last_name &&
                                                            errors.last_name
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <span>
                                                            {errors.last_name &&
                                                                touched.last_name &&
                                                                errors.last_name}
                                                        </span>
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <Form.Control
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.company_name &&
                                                            touched.company_name &&
                                                            errors.company_name
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        type="text"
                                                        name="company_name"
                                                        value={values.company_name}
                                                        placeholder="Company Name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group>
                                            <Row className="mb-3 gap-3 gap-lg-0">
                                                <Col md="12">
                                                    <Form.Control
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.address_1 &&
                                                            touched.address_1 &&
                                                            errors.address_1
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        type="text"
                                                        name="address_1"
                                                        value={values.address_1}
                                                        placeholder="Address01"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            errors.address_1 &&
                                                            touched.address_1 &&
                                                            errors.address_1
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <span>
                                                            {errors.address_1 &&
                                                                touched.address_1 &&
                                                                errors.address_1}
                                                        </span>
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group>
                                            <Row className="mb-3 gap-3 gap-lg-0">
                                                <Col md="12">
                                                    <Form.Control
                                                        className="user-address-placeholder input-main-all is-valid-paper my-paper-UserAddress"
                                                        type="text"
                                                        name="address_2"
                                                        value={values.address_2}
                                                        placeholder="Address02"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                                            <Col md="6" className="collect-form-address">
                                                {!state_name && <Form.Group>
                                                    <Form.Control
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.state &&
                                                            touched.state &&
                                                            errors.state
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        type="text"
                                                        name="state"
                                                        placeholder="State"
                                                        value={values.state}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.state && touched.state}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <span>
                                                            {errors.state &&
                                                                touched.state &&
                                                                errors.state}
                                                        </span>
                                                    </Form.Control.Feedback>
                                                </Form.Group>}
                                                {state_name && <Form.Group controlId="formGridfirstname">

                                                    <Form.Select aria-label="Default select example"
                                                        placeholder='Select' onBlur={handleBlur}
                                                        className={`Pickup-select-input shadow-none  ${errors.state && touched.state && errors.state ? "is-invalid-paper" : "is-valid-paper" && formData?.state === "Select" && "pickup-placeholder-select"}`} onChange={handleSelectChange} value={formData.state}
                                                        isInvalid={
                                                            errors.state &&
                                                            touched.state &&
                                                            errors.state
                                                        }>
                                                        {state_name?.map((option, index) => {
                                                            return (
                                                                <option key={index} value={option?.code} >
                                                                    {option?.default_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                    {errors?.state && (
                                                        <Form.Text className="text-danger mt-0" type="invalid">
                                                            {errors.state &&
                                                                touched.state &&
                                                                errors.state}
                                                        </Form.Text>
                                                    )}
                                                </Form.Group>}
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <Form.Control
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.city && touched.city && errors.city
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        type="text"
                                                        name="city"
                                                        placeholder="Enter City"
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            errors.city && touched.city && errors.city
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <span>
                                                            {errors.city &&
                                                                touched.city &&
                                                                errors.city}
                                                        </span>
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Control
                                                        className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.email &&
                                                            touched.email &&
                                                            errors.email
                                                            ? "is-invalid-paper"
                                                            : "is-valid-paper"
                                                            }`}
                                                        type="text"
                                                        name="email"
                                                        value={values.email}
                                                        placeholder="Enter Email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            errors.email &&
                                                            touched.email &&
                                                            errors.email
                                                        }
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        <span>
                                                            {errors.email &&
                                                                touched.email &&
                                                                errors.email}
                                                        </span>
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={3} xs={3} className="pe-0">
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.contact_no_prefix &&
                                                                touched.contact_no_prefix &&
                                                                errors.contact_no_prefix
                                                                ? "is-invalid-paper"
                                                                : "is-valid-paper"
                                                                }`}
                                                            type="number"
                                                            name="contact_no_prefix"
                                                            value={values.contact_no_prefix}
                                                            placeholder="+91"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={
                                                                errors.contact_no_prefix &&
                                                                touched.contact_no_prefix &&
                                                                errors.contact_no_prefix
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            <span>
                                                                {errors.contact_no_prefix &&
                                                                    touched.contact_no_prefix &&
                                                                    errors.contact_no_prefix}
                                                            </span>
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={9} xs={9}>
                                                        <Form.Control
                                                            className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.contact_number &&
                                                                touched.contact_number &&
                                                                errors.contact_number
                                                                ? "is-invalid-paper"
                                                                : "is-valid-paperz"
                                                                }`}
                                                            type="number"
                                                            placeholder="contact No"
                                                            name="contact_number"
                                                            value={values.contact_number}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={
                                                                errors.contact_number &&
                                                                touched.contact_number &&
                                                                errors.contact_number
                                                            }
                                                        ></Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            <span>
                                                                {errors.contact_number &&
                                                                    touched.contact_number &&
                                                                    errors.contact_number}
                                                            </span>
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <p className="profile_address-error">
                                                    {addressErrors?.phone_number}
                                                </p>
                                            </Col>
                                        </Row>

                                        <div className="radiobtnAddress mb-3 text-nowrap d-flex flex-row align-content-center  gap-2  gap-lg-3 gap-sm-2">
                                            <p className="me-1">Select address type : </p>
                                            <div className="radio-holderaddress custom_radio d-flex align-content-center gap-2 flex-row text-nowrap">
                                                <div className=" homeRadio d-flex flex-row gap-1 p-1">
                                                    <input
                                                        className="chek"
                                                        type="radio"
                                                        id="Home"
                                                        name="select"
                                                        value="Home"
                                                        checked={selectedOption === "Home"}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <label for="Home">Home</label>
                                                </div>
                                                <div className="d-flex flex-row gap-1 p-1 ">
                                                    <input
                                                        type="radio"
                                                        id="Work"
                                                        name="select"
                                                        value="Work"
                                                        checked={selectedOption === "Work"}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <label for="Work">Work</label>
                                                </div>
                                            </div>
                                        </div>
                                        {parcelError && <>
                                            {
                                                parcelError[0]?.map((i, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className='text-danger d-flex flex-column'>
                                                                <p>
                                                                    {i}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )

                                                })
                                            }
                                            {/* <p>{parcelError}</p> */}
                                        </>}
                                        <div className="d-flex gap-2 justify-content-end">
                                            <Button className="blue-text height-50 primarybtn btn text-nowrap" type="submit" >
                                                {isbulkorderEditLoading ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" />
                                                    </>
                                                ) : (
                                                    <>
                                                        Save and Select Address
                                                    </>
                                                )}
                                            </Button>
                                        </div>

                                    </div>
                                    {/* address component end */}
                                </div>
                            </Form >
                        </>
                    )}
                </Formik>
            </div>

        </>
    );
}

export default BulkOrderPreviewModal;
