import React, { useEffect, useState } from "react";
import { Card, Dropdown, Form, Spinner } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { IconButton, Skeleton, Tooltip, Button } from "@mui/material";
import "../../assets/styles/userProfile/userParcel.css";
import { useDispatch, useSelector } from "react-redux";
import {
    customerParcel,
    getParcels,
} from "../../redux/user_profile/ProfileThunk";
import Swal from "sweetalert2";
import { resetErrors } from "../../redux/user_profile/ProfileSlice";
import UserSkeleton from "./UserSkeleton";
import CustomAlert from "../common/CustomAlert";
import SwalFireComponent from "../common/SwalFireComponent";
const UserParcels = () => {
    const dispatch = useDispatch();
    const parcel_details = useSelector(
        (state) => state?.profileData?.parcelData?.Parcel?.data
    );
    const isParcelLoading = useSelector(
        (state) => state.profileData.isParcelLoading
    );
    const isParcelCrudApi = useSelector(
        (state) => state.profileData.isParcelCrudApi
    );
    const userData = JSON.parse(localStorage.getItem("user"));
    const [editIndex, setEditIndex] = useState(null);
    const [loader, setloader] = useState({ delete: false, add: false });
    const [showParcels, setShowParcels] = useState(true);
    const [deleteId, setDeleteID] = useState(null);
    const weight_options = ["LBS", "KG"];
    const size_option = ["IN", "CM"];

    const initialValues = {
        shipments: [
            {
                parcel_description: "",
                parcel_weight: "",
                parcel_length: "",
                parcel_width: "",
                parcel_height: "",
                parcel_dimension_in: "IN",
                parcel_weight_measure: "LBS",
                value_of_items: "",
                parcel_name: "",
            },
        ],
    };

    const validationSchema = Yup.object().shape({
        shipments: Yup.array().of(
            Yup.object().shape({
                parcel_description: Yup.string().required("Required"),
                parcel_weight: Yup.number().required("Required"),
                parcel_length: Yup.string().required("Required"),
                parcel_width: Yup.number().required("Required"),
                parcel_height: Yup.number().required("Required"),
                value_of_items: Yup.number().required("Required"),
                parcel_name: Yup.string().required("Required"),
            })
        ),
    });
    const getParcelsData = () => {
        dispatch(getParcels({ customer_id: userData?.id }));
    };
    useEffect(() => {
        getParcelsData();
    }, []);

    const submit = (values, { resetForm }) => {
        setloader({ add: true });
        if (editIndex !== null) {
            setEditIndex(null);
        }
        const data = {
            customer_id: userData?.id,
            parcel_weight: values?.shipments[0].parcel_weight,
            parcel_weight_measure: values?.shipments[0].parcel_weight_measure,
            parcel_width: values?.shipments[0].parcel_width,
            parcel_length: values?.shipments[0].parcel_length,
            parcel_dimension_in: values?.shipments[0].parcel_dimension_in,
            parcel_height: values?.shipments[0].parcel_height,
            parcel_description: values?.shipments[0].parcel_description,
            parcel_name: values?.shipments[0].parcel_name,
            value_of_items: values?.shipments[0].value_of_items,
            parcel_id: values?.shipments[0].id ? values?.shipments[0].id : "",
        };
        dispatch(
            customerParcel({
                data,
                store: `${values?.shipments[0].id ? "update" : "store"}`,
            })
        )
            .unwrap()
            .then((response) => {
                setloader({ add: false });
                setShowParcels(true);
                resetForm();
                if (
                    response.Parcel.code === "200" &&
                    response.Parcel.status === "success"
                ) {
                    SwalFireComponent({
                        icon: "success",
                        title: `${values?.shipments[0].id
                            ? "Parcel details updated successfully"
                            : "Parcel details saved successfully"
                            }`,
                    });
                    getParcelsData();
                }
            });
    };

    const handleEdit = (index, setValues) => {
        setShowParcels(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setEditIndex(index);
        const parcelToEdit = parcel_details[index];
        setValues({ shipments: [parcelToEdit] });
    };

    const handleDelete = (shipment, shipmentIndex) => {
        setDeleteID(shipment?.id);
        const parcelToDelete = parcel_details[shipmentIndex]?.id;
        const data = {
            customer_id: userData?.id,
            parcel_id: parcelToDelete,
        };
        setloader({ delete: true });
        dispatch(customerParcel({ data, store: "delete" }))
            .unwrap()
            .then((response) => {
                setloader({ delete: false });

                if (
                    response.Parcel.code === "200" &&
                    response.Parcel.status === "success"
                ) {
                    SwalFireComponent({
                        icon: "success",
                        title: `${shipment.parcel_name} deleted successfully`,
                    });
                    getParcelsData();
                }
            });
    };

    const handleCancel = (setValues) => {
        setShowParcels(true);
        setValues({
            shipments: [
                {
                    parcel_description: "",
                    parcel_weight: "",
                    parcel_length: "",
                    parcel_width: "",
                    parcel_height: "",
                    parcel_dimension_in: "IN",
                    parcel_weight_measure: "LBS",
                    value_of_items: "",
                    parcel_name: "",
                },
            ],
        });
    };

    return (
        <>
            <div className="userParcel-main" style={{ width: "100%" }}>
                <Card className="px-4 py-4 ps-4 userParcel-card" border="0">
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={submit}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            setValues,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            touched,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="d-flex flex-column gap-3">
                                    <div className=" d-flex gap-2">
                                        <h5 className="personal-text m-0">Parcels</h5>
                                        {!showParcels && (
                                            <>
                                                <Button
                                                    variant="link"
                                                    className="primary_btn_link btn reset-btn text-danger py-0"
                                                    onClick={() => handleCancel(setValues)}
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {values?.shipments?.map((shipment, index) => (
                                            <>
                                                <div className="d-flex flex-column gap-3 gap-lg-4 userParcel-head ">
                                                    <div
                                                        className="d-flex flex-column flex-sm-column flex-md-row gap-3 gap-lg-4"
                                                        style={{ flex: "2" }}
                                                    >
                                                        <div
                                                            className="parcel_name-user "
                                                            style={{ flex: "2" }}
                                                        >
                                                            <Form.Group>
                                                                <Form.Label className="paragraph2 p-multihead">
                                                                    Enter Name
                                                                </Form.Label>
                                                                <div className="d-flex position-relative">
                                                                    <Field
                                                                        type="text"
                                                                        name={`shipments.${index}.parcel_name`}
                                                                        placeholder="Enter shipment name"
                                                                        className={`form-control w-100 multiship-fields ${errors.shipments &&
                                                                            errors.shipments[index] &&
                                                                            errors.shipments[index].parcel_name &&
                                                                            touched.shipments &&
                                                                            touched.shipments[index] &&
                                                                            touched.shipments[index].parcel_name
                                                                            ? "is-invalid-paper"
                                                                            : "is-valid-paper"
                                                                            }`}
                                                                        value={shipment.parcel_name}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            const limitedValue = inputValue.slice(
                                                                                0,
                                                                                20
                                                                            );
                                                                            handleChange({
                                                                                target: {
                                                                                    name: `shipments.${index}.parcel_name`,
                                                                                    value: limitedValue,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <ErrorMessage
                                                                    name={`shipments.${index}.parcel_name`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                                {errors?.shipments?.parcel_name &&
                                                                    touched?.shipments?.parcel_name &&
                                                                    errors?.shipments?.parcel_name}
                                                            </Form.Group>
                                                        </div>
                                                        <div
                                                            className="parcel_description-user"
                                                            style={{ flex: "4" }}
                                                        >
                                                            <Form.Group>
                                                                <Form.Label className="paragraph2 p-multihead">
                                                                    Enter Description
                                                                </Form.Label>
                                                                <div className="d-flex position-relative">
                                                                    <Field
                                                                        type="text"
                                                                        name={`shipments.${index}.parcel_description`}
                                                                        placeholder="Enter Description"
                                                                        className={`form-control w-100 multiship-fields ${errors.shipments &&
                                                                            errors.shipments[index] &&
                                                                            errors.shipments[index]
                                                                                .parcel_description &&
                                                                            touched.shipments &&
                                                                            touched.shipments[index] &&
                                                                            touched.shipments[index]
                                                                                .parcel_description
                                                                            ? "is-invalid-paper"
                                                                            : "is-valid-paper"
                                                                            }`}
                                                                        value={shipment.parcel_description}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            const limitedValue = inputValue.slice(
                                                                                0,
                                                                                35
                                                                            );
                                                                            handleChange({
                                                                                target: {
                                                                                    name: `shipments.${index}.parcel_description`,
                                                                                    value: limitedValue,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <ErrorMessage
                                                                    name={`shipments.${index}.parcel_description`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                                {errors?.shipments?.parcel_description &&
                                                                    touched?.shipments?.parcel_description &&
                                                                    errors?.shipments?.parcel_description}
                                                            </Form.Group>
                                                        </div>
                                                        <div
                                                            className="parcel_value-user "
                                                            style={{ flex: "1" }}
                                                        >
                                                            <Form.Group>
                                                                <Form.Label className="paragraph2 p-multihead text-nowrap ">
                                                                    Enter Value
                                                                </Form.Label>
                                                                <div className="d-flex position-relative">
                                                                    <Field
                                                                        type="number"
                                                                        name={`shipments.${index}.value_of_items`}
                                                                        placeholder=" Enter Value"
                                                                        className={`form-control w-100 multiship-fields ${errors.shipments &&
                                                                            errors.shipments[index] &&
                                                                            errors.shipments[index].value_of_items &&
                                                                            touched.shipments &&
                                                                            touched.shipments[index] &&
                                                                            touched.shipments[index].value_of_items
                                                                            ? "is-invalid-paper"
                                                                            : "is-valid-paper"
                                                                            }`}
                                                                        value={shipment.value_of_items}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            const limitedValue = inputValue.slice(
                                                                                0,
                                                                                5
                                                                            );
                                                                            handleChange({
                                                                                target: {
                                                                                    name: `shipments.${index}.value_of_items`,
                                                                                    value: limitedValue,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <ErrorMessage
                                                                    name={`shipments.${index}.value_of_items`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                                {errors?.shipments?.value_of_items &&
                                                                    touched?.shipments?.value_of_items &&
                                                                    errors?.shipments?.value_of_items}
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-row  gap-3 gap-lg-4 "
                                                        style={{ flex: "1" }}
                                                    >
                                                        <div style={{ flex: "3" }}>
                                                            <div className="size " style={{ flex: "3" }}>
                                                                <Form.Group
                                                                    key={index}
                                                                    className="size-formgroup-multiship"
                                                                >
                                                                    <Form.Label className="paragraph2 p-multihead">
                                                                        Size
                                                                    </Form.Label>
                                                                    <div className="d-flex gap-2">
                                                                        <div
                                                                            className="d-flex sizeinput"
                                                                            style={{ flex: "4" }}
                                                                        >
                                                                            <div
                                                                                className="d-flex"
                                                                                style={{ flex: "1" }}
                                                                            >
                                                                                <div className="w-100">
                                                                                    <Field
                                                                                        type="number"
                                                                                        name={`shipments.${index}.parcel_length`}
                                                                                        placeholder="length"
                                                                                        className={`form-control sizeinput-multi multiship-fields ${errors.shipments &&
                                                                                            errors.shipments[index] &&
                                                                                            errors.shipments[index]
                                                                                                .parcel_length &&
                                                                                            touched.shipments &&
                                                                                            touched.shipments[index] &&
                                                                                            touched.shipments[index]
                                                                                                .parcel_length
                                                                                            ? "is-invalid-paper"
                                                                                            : "is-valid-paper"
                                                                                            }`}
                                                                                        value={shipment.parcel_length}
                                                                                        onChange={(e) => {
                                                                                            const inputValue = e.target.value;
                                                                                            const limitedValue =
                                                                                                inputValue.slice(0, 3);
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: `shipments.${index}.parcel_length`,
                                                                                                    value: limitedValue,
                                                                                                },
                                                                                            });
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                    />

                                                                                    <ErrorMessage
                                                                                        name={`shipments.${index}.parcel_length`}
                                                                                        component="div"
                                                                                        className="text-danger"
                                                                                    />
                                                                                    {errors?.shipments?.parcel_length &&
                                                                                        touched?.shipments?.parcel_length &&
                                                                                        errors?.shipments?.parcel_length}
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                                <CloseIcon
                                                                                    style={{
                                                                                        color: "#bababa",
                                                                                        paddingLeft: "0px !important",
                                                                                        paddingRight: "0px !important",
                                                                                        height: "20px !important",
                                                                                        width: "20px",
                                                                                        marginTop: "14px",
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div
                                                                                className="d-flex"
                                                                                style={{ flex: "1" }}
                                                                            >
                                                                                <div className="w-100">
                                                                                    <Field
                                                                                        type="number"
                                                                                        name={`shipments.${index}.parcel_width`}
                                                                                        placeholder="width"
                                                                                        className={`form-control  sizeinput-multi multiship-fields ${errors.shipments &&
                                                                                            errors.shipments[index] &&
                                                                                            errors.shipments[index]
                                                                                                .parcel_width &&
                                                                                            touched.shipments &&
                                                                                            touched.shipments[index] &&
                                                                                            touched.shipments[index]
                                                                                                .parcel_width
                                                                                            ? "is-invalid-paper"
                                                                                            : "is-valid-paper"
                                                                                            }`}
                                                                                        value={shipment.parcel_width}
                                                                                        onChange={(e) => {
                                                                                            const inputValue = e.target.value;
                                                                                            const limitedValue =
                                                                                                inputValue.slice(0, 3);
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: `shipments.${index}.parcel_width`,
                                                                                                    value: limitedValue,
                                                                                                },
                                                                                            });
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`shipments.${index}.parcel_width`}
                                                                                        component="div"
                                                                                        className="text-danger"
                                                                                    />
                                                                                    {errors?.shipments?.parcel_width &&
                                                                                        touched?.shipments?.parcel_width &&
                                                                                        errors?.shipments?.parcel_width}
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                                <CloseIcon
                                                                                    style={{
                                                                                        color: "#bababa",
                                                                                        paddingLeft: "0px !important",
                                                                                        paddingRight: "0px !important",
                                                                                        height: "20px !important",
                                                                                        width: "20px",
                                                                                        marginTop: "14px",
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div
                                                                                className="d-flex flex-column"
                                                                                style={{ flex: "1" }}
                                                                            >
                                                                                <div className="w-100">
                                                                                    <Field
                                                                                        type="number"
                                                                                        name={`shipments.${index}.parcel_height`}
                                                                                        placeholder="height"
                                                                                        className={`form-control sizeinput-multi multiship-fields  ${errors.shipments &&
                                                                                            errors.shipments[index] &&
                                                                                            errors.shipments[index]
                                                                                                .parcel_height &&
                                                                                            touched.shipments &&
                                                                                            touched.shipments[index] &&
                                                                                            touched.shipments[index]
                                                                                                .parcel_height
                                                                                            ? "is-invalid-paper"
                                                                                            : "is-valid-paper"
                                                                                            }`}
                                                                                        value={shipment.parcel_height}
                                                                                        onChange={(e) => {
                                                                                            const inputValue = e.target.value;
                                                                                            const limitedValue =
                                                                                                inputValue.slice(0, 3);
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: `shipments.${index}.parcel_height`,
                                                                                                    value: limitedValue,
                                                                                                },
                                                                                            });
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`shipments.${index}.parcel_height`}
                                                                                        component="div"
                                                                                        className="text-danger"
                                                                                    />
                                                                                    {errors?.shipments?.parcel_height &&
                                                                                        touched?.shipments?.parcel_height &&
                                                                                        errors?.shipments?.parcel_height}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div style={{ flex: "1" }}>
                                                                            <div
                                                                                className="h-100 "
                                                                                style={{ flex: "1" }}
                                                                            >
                                                                                <Form.Select
                                                                                    className="multiship-fields user-parcel-select"
                                                                                    style={{ borderRadius: "6px" }}
                                                                                    size="sm"
                                                                                    name={`shipments.${index}.parcel_dimension_in`}
                                                                                    value={shipment.parcel_dimension_in}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                >
                                                                                    {size_option?.map((option, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option key={index}>
                                                                                                    {option}
                                                                                                </option>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </Form.Select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="d-flex  gap-3 gap-lg-4"
                                                            style={{ flex: "2" }}
                                                        >
                                                            <div className="Weight " style={{ flex: "1" }}>
                                                                <Form.Group>
                                                                    <Form.Label className="paragraph2 p-multihead">
                                                                        Weight
                                                                    </Form.Label>
                                                                    <div className="d-flex position-relative">
                                                                        <Field
                                                                            type="number"
                                                                            name={`shipments.${index}.parcel_weight`}
                                                                            placeholder="E.g. 240"
                                                                            className={`form-control w-100 multiship-fields ${errors.shipments &&
                                                                                errors.shipments[index] &&
                                                                                errors.shipments[index].parcel_weight &&
                                                                                touched.shipments &&
                                                                                touched.shipments[index] &&
                                                                                touched.shipments[index].parcel_weight
                                                                                ? "is-invalid-paper"
                                                                                : "is-valid-paper"
                                                                                }`}
                                                                            onChange={(e) => {
                                                                                const inputValue = e.target.value;
                                                                                const [integerPart, decimalPart] =
                                                                                    inputValue.split(".");
                                                                                const limitedIntegerPart =
                                                                                    integerPart.slice(0, 2);
                                                                                const limitedDecimalPart = decimalPart
                                                                                    ? decimalPart.slice(0, 2)
                                                                                    : "";
                                                                                const limitedValue = limitedDecimalPart
                                                                                    ? `${limitedIntegerPart}.${limitedDecimalPart}`
                                                                                    : limitedIntegerPart;
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: `shipments.${index}.parcel_weight`,
                                                                                        value: limitedValue,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />

                                                                        <Form.Select
                                                                            onChange={handleChange}
                                                                            value={shipment.parcel_weight_measure}
                                                                            className="bg-transparent form-control border-0 position-absolute w-auto ps-0 multiship-fields multiship-select-in-lbs"
                                                                            size="sm"
                                                                            name={`shipments.${index}.parcel_weight_measure`}
                                                                        >
                                                                            {weight_options?.map((option, index) => {
                                                                                return (
                                                                                    <option key={index}>{option}</option>
                                                                                );
                                                                            })}
                                                                        </Form.Select>
                                                                    </div>
                                                                    <ErrorMessage
                                                                        name={`shipments.${index}.parcel_weight`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                    {errors?.shipments?.parcel_weight &&
                                                                        touched?.shipments?.parcel_weight &&
                                                                        errors?.shipments?.parcel_weight}
                                                                </Form.Group>
                                                            </div>
                                                            <div
                                                                className="button  w-100"
                                                                style={{ flex: "1" }}
                                                            >
                                                                <Form.Group>
                                                                    <Form.Label className="paragraph2 p-multihead w-100">
                                                                        &nbsp;
                                                                    </Form.Label>
                                                                    <Button
                                                                        className="secondry btn px-auto multiship-input text-nowrap multiship-fields add-shipment-multi-btn multiship-fields"
                                                                        type="submit"
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        {loader?.add && isParcelCrudApi ? (
                                                                            <>
                                                                                <Spinner animation="border" size="sm" />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {editIndex !== null && !showParcels
                                                                                    ? "Update Shipment"
                                                                                    : "+ Add Shipment"}
                                                                            </>
                                                                        )}
                                                                    </Button>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>

                                    <p className="paragraph2 p-multihead">
                                        <span className="ps-1">
                                            Use this feature to save shipment details for later. It
                                            will help you to choose directly from the list while
                                            shipping parcels.
                                        </span>
                                    </p>
                                    <div className="d-flex flex-wrap gap-1 ">
                                        {isParcelLoading ? (
                                            <>
                                                <UserSkeleton
                                                    width="100%"
                                                    twoLine={true}
                                                    tworound={true}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {parcel_details?.length && showParcels ? (
                                                    <>
                                                        <div className="w-100  ">
                                                            <div className="d-flex flex-wrap gap-4 flex-column flex-sm-column flex-md-row  w-100">
                                                                {parcel_details &&
                                                                    parcel_details?.map(
                                                                        (shipment, shipmentIndex) => (
                                                                            <>
                                                                                <div
                                                                                    className="mapped-cards-userPaecels"
                                                                                    key={shipmentIndex}
                                                                                    style={{ flex: "0 0 50%" }}
                                                                                >
                                                                                    <Card className="card-border mb-3 ">
                                                                                        <Card.Body>
                                                                                            <div className="d-flex w-100 justify-content-between ">
                                                                                                <div className="default-address-label mb-2 text-center userParcel-label  ">
                                                                                                    <span>
                                                                                                        {shipment.parcel_name}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <Dropdown
                                                                                                    className="top-dropdown text-end drpdwn-header"
                                                                                                    style={{ padding: "0px" }}
                                                                                                >
                                                                                                    {deleteId === shipment?.id &&
                                                                                                        loader?.delete &&
                                                                                                        isParcelCrudApi ? (
                                                                                                        <>
                                                                                                            <div className="d-flex justify-content-end">
                                                                                                                <Spinner
                                                                                                                    animation="border"
                                                                                                                    size="sm"
                                                                                                                />
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <Dropdown.Toggle
                                                                                                                variant="light"
                                                                                                                id="dropdown-basic"
                                                                                                                className="user-custom-toggle p-0"
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={`${window.location.origin}/assets/user-profile/editdots.svg`}
                                                                                                                    alt="Dropdown"
                                                                                                                />
                                                                                                            </Dropdown.Toggle>
                                                                                                        </>
                                                                                                    )}
                                                                                                    <Dropdown.Menu>
                                                                                                        <Dropdown.Item
                                                                                                            onClick={() =>
                                                                                                                handleEdit(
                                                                                                                    shipmentIndex,
                                                                                                                    setValues
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            Edit
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item
                                                                                                            onClick={() =>
                                                                                                                handleDelete(
                                                                                                                    shipment,
                                                                                                                    shipmentIndex
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            Delete
                                                                                                        </Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>

                                                                                                {/* <div className="d-flex gap-3 align-items-start  ">
                                                                                                    <div className="" style={{ flex: "1" }}>
                                                                                                        <Tooltip title="Edit" className="pointer-icon"    >
                                                                                                            <IconButton className="p-0" onClick={() => handleEdit(shipmentIndex, setValues)}>
                                                                                                                <>
                                                                                                                    <img className="pointer-icon" alt="icon" src={`${window.location.origin}/assets/address-icons/edit.svg`} />
                                                                                                                </>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </div>
                                                                                                    <div className="" style={{ flex: "1" }}>
                                                                                                        <Tooltip title="Delete" className="pointer-icon"    >
                                                                                                            <IconButton className="p-0 w-100 " onClick={() => deleteId === shipment?.id && loader?.delete && isParcelCrudApi ? null : handleDelete(shipment, shipmentIndex)} >
                                                                                                                {deleteId === shipment?.id && loader?.delete && isParcelCrudApi ? (
                                                                                                                    <>
                                                                                                                        <Spinner animation="border" className="userParcel-spinner" size="sm" />
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <img className="pointer-icon" alt="icon" src={`${window.location.origin}/assets/address-icons/delete.svg`} />
                                                                                                                    </>

                                                                                                                )}
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </div>
                                                                                                </div> */}
                                                                                            </div>
                                                                                            <Card.Text>
                                                                                                <div>
                                                                                                    <p className="order-confirm-p-2 paragraph2">
                                                                                                        <span className="order-confirm-p-head paragraph2">
                                                                                                            Description:
                                                                                                        </span>
                                                                                                        <span className="ps-1">
                                                                                                            {
                                                                                                                shipment?.parcel_description
                                                                                                            }
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p className="order-confirm-p-2 paragraph2">
                                                                                                        <span className="order-confirm-p-head paragraph2">
                                                                                                            Parcel Value:
                                                                                                        </span>
                                                                                                        <span className="ps-1">
                                                                                                            {shipment?.value_of_items}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-flex flex-column flex-sm-row flex-md-column  flex-lg-column flex-xl-column flex-xxl-row  gap-2 w-100 order-confirm-w"
                                                                                                    style={{ flex: "0" }}
                                                                                                >
                                                                                                    <div className="d-flex flex-sm-row gap-5 gap-sm-2  gap-lg-2">
                                                                                                        <div className="width-max">
                                                                                                            <p className="order-confirm-p-2 paragraph2">
                                                                                                                <span className="order-confirm-p-head paragraph2">
                                                                                                                    Weight:
                                                                                                                </span>
                                                                                                                <span className="ps-1">
                                                                                                                    {
                                                                                                                        shipment.parcel_weight
                                                                                                                    }{" "}
                                                                                                                    {
                                                                                                                        shipment.parcel_weight_measure
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="width-max d-none d-sm-flex d-md-none d-xxl-flex ">
                                                                                                            |
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="d-flex gap-2">
                                                                                                        <div className="width-max">
                                                                                                            <p className="order-confirm-p-2 paragraph2">
                                                                                                                <span className="order-confirm-p-head paragraph2">
                                                                                                                    Length:
                                                                                                                </span>
                                                                                                                <span className="ps-1">
                                                                                                                    {
                                                                                                                        shipment.parcel_length
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="width-max">
                                                                                                            x
                                                                                                        </div>
                                                                                                        <div className="width-max">
                                                                                                            <p className="order-confirm-p-2 paragraph2">
                                                                                                                <span className="order-confirm-p-head paragraph2">
                                                                                                                    Width:
                                                                                                                </span>
                                                                                                                <span className="ps-1">
                                                                                                                    {
                                                                                                                        shipment.parcel_width
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="width-max">
                                                                                                            x
                                                                                                        </div>
                                                                                                        <div className="width-max">
                                                                                                            <p className="order-confirm-p-2 paragraph2">
                                                                                                                <span className="order-confirm-p-head paragraph2">
                                                                                                                    Height:
                                                                                                                </span>
                                                                                                                <span className="ps-1">
                                                                                                                    {
                                                                                                                        shipment.parcel_height
                                                                                                                    }{" "}
                                                                                                                    {
                                                                                                                        shipment.parcel_dimension_in
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Card.Text>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {showParcels && (
                                                            <>
                                                                <Card className="col-lg-4 col-md-12">
                                                                    <Card.Body>
                                                                        <Card.Text>No Parcel Saved..</Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </div>
        </>
    );
};

export default UserParcels;
