import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import AccordianCollection from "./AccordianCollection";
import AccordianDelivery from "./AccordianDelivery";
import AccordianShipmentDetails from "./AccordianShipmentDetails";
import "../../assets/styles/addressBook/addressbook.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddressList,
  fetchUserCardDetails,
} from "../../redux/address/adressThunk";
import SelectDateTime from "../addressBook/SelectDateTime";
import OrderStripePayment from "../../pages/afterlogin/OrderStripePayment";
import { useNavigate } from "react-router";
import AccordianCustomDetails from "./AccordianCustomDetails";
import { fetchShippingServices, fetchpickupdropdata } from "../../redux/services/ServicesThunk";
import { Box, Button, Skeleton } from "@mui/material";
import CustomAlert from "../common/CustomAlert";
import SwalFireComponent from "../common/SwalFireComponent";
import { fetchStateName } from "../../redux/landing/landingThunk";

const AccordianCollectionDelivery = ({
  shipContent,
  setShipContent,
  setFormShipData,
  formShipData,
  formData,
  setFormData,
  formDataDelivery,
  setFormDataDelivery,
  isCollectionValidate,
  setIsCollectionValidate,
  isDeliveryValidate,
  setIsDeliveryValidate,
  selectedRadio,
  setSelectedRadio,
  selectedOption,
  setSelectedOption,
  selectedOptionDel,
  setSelectedOptionDel,
  collectionEventChange,
  setCollectionEventChange,
  deliveryEventChange,
  setDeliveryEventChange,
  collectionDates,
  setCollectionDates,
  dropupDates,
  setDropupDates,
  collectionValues,
  setCollectionValues,
  dropDates,
  selectedCollectionService,
  setSelectedCollectionService,
  valueArray,
  contentArray,
  setFinalGoLoader,
  finalGoLoader
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart_id = localStorage.getItem("cart_id");
  const cartdetails_id = localStorage.getItem("cartdetail_id");
  // const pickUpDatalocal = JSON.parse(localStorage.getItem("pickupDates"));
  // const dropOffDatalocal = JSON.parse(localStorage.getItem("dropOffDates"));

  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const Address_Details = useSelector(
    (state) => state?.servicedata.ShippingService?.data
  );
  const addressList = useSelector(
    (state) => state.addressValidation.addressList?.CustomerAddress?.data
  );
  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );
  const pickup_loader = useSelector((state) => state?.servicedata.isServiceLoading);

  const ShipmentError = useSelector(
    (state) => state?.servicedata?.insuranceError?.insuranceerror
  );
  const isPickupLoading = useSelector((state) => state?.servicedata?.isPickupLoading);

  const pickupdata = useSelector(
    (state) => state?.servicedata?.ShippingService?.data?.pickup_date
  );
  const dropoffdata = useSelector(
    (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location
  );
  const pickupdatanew = useSelector(
    (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.pickup_date
  );
  const dropoffdatanew = useSelector(
    (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location?.date
  );
  const dropdowndata = [
    "Sale or Purchase",
    "Gift",
    "Sample",
    "Repair/Return",
    "Documents",
    "Intracompany Transfer",
    "Temporary Export",
    "Personal Effect ",
  ];

  const cityerror = useSelector((state) => state?.servicedata?.error);
  const serviceDatanew = JSON.parse(localStorage.getItem("serviceData"));
  const [isCollectionFormOpen, setIsCollectionFormOpen] = useState(false);
  const [isDeliveryFormOpen, setIsFormDeliveryOpen] = useState(false);
  const [country_details, setCountry_details] = useState();
  const [service_Data, setservice_Data] = useState();
  const [user_id, setUser] = useState();
  const [activeKey, setActiveKey] = useState("0")
  const [pickupLabels, setPickupLabels] = useState();
  const [dropOffLabels, setDropOffLabels] = useState();
  const [selectOpen, setSelectOpen] = useState(false);
  const [pickupValidation, setPickupValidation] = useState(false);
  const [newError, setNewError] = useState();
  const [shipDetailsError, setShipDetailsError] = useState();
  const [fromSteteData, setFromStateData] = useState();
  const [toSteteData, setToStateData] = useState();

  const [savedDataCollection, setSavedDataCollection] = useState({
    firstName: "",
    lastName: "",
    address01: "",
    address02: "",
    city: "",
    state: "",
    Postcode: "",
  });
  const [savedDataDelivery, setSavedDataDelivery] = useState({
    firstName: "",
    lastName: "",
    address01: "",
    address02: "",
    city: "",
    state: "",
    Postcode: "",
  });
  const [showChangeButton, setShowChangeButton] = useState({
    changecollection: false,
    changedelivery: false,
    changeshipment: false,
    changepayment: false,
  });

  const [validations, setValidations] = useState({
    collection_validation: "",
    collection_form: "",
  });

  const [deliveryValidations, setDeliveryValidations] = useState({
    delivery_validation: "",
    delivery_form: "",
  });

  const excludedEmptyFieldsCollection = [
    "address02",
    "contactNo",
    "address_id",
    "companyName",
  ];
  const isCollectionValid = Object.entries(formData).every(([key, value]) => {
    return excludedEmptyFieldsCollection.includes(key) || value !== "";
  });

  const [vatdata, setVatdata] = useState({
    countryInput: "Canada",
    Reason: dropdowndata[0],
  });

  useEffect(() => {
    if (selectOpen) {
      dispatch(
        fetchpickupdropdata({
          // is_pickup_rate: "Yes",
          customer_id: user_id?.id,
          cart_id: service_Data?.cart_details?.cart_id,
          cartdetail_id: service_Data?.cart_details?.id,
          uniquesessionid: uniquesessionid,
        })
      )
        .unwrap()
        .then((response) => {
          if (response?.DropPickupData?.data?.pickup_date?.length) {
            if (!response?.DropPickupData?.data?.pickup_date[0]?.error || response?.DropPickupData?.data?.pickup_date[0]?.value !== null) {
            } else {
              setNewError(response?.DropPickupData?.data?.pickup_date[0]?.error)
            }
          }
          if (response?.DropPickupData?.status === "success" && response?.DropPickupData?.code === '200') {

          }
        })
        .catch((error) => {
          if (error?.status === 429 || error?.status === 500) {
            CustomAlert(
              "Something went wrong! please try some time latter"
            );
          }
        });
    }
  }, [selectOpen])

  useEffect(() => {
    const countrydetails = JSON.parse(localStorage.getItem("searchData"));

    const serviceData = JSON.parse(localStorage.getItem("serviceData"));
    const useriD = JSON.parse(localStorage.getItem("user"));

    if (countrydetails) {
      setCountry_details(countrydetails);
    }
    if (serviceData) {
      setservice_Data(serviceData);
    }
    if (useriD) {
      setUser(useriD);
    }
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const useriD = JSON.parse(localStorage.getItem("user"));

    if (useriD) {
      dispatch(
        fetchAddressList({
          customer_id: useriD?.id,
          uniquesessionid: uniquesessionid,
        })
      );
      dispatch(
        fetchUserCardDetails({
          customer_id: useriD?.id,
          uniquesessionid: uniquesessionid,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (country_details?.fromCountryId) {
      const stateNameFrom = country_details?.fromCountryId;
      dispatch(fetchStateName(stateNameFrom))
        .unwrap()
        .then((response) => {
          if (response?.statusCode === 200 && response.status === true) {
            setFromStateData(response?.data)
          }
        })
    }
    if (country_details?.toCountryId) {
      const stateNameto = country_details?.toCountryId;
      dispatch(fetchStateName(stateNameto))
        .unwrap()
        .then((response) => {
          if (response?.statusCode === 200 && response.status === true) {
            setToStateData(response?.data)
          }
        })
    }
  }, [country_details?.fromCountryId, country_details?.toCountryId, dispatch]);

  const showContinueButtons = {
    changecollection: true,
    changedelivery: true,
    changeshipment: true,
    changepayment: true,
  };

  const handleAccordionToggle = (eventKey, e) => {
    e.preventDefault();
  };

  const handleContinueClick = (buttonName) => {
    setDeliveryValidations({
      delivery_validation: "",
      delivery_form: "",
    });
    setValidations({
      collection_validation: "",
      collection_form: "",
    });
    setShipDetailsError("");

    if (buttonName === "changecollection") {
      const serviceData = JSON.parse(localStorage.getItem("serviceData"));
      const cartPackage = serviceData?.cart_details?.cart_package;
      let idIndex = 0;
      const data1 = shipContent?.flatMap((item, index) => {
        return item?.parcel?.map((p, parcelIndex) => {
          const id = cartPackage[idIndex]?.id || null; // Get the ID from cartPackage
          idIndex++; // Increment the index for the next ID assignment
          return {
            contents: p.contents,
            ship_value: p.ship_value,
            id: id,
          };
        });
      });
      let data = {
        cartdetail_id: cartdetails_id,
        cart_id: cart_id,
        customer_id: user_id?.id,
        courier_id: serviceData?.courier_details?.courier_id,
        has_drop_off_service:
          selectedCollectionService === "Drop" ? "Yes" : "No",
        collection_date:
          selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew[0]?.label : "",
        collection_value:
          selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew[0]?.value : "",
        drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew[0] : "",
        insurance: serviceData?.cart_details?.edit && formShipData?.insuranceRadio,
        shipContent: serviceData?.cart_details?.edit && data1,
        channelType: serviceData?.channelType,
        channel_order_id: serviceData?.cart_details?.channel_order_id


      };
      dispatch(fetchShippingServices(data))
        .unwrap()
        .then((response) => {
          if (response.status === true && response.statusCode === 200) {
            window.scrollTo({
              top: 170,
              behavior: "smooth",
            });
            setShowChangeButton({
              changecollection: true,
              changedelivery: false,
              changeshipment: false,
              changepayment: false,
            });
            setActiveKey("1");
            localStorage.setItem("DropOffData", JSON.stringify(data));
          } else if (response.statusCode === 440 && response.status === false) {
            navigate("/service");
          }
        });

    }

    if (buttonName === "changedelivery") {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
      setShowChangeButton({
        changecollection: true,
        changedelivery: true,
        changeshipment: false,
        changepayment: false,
      });
      setActiveKey("2");
    }
    if (buttonName === "changeshipment") {
      window.scrollTo({
        top: 400,
        behavior: "smooth",
      });
      // navigate("/cartpreview");
      const data = shipContent.flatMap((item, index) => {
        const parcel = item.parcel.map((p) => ({
          contents: p.contents,
          ship_value: p.ship_value,
        }));
        return parcel;
      });
      function isInsuranceDataIncomplete() {
        return data?.some((item) => !item.contents || !item.ship_value);
      }
      if (!isInsuranceDataIncomplete()) {
        setShowChangeButton({
          changecollection: true,
          changedelivery: true,
          changeshipment: true,
          changepayment: false,
        });
        setActiveKey("3");
        setShipDetailsError("");
      } else {
        setShipDetailsError("Fill Shipment Details");
      }
    }
    if (buttonName === "finalgo") {
      const data = shipContent.flatMap((item, index) => {
        const parcel = item.parcel.map((p) => ({
          contents: p.contents,
          ship_value: p.ship_value,
        }));
        return parcel;
      });
      function isInsuranceDataIncomplete() {
        return data?.some((item) => !item.contents || !item.ship_value);
      }
      if (!isInsuranceDataIncomplete()) {
        setFinalGoLoader(true)
        const serviceData = JSON.parse(localStorage.getItem("serviceData"));
        const datad = country_Name?.find((i) => {
          return i?.name === vatdata?.countryInput;
        });
        const cartPackage = serviceData.cart_details.cart_package;

        let idIndex = 0; // Initialize index for assigning IDs
        const datas = shipContent.flatMap((item, index) => {
          return item.parcel.map((p, parcelIndex) => {
            const id = cartPackage[idIndex]?.id || null; // Get the ID from cartPackage
            idIndex++; // Increment the index for the next ID assignment
            return {
              contents: p.contents,
              ship_value: p.ship_value,
              id: id,
            };
          });
        });
        // const DropOffData = localStorage.getItem("DropOffData");
        const DropOffData = JSON.parse(localStorage.getItem("DropOffData"));

        let data = {
          cartdetail_id: cartdetails_id,
          cart_id: cart_id,
          courier_id: serviceData?.courier_details?.courier_id,
          customer_id: user_id?.id,
          is_completed: true,
          insurance: formShipData?.insuranceRadio,
          shipContent: datas,
          has_drop_off_service:
            selectedCollectionService === "Drop" ? "Yes" : "No",
          collection_date:
            selectedCollectionService !== "Drop" ? DropOffData?.collection_date : "",
          collection_value:
            selectedCollectionService !== "Drop" ? DropOffData?.collection_value : "",
          drop_off_date: selectedCollectionService === "Drop" ? DropOffData?.drop_off_date : "",
          is_batteries: formShipData.ups_one ? "Yes" : "",
          is_dry_ice: formShipData.ups_two ? "Yes" : "",
          is_signature: formShipData.ups_three ? "Yes" : "",
          has_customdoc: serviceData?.cart_details
            ?.collection_country_id === serviceData?.cart_details
              ?.delivery_country_id ? "No" : "Yes",
          channelType: serviceData?.channelType,
          channel_order_id: serviceData?.cart_details?.channel_order_id

          // is_cod: formShipData.ups_four ? "Yes" : "",
        };

        if (country_details?.fromCountryId !== country_details?.toCountryId) {
          data.reason_for_export = vatdata?.Reason;
          data.country_of_manufacture = datad?.id;
        }

        function isInsuranceDataIncomplete(datas) {
          return datas?.some((item) => !item.contents || !item.ship_value);
        }
        if (!isInsuranceDataIncomplete(datas)) {
          dispatch(fetchShippingServices(data))
            .unwrap()
            .then((response) => {
              setFinalGoLoader(false)
              if (response.status === true && response.statusCode === 200) {
                localStorage.removeItem("multiship_data");
                localStorage.removeItem("searchData");
                localStorage.removeItem("serviceData");
                localStorage.removeItem("DropOffData");
                localStorage.removeItem("cartdetail_id");

                navigate("/cartpreview");
              } else if (response?.statusCode === 440 && response?.status === false) {
                setFinalGoLoader(false)
                navigate("/service");
              } else if (response?.statusCode === 400 && response?.status === false) {
                setFinalGoLoader(false)
                // setShipDetailsError(response?.errors);
                // SwalFireComponent({ icon: "error", title: response?.errors });
                // CustomAlert(response?.errors);
                setShipDetailsError(response?.errors);


              }
            }).catch((error) => {
              setFinalGoLoader(false)
            });
          setActiveKey("3");
          setShipDetailsError("");
        } else {
          setFinalGoLoader(false)
          setShipDetailsError("Fill Shipment Details");
        }
      } else {
        setFinalGoLoader(false)
        setShipDetailsError("Fill Shipment Details");
      }
    }
  };

  const handleShowChange = (buttonName) => {
    if (buttonName === "changecollection") {
      window.scrollTo({
        top: 130,
        behavior: "smooth",
      });
      setShowChangeButton({
        changecollection: false,
        changedelivery: false,
        changeshipment: false,
        changepayment: false,
      });
      setActiveKey("0");
    }
    if (buttonName === "changedelivery") {
      window.scrollTo({
        top: 280,
        behavior: "smooth",
      });
      setShowChangeButton({
        changecollection: true,
        changedelivery: false,
        changeshipment: false,
        changepayment: false,
      });
      setActiveKey("1");
    }
    if (buttonName === "changeshipment") {
      window.scrollTo({
        top: 380,
        behavior: "smooth",
      });
      setShowChangeButton({
        changecollection: true,
        changedelivery: true,
        changeshipment: false,
        changepayment: false,
      });
      setActiveKey("2");
    }
    if (buttonName === "changepayment") {
      window.scrollTo({
        top: 500,
        behavior: "smooth",
      });
      setShowChangeButton({
        changecollection: true,
        changedelivery: true,
        changeshipment: true,
        changepayment: false,
      });
      setActiveKey("3");
    }
  };

  return (
    <>
      <div className="AccordianCollectionDelivery">
        <Accordion activeKey={activeKey} className="accordian-multi">
          <div className="d-flex gap-4 m-4 m-sm-0 s-md-0 s-lg-0  flex-column ">
            <div>
              <Accordion.Item
                eventKey="0"
                className="p-3 p-sm-4 p-lg-4 border-0"
              >
                <div className="gap-xxl-0  d-flex flex-column gap-2">
                  <Accordion.Header
                    onClick={(e) =>
                      handleAccordionToggle("changecollection", e)
                    }
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex w-100 d-flex align-items-center">
                        <div className="me-4">
                          <p
                            className="collectoinh5 blue-text"
                            style={{ width: "max-content" }}
                          >
                            <span>Collection Details</span>
                          </p>
                        </div>

                        {showChangeButton?.changecollection ? (
                          <div className="w-100 d-none d-sm-block d-md-block ">
                            <hr
                              className="w-auto"
                              style={{ border: "2px solid #bababa" }}
                            />
                          </div>
                        ) : (
                          <div className="w-100">
                            <hr
                              className="w-100"
                              style={{ border: "2px solid #bababa" }}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          showChangeButton?.changecollection ? "ms-4" : "ms-0"
                        }
                      >
                        {showChangeButton?.changecollection && (
                          <Button
                            className="address_btn border "
                            variant="link"
                            onClick={() => handleShowChange("changecollection")}
                          >
                            Change
                          </Button>
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  {showChangeButton?.changecollection && (
                    // <p>collection detail</p>
                    <div className="col align-items-center" variant="link">
                      <span>
                        {savedDataCollection.firstName}{" "}
                        {savedDataCollection.lastName},{" "}
                        {savedDataCollection.address01}{" "}
                        {savedDataCollection?.address02},{" "}
                        {savedDataCollection?.city},{" "}
                        {savedDataCollection?.state}-
                        {savedDataCollection?.Postcode}
                      </span>
                      <br />
                      {selectedCollectionService === "Pickup" && (
                        <span>
                          Pickup date : {collectionDates || pickupdatanew[0]?.label},&nbsp; Pickup Charge:
                          ${collectionValues || pickupdatanew[0]?.value}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <Accordion.Body className="p-0">
                  <AccordianCollection
                    isCollectionFormOpen={isCollectionFormOpen}
                    setIsCollectionFormOpen={setIsCollectionFormOpen}
                    savedDataCollection={savedDataCollection}
                    setSavedDataCollection={setSavedDataCollection}
                    country_data={country_details}
                    userId={user_id}
                    serviceData={service_Data}
                    addressListf={addressList}
                    formData={formData}
                    setFormData={setFormData}
                    setCollectionEventChange={setCollectionEventChange}
                    isCollectionValidate={isCollectionValidate}
                    setIsCollectionValidate={setIsCollectionValidate}
                    selectOpen={selectOpen}
                    setSelectOpen={setSelectOpen}
                    setValidations={setValidations}
                    validations={validations}
                    deliveryValidations={deliveryValidations}
                    setDeliveryValidations={setDeliveryValidations}
                    isCollectionValid={isCollectionValid}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    pickupValidation={pickupValidation}
                    setSelectedCollectionService={
                      setSelectedCollectionService
                    }
                    setPickupValidation={setPickupValidation}
                    setNewError={setNewError}
                    fromSteteData={fromSteteData}
                  />
                  {newError ? (
                    <> <p className="text-danger">
                      {newError}
                    </p></>
                  ) : (
                    <>
                      {selectOpen && (
                        <SelectDateTime
                          serviceData={service_Data}
                          shipContent={shipContent}
                          selectOpen={selectOpen}
                          setSelectOpen={setSelectOpen}
                          collectionDates={collectionDates}
                          setCollectionDates={setCollectionDates}
                          dropupDates={dropupDates}
                          setDropupDates={setDropupDates}
                          collectionValues={collectionValues}
                          setCollectionValues={setCollectionValues}
                          selectedCollectionService={selectedCollectionService}
                          setSelectedCollectionService={
                            setSelectedCollectionService
                          }
                          dropDates={dropDates}
                          setPickupValidation={setPickupValidation}
                          pickupDates={pickupLabels}
                          dropdates={dropOffLabels}
                          user_id={user_id}
                        />
                      )}
                    </>
                  )}
                  {/* {isPickupLoading !== false || isPickupLoading === undefined && (
                    <>
                      <Card className="card-border order-details-card">
                        <Card.Body>
                          <div className="d-flex gap-5 ">
                            <Box className="d-flex align-items-center  w-100 " style={{ flex: "4" }}>
                              <Skeleton height={40} className="w-75" />
                            </Box>
                            <Box className="d-flex align-items-center w-100 " style={{ flex: "1" }}>
                              <Skeleton height={40} className="w-100" />
                            </Box>
                          </div>
                          <div className="">
                            <Box className="d-flex align-items-center w-100 ">
                              <Skeleton height={10} className="w-100" />
                            </Box>
                          </div>
                          <div className="">
                            <Box className="d-flex align-items-center gap-1 w-50">
                              <Skeleton height={70} className="w-100" />
                              <Skeleton height={70} className="w-100" />
                            </Box>
                          </div>
                          <div className="w-100">
                            <div className="d-flex">
                              <div style={{ flex: "1" }}>
                                <Box className="d-flex gap-1 align-items-center">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                  />
                                  <Skeleton height={30} className="w-100" />
                                </Box>
                                <Box className="d-flex gap-1 align-items-center">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                  />
                                  <Skeleton height={30} className="w-100" />
                                </Box>
                                <Box className="d-flex gap-1 align-items-center">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                  />
                                  <Skeleton height={30} className="w-100" />
                                </Box>
                                <Box className="d-flex gap-1 align-items-center">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                  />
                                  <Skeleton height={30} className="w-100" />
                                </Box>
                              </div>
                              <div style={{ flex: "1" }}>
                                <Box className="d-flex justify-content-end">
                                  <Skeleton height={30} className="w-25" />
                                </Box>
                                <Box className="d-flex justify-content-end">
                                  <Skeleton height={30} className="w-25" />
                                </Box>
                                <Box className="d-flex justify-content-end">
                                  <Skeleton height={30} className="w-25" />
                                </Box>
                                <Box className="d-flex justify-content-end">
                                  <Skeleton height={30} className="w-25" />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </>
                  )} */}
                  <div className="d-flex justify-content-between align-items-center">
                    {/* {dropoffdata?.error?.error && selectedCollectionService === "Drop" && <div className="justify-content-start error-text">Please Select Pickup Date</div>} */}
                    <div className="justify-content-start error-text"></div>
                    <div className="d-flex justify-content-end">
                      {selectOpen && (
                        <Button
                          className="address_btn border "
                          onClick={() =>
                            handleContinueClick("changecollection")
                          }
                          variant="link"
                          disabled={dropoffdata?.error?.error && selectedCollectionService === "Drop"}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div>
              <Accordion.Item eventKey="1" className="p-3 border-0">
                <div className="gap-xxl-0  d-flex flex-column gap-2">
                  <Accordion.Header
                    onClick={(e) => handleAccordionToggle("changedelivery", e)}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex w-100">
                        <div className="me-4 d-flex align-items-center">
                          <p
                            className="collectoinh5 blue-text"
                            style={{ width: "max-content" }}
                          >
                            <span>Delivery Details</span>
                          </p>
                        </div>

                        {showChangeButton?.changedelivery ? (
                          <div className="w-100 d-none d-sm-block d-md-block">
                            <hr
                              className="w-auto"
                              style={{ border: "2px solid #bababa" }}
                            />
                          </div>
                        ) : (
                          <div className="w-100">
                            <hr
                              className="w-100"
                              style={{ border: "2px solid #bababa" }}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          showChangeButton?.changedelivery ? "ms-4" : "ms-0"
                        }
                      >
                        {showChangeButton?.changedelivery && (
                          <Button
                            className="address_btn border "
                            variant="link"
                            onClick={() => handleShowChange("changedelivery")}
                          >
                            Change
                          </Button>
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  {showChangeButton?.changedelivery && (
                    <span>
                      {savedDataDelivery.firstName} {savedDataDelivery.lastName}
                      , {savedDataDelivery.address01}{" "}
                      {savedDataDelivery?.address02}, {savedDataDelivery?.city},{" "}
                      {savedDataDelivery?.state}-{savedDataDelivery?.Postcode}
                    </span>
                  )}
                </div>
                <Accordion.Body className="p-0">
                  <AccordianDelivery
                    isFormOpen={isDeliveryFormOpen}
                    setIsFormOpen={setIsFormDeliveryOpen}
                    country_data={country_details}
                    userId={user_id}
                    serviceData={service_Data}
                    formDataDelivery={formDataDelivery}
                    setFormDataDelivery={setFormDataDelivery}
                    setDeliveryEventChange={setDeliveryEventChange}
                    addressListf={addressList}
                    setIsDeliveryValidate={setIsDeliveryValidate}
                    setValidations={setValidations}
                    setDeliveryValidations={setDeliveryValidations}
                    validations={validations}
                    setSelectedOptionDel={setSelectedOptionDel}
                    selectedOptionDel={selectedOptionDel}
                    setSavedDataDelivery={setSavedDataDelivery}
                    collectionDates={collectionDates}
                    setCollectionDates={setCollectionDates}
                    collectionValues={collectionValues}
                    selectedCollectionService={selectedCollectionService}
                    handleContinueClick={handleContinueClick}
                    toSteteData={toSteteData}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <div className="justify-content-start error-text">
                      <span>{deliveryValidations?.delivery_validation}</span>
                    </div> */}
                    {/* <div className="d-flex justify-content-end">
                      {showContinueButtons?.changedelivery && (
                        <Button
                          className="address_btn border "
                          onClick={() => handleContinueClick("changedelivery")}
                          variant="link"
                        >
                          Continue
                        </Button>
                      )}
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div>
              <Accordion.Item eventKey="2" className="p-3 border-0">
                <Accordion.Header
                  onClick={(e) => handleAccordionToggle("changeshipment", e)}
                >
                  <div className="d-flex justify-content-between w-100">
                    <div className="d-flex w-100">
                      <div className="me-4 d-flex align-items-center">
                        <p
                          className="collectoinh5 blue-text "
                          style={{ width: "max-content" }}
                        >
                          <span>Shipment Details</span>
                        </p>
                      </div>

                      {showChangeButton?.changeshipment ? (
                        <div className="w-100">
                          <hr
                            className="w-auto"
                            style={{ border: "2px solid #bababa" }}
                          />
                        </div>
                      ) : (
                        <div className="w-100">
                          <hr
                            className="w-100"
                            style={{ border: "2px solid #bababa" }}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        showChangeButton?.changeshipment ? "ms-4" : "ms-0"
                      }
                    >
                      {showChangeButton?.changeshipment && (
                        <Button
                          className="address_btn border "
                          variant="link"
                          onClick={() => handleShowChange("changeshipment")}
                        >
                          Change
                        </Button>
                      )}
                    </div>
                  </div>
                </Accordion.Header>
                {showChangeButton?.changeshipment && (
                  <div className="col">
                    <span>
                      Weight: {country_details.weight}, Length:{" "}
                      {country_details.length}, Width: {country_details.width}{" "}
                      Height: {country_details?.height},
                    </span>
                    <br />
                    {/* <span>
                      Parcel Content: {shipContent?.contents}, Parcel Value:
                      {shipContent?.ship_value}
                      {selectedRadio === "Yes" ? (
                        <span>
                          , Insurance Cost:
                          {shipContent?.ship_value > 100 ? (
                            `$${shipmentDataNew?.insurance_cost}`
                          ) : (
                            <span>free</span>
                          )}
                        </span>
                      ) : null}
                      {/* {measurement?.weight} {measurement?.weight_in} 
                    </span> */}
                  </div>
                )}
                <Accordion.Body className="p-0">
                  <AccordianShipmentDetails
                    Address_Details={Address_Details}
                    serviceData={service_Data}
                    formShipData={formShipData}
                    setFormShipData={setFormShipData}
                    shipContent={shipContent}
                    setShipContent={setShipContent}
                    selectedRadio={selectedRadio}
                    setSelectedRadio={setSelectedRadio}
                    ShipmentError={ShipmentError}
                    valueArray={valueArray}
                    setShipDetailsError={setShipDetailsError}
                    contentArray={contentArray}
                  />
                  {shipDetailsError ? (
                    <span className="error-text">{shipDetailsError}</span>
                  ) : (
                    ""
                  )}

                  <div className="d-flex justify-content-end">
                    {showContinueButtons?.changeshipment && (
                      <Button
                        className="address_btn border"
                        onClick={() =>
                          handleContinueClick(
                            serviceDatanew?.cart_details
                              ?.collection_country_id !== serviceDatanew?.cart_details
                                ?.delivery_country_id
                              ? "changeshipment"
                              : "finalgo"
                          )
                        }
                        variant="link"
                      >
                        Continue
                      </Button>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
            {/* serviceData?.cart_details
            ?.collection_country_id === serviceData?.cart_details
              ?.delivery_country_id */}
            {(serviceDatanew?.cart_details?.collection_country_id) !== (serviceDatanew?.cart_details?.delivery_country_id) && (
              <div>
                <Accordion.Item eventKey="3" className="p-3 border-0">
                  <Accordion.Header
                    onClick={(e) => handleAccordionToggle("changepayment", e)}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex w-100">
                        <div className="me-4 d-flex align-items-center">
                          <p
                            className="collectoinh5 blue-text d-flex align-items-center"
                            style={{ width: "max-content" }}
                          >
                            <span>Custom Details</span>
                          </p>
                        </div>

                        {showChangeButton?.changepayment ? (
                          <div className="w-100">
                            <hr
                              className="w-auto"
                              style={{ border: "2px solid #bababa" }}
                            />
                          </div>
                        ) : (
                          <div className="w-100">
                            <hr
                              className="w-100"
                              style={{ border: "2px solid #bababa" }}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          showChangeButton?.changepayment ? "ms-4" : "ms-0"
                        }
                      >
                        {showChangeButton?.changepayment && (
                          <Button
                            className="address_btn border "
                            variant="link"
                            onClick={() => handleShowChange("changepayment")}
                          >
                            Change
                          </Button>
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    {/* <OrderStripePayment /> */}
                    <AccordianCustomDetails
                      dropdowndata={dropdowndata}
                      vatdata={vatdata}
                      setVatdata={setVatdata}
                    />
                    {shipDetailsError ? (
                      <span className="error-text">{shipDetailsError}</span>
                    ) : (
                      ""
                    )}
                    <div className="d-flex justify-content-end mt-3">
                      {showContinueButtons?.changepayment && (
                        <Button
                          className="address_btn border"
                          onClick={() => handleContinueClick("finalgo")}
                          variant="link"
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )}
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default AccordianCollectionDelivery;
