import React, { useEffect, useState } from "react";
import "../../assets/styles/addressBook/selectDateTime.css";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import setHours from "date-fns/esm/setHours";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, setMinutes } from "date-fns";
import { fetchShippingServices } from "../../redux/services/ServicesThunk";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../common/CustomAlert";
import { useNavigate } from "react-router-dom";
import { resetServiceErrors } from "../../redux/services/ServicesSlice";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

const SelectDateTime = ({
  serviceData,
  selectOpen,
  setSelectOpen,
  collectionDates,
  setCollectionDates,
  dropupDates,
  setDropupDates,
  selectedCollectionService,
  setSelectedCollectionService,
  setPickupValidation,
  collectionValues,
  setCollectionValues,
  pickupDates,
  dropdates,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPickupLoading = useSelector(
    (state) => state?.servicedata?.isPickupLoading
  );
  const pickupdata = useSelector(
    (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.pickup_date
  );
  const dropoffdata = useSelector(
    (state) => state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location
  );
  const cityerror = useSelector((state) => state?.servicedata?.error);
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const useriD = JSON.parse(localStorage.getItem("user"));
  // const dropOffDates = JSON.parse(localStorage.getItem("dropOffDates"));
  const [selectOpenarrow, setSelectOpenarrow] = useState(true);
  // const toggleAccordion = () => {
  //   setSelectOpen(false);
  //   setSelectOpenarrow(!selectOpenarrow);
  // };

  const handleOptionChange = (option) => {
    // dispatch(())
    // dispatch(resetServiceErrors());
    setCollectionDates(pickupdata[0]?.label);
    setCollectionValues(pickupdata[0]?.value);
    setDropupDates(dropoffdata?.date[0])

    setSelectedCollectionService(option);
    // if (option === "Drop") {
    //   dispatch(
    //     fetchShippingServices({
    //       cart_id: serviceData?.cart_details?.cart_id,
    //       cartdetail_id: serviceData?.cart_details?.id,
    //       customer_id: useriD.id,
    //       // has_drop_off_service:
    //       //   selectedCollectionService === "Drop" ? "Yes" : "No",
    //       is_dropoff_data: "Yes",
    //       // collection_date:
    //       //   selectedCollectionService !== "Drop" ? collectionDates : "",
    //       // collection_value:
    //       //   selectedCollectionService !== "Drop" ? collectionValues : "",
    //       // drop_off_date: dropupDates,
    //       // is_pickup_rate: (selectedCollectionService && "Yes"),
    //       // insurance: shipContent?.insurance ? "Yes" : "No",
    //       // parcel_content: shipContent?.contents,
    //       // parcel_value: shipContent?.ship_value,
    //       uniquesessionid: uniquesessionid,
    //     })
    //   )
    //     .unwrap()
    //     .then((response) => {
    //       if (response.status === true && response.statusCode === 200) {
    //         let data = response?.data?.dropoff_location;
    //         if (data) {
    //           const dropOffDates = localStorage.setItem(
    //             "dropOffDates",
    //             JSON.stringify(data)
    //           );
    //         }

    //         setSelectOpen(true);
    //       }
    //       if (
    //         response.status === false &&
    //         response.statusCode === 400 &&
    //         response.error === "Session Time Out"
    //       ) {
    //         navigate("/");
    //       }
    //     })
    //     .catch((error) => {
    //       if (error?.status === 429 || error?.status === 500) {
    //         CustomAlert("Something went wrong! please try some time latter");
    //       }
    //     });
    // }
  };

  const handleDateChange = (e) => {
    setCollectionDates(e.target.value);
    setPickupValidation(true);
    let pickup_data = pickupdata?.filter((pickupvalue) => {
      return pickupvalue.label === e.target.value;
    });
    setCollectionValues(pickup_data[0]?.value);
  };
  const handleDropChange = (e) => {
    setDropupDates(e.target.value);
  };
  // useEffect(() => {
  //   dispatch(
  //     fetchShippingServices({
  //       cart_id: serviceData?.cart_details?.cart_id,
  //       cartdetail_id: serviceData?.cart_details?.id,
  //       customer_id: useriD.id,
  //       has_drop_off_service:
  //         selectedCollectionService === "Drop" ? "Yes" : "No",
  //       // is_dropoff_data: "Yes",
  //       collection_date:
  //         selectedCollectionService !== "Drop" ? collectionDates : "",
  //       collection_value:
  //         selectedCollectionService !== "Drop" ? collectionValues : "",
  //       drop_off_date: dropupDates,
  //       // is_pickup_rate: (selectedCollectionService && "Yes"),
  //       // insurance: shipContent?.insurance ? "Yes" : "No",
  //       // parcel_content: shipContent?.contents,
  //       // parcel_value: shipContent?.ship_value,
  //       uniquesessionid: uniquesessionid,
  //     })
  //   )
  //     .unwrap()
  //     .then((response) => {
  //       if (response.status === true && response.statusCode === 200) {
  //         let data = response?.data?.dropoff_location;
  //         if (data) {
  //           const dropOffDates = localStorage.setItem(
  //             "dropOffDates",
  //             JSON.stringify(data)
  //           );
  //         }
  //         setSelectOpen(true);
  //       }
  //       if (
  //         response.status === false &&
  //         response.statusCode === 400 &&
  //         response.error === "Session Time Out"
  //       ) {
  //         navigate("/");
  //       }
  //     })
  //     .catch((error) => {
  //       if (error?.status === 429 || error?.status === 500) {
  //         CustomAlert("Something went wrong! please try some time latter");
  //       }
  //     });
  // }, [selectedCollectionService, dropupDates, collectionDates]);
  const loading = true;
  return (
    <>
      <div className="selectdatetimecomponent pb-4">
        <div>
          <div className="selectdate-time-outer">
            <div className="selectdate-time-accordion">
              {dropoffdata?.date?.length || pickupdata?.length ? <Accordion defaultActiveKey={"0"} className="py-0">
                <Accordion.Item
                  eventKey={`${selectOpen ? "0" : "1"}`}
                  className="  shadow-none "
                >
                  <div className="select-accordion  d-flex flex-column  gap-4">
                    <Accordion.Header
                      className={`d-flex justify-content-between align-items-center  height-50 px-2 
                      open`}
                    // onClick={toggleAccordion}
                    >
                      <div className="d-none justify-content-between gap-4 w-100 px-3 d-lg-flex align-items-center">
                        <p
                          className={`mb-0  paragraph1 blue-text ${selectedCollectionService === "Pickup"
                            ? "label-pickup"
                            : "label-drop"
                            }`}
                        >
                          Select Date & Time for Pickup and Drop
                        </p>
                        <div className="dateselect-icon text-end">
                          <span className="collapse-icon px-2">
                            {/* <img
                              src={`${window.location.origin}/assets/${selectOpenarrow ? "dropupicon" : "dropdownicon"
                                }.svg`}
                              alt="Toggle Icon"
                            /> */}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between w-100 d-lg-none">
                        <div className="d-flex flex-column W-100 ps-2">
                          <div>
                            <p
                              className={`mb-0  paragraph1 blue-text me-3  ${selectedCollectionService === "Pickup"
                                ? "label-pickup"
                                : "label-drop"
                                }`}
                            >
                              Select Date & Time for  Pickup and Drop
                            </p>
                          </div>
                          {/* <div>
                            <p className="mb-0 paragraph1 blue-text ">
                             
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="p-0 shadow-none   d-flex flex-column  gap-4">
                      <>
                        <div className="d-flex pickup-drop-holder justify-content-center justify-content-lg-start px-0 px-lg-3">
                          <div className="drop-holder">
                            <input
                              type="radio"
                              className="btn-check"
                              name="options-outlined"
                              id="danger-outlined"
                              autoComplete="off"
                              checked={selectedCollectionService === "Drop"}
                              onChange={() => handleOptionChange("Drop")}
                            // disabled={true}
                            />
                            <label
                              className={`btn btn-outline-danger px-5 ${selectedCollectionService === "Drop"
                                ? "active-btn"
                                : ""
                                }`}
                              htmlFor="danger-outlined"
                            >
                              Drop
                            </label>
                          </div>
                          <div className="pickup-holder">
                            <input
                              type="radio"
                              className="btn-check"
                              name="options-outlined"
                              id="success-outlined"
                              autoComplete="off"
                              checked={selectedCollectionService === "Pickup"}
                              onChange={() => handleOptionChange("Pickup")}
                            />
                            <label
                              className={`btn btn-outline-success px-5 ${selectedCollectionService === "Pickup"
                                ? "active-btn"
                                : ""
                                }`}
                              htmlFor="success-outlined" >
                              Pickup
                            </label>
                          </div>
                        </div>

                        {selectedCollectionService === "Drop" && (
                          <div>
                            <Row className="mb-4 px-4">

                              <div className="d-flex justify-content-between">
                                <div style={{ flex: "1" }}>
                                  <div className="d-flex flex-column gap-2 select-date-time-label custom_radio">
                                    {/* <div className="">
                                  <p className="d-flex justify-content-center ">
                                    {" "}
                                    {dropOffDates.date}
                                  </p>
                                </div> */}

                                    {!dropoffdata?.error ? dropoffdata?.date?.map((date, index) => (
                                      <div className="col d-flex" key={index}>

                                        <input
                                          className="d-flex  align-items-center "
                                          type="radio"
                                          id={index}
                                          onChange={handleDropChange}
                                          value={`${date}`}
                                          checked={
                                            (dropupDates === "" || dropupDates === undefined) && index === 0 ||
                                            dropupDates === `${date}`
                                          }
                                        />
                                        <div className="select-date-label d-flex px-2 w-100 ">
                                          <label for={index}>
                                            <p className="paragraph2">{date}</p>
                                          </label>
                                        </div>
                                      </div>
                                    )) :
                                      <>
                                        <p className="error-text">
                                          {dropoffdata?.error?.error}
                                        </p>
                                      </>}

                                  </div>
                                </div>
                                {dropoffdata?.data && !dropoffdata?.error &&
                                  <div className="overflow-auto hide-scrollbar" style={{ flex: "2", height: "250px" }}>
                                    <h5 className="mb-3">Nearby dropoff location</h5>
                                    {dropoffdata?.data?.map((e, index) => {
                                      return <>
                                        {e?.company !== "" && <p><b>Store Name :</b> {e?.company}</p>}
                                        {e?.address && <p ><b>Address :</b> {e?.address},{e?.city},{e?.state}-{e?.postalcode}</p>}
                                        {e?.phonenumber && <p> <b>Ph. No : </b>{e?.phonenumber}</p>}
                                        {index !== dropoffdata.data.length - 1 && <hr />}
                                      </>
                                    })}
                                  </div>
                                }
                              </div>
                            </Row>
                          </div>
                        )}

                        {selectedCollectionService === "Pickup" && (
                          <div className="">
                            <Row className="mb-4 px-4">
                              <div className="d-flex select-date-time-label w-100 flex-column flex-lg-row">
                                <div className=" select-date-holder radio-holderaddress w-100">
                                  <div className="radiobtnAddress select-radiobtn d-flex custom_radio">
                                    <Form.Group className="w-100 d-flex align-items-center ">
                                      <div className="w-100 d-flex flex-column gap-2  ">
                                        {pickupdata &&
                                          pickupdata?.map((option, index) => (
                                            <div className="col d-flex">
                                              <input
                                                className="d-flex  align-items-center "
                                                key={index}
                                                type="radio"
                                                id={index}
                                                onChange={handleDateChange}
                                                value={`${option?.label}`}
                                                checked={
                                                  (collectionDates === "" || collectionDates === undefined) && index === 0 ||
                                                  collectionDates === `${option?.label}`
                                                }
                                              />
                                              <label for={index}>
                                                <div className="select-date-label d-flex px-2 w-100 ">
                                                  <p className="paragraph2">
                                                    {option?.label}
                                                  </p>
                                                  <p className="paragraph2 select-holder-value-label">
                                                    ${option?.value}
                                                  </p>
                                                </div>
                                              </label>
                                            </div>
                                          ))}
                                      </div>
                                    </Form.Group>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                            </Row>
                          </div>
                        )}
                      </>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>
              </Accordion>
                :
                <>
                  <Card className="card-border order-details-card">
                    <Card.Body>
                      <div className="d-flex gap-5 ">
                        <Box className="d-flex align-items-center  w-100 " style={{ flex: "4" }}>
                          <Skeleton height={40} className="w-75" />
                        </Box>
                        <Box className="d-flex align-items-center w-100 " style={{ flex: "1" }}>
                          <Skeleton height={40} className="w-100" />
                        </Box>
                      </div>
                      <div className="">
                        <Box className="d-flex align-items-center w-100 ">
                          <Skeleton height={10} className="w-100" />
                        </Box>
                      </div>
                      <div className="">
                        <Box className="d-flex align-items-center gap-1 w-50">
                          <Skeleton height={70} className="w-100" />
                          <Skeleton height={70} className="w-100" />
                        </Box>
                      </div>
                      <div className="w-100">
                        <div className="d-flex">
                          <div style={{ flex: "1" }}>
                            <Box className="d-flex gap-1 align-items-center">
                              <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                              />
                              <Skeleton height={30} className="w-100" />
                            </Box>
                            <Box className="d-flex gap-1 align-items-center">
                              <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                              />
                              <Skeleton height={30} className="w-100" />
                            </Box>
                            <Box className="d-flex gap-1 align-items-center">
                              <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                              />
                              <Skeleton height={30} className="w-100" />
                            </Box>
                            <Box className="d-flex gap-1 align-items-center">
                              <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                              />
                              <Skeleton height={30} className="w-100" />
                            </Box>
                          </div>
                          <div style={{ flex: "1" }}>
                            <Box className="d-flex justify-content-end">
                              <Skeleton height={30} className="w-25" />
                            </Box>
                            <Box className="d-flex justify-content-end">
                              <Skeleton height={30} className="w-25" />
                            </Box>
                            <Box className="d-flex justify-content-end">
                              <Skeleton height={30} className="w-25" />
                            </Box>
                            <Box className="d-flex justify-content-end">
                              <Skeleton height={30} className="w-25" />
                            </Box>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              }
            </div>
          </div >
        </div >

      </div >
    </>
  );
};

export default SelectDateTime;
