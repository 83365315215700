import React, { useEffect, useState } from "react";
import "../../assets/styles/servicePage.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetErrors } from "../../redux/landing/landingSlice";
import { Container, Card, Row, Col, Spinner } from "react-bootstrap";
import { fetchShippingServices } from "../../redux/services/ServicesThunk";
import { useModel } from "../../utils/modalContext";
import { deletecartdetail, fetchShippingRates } from "../../redux/landing/landingThunk";
import { Skeleton, Button } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const ServicePage = () => {
  const searchData = JSON.parse(localStorage.getItem("searchData"));
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const user_id = JSON.parse(localStorage.getItem("user"));
  const cart_id = localStorage.getItem("cart_id")
  const cartdetails_id = localStorage.getItem("cartdetail_id")

  const [cardId, setCardId] = useState(null);

  const Shipping_Ratesnew = useSelector(
    (state) => state.landingReducer?.landingShipServices?.data
  );
  const [Shipping_Rates, setShipping_Rates] = useState()
  const [id, setId] = useState({ ip_address: "", unique_address: "" })
  useEffect(() => {
    if (Shipping_Ratesnew) {
      setShipping_Rates(Shipping_Ratesnew)
    }
  }, [Shipping_Ratesnew])
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setId({ ip_address: res?.data?.ip })
    // localStorage.setItem("ipaddress", res?.data?.ip);
  };
  useEffect(() => {
    getData()
    const uniquesessionid = localStorage.getItem("uniquesessionid");
    if (!uniquesessionid) {
      const newUniqueID = uuidv4();
      localStorage.setItem("uniquesessionid", newUniqueID);
      setId({ unique_address: newUniqueID })
    }
  }, []);

  const isLoading = useSelector(
    (state) => state.landingReducer.isLoading?.isServicesLoading
  );

  const isServiceLoading = useSelector(
    (state) => state?.servicedata.isServiceLoading
  );

  const ServerError = useSelector(
    (state) => state?.landingReducer?.serverError?.status
  );

  const { setModalname, setShowAuthenticationModal } = useModel();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("cheap");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(resetErrors());
    // localStorage.removeItem("pickupDates");
    // localStorage.removeItem("dropOffDates");
    localStorage.removeItem("Address_Details");
    localStorage.removeItem("courierdata");
    localStorage.removeItem("serviceData");
    // localStorage.removeItem("multiship_data");

    localStorage.removeItem("orderConfirmData");
    localStorage.removeItem("cityName");
    if (cartdetails_id) {
      let data = {
        cart_id: cart_id,
        cartdetail_id: cartdetails_id,
        customer_id: user_id?.id
      }
      dispatch(deletecartdetail(data))
        .then((response) => {
          if (response?.payload?.status === true && response?.payload?.statusCode === 200) {
            localStorage.removeItem("cartdetail_id");
          }
        }).catch((error) => {
          if (error.status === 429) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time latter",
            });
          }
          if (ServerError === 500) {
            navigate("/server-error");
          }
        });
    }
  }, [cartdetails_id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(resetErrors());
    if (searchData) {
      setActiveButton("cheap");
      dispatch(
        fetchShippingRates({
          ...searchData,
          cheapest: "Yes",
          uniquesessionid: uniquesessionid,
        })
      )
        .then((response) => { })
        .catch((error) => {
          if (error.status === 429) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time latter",
            });
          }
          if (ServerError === 500) {
            navigate("/server-error");
          }
        });

    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Something went wrong! please try some time later",
        timer: 5000,
      }).then((result) => {
        if (
          result.isConfirmed ||
          result.dismiss === Swal.DismissReason.timer
        ) {
          navigate("/");
        }
      });
    }
    // }
  }, [dispatch]);

  const handleBookService = (e) => {
    if (user_id) {
      setCardId(e.courier_service_code);
      dispatch(
        fetchShippingServices({
          customer_id: user_id?.id,
          cart_id: cart_id ? cart_id : "",
          calculatedSubtotal: e.calculatedSubtotal,
          baseSubtotal: e.baseSubtotal,
          service_title: e.title,
          length: e.length,
          height: e.height,
          weight: e.weight,
          width: e.width,
          weight_in: e.weight_in,
          size_in: e.size_in,
          courier_name: e?.courier_name,
          courier_service_code: e?.courier_service_code,
          delivery_date: e?.delivery_date,
          customer_account_type: user_id?.account_type,
          // coupon_code: e?.coupon_code,
          courier_id: e?.courier_id,
          courier_service_name: e?.courier_service_name,
          fromPostalCode: e?.fromPostalCode,
          fromCountryCode: e?.fromCountryCode,
          toCountryCode: e?.toCountryCode,
          toPostalCode: e?.toPostalCode,
          qty: e?.qty,
          uniquesessionid: uniquesessionid,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.status === true && response.statusCode === 200) {
            localStorage.setItem("serviceData", JSON.stringify(response?.data));
            navigate("/Address");
          } else if (response.statusCode === 400 && response.status === false && response.res === 0) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: `${response?.errors}`,
              timer: 5000,
            }).then((result) => {
              if (
                result.isConfirmed ||
                result.dismiss === Swal.DismissReason.timer
              ) {
                navigate("/cartpreview");
                // window.location.reload()
              }
            });
          } else if (response.statusCode === 400 && response.status === false) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time later",
              timer: 5000,
            }).then((result) => {
              if (
                result.isConfirmed ||
                result.dismiss === Swal.DismissReason.timer
              ) {
                navigate("/");
                window.location.reload()
              }
            });
          } else if (response.statusCode === 440 && response.status === false) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time later",
              timer: 5000,
            }).then((result) => {
              if (
                result.isConfirmed ||
                result.dismiss === Swal.DismissReason.timer
              ) {
                // navigate("/");
                window.location.reload()
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Something went wrong! Please wait",
            timer: 5000,
          }).then((result) => {
            if (
              result.isConfirmed ||
              result.dismiss === Swal.DismissReason.timer
            ) {
              window.location.reload()
            }
          });
        });
    } else {
      setModalname("login");
      setShowAuthenticationModal(true);
    }
  };

  let sortingOptionsa = {};

  const dataSorting = (sortingType) => {
    setActiveButton(sortingType);

    if (sortingType === "cheap") {
      sortingOptionsa = {
        ...searchData,
        cheapest: "Yes",
        customer_id: user_id ? user_id.id : "",
      };
    } else if (sortingType === "fast") {
      sortingOptionsa = {
        ...searchData,
        fastest: "Yes",
        customer_id: user_id ? user_id.id : "",
      };
    } else if (sortingType === "all") {
      sortingOptionsa = {
        ...searchData,
        view_all: "Yes",
        customer_id: user_id ? user_id.id : "",
      };
    }

    dispatch(
      fetchShippingRates({
        ...sortingOptionsa,
        uniquesessionid: uniquesessionid,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.status === true && response.statusCode === 200) {
          setActiveButton(sortingType);
        }
        if (response.statusCode === 404) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Something went wrong! please Select another filter",
          });
          dispatch(
            fetchShippingRates({
              ...searchData,
              cheapest: "Yes",
              uniquesessionid: uniquesessionid,
            })
          )
            .then((response) => { })
            .catch((error) => {
              if (error.status === 429) {
                Swal.fire({
                  icon: "warning",
                  title: "Oops...",
                  text: "Something went wrong! please try some time latter",
                });
              }
              if (ServerError === 500) {
                navigate("/server-error");
              }
            });
        }
      })
      .catch((error) => {
        if (error.status === 429) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Something went wrong! please try some time latter",
          });
          navigate("/");
        }
        if (ServerError === 500) {
          navigate("/server-error");
        }
      });
  };
  return (
    <div className="service-holder-outer">
      <div className="service-holder pb-4 px-4 px-lg-0">
        <div className="service-title-holder d-none d-lg-block">
          <div className="service-page-banner-bg">
            <Container className="p-0">
              <div className="service-title align-items-center d-flex">
                <h4>Discover Our Exceptional Services, Compare and Save</h4>
              </div>
            </Container>
          </div>
        </div>
        <div className="service-container-hold container p-0">
          <div className="py-4">
            <div className="d-flex flex-column flex-lg-row choose-services px-lg-0 gap-3">
              <div
                className="sorting-filter d-flex justify-content-start"
                style={{ width: "-webkit-fill-available", flex: "6" }}
              >
                <div className="justify-content-start d-flex ">
                  <h5 className="services-title text-nowrap m-0">
                    Choose from the below services
                  </h5>
                </div>
                <div
                  className="hr-holder d-lg-none d-xl-flex d-xxl-flex"
                  style={{ width: "-webkit-fill-available" }}
                >
                  <div className="h-100 d-flex align-items-center w-100">
                    <hr
                      className="hrline-services ms-3 m-0"
                      style={{ width: "-webkit-fill-available" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="  d-flex flex-column flex-sm-row flex-md-row gap-3 justify-content-between justify-content-sm-between justify-content-md-start justify-content-lg-end "
                style={{ flex: "5" }}
              >
                <div
                  className="choose-buttons d-flex flex-nowrap gap-3 gap-2 gap-lg-3 "
                  style={{ flex: "3" }}
                >
                  <Button className={`view-all-btn btn w-100 ${activeButton === "cheap" ? "activebuttoncss w-100" : ""
                    }`}
                    onClick={() => dataSorting("cheap")}
                    style={{ height: "40px" }}
                    disabled={activeButton === "cheap" || isLoading}
                  >
                    {isLoading && activeButton === "cheap" ? (
                      <>
                        <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      <>Cheapest</>
                    )}
                  </Button>
                  <Button
                    className={`view-all-btn btn  w-100 ${activeButton === "fast" ? "activebuttoncss w-100" : ""
                      }`}
                    onClick={() => dataSorting("fast")}
                    style={{ height: "40px" }}
                    disabled={activeButton === "fast" || isLoading}
                  >
                    {isLoading && activeButton === "fast" ? (
                      <>
                        <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      <>Fastest</>
                    )}
                  </Button>
                  <Button
                    className={`view-all-btn btn w-100 text-nowrap ${activeButton === "all" ? "activebuttoncss w-100" : ""
                      }`}
                    onClick={() => dataSorting("all")}
                    style={{ height: "40px" }}
                    disabled={activeButton === "all" || isLoading}
                  >
                    {isLoading && activeButton === "all" ? (
                      <>
                        <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      <>View All</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {!isLoading ? (
            <div className="service-card-container">
              <Row className="cards-gap p-0">
                {Shipping_Rates?.length ?
                  Shipping_Rates?.map((card, index) => (
                    <Col
                      key={index}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      // xxl={1}
                      className="service-card-row "
                    >
                      <div className="d-flex flex-column gap-1 mb-3">
                        <Card
                          key={card.id}
                          className="service-card-holder w-100 w-auto border-0 order-details-card"
                        >
                          <div className="care-head-hold">
                            <div className="card-head px-4 pt-3">
                              <div className="logo-holder object-fit-contain">
                                <div className=" shipping-logo-img-div">
                                  <Card.Img
                                    className="shipping-logo"
                                    src={card?.cardlogo}
                                  />
                                </div>
                              </div>
                              <Card.Title className="d-flex align-content-center">
                                {card.title}
                              </Card.Title>
                              {/* <Card.Img
                                className="ratings"
                                variant="top"
                                src={card.ratingsSrc}
                              /> */}
                              {card?.delivery_date ? (
                                <p><b>
                                  By {card?.delivery_date} {(card?.delivery_date === 1 || +card?.delivery_date === 1) ? 'Business Day' : 'Business Days'}
                                </b></p>) :
                                <p><b>
                                </b></p>
                              }
                            </div>
                          </div>
                          <Card.Body className="card-body px-4 py-2 ">
                            {card.details.map((detail, i) => (
                              <Card.Text key={i} className="body-content mb-1">
                                <Card.Img
                                  variant="top"
                                  src={card.cardLogos[i]}
                                  className="me-2"
                                />
                                {detail}
                              </Card.Text>
                            ))}
                          </Card.Body>
                          <div className="px-4  d-flex pricesec-service">
                            <div className="" style={{ flex: "1" }}>
                              <Card.Text className="d-flex align-items-center text-nowrap gap-2 justify-content-start pb-2">
                                <div>
                                  <span className="w-auto body-content price-tag  ">
                                    {" "}
                                    <b>Amount:</b>
                                  </span>
                                </div>
                                <div className="w-auto d-flex justify-content-end align-items-center">
                                  <h5 className="disscount-price m-0">
                                    ${card.calculatedSubtotal}
                                  </h5>
                                  {card.showStrikePrice === "Yes" && <span className="real-price ms-1 text-decoration-line-through">
                                    <p className="paragraph2 ">
                                      ${card.baseSubtotal}
                                    </p>
                                  </span>}
                                </div>
                              </Card.Text>
                            </div>
                          </div>
                        </Card>
                        <div
                          className=" text-nowrap pb-2 "
                          style={{ flex: "4" }}
                        >
                          <Button className="book-service w-100 card-view-details border-0 fs-6 " style={{ height: "40px" }} onClick={() => handleBookService(card)}                          >
                            {isServiceLoading &&
                              card.courier_service_code === cardId ? (
                              <>
                                <Spinner animation="border" className="p-0" size="sm" />
                              </>
                            ) : (
                              <>
                                Book Service
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))
                  : <>
                    <div className="service-card-container d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                      <Row className="cards-gap">
                        {[1].map((index) => (
                          <>
                            <Col
                              key={index}
                              xs={12}
                              md={3}
                              className="service-card-row"
                            >
                              <Card key={index} className="service-card-holder w-100">
                                <div className="mx-2 py-2 mb-1">
                                  <Skeleton
                                    variant="rounded"
                                    height={150}
                                    className="shipping-logo-img-div"
                                  />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1 mb-2">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="mx-3">
                                  <Row className="d-flex">
                                    <Col className="mb-2">
                                      <Skeleton variant="text" />
                                    </Col>
                                    <Col>
                                      <Skeleton variant="text" />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </div>
                    <div className="service-card-container d-none d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none">
                      <Row className="cards-gap">
                        {[1, 2].map((index) => (
                          <>
                            <Col
                              key={index}
                              xs={12}
                              md={6}
                              className="service-card-row"
                            >
                              <Card key={index} className="service-card-holder w-100">
                                <div className="mx-2 py-2 mb-1">
                                  <Skeleton
                                    variant="rounded"
                                    height={150}
                                    className="shipping-logo-img-div"
                                  />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1 mb-2">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="mx-3">
                                  <Row className="d-flex">
                                    <Col className="mb-2">
                                      <Skeleton variant="text" />
                                    </Col>
                                    <Col>
                                      <Skeleton variant="text" />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </div>
                    {/* // screen above 1080*/}
                    <div className="service-card-container d-none d-sm-none d-md-none d-lg-block  d-xl-none d-xxl-none">
                      <Row className="cards-gap">
                        {[1, 2, 3].map((index) => (
                          <>
                            <Col
                              key={index}
                              xs={12}
                              md={4}
                              className="service-card-row"
                            >
                              <Card key={index} className="service-card-holder w-100">
                                <div className="mx-2 py-2 mb-1">
                                  <Skeleton
                                    variant="rounded"
                                    height={150}
                                    className="shipping-logo-img-div"
                                  />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1 mb-2">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="mx-3">
                                  <Row className="d-flex">
                                    <Col className="mb-2">
                                      <Skeleton variant="text" />
                                    </Col>
                                    <Col>
                                      <Skeleton variant="text" />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </div>
                    <div className="service-card-container d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                      <Row className="cards-gap">
                        {[1, 2, 3, 4].map((index) => (
                          <>
                            <Col
                              key={index}
                              xs={12}
                              md={3}
                              className="service-card-row"
                            >
                              <Card key={index} className="service-card-holder w-100">
                                <div className="mx-2 py-2 mb-1">
                                  <Skeleton
                                    variant="rounded"
                                    height={150}
                                    className="shipping-logo-img-div"
                                  />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="d-flex mx-3 py-1 mb-2">
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={15}
                                    className="mt-1"
                                  />
                                  <Skeleton variant="text" className="ms-4 w-100" />
                                </div>
                                <div className="mx-3">
                                  <Row className="d-flex">
                                    <Col className="mb-2">
                                      <Skeleton variant="text" />
                                    </Col>
                                    <Col>
                                      <Skeleton variant="text" />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </div>
                  </>}
              </Row>
            </div>
          ) : (
            <>
              <div className="service-card-container d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                <Row className="cards-gap">
                  {[1].map((index) => (
                    <>
                      <Col
                        key={index}
                        xs={12}
                        md={3}
                        className="service-card-row"
                      >
                        <Card key={index} className="service-card-holder w-100">
                          <div className="mx-2 py-2 mb-1">
                            <Skeleton
                              variant="rounded"
                              height={150}
                              className="shipping-logo-img-div"
                            />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1 mb-2">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="mx-3">
                            <Row className="d-flex">
                              <Col className="mb-2">
                                <Skeleton variant="text" />
                              </Col>
                              <Col>
                                <Skeleton variant="text" />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
              <div className="service-card-container d-none d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none">
                <Row className="cards-gap">
                  {[1, 2].map((index) => (
                    <>
                      <Col
                        key={index}
                        xs={12}
                        md={6}
                        className="service-card-row"
                      >
                        <Card key={index} className="service-card-holder w-100">
                          <div className="mx-2 py-2 mb-1">
                            <Skeleton
                              variant="rounded"
                              height={150}
                              className="shipping-logo-img-div"
                            />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1 mb-2">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="mx-3">
                            <Row className="d-flex">
                              <Col className="mb-2">
                                <Skeleton variant="text" />
                              </Col>
                              <Col>
                                <Skeleton variant="text" />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
              {/* // screen above 1080*/}
              <div className="service-card-container d-none d-sm-none d-md-none d-lg-block  d-xl-none d-xxl-none">
                <Row className="cards-gap">
                  {[1, 2, 3].map((index) => (
                    <>
                      <Col
                        key={index}
                        xs={12}
                        md={4}
                        className="service-card-row"
                      >
                        <Card key={index} className="service-card-holder w-100">
                          <div className="mx-2 py-2 mb-1">
                            <Skeleton
                              variant="rounded"
                              height={150}
                              className="shipping-logo-img-div"
                            />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1 mb-2">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="mx-3">
                            <Row className="d-flex">
                              <Col className="mb-2">
                                <Skeleton variant="text" />
                              </Col>
                              <Col>
                                <Skeleton variant="text" />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
              <div className="service-card-container d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                <Row className="cards-gap">
                  {[1, 2, 3, 4].map((index) => (
                    <>
                      <Col
                        key={index}
                        xs={12}
                        md={3}
                        className="service-card-row"
                      >
                        <Card key={index} className="service-card-holder w-100">
                          <div className="mx-2 py-2 mb-1">
                            <Skeleton
                              variant="rounded"
                              height={150}
                              className="shipping-logo-img-div"
                            />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="d-flex mx-3 py-1 mb-2">
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={15}
                              className="mt-1"
                            />
                            <Skeleton variant="text" className="ms-4 w-100" />
                          </div>
                          <div className="mx-3">
                            <Row className="d-flex">
                              <Col className="mb-2">
                                <Skeleton variant="text" />
                              </Col>
                              <Col>
                                <Skeleton variant="text" />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
