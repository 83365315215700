/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Card, Container, Image, Nav, Spinner, Tab, } from "react-bootstrap";
import { Box, Skeleton, Button } from "@mui/material";
import "../../../assets/styles/cartPreview/cartPreview.css";
import { IconButton, Tooltip } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getcart } from "../../../redux/user_profile/ProfileThunk";
import { deletecartdetail } from "../../../redux/landing/landingThunk";
import Swal from "sweetalert2";
import SwalFireComponent from "../../../components/common/SwalFireComponent";
import { formatPrice } from "../../../utils/formatPrice";
import { orderConfirm } from "../../../redux/payment/PaymentThunk";

const CartPreview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cartDatanew = useSelector(
        (state) => state?.profileData?.cartData?.cartData?.data?.cart_details
    );
    const iscartloading = useSelector(
        (state) => state?.profileData?.isCartDataLoading
    );
    const deleteCartLoader = useSelector(
        (state) => state?.landingReducer?.isDeleteCardLoading
    );
    const confirmorderloading = useSelector(
        (state) => state?.createPayment?.isorderConfirmLoading
    );
    const channel_Type = useSelector(
        (state) => state?.profileData?.cartData?.cartData?.data?.channel
    );
    const [activeTab, setActiveTab] = useState([0]);
    const userData = JSON.parse(localStorage.getItem("user"));
    const uniquesessionid = localStorage.getItem("uniquesessionid");
    const cartId = localStorage.getItem("cart_id");
    const [activebutton, setActiveButton] = useState(0);
    const [data, setData] = useState();
    const [cartData, setcartData] = useState();

    // useEffect(() => {
    //     let isredirect = JSON.parse(localStorage.getItem("order_data"))
    //     if (isredirect) {
    //         navigate("/orderpreview");
    //     }
    // }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("order_data");

        // if (Number(cartId) > 0 && userData?.id) {
        let data = {
            // cart_id: Number(cartId),
            customer_id: userData?.id,
        };
        dispatch(getcart(data));
        // } else {
        //     setcartData([])
        // }
        // if (!cartData) {
        // }
    }, [cartId, dispatch, userData?.id]);

    useEffect(() => {
        if (cartDatanew) {
            setcartData(cartDatanew);
            setActiveTab([0]);
            setActiveButton(0);
            setData(cartDatanew[0]);
        } else {
            setcartData()
        }
    }, [cartDatanew]);

    const handleTabClick = (index) => {
        if (!activeTab.includes(index)) {
            setActiveTab([index]);
            setActiveButton(index);
            setData(cartData[index]);
        }
    };

    const handleEditAndRemove = (event) => {
        let activedata = cartData[activebutton];
        if (event === "remove") {
            let finalactivedata = {
                cart_id: activedata?.cart_id,
                cartdetail_id: activedata?.id,
                customer_id: userData?.id,
            };
            dispatch(deletecartdetail(finalactivedata))
                .then((response) => {
                    let data = {
                        // cart_id: Number(cartId),
                        customer_id: userData?.id,
                    };
                    dispatch(getcart(data));
                    if (
                        response?.payload?.status === true &&
                        response?.payload?.statusCode === 200
                    ) {

                        SwalFireComponent({
                            icon: "success",
                            title: "Cart Remove Successfully",
                        });
                        localStorage.removeItem("cartdetail_id");
                    }
                })
                .catch((error) => {
                    let data = {
                        // cart_id: Number(cartId),
                        customer_id: userData?.id,
                    };
                    dispatch(getcart(data));
                });
        }
        if (event === "edit") {
            let multishipDataJSON = {
                height: [],
                length: [],
                qty: [],
                size_in: [],
                unit: [],
                weight: [],
                weight_in: [],
                width: []
            };

            activedata?.cart_package?.map((multiship, index) => {
                multishipDataJSON.height.push(multiship?.parcel_height.toString());
                multishipDataJSON.length.push(multiship?.parcel_length.toString());
                multishipDataJSON.qty.push('1');
                multishipDataJSON.size_in.push(multiship?.parcel_dimension_in.toString());
                // multishipDataJSON.unit.push("1");
                multishipDataJSON.weight.push(multiship?.parcel_weight.toString());
                multishipDataJSON.weight_in.push(multiship?.parcel_weight_measure);
                multishipDataJSON.width.push(multiship?.parcel_width.toString());
            });
            let searchDataJSON = {
                ...multishipDataJSON[0],
                customer_id: userData?.id,
                fromCountryCode: activedata.collection_country.code,
                fromCountryId: activedata.collection_country.id,
                fromPostalCode: activedata?.collection_postcode,
                fromcountry: activedata.collection_country.name,
                package_type: "Package",
                toCountryCode: activedata?.delivery_country?.code,
                toCountryId: activedata?.delivery_country?.id,
                toPostalCode: activedata?.delivery_postcode,
                tocountry: activedata?.delivery_country?.name,
                uniquesessionid: uniquesessionid,
                channelType: channel_Type,
                channel_order_id: activedata?.channel_order_id
            }
            let servicedataJson = {
                edit: true,
                base_price: activedata?.subtotal ? activedata?.subtotal : "",
                cardlogo: activedata?.cardlogo ? activedata?.cardlogo : "",
                cart_id: activedata?.cart_id ? activedata?.cart_id : "",
                cart_package: activedata?.cart_package,
                channel_order_id: activedata?.channel_order_id ? activedata?.channel_order_id : "",
                channelType: channel_Type,
                close_time: activedata?.close_time ? activedata?.close_time : "",
                collection_address1: activedata?.collection_address1 ? activedata?.collection_address1 : "",
                collection_address2: activedata?.collection_address2 ? activedata?.collection_address2 : "",
                collection_address_type: activedata?.collection_address_type ? activedata?.collection_address_type : "",
                collection_charge: activedata?.collection_charge ? activedata?.collection_charge : "",
                collection_city: activedata?.collection_city ? activedata?.collection_city : "",
                collection_company_name: activedata?.collection_company_name ? activedata?.collection_company_name : "",
                collection_conf_number: activedata?.collection_conf_number ? activedata?.collection_conf_number : "",
                collection_contact_extention: activedata?.collection_contact_extention ? activedata?.collection_contact_extention : "",
                collection_contact_no: activedata?.collection_contact_no ? activedata?.collection_contact_no : "",
                collection_country: activedata?.collection_country ? activedata?.collection_country : "",
                collection_country_id: activedata?.collection_country?.id ? activedata?.collection_country?.id : "",
                collection_date: activedata?.collection_date ? activedata?.collection_date : "",
                collection_email: activedata?.collection_email ? activedata?.collection_email : "",
                collection_first_name: activedata?.collection_first_name ? activedata?.collection_first_name : "",
                collection_last_name: activedata?.collection_last_name ? activedata?.collection_last_name : "",
                collection_name_prefix: activedata?.collection_name_prefix ? activedata?.collection_name_prefix : "",
                collection_postcode: activedata?.collection_postcode ? activedata?.collection_postcode : "",
                collection_state: activedata?.collection_state ? activedata?.collection_state : "",
                country_of_manufacture: activedata?.country_of_manufacture ? activedata?.country_of_manufacture : "",
                courier_id: activedata?.courier_id ? activedata?.courier_id : "",
                courier_service_code: activedata?.courier_service_code ? activedata?.courier_service_code : "",
                courier_service_description: activedata?.courier_service_description ? activedata?.courier_service_description : "",
                courier_service_name: activedata?.courier_service_name ? activedata?.courier_service_name : "",
                created_at: activedata?.created_at ? activedata?.created_at : "",
                delivery_address1: activedata?.delivery_address1 ? activedata?.delivery_address1 : "",
                delivery_address2: activedata?.delivery_address2 ? activedata?.delivery_address2 : "",
                delivery_address_type: activedata?.delivery_address_type ? activedata?.delivery_address_type : "",
                delivery_city: activedata?.delivery_city ? activedata?.delivery_city : "",
                delivery_company_name: activedata?.delivery_company_name ? activedata?.delivery_company_name : "",
                delivery_contact_extention: activedata?.delivery_contact_extention ? activedata?.delivery_contact_extention : "",
                delivery_contact_no: activedata?.delivery_contact_no ? activedata?.delivery_contact_no : "",
                delivery_country: activedata.delivery_country ? activedata.delivery_country : "",
                delivery_country_id: activedata.delivery_country.id ? activedata.delivery_country.id : "",
                delivery_date: activedata?.delivery_date ? activedata?.delivery_date : "",
                delivery_email: activedata?.delivery_email ? activedata?.delivery_email : "",
                delivery_first_name: activedata?.delivery_first_name ? activedata?.delivery_first_name : "",
                delivery_last_name: activedata?.delivery_last_name ? activedata?.delivery_last_name : "",
                delivery_name_prefix: activedata?.delivery_name_prefix ? activedata?.delivery_name_prefix : "",
                delivery_postcode: activedata?.delivery_postcode ? activedata?.delivery_postcode : "",
                delivery_state: activedata?.delivery_state ? activedata?.delivery_state : "",
                delivery_surcharge: activedata?.delivery_surcharge ? activedata?.delivery_surcharge : "",
                discount_amount: activedata?.discount_amount ? activedata?.discount_amount : "",
                enhanced_cover_cost: activedata?.enhanced_cover_cost ? activedata?.enhanced_cover_cost : "",
                has_customdoc: activedata?.has_customdoc ? activedata?.has_customdoc : "",
                has_drop_off_service: activedata?.has_drop_off_service ? activedata?.has_drop_off_service : "",
                id: activedata?.id ? activedata?.id : "",
                insurance_cost: activedata?.enhanced_cover_cost ? activedata?.enhanced_cover_cost : "",
                is_batteries: activedata?.is_batteries ? activedata?.is_batteries : "",
                is_dry_ice: activedata?.is_dry_ice ? activedata?.is_dry_ice : "",
                is_enhanced_cover: activedata?.is_enhanced_cover ? activedata?.is_enhanced_cover : "",
                is_signature: activedata?.is_signature ? activedata?.is_signature : "",
                quantity: activedata?.quantity ? activedata?.quantity : "",
                ratingsSrc: activedata?.ratingsSrc ? activedata?.ratingsSrc : "",
                ready_time: activedata?.ready_time ? activedata?.ready_time : "",
                reason_for_export: activedata?.reason_for_export ? activedata?.reason_for_export : "",
                return_address1: activedata?.return_address1 ? activedata?.return_address1 : "",
                return_address_type: activedata?.return_address_type ? activedata?.return_address_type : "",
                return_city: activedata?.return_city ? activedata?.return_city : "",
                return_company_name: activedata?.return_company_name ? activedata?.return_company_name : "",
                return_contact_extention: activedata?.return_contact_extention ? activedata?.return_contact_extention : "",
                return_contact_no: activedata?.return_contact_no ? activedata?.return_contact_no : "",
                return_country_id: activedata?.return_country_id ? activedata?.return_country_id : "",
                return_email: activedata?.return_email ? activedata?.return_email : "",
                return_first_name: activedata?.return_first_name ? activedata?.return_first_name : "",
                return_last_name: activedata?.return_last_name ? activedata?.return_last_name : "",
                return_name_prefix: activedata?.return_name_prefix ? activedata?.return_name_prefix : "",
                return_postcode: activedata?.return_postcode ? activedata?.return_postcode : "",
                return_state: activedata?.return_state ? activedata?.return_state : "",
                servicetype_id: activedata?.servicetype_id ? activedata?.servicetype_id : "",
                shipping_label_file: activedata?.shipping_label_file ? activedata?.shipping_label_file : "",
                status: activedata?.status ? activedata?.status : "",
                subtotal: activedata?.subtotal ? activedata?.subtotal : "",
                total: activedata?.total ? activedata?.total : "",
                updated_at: activedata?.updated_at ? activedata?.updated_at : "",
                vat_number: activedata?.vat_number ? activedata?.vat_number : "",
                vat_status: activedata?.vat_status ? activedata?.vat_status : "",
            }
            let Cart_Data = {
                cart_details: servicedataJson,
                channelType: channel_Type,
                channel_order_id: activedata?.channel_order_id


            };

            const multiship_data = JSON.stringify(multishipDataJSON);
            const searchData = JSON.stringify(searchDataJSON);
            const servicedata = JSON.stringify(Cart_Data);

            localStorage.setItem('multiship_data', multiship_data);
            localStorage.setItem('searchData', searchData);
            localStorage.setItem('serviceData', servicedata);
            navigate("/address")
        }

    };

    const handleaddNewClick = () => {
        navigate("/multishipment");
    };

    const handleOrderPreview = () => {
        navigate("/orderpreview");
        let dataorder = {
            // cart_id: Number(cartId),
            customer_id: userData?.id,
        };
        // dispatch(orderConfirm(dataorder))
        //     .unwrap()
        //     .then((response) => {
        //         if (response?.orderData?.code === 200 && response?.orderData?.status === "success") {
        //             localStorage.setItem("order_data", JSON.stringify(response.orderData.data))
        //         }
        //     });
    };

    return (
        <>
            <div className="pickupdrop-outer">
                <Container className="p-0">
                    {!iscartloading ? (
                        <>
                            {cartData?.length ? (
                                <>
                                    <div className="d-flex justify-content-center my-5">
                                        <div className="div1 d-flex gap-3 flex-column cart-outer px-0 px-sm-0">
                                            <div className="d-flex w-100 d-flex align-items-center ps-4 ps-sm-0">
                                                <div className="me-4">
                                                    <p className="collectoinh5 blue-text" style={{ width: "max-content" }}  >
                                                        <span>Review your shipments</span>
                                                    </p>
                                                </div>

                                                <div className="w-100 d-none d-sm-block d-md-block ">
                                                    <hr className="w-auto" style={{ border: "2px solid #bababa" }} />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <Card className="pricing-card-outer border-0 "
                                                    style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", }}  >
                                                    <Card.Body className="p-4">
                                                        <Tab.Container id="left-cartData-example">
                                                            <div className="gap-4 d-flex flex-column flex-sm-column flex-md-column flex-lg-row  flex-xl-row">
                                                                <div className="cart-btn-scroll" style={{ flex: "1" }}>
                                                                    <Nav variant="pills" className="flex-row flex-lg-column gap-3 cart-user-btn flex-nowrap">
                                                                        {cartData?.map((tab, index) => (
                                                                            <button key={index}
                                                                                className={`cart-user-btn btn btn-link p-0 border border-1 overflow-hidden ${activebutton === index ? "activebutton" : "notactive"}`} onClick={() => handleTabClick(index)}  >
                                                                                <Nav.Item>
                                                                                    <Nav.Link
                                                                                        eventKey={index}
                                                                                        className={`cart_btn w-100 px-4 cart-review-navlink bg-transparent ${activebutton === index ? "activebutton" : "notactive"}`}  >
                                                                                        {tab?.collection_country?.code} to{" "}
                                                                                        {tab?.delivery_country?.code}
                                                                                    </Nav.Link>
                                                                                </Nav.Item>
                                                                            </button>
                                                                        ))}
                                                                        <Button className={` primary_btn_link py-2 border border-1 overflow-hidden`} onClick={handleaddNewClick}  >
                                                                            {/* <Nav.Item> */}
                                                                            {/* <Nav.Link className='cart_btn w-100 px-4 cart-review-navlink bg-transparent'> */}
                                                                            Add New
                                                                            {/* </Nav.Link> */}
                                                                            {/* </Nav.Item> */}
                                                                        </Button>
                                                                    </Nav>
                                                                </div>
                                                                <div className="" style={{ flex: "4" }}>
                                                                    <Tab.Content>
                                                                        <div className="d-flex gap-3 justify-content-end me-0">
                                                                            <Tooltip
                                                                                title="Edit Details"
                                                                                className="pointer-icon"
                                                                            >
                                                                                <IconButton
                                                                                    className="p-0 cart-icons"
                                                                                    onClick={() =>
                                                                                        handleEditAndRemove("edit")
                                                                                    }
                                                                                >
                                                                                    <p>
                                                                                        {" "}
                                                                                        <img
                                                                                            className="pointer-icon"
                                                                                            alt="icon"
                                                                                            src={`${window.location.origin}/assets/address-icons/edit.svg`}
                                                                                        />
                                                                                    </p>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title="Remove Shipment"
                                                                                className="pointer-icon"
                                                                            >
                                                                                {!deleteCartLoader ? (
                                                                                    <IconButton
                                                                                        className="p-0 cart-icons"
                                                                                        onClick={() =>
                                                                                            handleEditAndRemove("remove")
                                                                                        }
                                                                                    >
                                                                                        <p>
                                                                                            {" "}
                                                                                            <img
                                                                                                className="pointer-icon"
                                                                                                alt="dlt_icon"
                                                                                                src={`${window.location.origin}/assets/address-icons/delete.svg`}
                                                                                            />
                                                                                        </p>
                                                                                    </IconButton>
                                                                                ) : (
                                                                                    <>
                                                                                        <IconButton className="p-0 cart-icons">
                                                                                            <p>
                                                                                                {" "}
                                                                                                <Spinner
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                />
                                                                                            </p>
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className="w-100 d-none d-sm-none d-md-none d-lg-block ">
                                                                            <hr
                                                                                className="w-auto m-1"
                                                                                style={{ border: "2px solid #bababa" }}
                                                                            />
                                                                        </div>
                                                                        {cartData?.length && (
                                                                            <>
                                                                                <div>
                                                                                    <>
                                                                                        <div className="d-flex gap-4 px-lg-0 px-0 flex-column flex-lg-row">
                                                                                            <div
                                                                                                className="d-flex gap-4 flex-column px-lg-0 px-0"
                                                                                                style={{ flex: "2" }}
                                                                                            >
                                                                                                <div className="d-flex gap-lg-5 gap-2 flex-column flex-sm-row flex-lg-row">
                                                                                                    <div className=" w-100">
                                                                                                        <div className="blue-text order-confirm-p-head">
                                                                                                            <p className="paragraph2 py-2">
                                                                                                                <b>
                                                                                                                    <span className="order-confirm-p-head paragraph2 blue-text">
                                                                                                                        Shipper Details:
                                                                                                                    </span>
                                                                                                                </b>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <hr className="payment-confirm-hrline w-100" />
                                                                                                        </div>
                                                                                                        <>
                                                                                                            <div className="order-confirm-p-head">
                                                                                                                <p className="paragraph2">
                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                        {
                                                                                                                            data?.collection_first_name
                                                                                                                        }{" "}
                                                                                                                        {
                                                                                                                            data?.collection_last_name
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="d-flex">
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {
                                                                                                                        data?.collection_address1
                                                                                                                    }
                                                                                                                </p>
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {
                                                                                                                        data?.collection_address2
                                                                                                                    }
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="d-flex">
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {
                                                                                                                        data?.collection_city
                                                                                                                    }
                                                                                                                </p>
                                                                                                                <p className="paragraph2 order-confirm-p-2 ms-1">
                                                                                                                    {
                                                                                                                        data?.collection_state
                                                                                                                    }
                                                                                                                </p>
                                                                                                                <p className="paragraph2 order-confirm-p-2 ms-1">
                                                                                                                    {
                                                                                                                        data?.collection_postcode
                                                                                                                    }
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="order-confirm-p-head">
                                                                                                                <p className="paragraph2">
                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                        Collection Between:
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {data?.collection_date}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    </div>
                                                                                                    <div className=" w-100">
                                                                                                        <div className="blue-text order-confirm-p-head py-2">
                                                                                                            <p className="paragraph2">
                                                                                                                <b>
                                                                                                                    <span className="order-confirm-p-head paragraph2 blue-text">
                                                                                                                        Receiver Details:
                                                                                                                    </span>
                                                                                                                </b>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <hr className="payment-confirm-hrline" />
                                                                                                        </div>
                                                                                                        <>
                                                                                                            <div className="order-confirm-p-head">
                                                                                                                <p className="paragraph2">
                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                        {
                                                                                                                            data?.delivery_first_name
                                                                                                                        }{" "}
                                                                                                                        {
                                                                                                                            data?.delivery_last_name
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="d-flex gap-2">
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {data?.delivery_address1}
                                                                                                                </p>
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {data?.delivery_address2}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="d-flex">
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    {
                                                                                                                        data?.delivery_city
                                                                                                                    }
                                                                                                                </p>
                                                                                                                <p className="paragraph2 order-confirm-p-2 ms-1">
                                                                                                                    {
                                                                                                                        data?.delivery_state
                                                                                                                    }
                                                                                                                </p>
                                                                                                                <p className="paragraph2 order-confirm-p-2 ms-1">
                                                                                                                    {
                                                                                                                        data?.delivery_postcode
                                                                                                                    }
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="order-confirm-p-head">
                                                                                                                <p className="paragraph2">
                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                        Expected Delivery:
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            {data?.delivery_date ? <div>
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    Up to {data?.delivery_date} Days
                                                                                                                </p>
                                                                                                            </div> : <div>
                                                                                                                <p className="paragraph2 order-confirm-p-2">
                                                                                                                    -
                                                                                                                </p>
                                                                                                            </div>}
                                                                                                        </>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex gap-lg-5 gap-2 flex-column flex-sm-row flex-lg-row">
                                                                                                    <div className=" w-100">
                                                                                                        <div className="blue-text order-confirm-p-head">
                                                                                                            <p className="paragraph2 py-2">
                                                                                                                <b>
                                                                                                                    <span className="order-confirm-p-head paragraph2 blue-text">
                                                                                                                        Shipment Details:
                                                                                                                    </span>
                                                                                                                </b>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <hr className="payment-confirm-hrline w-100" />
                                                                                                        </div>
                                                                                                        {data?.cart_package?.map(
                                                                                                            (
                                                                                                                packageItem,
                                                                                                                packageIndex
                                                                                                            ) => (
                                                                                                                <>
                                                                                                                    <div key={packageIndex}>
                                                                                                                        <>
                                                                                                                            <div className="order-confirm-p-head">
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Parcel No{" "}
                                                                                                                                        {packageIndex +
                                                                                                                                            1}
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="order-confirm-p-head d-flex gap-3">
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Weight:
                                                                                                                                    </span>
                                                                                                                                    <span className="paragraph2 order-confirm-p-2">
                                                                                                                                        {""}{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_weight
                                                                                                                                        }{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_weight_measure
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                                <p className="paragraph2">
                                                                                                                                    |
                                                                                                                                </p>
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Length:
                                                                                                                                    </span>
                                                                                                                                    <span className="paragraph2 order-confirm-p-2">
                                                                                                                                        {""}{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_length
                                                                                                                                        }{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_dimension_in
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="order-confirm-p-head d-flex gap-3">
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Width:
                                                                                                                                    </span>
                                                                                                                                    <span className="paragraph2 order-confirm-p-2">
                                                                                                                                        {""}{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_width
                                                                                                                                        }{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_dimension_in
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                                <p className="paragraph2">
                                                                                                                                    |
                                                                                                                                </p>
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Height:
                                                                                                                                    </span>
                                                                                                                                    <span className="paragraph2 order-confirm-p-2">
                                                                                                                                        {""}{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_height
                                                                                                                                        }{" "}
                                                                                                                                        {
                                                                                                                                            packageItem?.parcel_dimension_in
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                            </div>

                                                                                                                            <div className="order-confirm-p-head">
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Parcel Contents:
                                                                                                                                    </span>
                                                                                                                                    <span className="paragraph2 order-confirm-p-2">
                                                                                                                                        {""}{" "}
                                                                                                                                        {packageItem?.parcel_description}
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="order-confirm-p-head">
                                                                                                                                <p className="paragraph2">
                                                                                                                                    <span className="order-confirm-p-head paragraph2">
                                                                                                                                        Value:
                                                                                                                                    </span>
                                                                                                                                    <span className="paragraph2 order-confirm-p-2">
                                                                                                                                        {""}{" "}
                                                                                                                                        {formatPrice(
                                                                                                                                            packageItem?.value_of_items
                                                                                                                                        )}
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            {packageIndex !==
                                                                                                                                data.cart_package
                                                                                                                                    .length -
                                                                                                                                1 && (
                                                                                                                                    <div
                                                                                                                                        key={packageIndex}
                                                                                                                                    >
                                                                                                                                        <hr className="payment-confirm-hrline w-100" />
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                        </>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className=" w-100">
                                                                                                        <div className="blue-text order-confirm-p-head py-2">
                                                                                                            <p className="paragraph2">
                                                                                                                <b>
                                                                                                                    <span className="order-confirm-p-head paragraph2 blue-text">
                                                                                                                        Selected Service:
                                                                                                                    </span>
                                                                                                                </b>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <hr className="payment-confirm-hrline" />
                                                                                                        </div>
                                                                                                        <>
                                                                                                            <div className="usps py-2 mb-2 text-center">
                                                                                                                <Image
                                                                                                                    src={data?.cardlogo}
                                                                                                                    alt="service-logo"
                                                                                                                />
                                                                                                                <p className="order-confirm-p-2 paragraph2">
                                                                                                                    {
                                                                                                                        data?.courier_service_name
                                                                                                                    }
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <li className="d-flex justify-content-between align-items-center border-0 px-0">
                                                                                                                    <div>
                                                                                                                        <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                            Sub Total
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <span>
                                                                                                                        <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                            <b>
                                                                                                                                ${data?.subtotal}
                                                                                                                            </b>
                                                                                                                        </p>
                                                                                                                    </span>
                                                                                                                </li>
                                                                                                                {data?.collection_charge > 0 && (
                                                                                                                    <>
                                                                                                                        <li className="d-flex justify-content-between align-items-center border-0 px-0">
                                                                                                                            <div>
                                                                                                                                <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                                    Pickup Charge:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <span>
                                                                                                                                <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                                    <b>
                                                                                                                                        $
                                                                                                                                        {
                                                                                                                                            data?.collection_charge
                                                                                                                                        }
                                                                                                                                    </b>
                                                                                                                                </p>
                                                                                                                            </span>
                                                                                                                        </li>
                                                                                                                    </>
                                                                                                                )}

                                                                                                                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                                                                    <div>
                                                                                                                        <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                            TAX{" "}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <span>
                                                                                                                        <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                            <b>{formatPrice("0.00")}</b>
                                                                                                                        </p>
                                                                                                                    </span>
                                                                                                                </li>
                                                                                                                {data?.is_enhanced_cover ===
                                                                                                                    "Yes" && (
                                                                                                                        <>
                                                                                                                            <li className="list-group-item d-flex  align-items-center border-0 px-0">
                                                                                                                                <div className="d-flex">
                                                                                                                                    <div>
                                                                                                                                        <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                                            Insurance
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className=" d-flex justify-content-end insurance">
                                                                                                                                    <span>
                                                                                                                                        <p className="mb-0 paragraph2 order-confirm-p-2">
                                                                                                                                            <b>
                                                                                                                                                {data?.enhanced_cover_cost >
                                                                                                                                                    0 ? (
                                                                                                                                                    `$${data?.enhanced_cover_cost}`
                                                                                                                                                ) : (
                                                                                                                                                    <span>
                                                                                                                                                        Free
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                            </b>
                                                                                                                                        </p>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </li>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                <div>
                                                                                                                    <hr className="doted-hr m-1" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <li className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                                                                                                                        <div>
                                                                                                                            <span>
                                                                                                                                <p className="paragraph1 mb-0 p-0">
                                                                                                                                    <b>Total</b>
                                                                                                                                </p>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <span>
                                                                                                                                <p className="paragraph1 mb-0">
                                                                                                                                    <b>
                                                                                                                                        ${data?.total}
                                                                                                                                    </b>
                                                                                                                                </p>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Tab.Content>
                                                                </div>
                                                            </div>
                                                        </Tab.Container>
                                                    </Card.Body>
                                                </Card>
                                                <Card
                                                    className="border-0"
                                                    style={{
                                                        borderTopRightRadius: "0px",
                                                        borderTopLeftRadius: "0px",
                                                        borderTop: "1px solid #bababa",
                                                    }}
                                                >
                                                    <Card.Body className="p-4 d-flex justify-content-end">
                                                        <div className="cart-button-preview" style={{ flex: "1" }}>

                                                        </div>
                                                        <div className="d-flex justify-content-end  cart-processtopay" style={{ flex: "1", height: "40px" }}>
                                                            <Button className="primarybtn btn px-4 py-2 w-100 h-100 text-nowrap d-flex align-items-center" onClick={confirmorderloading ? null : handleOrderPreview}  >
                                                                {confirmorderloading ? (
                                                                    <>
                                                                        <Spinner animation="border" size="sm" />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Process to Pay
                                                                    </>
                                                                )}
                                                            </Button>

                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        {/* <div className="div1" style={{ flex: 1 }}>
                                    <div className="d-flex d-none d-lg-flex">
                                        <div className="me-3 d-flex align-items-center">
                                            <p className="collectoinh5 blue-text">
                                                <span>Price Details</span>
                                            </p>
                                        </div>
                                        <div className="hrlinedivdelivery">
                                            <hr className="hrlinedelivery" />
                                        </div>
                                    </div>
                                    <div className='d-flex gap-2 flex-column '>
                                        <div className="price-col py-2">
                                            <>
                                                <Card className="pricing-card-outer p-0 border-0 box-shadow">
                                                    <Card.Body className='p-4' >
                                                        <ul className="list-group list-group-flush pricing-card d-flex gap-2">
                                                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                <div>
                                                                    <p className="mb-0">Sub Total</p>
                                                                </div>
                                                                <span>
                                                                    <p className="mb-0">${Subtotal}</p>
                                                                </span>
                                                            </li>
                                                            {insurance > 0 || insurance !== null || insurance !== undefined ? (
                                                                <>
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                        <div>
                                                                            <p className="mb-0">Insurance</p>
                                                                        </div>
                                                                        <span>
                                                                            <p className="mb-0">${insurance}</p>
                                                                        </span>
                                                                    </li>
                                                                </>
                                                            ) : <></>}
                                                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                <div>
                                                                    <p className="mb-0"> Collection Charge:</p>
                                                                </div>
                                                                <span>
                                                                    <p className="mb-0"><b>${pickup}</b></p>
                                                                </span>
                                                            </li>
                                                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                <div>
                                                                    <p className="mb-0">Tax / Shipping Charge</p>
                                                                </div>
                                                                <span>
                                                                    <p className="mb-0"><b>Tax Free</b></p>
                                                                </span>
                                                            </li>
                                                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                                                <div>
                                                                    <p className="mb-0">Discount</p>
                                                                </div>
                                                                <span>
                                                                    <p className="mb-0">$3.58</p>
                                                                </span>
                                                            </li>
                                                            <div className="py-3 " style={{ height: "50px", flex: "2" }}>
                                                                <div className="d-flex input-group promo-input-border flex-nowrap" style={{ height: "50px" }}>
                                                                    <input style={{ borderRadius: "6px" }}
                                                                        type="text"
                                                                        className={`form-control promocode-input  px-3 position-relative  is-valid-paper`}
                                                                        name="coupen"
                                                                        placeholder="Enter Promocode"
                                                                        aria-label="Enter Promocode"
                                                                    />
                                                                    <span className="input-group-btn position-absolute " style={{ right: "5px", zIndex: "999", top: "4px", color: "#113468" }}>
                                                                        <button className="btn Promocode-btn py-2 border-0 blue-text"
                                                                            type="button" style={{ height: "40px" }} >
                                                                            <>
                                                                            </>
                                                                            <>
                                                                                Apply
                                                                            </>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                                <div className="justify-content-start d-flex">
                                                                    <div className=" d-flex justify-content-center">
                                                                        <p className="form-error-color">
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                                                <div className="total-price">
                                                                    <span>
                                                                        <p>Total</p>
                                                                    </span>
                                                                </div>
                                                                <div className="total-price">
                                                                    <span>
                                                                        <p>${Number(Total).toFixed(2)}</p>
                                                                    </span>

                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </Card.Body>
                                                </Card>
                                            </>
                                        </div>
                                        <div className='w-100 border border-2'>
                                            <Button className='primarybtn w-100 h-100 py-2'>Process To CheckOut</Button>
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="div1 w-100 d-flex gap-3 align-items-center justify-content-center"
                                        style={{ flex: 2, height: "70vh", flexDirection: "column" }}
                                    >
                                        <h1>Your Cart Is Empty</h1>
                                        <p>if you want to add service ? </p>
                                        <Button
                                            className="primarybtn btn px-5 py-2"
                                            onClick={handleaddNewClick}
                                        >
                                            Add Service Now
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="d-flex flex-column gap-1 gap-xxl-3 justify-content-center align-items-center">
                                <Card className="w-75 border-0 order-details-card my-5">
                                    <Card.Body>
                                        <div className="d-flex flex-column flex-sm-row flex-xxl-row gap-3">
                                            <div className="gap-md-0 align-items-center justify-content-end justify-content-md-start justify-content-lg-start d-none d-sm-none d-md-flex d-xxl-flex flex-md-column"
                                                style={{ flex: "1" }}  >
                                                <Box className="d-flex flex-row flex-md-column flex-xxl-column">
                                                    <Skeleton width={150} height={60} className="" />
                                                    <Skeleton width={150} height={60} className="" />
                                                    <Skeleton width={150} height={60} className="" />
                                                    <Skeleton width={150} height={60} className="" />
                                                </Box>
                                            </div>
                                            <div className=" d-flex flex-sm-column-reverse flex-md-column justify-content-start align-items-sm-start gap-3 gap-sm-0" style={{ flex: "2" }}  >
                                                <div className="w-100">
                                                    <Skeleton height={40} className="w-100" />
                                                    <Skeleton
                                                        variant="square"
                                                        height={150}
                                                        className="rounded w-100 load-img-skeleton"
                                                    />
                                                </div>
                                                <div className="w-100">
                                                    <Skeleton height={40} className="w-100" />
                                                    <Skeleton
                                                        variant="square"
                                                        height={150}
                                                        className="rounded w-100 load-img-skeleton"
                                                    />
                                                </div>
                                            </div>
                                            <div className=" d-flex flex-sm-column-reverse flex-md-column justify-content-start align-items-sm-start gap-3 gap-sm-0" style={{ flex: "2" }}  >
                                                <div className="w-100">
                                                    <Skeleton height={40} className="w-100" />
                                                    <Skeleton
                                                        variant="square"
                                                        height={150}
                                                        className="rounded w-100 load-img-skeleton"
                                                    />
                                                </div>
                                                <div className="w-100">
                                                    <Skeleton height={40} className="w-100" />
                                                    <Skeleton
                                                        variant="square"
                                                        height={150}
                                                        className="rounded w-100 load-img-skeleton"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="d-block d-sm-block d-lg-none border-0 rounded-3 card-view-details">
                                    <div className="d-flex flex-column justify-content-center gap-2">
                                        <div className="d-flex justify-content-center">
                                            <Skeleton height={50} className="w-75" />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default CartPreview;
