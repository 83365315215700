/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import EastIcon from "@mui/icons-material/East";
import "../../assets/styles/userProfile/userOrderDetails.css";
import UserMyOrderDetailsChildDetails from "./UserMyOrderDetailsChildDetails";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "../../redux/address/addressSlice";
import { Box, Pagination, Skeleton } from "@mui/material";
import { parse } from "date-fns";
import ReactDatePicker from "react-datepicker";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { orderDetails } from "../../redux/user_profile/ProfileThunk";
import { useNavigate, useParams } from "react-router-dom";

const UserMyOrderDetails = ({ setActiveSection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderList = useSelector(
    (state) => state.profileData?.orderDetailsData?.Orders?.data
  );
  const isOrderLoading = useSelector(
    (state) => state.profileData?.isOrderLoading
  );

  const user_id = JSON.parse(localStorage.getItem("user"));
  const [selectedCard, setSelectedCard] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePickerstart, setShowDatePickerstart] = useState(false);
  const [showDatePickerend, setShowDatePickerend] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 5;

  // Update current page when Pagination is clicked
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);


  const handleViewMore = (orderList) => {
    setSelectedCard(true);
    // setSelectedCard(selectedCard === orderList?.id ? null : orderList);
    navigate(`/user/myorders?${orderList?.id}`);
  };

  const getOrderStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "SUCCESSFUL PAYMENT":
        return "#C4E9C4";
      case "IN TRANSIT":
        return "#E6CDBB";
      case "FAILED":
        return "#FFC0C0";
      default:
        return "#C4E9C4";
    }
  };


  useEffect(() => {
    if (user_id) {
      dispatch(orderDetails(user_id?.id));
    }
    dispatch(resetErrors());

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [dispatch]);

  useEffect(() => {
    if (searchVal || endDate || startDate) {

      const updatedFilteredData = (orderList || []).filter((item) => {
        const orderDate = parseDate(item?.created_at);
        return (
          (!startDate || orderDate >= startDate) &&
          (!endDate || orderDate <= setEndOfDay(endDate)) &&
          (
            item?.id?.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
            item?.order?.lsn_reference?.toString().toLowerCase().includes(searchVal.toLowerCase())
          )
        );
      })
        .sort((a, b) => {
          const dateA = parseDate(a.created_at);
          const dateB = parseDate(b.created_at);
          return dateB - dateA;
        });
      setFilteredData(updatedFilteredData);
      setCurrentPage(1);
    } else {
      setFilteredData(orderList);

    }

  }, [startDate, endDate, orderList, searchVal]);


  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData?.slice(startIndex, endIndex);
  };

  const parseDate = (dateString) => {
    return parse(dateString, "yyyy-MM-dd h:mm:s", new Date());
  };

  const setEndOfDay = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  };

  const handleinput = (e) => {
    setSearchVal(e.target.value);
  };

  const filteredDataCount = filteredData?.length;

  const handleClear = () => {
    setSearchVal("");
  };

  const handleclearDate = (name) => {
    if (name === "startDate") {
      setStartDate("");
    }
    if (name === "endDate") {
      setEndDate("");
    }
  };

  const handleShowDatePicker = (name) => {
    if (name === "startDate") {
      setShowDatePickerstart(!showDatePickerstart);
      setShowDatePickerend(false);
    } else if (name === "endDate") {
      setShowDatePickerend(!showDatePickerend);
      setShowDatePickerstart(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleIconClick = (dateType) => {
    if (dateType === 'startDate') {
      startDatePickerRef.current.setOpen(true);
    } else if (dateType === 'endDate') {
      endDatePickerRef.current.setOpen(true);
    }
  };

  return (
    <>
      <div className="bord">
        {!isOrderLoading ? (
          <div>
            {!selectedCard ? (
              <>
                <div className="d-flex flex-column gap-2">
                  <Card className="border-0 pb-3 bg-transparent">
                    <div className="d-flex flex-column flex-md-row flax-xxl-row gap-3 ">
                      <div className="" style={{ flex: "1" }}>
                        <div className=" d-flex">
                          <div className="d-flex gap-2 my_orders w-75 my-order-search-input position-relative ">
                            <Form.Group
                              controlId="formGridfirstname "
                              className="w-100 d-flex"
                            >
                              <Form.Control
                                className="w-100  my-order-search-input my-order-input my-order-search-input my-order-search-input my-order-date"
                                type="text"
                                name="trackship"
                                placeholder="Search by order id / tracking id"
                                onChange={handleinput}
                                value={searchVal}
                              />
                              {searchVal.length > 0 && (
                                <img alt="icon"
                                  className="position-absolute z-3"
                                  style={{
                                    right: "15px",
                                    top: "15px",
                                    cursor: "pointer",
                                  }}
                                  src={`${window.location.origin}/assets/address-icons/close.svg`}
                                  onClick={handleClear}
                                />
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex gap-3 my-order-date-picker"
                        style={{ flex: "1" }}
                      >
                        <div className=" w-100" onClick={() => handleIconClick('startDate')}>
                          <div className=" w-100 my-order-date-picker position-relative">
                            <ReactDatePicker ref={startDatePickerRef}
                              className="py-1 m-0 myorder-datepicker my-order-search-input px-2 w-100"
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                              }}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              placeholderText="Select Start Date"
                              dateFormat="dd-MM-yyyy"
                            />
                            {startDate ? (
                              <>
                                <img alt="icon"
                                  className="position-absolute"
                                  style={{
                                    right: "15px",
                                    top: "15px",
                                    cursor: "pointer",
                                  }}
                                  src={`${window.location.origin}/assets/address-icons/close.svg`}
                                  onClick={() => handleclearDate("startDate")}
                                />
                              </>
                            ) : (
                              <>
                                <DateRangeOutlinedIcon
                                  className="bg-transparent position-absolute top-0 my-2 "
                                  style={{ color: "#113468", right: "5" }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className=" w-100" onClick={() => handleIconClick('endDate')}>
                          <div
                            className=" w-100 my-order-date-picker position-relative"
                            onClick={() => handleShowDatePicker("endDate")}
                          >
                            <ReactDatePicker
                              ref={endDatePickerRef}
                              className="py-1 m-0 myorder-datepicker my-order-search-input w-100 px-2"
                              selected={endDate}
                              onChange={(date) => {
                                setEndDate(date);
                              }}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              placeholderText="Select End Date"
                              dateFormat="dd-MM-yyyy"
                            />
                            {endDate ? (
                              <>
                                <img alt="icon"
                                  className="position-absolute"
                                  style={{
                                    right: "15px",
                                    top: "15px",
                                    cursor: "pointer",
                                  }}
                                  src={`${window.location.origin}/assets/address-icons/close.svg`}
                                  onClick={() => handleclearDate("endDate")}
                                />
                              </>
                            ) : (
                              <>
                                <DateRangeOutlinedIcon
                                  className="bg-transparent position-absolute top-0 my-2"
                                  style={{ color: "#113468", right: "5" }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  {/* misscde */}

                  {/* misscde */}
                  <div className="d-flex flex-column gap-3">
                    {orderList ? (
                      <>
                        {getCurrentPageData() && getCurrentPageData().length ? (
                          <div className="user-orderdetails d-flex  flex-column gap-4 ">
                            {getCurrentPageData().map((order, index) => (
                              <>
                                <div
                                  key={order?.id}
                                  className="d-flex flex-column gap-1 gap-lg-0"
                                >
                                  {/* {orderList?.order_details?.map((order, index) => ( */}
                                  <>
                                    <Card className="border-0 rounded-3 order-details-card">
                                      <Card.Body>
                                        <div className=" d-flex gap-2 gap-lg-4 gap-xl-5 gap-xxl-5align-items-lg-center justify-content-sm-between  align-items-start align-items-sm-center flex-column flex-sm-row flex-lg-row">
                                          <div className="h-100" style={{ flex: "1" }}  >
                                            <div className="align-items-center d-none d-sm-none d-lg-flex w-100">
                                              <div className="user-service-logo px-4 rounded-3 py-lg-2 py-xl-4 w-100">
                                                <div className="py-2 w-100 h-100 d-flex align-items-center">
                                                  <img
                                                    className="mx-auto d-lg-block img-fluid"
                                                    src={order?.cardlogo}
                                                    alt="logo"
                                                    style={{
                                                      maxWidth: "100%",
                                                      maxHeight: "100%",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div className="d-flex gap-3 d-sm-flex  flex-sm-column flex-sm-column-reverse d-lg-none">
                                              <div className="">
                                                <div className="user-service-logo px-2 rounded-3 py-1 h-auto align-items-center d-flex justify-content-center">
                                                  <div className="py-2">
                                                    <img
                                                      className="mx-auto d-block img-fluid"
                                                      src={
                                                        order?.cardlogo
                                                      }
                                                      alt="logo"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="d-flex d-sm-flex d-md-none align-items-center">
                                                <div
                                                  style={{ color: "#113468" }}
                                                  className="w-auto order-status-user"
                                                >
                                                  <p className="px-2 rounded-1"
                                                    style={{
                                                      width: "max-content",
                                                      fontWeight: "400",
                                                      fontSize: "12px",
                                                      backgroundColor:
                                                        getOrderStatusColor(order?.order?.order_status),
                                                    }}>
                                                    {order?.has_drop_off_service === "Yes" ? "Drop-off" : "Pickup"}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className=" d-flex  justify-content-between flex-column flex-lg-row flex-sm-row  gap-2 "
                                            style={{ flex: "5" }}
                                          >
                                            <div className="d-flex flex-column gap-3  gap-lg-2">
                                              <div className=" d-flex gap-0 gap-md-4 gap-lg-4 flex-column flex-sm-row flex-md-row flex-lg-row">
                                                <div className="d-flex flex-column flex-sm-row  gap-sm-3">
                                                  <div>
                                                    <p className="paragraph1" style={{ fontWeight: "500", }}>
                                                      {order?.courier_service_name}
                                                      <sup>®</sup>
                                                    </p>
                                                  </div>
                                                  <span className="d-none d-sm-block d-md-block  d-lg-block">
                                                    |
                                                  </span>
                                                  <div>
                                                    <p className="paragraph2 text-nowrap">{" "}
                                                      From{" "} {order?.collection_country?.iso_code}{" "}
                                                      To {"  "} {order?.delivery_country?.iso_code}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className=" ms-3 ms-sm-0 ">
                                                <ul className="m-0 p-0 ps-sm-4 ">
                                                  <li>
                                                    <p className="paragraph2">
                                                      Order ID: #
                                                      {order?.id}
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p className="paragraph2">
                                                      Order Date and Time:{" "}
                                                      {order?.order_created_at}
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p className="paragraph2">
                                                      Tracking ID: #
                                                      {
                                                        order?.order?.lsn_reference
                                                      }
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className=" d-flex d-sm-flex d-md-none gap-lg-4 gap-2 flex-column flex-sm-row flex-lg-row d-lg-flex">
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none d-flex align-items-center p-0 gap-2"
                                                    href={order?.shipping_file && order?.shipping_file}
                                                    target="_blank"
                                                    variant="link"
                                                    disabled={!order?.shipping_file}
                                                  >
                                                    <img alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dlabel.svg`}
                                                    />
                                                    <a className="paragraph2 text-decoration-none m-0" target="_blank"
                                                      style={{ color: "#6B6B6B", fontWeight: "300", }} >
                                                      Download Label
                                                    </a>
                                                  </Button>
                                                </div>
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none  d-flex align-items-center p-0 gap-2"
                                                    variant="link"
                                                    disabled={!order?.invoice_file}
                                                    // onClick={() => handleViewMore(order?.id)}
                                                    href={
                                                      order?.invoice_file
                                                      && order?.invoice_file

                                                    }
                                                    target="_blank"
                                                  >
                                                    <img alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dinvoice.svg`}
                                                    />
                                                    <a target="_blank" className="paragraph2 text-decoration-none m-0"
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300"
                                                      }}    >
                                                      Download Invoice
                                                    </a>
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-none d-sm-block d-md-flex  d-lg-flex  flex-column gap-4">
                                              <div className="d-flex flex-column  justify-content-center gap-2 gap-sm-5">
                                                <div className="d-none d-sm-none d-md-block d-lg-block">
                                                  <div
                                                    style={{ color: "#113468" }}
                                                    className="w-auto order-status-user"
                                                  >
                                                    <p className="px-2 rounded-1"
                                                      style={{
                                                        width: "max-content",
                                                        fontWeight: "400",
                                                        fontSize: "12px",
                                                        backgroundColor: getOrderStatusColor(order?.orderdetails?.order_status),
                                                      }}  >
                                                      {/* {order?.order?.order_status} */}
                                                      {order?.has_drop_off_service === "Yes" ? "Drop-off" : "Pickup"}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="d-none d-sm-none  d-lg-flex ">
                                                  <div className="d-flex justify-content-center">
                                                    {/* {selectedCard !== order?.id && ( */}
                                                    <Button
                                                      className="text-decoration-none d-flex gap-2"
                                                      variant="link"
                                                      onClick={() =>
                                                        handleViewMore(
                                                          order
                                                        )
                                                      }
                                                    >
                                                      <p
                                                        className="paragraph1 text-decoration-none m-0"
                                                        style={{
                                                          color: "#113468",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        View Details
                                                      </p>
                                                      <div
                                                        style={{
                                                          color: "#113468",
                                                        }}
                                                      >
                                                        <EastIcon />
                                                      </div>
                                                    </Button>
                                                    {/* )} */}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className=" d-flex d-sm-none d-md-flex d-lg-none gap-lg-4 gap-3 flex-column flex-sm-column">
                                                <div className="text-nowrap ">
                                                  <Button className="text-decoration-none d-flex align-items-center p-0 gap-2" variant="link"
                                                    disabled={order?.shipping_label_file === "null"} >
                                                    <img alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dlabel.svg`}
                                                    />
                                                    <a
                                                      className="paragraph2 text-decoration-none m-0"
                                                      href={order?.invoice_file ? order?.invoice_file : "https://letsshipnow.com"}
                                                      target="_blank"
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300",
                                                      }} rel="noreferrer"
                                                    >
                                                      Download Label
                                                    </a>
                                                  </Button>
                                                </div>
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none  d-flex align-items-center p-0 gap-2"
                                                    variant="link"
                                                  // onClick={() => handleViewMore(order?.id)}
                                                  >
                                                    <img alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dinvoice.svg`}
                                                    />
                                                    <a
                                                      href={
                                                        order?.label
                                                          ? order?.label
                                                          : "https://letsshipnow.com"
                                                      }
                                                      target="_blank"
                                                      className="paragraph2 text-decoration-none m-0"
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300",
                                                      }} rel="noreferrer"
                                                    >
                                                      Download Invoice
                                                    </a>
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                    <Card
                                      className="d-block d-sm-block d-lg-none border-0 rounded-3 card-view-details"
                                      onClick={() => handleViewMore(order)}
                                    >
                                      <div className="d-flex flex-column justify-content-center gap-2">
                                        <div className="d-none d-lg-block">
                                          <div
                                            style={{ color: "#113468" }}
                                            className="w-auto order-status-user"
                                          >
                                            <p
                                              className="px-2 rounded-1"
                                              style={{
                                                width: "max-content",
                                                fontWeight: "400",
                                                fontSize: "12px",
                                                backgroundColor:
                                                  getOrderStatusColor(
                                                    order?.order
                                                      ?.order_status
                                                  ),
                                              }}
                                            >
                                              {/* {
                                                order?.order
                                                  ?.order_status
                                              } */}
                                              {order?.has_drop_off_service === "Yes" ? "Drop-off" : "Pickup"}
                                            </p>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="d-flex justify-content-center">
                                            {selectedCard !==
                                              order?.id && (
                                                <Button
                                                  className="text-decoration-none d-flex gap-2"
                                                  variant="link"
                                                  onClick={() =>
                                                    handleViewMore(order)
                                                  }
                                                >
                                                  <p
                                                    className="paragraph1 text-decoration-none m-0"
                                                    style={{
                                                      color: "#113468",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    View Details tab
                                                  </p>
                                                  <div style={{ color: "#113468" }}>
                                                    <EastIcon />
                                                  </div>
                                                </Button>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </>
                                  {/* ))} */}
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          <>
                            <div className="user-orderdetails ">
                              <Card className="border-0 py-5">
                                <Card.Body>
                                  <p>
                                    No orders found. Adjust your search or date
                                    range for results..
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </>
                        )}
                        {filteredDataCount >= 5 && (
                          <div className=" d-flex justify-content-end">
                            <Pagination
                              count={Math.ceil(
                                filteredDataCount / itemsPerPage
                              )}
                              variant="outlined"
                              shape="rounded"
                              page={currentPage}
                              onChange={(event, page) => {
                                handlePageChange(event, page);
                                scrollToTop();
                              }}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="user-orderdetails ">
                          <Card className="border-0 py-5">
                            <Card.Body>
                              <p>No Order Found.......</p>
                            </Card.Body>
                          </Card>
                        </div>

                      </>
                    )}
                  </div>
                </div>
              </>
            ) :
              <UserMyOrderDetailsChildDetails
                getOrderStatusColor={getOrderStatusColor}
                item={selectedCard}
                setSelectedCard={setSelectedCard}
              />
            }
          </div>
        ) : (
          <>
            <div className="d-flex flex-column gap-1 gap-xxl-3">
              <div className="d-flex flex-column flex-md-row flex-lg-row flex-xxl-row justify-content-between gap-3 pb-3 pb-xl-3 pb-xxl-0">
                <div className="" style={{ flex: "3" }}>
                  <Skeleton
                    variant="square"
                    height={35}
                    className="rounded w-100"
                  />
                </div>
                <div
                  className=" d-none d-xxl-flex "
                  style={{ flex: "2" }}
                ></div>
                <div className="d-flex " style={{ flex: "4" }}>
                  <div className="d-flex w-100 gap-3">
                    <div className="" style={{ flex: "1" }}>
                      <Skeleton
                        variant="square"
                        height={35}
                        className="rounded w-100"
                      />
                    </div>
                    <div className="" style={{ flex: "1" }}>
                      <Skeleton
                        variant="square"
                        height={35}
                        className="rounded w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Card className="border-0 order-details-card">
                <Card.Body>
                  <div className="d-flex flex-column flex-sm-row flex-xxl-row gap-3">
                    <div
                      className=" d-flex flex-sm-column-reverse flex-md-column justify-content-start align-items-sm-start gap-3 gap-sm-0"
                      style={{ flex: "2" }}
                    >
                      <div className="w-100">
                        <Skeleton
                          variant="square"
                          height={150}
                          className="rounded w-100 load-img-skeleton"
                        />
                      </div>
                      <div className="w-100 d-flex d-sm-flex d-md-none d-xxl-none">
                        <Skeleton height={50} className="w-100" />
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column gap-2 "
                      style={{ flex: "4" }}
                    >
                      <div className=" d-flex">
                        <div
                          className=" d-flex gap-0 gap-sm-3 gap-lg-0 gap-xl-2 flex-column"
                          style={{ flex: "3" }}
                        >
                          <div className="d-flex">
                            <Skeleton height={40} className="w-100" />
                          </div>
                          <div className="d-flex flex-column gap-2 gap-sm-3 gap-lg-0 gap-xl-2">
                            <div>
                              <Box className="d-flex gap-1 align-items-center">
                                <Skeleton
                                  variant="circular"
                                  width={15}
                                  height={15}
                                />
                                <Skeleton height={20} className="w-100" />
                              </Box>
                              <Box className="d-flex gap-1 align-items-center">
                                <Skeleton
                                  variant="circular"
                                  width={15}
                                  height={15}
                                />
                                <Skeleton height={20} className="w-100" />
                              </Box>
                              <Box className="d-flex gap-1 align-items-center">
                                <Skeleton
                                  variant="circular"
                                  width={15}
                                  height={15}
                                />
                                <Skeleton height={20} className="w-100" />
                              </Box>
                            </div>
                            <div className="d-flex d-sm-flex d-md-none d-lg-flex flex-column flex-sm-row flex-xxl-row gap-0 gap-sm-3 gap-xxl-3">
                              <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                                <Skeleton
                                  variant="rectangular"
                                  width={25}
                                  height={25}
                                  className="rounded"
                                />
                                <Skeleton height={40} className="w-100" />
                              </Box>
                              <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                                <Skeleton
                                  variant="rectangular"
                                  width={25}
                                  height={25}
                                  className="rounded"
                                />
                                <Skeleton height={40} className="w-100" />
                              </Box>
                            </div>
                          </div>
                        </div>
                        <div
                          className=" d-none d-lg-none d-xxl-flex"
                          style={{ flex: "1" }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="gap-md-0 align-items-center justify-content-end justify-content-md-start justify-content-lg-center d-none d-sm-none d-md-flex d-xxl-flex flex-md-column"
                      style={{ flex: "2" }}
                    >
                      <Box className="d-flex flex-row flex-md-column flex-xxl-column gap-3">
                        <Skeleton width={150} height={50} className="" />
                        <Skeleton
                          width={150}
                          height={50}
                          className="d-md-none d-lg-flex"
                        />
                      </Box>
                      <div className="d-none d-sm-none d-md-flex d-lg-none flex-column w-100 gap-md-1">
                        <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                          <Skeleton
                            variant="rectangular"
                            width={25}
                            height={25}
                            className="rounded"
                          />
                          <Skeleton height={40} className="w-100" />
                        </Box>
                        <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                          <Skeleton
                            variant="rectangular"
                            width={25}
                            height={25}
                            className="rounded"
                          />
                          <Skeleton height={40} className="w-100" />
                        </Box>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="d-block d-sm-block d-lg-none border-0 rounded-3 card-view-details">
                <div className="d-flex flex-column justify-content-center gap-2">
                  <div className="d-flex justify-content-center">
                    <Skeleton height={50} className="w-75" />
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserMyOrderDetails;
