/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Spinner, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useModel } from "../../utils/modalContext";
import { fetchUserCardDetails } from "../../redux/address/adressThunk";
import AddnewCard from "./stripePayment/AddnewCard";
import FailedPayment from "./FailedPayment";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreateCharge } from "../../redux/payment/PaymentThunk";
import { UserWallet, UserWallettransaction } from "../../redux/wallet/walletThunk";
import { getuserdetail } from "../../redux/user_profile/ProfileThunk";
import { Button } from "@mui/material";
const OrderStripePayment = ({
  formData,
  setFormData,
  handleCloseModal,
  newcard,
}) => {
  const stripe = useStripe();
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const userid = JSON.parse(localStorage.getItem("user"));

  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoadingPayment = useSelector(
    (state) => state.createPayment.isLoadingPayment
  );

  const isWalletLoading = useSelector(
    (state) => state?.WalletData?.isWalletLoading
  )
  const failpayment = useSelector((state) => state.createPayment?.CreatePayment?.WalletCard?.errors?.stripe_payment_method[0]);
  const [billingAddress, setBillingAddress] = useState();
  const [agreedTosavecard, setAgreedTosavecard] = useState(false);
  const [cardError, setCardError] = useState();
  const [cardErrornew, setCardErrornew] = useState();
  const [showFailedPaymentModal, setShowFailedPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isLoadingPayment) {
      setLoading(isLoadingPayment)
    }
    if (isWalletLoading) {
      setLoading(isWalletLoading)
    }
  }, [isLoadingPayment, isWalletLoading])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (billingAddress === undefined) {
      setCardError("Please fill all the Payment fields");
    } else {
      setLoading(true)
      const { error, paymentMethod, card } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (!error && paymentMethod) {
        if (!newcard) {
          dispatch(
            UserWallet({
              customer_id: userid?.id,
              // card_id: selectedCard?.id,
              store: "topup",
              stripe_payment_method: paymentMethod?.id,
              wallet_amount: formData?.add_amount,
              is_save_card: agreedTosavecard ? "Yes" : "No",
            })
          )
            .unwrap()
            .then((response) => {
              if (
                response?.WalletCard?.status === "success" &&
                response?.WalletCard?.code === "200"
              ) {
                setLoading(false)
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Wallet credited successfully",
                  showCloseButton: true,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setFormData({
                  below_amount: "",
                  reload_amount: "",
                  add_amount: "",
                });
                handleCloseModal();
                dispatch(
                  UserWallettransaction({
                    customer_id: userid?.id,
                    store: "list",
                  })
                )
                dispatch(
                  getuserdetail({
                    customer_id: userid?.id,
                  })
                )
                dispatch(
                  fetchUserCardDetails({
                    customer_id: userid.id,
                    uniquesessionid: uniquesessionid,
                  })
                );
              } else {
                setCardErrornew(response?.WalletCard?.errors?.stripe_payment_method[0])
                setShowFailedPaymentModal(true);
              }
            })
            .catch((error) => {
              setShowFailedPaymentModal(true);
              console.error("order failed:", error);
            });
        } else {
          dispatch(
            CreateCharge({
              customer_id: userid?.id,
              // card_id: selectedCard?.id,
              stripe_payment_method: paymentMethod?.id,
              is_save_card: "Yes",
              store: "store",
            })
          )
            .unwrap()
            .then((response) => {
              if (
                response?.WalletCard?.status === "success" &&
                response?.WalletCard?.code === "200"
              ) {
                setLoading(false)
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Card added successfully",
                  showCloseButton: true,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(
                  fetchUserCardDetails({
                    customer_id: userid.id,
                    uniquesessionid: uniquesessionid,
                  })
                );
                handleCloseModal();
              } else {
                setCardErrornew(response?.WalletCard?.errors?.stripe_payment_method[0])
                setShowFailedPaymentModal(true);
              }
            })
            .catch((error) => {
              setShowFailedPaymentModal(true);
              console.error("order failed:", error);
            });
        }
      }
      else if (error) {
        setLoading(false)
        setCardErrornew(error?.message)
        setShowFailedPaymentModal(true);

      }
    }
  };
  const handleTryAgain = () => {
    setShowFailedPaymentModal(false);
    setLoading(false)
  };
  const handleshowmodel = () => {
    setShowFailedPaymentModal(false);
    setLoading(false)

  };

  return (
    <>
      <div className="order-stripe-payment">
        <div className="">
          <div>
            <div className="">
              <Card className="p-0 card-border-remove">
                <Card.Body className="p-0 d-flex flex-column gap-3">
                  <>
                    <div className="p-0">
                      <Card.Body className="p-0">
                        <>
                          <div style={{ marginBottom: "15px" }}>
                            <AddnewCard
                              setCardError={setCardError}
                              setBillingAddress={setBillingAddress}
                            />
                          </div>
                          <fieldset className="FormGroup col-md-12 col-lg-12 sm-4">
                            <div className="FormRow order-input  col-md-12 col-lg-12 sm-12 bg-white input-order-payment">
                              <CardElement className="px-2 mx-auto cardorder-input" />
                            </div>
                          </fieldset>
                        </>
                      </Card.Body>
                    </div>

                    <Card.Body className="p-0">
                      <>
                        <div>
                          <div>
                            <>
                              <Form onSubmit={handleSubmit}>
                                <p className="error-text">{cardError}</p>
                                {!newcard && (
                                  <Col className="d-flex chexbx mb-2 justify-content-start align-items-center">
                                    <Form.Check
                                      type="checkbox"
                                      id="agreetosavecard"
                                      name="agreetosavecard"
                                      onChange={() =>
                                        setAgreedTosavecard(!agreedTosavecard)
                                      }
                                      style={{ marginTop: "5px" }}
                                      className="me-2"
                                    />
                                    <div className="price-card-read d-flex">
                                      Save Card For Future Payment
                                    </div>
                                  </Col>
                                )}
                                <div className="col-md-12 col-lg-12 sm-4">
                                  <Button className="confirm-btn btn primarybtn h-100 input-main-all"
                                    type="submit" onClick={!loading ? handleSubmit : undefined}    >
                                    {!loading ? (
                                      <>
                                        Confirm & Pay
                                      </>
                                    ) : (
                                      <>
                                        <Spinner animation="border" size="sm" />
                                      </>
                                    )}
                                  </Button>

                                </div>
                              </Form>
                            </>
                          </div>
                        </div>
                      </>
                    </Card.Body>

                    {/* <FailedPayment
                      showFailedPaymentModal={showFailedPaymentModal}
                      setShowFailedPaymentModal={setShowFailedPaymentModal}
                      failpayment={cardErrornew}
                    /> */}
                    <Modal show={showFailedPaymentModal}
                      onHide={() => handleshowmodel()}
                      backdrop="static"
                      keyboard={false}
                      className='failed-payment-modal'>

                      <Modal.Body className='mt-2 mt-lg-5 p-0'>
                        <div className='px-4 px-lg-5 py-4 py-lg-5 d-flex flex-column-reverse flex-lg-row gap-2' style={{ backgroundColor: "white", borderRadius: "10px" }}>
                          <div className='failedpayment-text-left d-flex flex-column gap-2 justify-content-center' style={{ flex: "3" }}>
                            <div className=' blue-text'>
                              <h4 className='m-0'>Payment failed</h4>
                            </div>
                            <div className=' blue-text fail-payment-text'>
                              <p className='paragraph2'>We apologize, but it seems there was an issue with processing your payment. Kindly review the payment information and card details you provided.</p>
                            </div>
                            {cardErrornew && <div className=' blue-text fail-payment-text ' >
                              <p className='paragraph2' style={{ color: 'red' }}>{cardErrornew}</p>
                            </div>}
                            <div className='mt-2 mt-lg-5'>
                              <Button className='primarybtn btn tryagainbtn px-4 py-2' onClick={handleTryAgain}>
                                Try Again
                              </Button>
                            </div>
                          </div>
                          <div className='payment-fail-image ' style={{ flex: "2" }}>
                            <div style={{ height: "100%" }}>
                              <img className='img-fluid' src={`${window.location.origin}/assets/payment_failed.svg`} alt="Payment Failed" />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderStripePayment;
